import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentDiscount, setPaymentTax, setPaymentTotal } from "store/vendor/vendorSlice";
import CardsHeaderPos from "./CardsHeaderPos";
import CardsProducts from "./CardsProducts/CardsProducts";
import DetailsOrder from "./DetailsOrder/PosCartDetails";
import "./Pos.css";

const Pos = () => {
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.vendor.cartDetails);
  const offers = useSelector((state) => state.vendor.offers);
  const cartServices = cartDetails.services;

  const detailsPay = useMemo(() => {
    const total = cartServices.reduce((acc, service) => acc + parseFloat(service.price), 0);
    const tax = total * 0.1;
    const paymentDetails = [
      { title: "المجموع", price: total.toFixed(2) },
      { title: "الضريبة", price: tax.toFixed(2) },
    ];

    let totalDiscount = 0;
    if (offers.length > 0) {
      const offer = offers[0]; // Only apply the first offer
      const applicableServices = cartServices.filter((cartService) =>
        offer.services.some((service) => service.id === cartService.serviceId)
      );

      if (applicableServices.length > 0) {
        const offerDiscount = applicableServices.reduce(
          (acc, service) => acc + (parseFloat(service.price) * parseFloat(offer.discount_percentage)) / 100,
          0
        );

        totalDiscount = offerDiscount; // Set totalDiscount directly instead of adding
        paymentDetails.push({
          title: offer.name_ar,
          price: `-${offerDiscount.toFixed(2)}`,
          newClassText: "text-danger",
        });
      }
    }

    const finalTotal = total + tax - totalDiscount;
    paymentDetails.push({ title: "الأجمالي", price: finalTotal.toFixed(2), newClass: "border-top border-2" });

    // Dispatch payment-related actions only when values change
    dispatch(setPaymentTotal(total));
    dispatch(setPaymentTax(tax));
    dispatch(setPaymentDiscount(totalDiscount));

    return paymentDetails;
  }, [cartServices, offers, dispatch]);

  if (cartDetails.length === 0) return null;
  return (
    <>
      <div data-aos="fade-left">
        <HeaderPageInfo title={"نقطة البيع"} isShowLeftContent={false} contentPageLeft={false} />
      </div>

      <div className="all-pos">
        <div className="row g-3">
          <div className="col-12 col-md-8 col-lg-12 col-xl-8">
            <div className="right-info-pos">
              <CardsHeaderPos />
              <CardsProducts />
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-12 col-xl-4">
            <DetailsOrder detailsPay={detailsPay} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Pos;
