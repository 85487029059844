import PageTilte from "Components/PageTilte/PageTilte";
import "./Notices.css";
import NoticeCard from "Components/Ui/NoticeCard/NoticeCard";
import iconBell from "../../assets/images/icons/bell.svg";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";

const Notices = () => {
  const cardNotices = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
  return (
    <>
    <HelmetInfo titlePage={"الأشعارات"} />
      <PageTilte titlePage={"الأشعارات"} />
      <main>
        <div className="notices padding-bottom-70">
          {/* =========== START CONTIANER ========== */}
          <div className="container">
            <div className="all-notices" data-aos="fade-up">
              <div className="row g-3">
                {cardNotices.map((item) => {
                  return (
                    <div className="col-12 col-card" key={item.id}>
                      {/* ========= START CARD NOTICE ============ */}
                      <NoticeCard
                        title={"تم قبول طلبك رقم 1456"}
                        routeLink={"/"}
                        linkText={
                          "https://www.google.com/maps/@30.4721589,31.3427256,15z?authuser"
                        }
                        textInfoDate={" 01:00 PM  06/06/2023"}
                        icon={iconBell}
                      />
                      {/* ========= EN CARD NOTICE ============ */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* =========== END CONTIANER ========== */}
        </div>
      </main>
    </>
  );
};

export default Notices;
