import { Link } from "react-router-dom";
import iconSuccess from "../../../../../assets/images/animation/Failed.gif";
import logo from "../../../../../assets/images/logo/logo.svg";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";

const ErrorPay = () => {
  return (
    <>
      <HelmetInfo titlePage={"فشلت العملية"} />
      <div className="logo-header pt-3">
        <div className="container">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </div>
      <div className="all-page-success-error d-flex  justify-content-center  align-items-center  text-center">
        <div className="content-info">
          <div className="icon-sucess">
            <img
              src={iconSuccess}
              alt="icon"
              width={"200px"}
              height={"200px"}
              className=" object-fit-cover "
            />
          </div>
          <div className="info-content">
            <h2 className="title failed-error">فشلت العملية</h2>
            <p className="text">لم ينجح الأمر، حاول مرة اخري</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPay;
