import { unwrapResult } from "@reduxjs/toolkit";
import * as echarts from "echarts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookingCountChart } from "store/admin/adminActions";
import { getBookingCountChart as getVendorBookingCountChart } from "store/vendor/vendorActions";
import "./ChartOne.css";

const ChartTwo = () => {
  const [chartData, setChartData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("month"); // Default period is 'day'
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const role = useSelector((state) => state.auth.role);

  const fetchData = async () => {
    dispatch(getBookingCountChart(selectedPeriod))
      .then(unwrapResult)
      .then((res) => {
        const formattedChartData = [];
        res.data.forEach((item) => formattedChartData.push([item.x_key, item.count]));
        setChartData(formattedChartData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchVendorData = async () => {
    dispatch(getVendorBookingCountChart(selectedPeriod))
      .then(unwrapResult)
      .then((res) => {
        const formattedChartData = [];
        res.data.forEach((item) => formattedChartData.push([item.x_key, item.total]));
        setChartData(formattedChartData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (selectedPeriod) {
      setIsLoading(true);
      if (role === "admin") {
        fetchData();
      } else {
        fetchVendorData();
      }
    }
  }, [selectedPeriod]);

  useEffect(() => {
    // Initialize ECharts
    const chartDom = document.getElementById("myChart2");
    const myChart2 = echarts.init(chartDom);

    // Define chart options
    const options = {
      title: {
        text: "عدد الحجوزات",
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        data:
          selectedPeriod === "month"
            ? ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            : ["السبت", "الاحد", "الاثنين", "الثلاثاء", "الاربعاء", "الخميس", "الجمعة"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: " عدد الحجوزات",
          type: "line",
          smooth: true, // Smooth curve
          data: chartData, // Use chartData state as series data
          lineStyle: {
            color: "#2D2D2D", // Change the color here
          },
        },
      ],
    };

    // Set options and render the chart
    myChart2.setOption(options);

    // Cleanup on component unmount
    return () => {
      myChart2.dispose();
    };
  }, [chartData]); // Re-render chart when chartData changes

  // Event handlers for the buttons to update the selected period
  const handleButtonClick = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <div className="chart-container">
      <div className="chart-header">
        <h3 className="chart-title">عدد الحجوزات</h3>
        <div className="period-buttons">
          <button
            onClick={() => handleButtonClick("day")}
            className={`period-button ${selectedPeriod === "day" ? "active" : ""}`}>
            يوم
          </button>
          <button
            onClick={() => handleButtonClick("week")}
            className={`period-button ${selectedPeriod === "week" ? "active" : ""}`}>
            أسبوع
          </button>
          <button
            onClick={() => handleButtonClick("month")}
            className={`period-button ${selectedPeriod === "month" ? "active" : ""}`}>
            شهر
          </button>
        </div>
      </div>
      <div id="myChart2" style={{ width: "100%", height: "400px" }}></div>
    </div>
  );
};

export default ChartTwo;
