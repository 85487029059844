import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useState } from "react";
import { Link } from "react-router-dom";

const EditAddRole = () => {
  const [contentList, setContentList] = useState([]);
  const [formIdCounter, setFormIdCounter] = useState(0);

  const handleAddContent = () => {
    const newFormId = `form-${formIdCounter}`;
    const newContent = (
      <div className="content-role-update mt-4" key={newFormId}>
        {/* ==== START CONTENT ROLE UPDATE ==== */}
        <div className="info-top d-flex justify-content-between align-items-center gap-3">
          <h2 className="title fs-6 fw-bold text-dark">الخدمات</h2>
          <div className="icon-remove" onClick={() => handleRemoveContent(newFormId)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
        <div className="all-main-info-edit mt-4">
          <div className="main-info-edit d-flex align-items-center gap-3">
            {/* ======= START FORM CHECK ========== */}
            <div className="form-check d-flex align-items-center gap-3">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id={`check1-${newFormId}`}
              />
              <label className="form-check-label" htmlFor={`check1-${newFormId}`}>
                اضافة
              </label>
            </div>
            {/* ======= END FORM CHECK ========== */}
            {/* ======= START FORM CHECK ========== */}

            <div className="form-check d-flex align-items-center gap-3">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id={`check2-${newFormId}`}
              />
              <label className="form-check-label" htmlFor={`check2-${newFormId}`}>
                تعديل
              </label>
            </div>
            {/* ======= END FORM CHECK ========== */}
            {/* ======= START FORM CHECK ========== */}
            <div className="form-check d-flex align-items-center gap-3">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id={`check3-${newFormId}`}
              />
              <label className="form-check-label" htmlFor={`check3-${newFormId}`}>
                عرض
              </label>
            </div>
            {/* ======= END FORM CHECK ========== */}
            {/* ======= START FORM CHECK ========== */}
            <div className="form-check d-flex align-items-center gap-3">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id={`check4-${newFormId}`}
              />
              <label className="form-check-label" htmlFor={`check4-${newFormId}`}>
                الحجب او التفعيل
              </label>
            </div>
            {/* ======= END FORM CHECK ========== */}
          </div>
          <button className="main-btn px-5 mt-4">حفظ</button>
        </div>
        {/* ==== END CONTENT ROLE UPDATE ==== */}
      </div>
    );
    setContentList([...contentList, newContent]);
    setFormIdCounter((prevCounter) => prevCounter + 1); // To Change Id Number IN Form check
  };

  const handleRemoveContent = (formId) => {
    setContentList((prevContentList) =>
      prevContentList.filter((content) => content.key !== formId),
    );
  };
  return (
    <>
      <HelmetInfo titlePage={"اضافة / تعديل الأدوار والصلاحيات"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/admin/homedashboard"}
          titleInfoPage={"اضافة / تعديل الأدوار والصلاحيات"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/rolespowers">
            عودة
          </Link>
        </div>
      </div>
      <div className="all-edit-add-role">
        <div className="mb-3">
          <label htmlFor="newRoleName" className="form-label">
            الأسم
          </label>
          <input type="text" className="form-control" id="roleName" />
        </div>
        <div className="add-update-role">
          {/* START HEAD ROLE */}
          <div className="head-role d-flex  align-items-center  gap-3">
            <h2 className="title fs-6 fw-bold  text-dark">تحديد الصلاحيات</h2>
            <div
              onClick={handleAddContent}
              className="button-add-role  text-color-num fs-6 fw-medium  cursor-pointer-1">
              <FontAwesomeIcon icon={faPlus} /> اضافة
            </div>
          </div>
          {/* END HEAD ROLE */}
          {contentList.map((content) => content)}
        </div>
      </div>
    </>
  );
};

export default EditAddRole;
