import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "Components/Loader/Loader";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getSubscriptions } from "store/vendor/vendorActions";
import { objectToQueryParams } from "utlis";
import "./ReportDetails.css";

const PackagesReport = () => {
  const user = useGetAuthUser();
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const fetchPackagesReport = useCallback(async () => {
    try {
      setIsLoading(true);
      const params = {
        with: ["package"],
        limit: 200,
        per_page: 200,
        offset: 0,
        sort: "ASC",
        paginate: true,
        field: "id",
        deleted: "all",
        resource: "all",
        resource_columns: ["id", "name_ar"],
        columns: ["vendor_id"],
        operand: ["="],
        column_values: [user.model_id],
      };
      const stringParam = objectToQueryParams(params);
      const response = await dispatch(getSubscriptions(stringParam)).unwrap();
      setSubscriptions(response.data.data);
    } catch (error) {
      console.error("Error fetching package report:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, user.model_id]);

  useEffect(() => {
    fetchPackagesReport();
  }, [fetchPackagesReport]);

  const { totalPackages, mostRequestedPackages } = useMemo(() => {
    const packagesMap = new Map();
    const packageCounts = new Map();
    subscriptions.forEach((subscription) => {
      const { id, name_ar } = subscription.package;
      packagesMap.set(id, name_ar);
      packageCounts.set(name_ar, (packageCounts.get(name_ar) || 0) + 1);
    });
    const sortedPackages = Array.from(packageCounts.entries()).sort((a, b) => b[1] - a[1]);
    return {
      totalPackages: subscriptions.length,
      mostRequestedPackages: sortedPackages,
    };
  }, [subscriptions]);

  if (isLoading) return <Loader />;

  return (
    <div className="packages-report">
      <div className="report-summary">
        <div className="summary-card">
          <div className="card-content">
            <h3>{totalPackages}</h3>
            <p>عدد الباقات</p>
          </div>
          <FontAwesomeIcon icon={faList} className="icon" />
        </div>
      </div>

      <div className="report-table">
        <h3 className="mb-4">الباقات الأكثر طلبا</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>الباقة</th>
              <th>عدد الحجوزات</th>
            </tr>
          </thead>
          <tbody>
            {mostRequestedPackages.map(([packageName, count], index) => (
              <tr key={packageName}>
                <td>{index + 1}</td>
                <td>{packageName}</td>
                <td>{count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PackagesReport;
