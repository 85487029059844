const MapStyle = {
  width: "100%",
  height: 600,
};

const MapLocation = ({ currentBranch }) => {
  return (
    <div className="map-info">
      <iframe
        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14944333.214956228!2d${currentBranch?.long}!3d${currentBranch?.lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15e7b33fe7952a41%3A0x5960504bc21ab69b!2s${currentBranch?.address}!5e0!3m2!1sar!2seg!4v1706968638829!5m2!1sar!2seg`}
        width={MapStyle.width}
        height={MapStyle.height}
        frameBorder="0"
        style={{ border: 0, borderRadius: "12px" }}
        allowFullScreen
        aria-hidden="false"
        tabIndex="0"
        title="map"
        className="iframe-map"
      />
    </div>
  );
};

export default MapLocation;
