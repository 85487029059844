import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getVendorWallet } from "store/general/generalActions";
import { formatDateToArabic } from "utlis";
import imgWallet from "../../../assets/images/dashboardImages/icons/wallet.svg";
import "./Wallet.css";

const noteTypes = new Map([
  ["credit", "اضافة"],
  ["debit", "طرح"],
]);

const Wallet = () => {
  const user = useGetAuthUser();
  const [data, setData] = useState();
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchWallet = (params) => {
    setIsLoading(true);
    dispatch(getVendorWallet(params))
      .then(unwrapResult)
      .then((res) => {
        setData(res.data.data[0]);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const params = {
      with: ["transactions", "user"],
      limit,
      per_page: currentPage,
      field: "created_at",
      deleted: "undeleted", //undeleted
      columns: ["user_id"],
      operand: ["="],
      column_values: [user?.id],
    };

    fetchWallet(params);
  }, [limit, currentPage]);

  return (
    <>
      <HelmetInfo titlePage={"المحفظة"} />
      <div data-aos="fade-left">
        <HeaderPageInfo title={"المحفظة"} isShowLeftContent={false} contentPageLeft={false} />
      </div>
      <div
        className="header-top-wallet d-flex align-items-center gap-2 justify-content-between gap-4"
        data-aos="fade-right">
        <div className="content-wallet-top">
          <h2 className="title">رصيد المتجر</h2>
          <h2 className="info-num">{data?.balance} ريال</h2>
        </div>
        <div className="image-wallet">
          <img src={imgWallet} alt="img wallet" />
        </div>
      </div>
      <div className="table-content mt-3 table-content-3" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">وصف المعاملة</th>
                <th scope="col">قيمة المعاملة</th>
                <th scope="col">تاريخ المعاملة</th>
                <th scope="col">نوع المعاملة</th>
              </>
            }
            dataTbody={
              <>
                {data?.transactions?.map((item, index) => {
                  return (
                    <tr key={`${item.id}-${index}`}>
                      <td>{item?.description}</td>
                      <td>{item?.value} ر.س</td>
                      <td>{formatDateToArabic(item?.created_at)}</td>
                      <td>
                        <span
                          className={` text-${item?.type === "credit" ? "success" : "danger"} `}>
                          {noteTypes.get(item?.type)}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default Wallet;
