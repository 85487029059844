// @ts-nocheck
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteAdminOffer, getOffers } from "store/admin/adminActions";
import { formatDateToArabic } from "utlis";
import "./OfferInfo.css";

const AdminOffersPage = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  // DATA OF TABLE AND SAVE IN LOCAL STORGE
  const isLoading = useSelector((state) => state.admin.isLoading);

  const fetchData = (params) => {
    dispatch(getOffers(params))
      .then(unwrapResult)
      .then((res) => {
        setData(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchData({ per_page: limit, page: currentPage, search });
  }, [limit, currentPage, search]);

  const navigate = useNavigate();

  const handleDeleteAdminOffer = async (id) => {
    await dispatch(deleteAdminOffer(id))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم حذف العرض بنجاح");
        fetchData({ per_page: limit, page: currentPage, search });
      });
  };

  return (
    <>
      <HelmetInfo titlePage={"العروض"} />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"العروض"}
          isShowLeftContent={true}
          contentPageLeft={<SearchHeaderPage onSearchChange={setSearch} />}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="table-content mt-3 table-content-2" data-aos="fade-up">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">العرض </th>
                  <th scope="col">حالة العرض</th>
                  <th scope="col">سعر العرض</th>
                  <th scope="col">الخصم</th>
                  <th scope="col" className="text-center">
                    تاريخ الانشاء
                  </th>
                  <th scope="col">الاجراءات</th>
                </>
              }
              dataTbody={
                <>
                  {data.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.name_ar}</td>
                        <td>
                          <div className={`${item.is_active ? "text-success" : "text-danger"}`}>
                            {item.is_active ? "مستمر" : "متوقف"}
                          </div>
                        </td>
                        <td>{item.service_price} ر.س</td>
                        <td>{item.discount_percentage} %</td>
                        <td className="text-center">{formatDateToArabic(item.created_at)}</td>
                        <td>
                          <IconsTable
                            typeIcon1={faEye}
                            functionEye={() => navigate(`detailsOffers/${item.id}`)}
                            typeIcon2={faEdit}
                            functionEdit={() => navigate(`editOffer/${item.id}`)}
                            isDeleteIcon={true}
                            functionDelete={() => handleDeleteAdminOffer(item.id)}
                            typeIcon3={faTrash}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              }
            />
          </div>
          <Pagination
            limit={limit}
            setLimit={setLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limitOptions={[10, 20, 30, 40]}
            currentPageItemsCount={currentPageItemCounts}
            totalItemsCount={totalItemsCount}
            totalPagesCount={totalPagesCount}
          />
        </>
      )}
    </>
  );
};

export default AdminOffersPage;
