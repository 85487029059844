import { useSelector } from "react-redux";
import imgEmp from "../../../../assets/images/userImg/user.png";
import TeamInfo from "./TeamInfo";
import "./TeamRate.css";

const EmployeesInfo = ({ currentBranch }) => {
  const employeesList = Object.values(useSelector((state) => state.customer.employees)).filter(
    (employee) => employee.vendor_id === currentBranch.vendor_id,
  );

  if (employeesList & (employeesList.length === 0)) return null;
  return (
    <div className="all-team-info">
      <div className="header-title">
        <h2 className="title">الفريق</h2>
      </div>

      <div
        className="main-team-info d-flex align-items-center flex-wrap gap-3"
        data-aos="fade-left">
        {employeesList.map((item, index) => {
          console.log({ item });
          return (
            <TeamInfo
              key={index}
              imgEmp={imgEmp}
              numRate={item.rate || 0}
              nameEmployee={item.user.name}
              titleJob={item.job_title ?? "مصفف شعر"}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EmployeesInfo;
