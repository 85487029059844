import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { editTermConditions, getTermConditions } from "store/general/generalActions";

const ServiceProviderTerms = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    content: "",
  });
  const [value, setValue] = useState("");

  const handleSubmit = () => {
    dispatch(editTermConditions({ ...data, content: value }))
      .then(unwrapResult)
      .then((res) => {
        setData(res.data);
        toast.success("تم التعديل بنجاح.");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchData = () => {
    dispatch(getTermConditions())
      .then(unwrapResult)
      .then((res) => {
        setData(res.data);
        setValue(res.data?.content);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <HelmetInfo titlePage={"شروط مزود الخدمة"} />
      <div className="header-page-w" data-aos="fade-left">
        <HeaderPageInfo
          title={"شروط مزود الخدمة"}
          isShowLeftContent={true}
          contentPageLeft={
            <div className="link-back">
              <Link
                className="size-text-color-dark main-text-color fs-5"
                to="/dashboard/admin/settings">
                عودة
              </Link>
            </div>
          }
        />
      </div>

      <div className="form-serv mt-3" data-aos="fade-up">
        <form action="">
          <div className="form-input-one mb-3">
            <label htmlFor="exampleInputReason" className="form-label fs-5">
              ادخل شروط الحجز
            </label>
            <textarea
              className="form-control"
              id="exampleInputReason"
              rows={8}
              value={value}
              onChange={(e) => setValue(e.target.value)}></textarea>
          </div>

          <button className="main-btn mt-4 px-5" type="button" onClick={handleSubmit}>
            حفظ
          </button>
        </form>
      </div>
    </>
  );
};

export default ServiceProviderTerms;
