import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import InputField from "./InputField";

const PasswordInput = ({ label, name, placeholder, handleOnChange }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="input-pass position-relative">
      <InputField
        label={label}
        name={name}
        placeholder={placeholder}
        success
        type={showPassword ? "text" : "password"}
        handleOnChange={handleOnChange}
      />
      <button
        type="button"
        className="icon-eye-button"
        onClick={() => setShowPassword(!showPassword)}>
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
      </button>
    </div>
  );
};

export default PasswordInput;
