// @ts-nocheck
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useDispatch } from "react-redux";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "store/admin/adminActions";
import { unwrapResult } from "@reduxjs/toolkit";

const ReceiveNotifications = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  const fetchData = () => {
    dispatch(getNotificationSettings())
      .then(unwrapResult)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const handleToggleSwitch = (newValue, index) => {
    dispatch(
      updateNotificationSettings({
        ...data,
        [index]: `${newValue ? "1" : "0"}`,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        const updatedData = [...data];
        updatedData[index] = newValue ? "1" : "0";
        setData(updatedData);
        toast.success("تم التعديل بنجاح.");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cardR = [
    { id: "1", title: "الأشعارات عبر رسائل البريد الألكتروني", key: "email" },
    { id: "2", title: "الأشعارات عبر رسائل الواتس اب", key: "whatsapp" },
    { id: "3", title: "الأشعارات عبر رسائل SMS", key: "sms" },
  ];

  return (
    <>
      <HelmetInfo titlePage={"استلام الأشعارات"} />
      <div className="header-page-w" data-aos="fade-left">
        <HeaderPageInfo
          title={"استلام الأشعارات"}
          isShowLeftContent={true}
          contentPageLeft={
            <div className="link-back">
              <Link
                className="size-text-color-dark main-text-color fs-5"
                to="/dashboard/admin/settings"
              >
                عودة
              </Link>
            </div>
          }
        />
      </div>

      <div className="all-card-receive" data-aos="fade-up">
        <div
          className={`border card-receive d-flex  align-items-center  justify-content-between  gap-4 flex-wrap`}
        >
          <h2 className="title">اشعار داخلى بلوحة التحكم</h2>
          <Toggleswitch
            switchId={data?.id}
            label={""}
            nameSwitch={`is_active`}
            value={data?.is_active === "1" ? true : false}
            onChange={(newValue) => {
              handleToggleSwitch(newValue, "is_active");
            }}
          />
        </div>
        {/* ======= START MAIN CARDS ONE ========= */}
        <div
          className={`main-cards-one ${
            !data?.is_active === "1" ? "opacity-50" : ""
          }`}
        >
          <h2 className="title-head-card border-bottom">اعدادات</h2>
          {cardR.map((item) => {
            return (
              <div
                key={item.key}
                className={`card-receive-1 card-receive d-flex  align-items-center  justify-content-between  gap-4 flex-wrap`}
              >
                <h2 className="title">{item.title}</h2>
                <Toggleswitch
                  switchId={item.key}
                  label={""}
                  nameSwitch={`is_active`}
                  value={data && data[item.key] === "1" ? true : false}
                  disabled={!data?.is_active === "1"}
                  onChange={(newValue) => {
                    handleToggleSwitch(newValue, item.key);
                  }}
                />
              </div>
            );
          })}
        </div>
        {/* ======= END MAIN CARDS ONE ========= */}
      </div>
    </>
  );
};

export default ReceiveNotifications;
