import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BgBody from "Components/Ui/BgBody/BgBody";
import { useEffect, useRef, useState } from "react";
import FooterForm from "../FormComponent/FooterForm";
import FormComponent from "../FormComponent/FormComponent";
import HeaderForm from "../FormComponent/HeaderForm";
import "./Otp.css";

const Otp = () => {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (inputRefs[0] && inputRefs[0].current) {
      inputRefs[0].current.focus();
    }
  }, []);

  const focusInput = (index) => {
    if (inputRefs[index] && inputRefs[index].current) {
      inputRefs[index].current.focus();
    }
  };

  const handleInputChange = (index, e) => {
    const value = e.target.value;

    const nextIndex = index + 1;

    const cloneValues = [...values];
    cloneValues[index] = value;
    setValues(cloneValues);

    if (value.length === 1 && nextIndex <= 3) {
      focusInput(nextIndex);
    }
  };

  const handleKeyDown = (index, e) => {
    const value = e.target.value;
    const prevIndex = index - 1;

    if (e.key === "Backspace" && index > 0 && value.length === 0) {
      focusInput(prevIndex);
    }
  };

  // TIMER RESEND CODE
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  // Function to reset the timer
  const resetTimer = () => {
    setTimer(60);
    setIsTimerRunning(true);
  };

  useEffect(() => {
    let interval;

    // Function to update the timer
    const updateTimer = () => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        setIsTimerRunning(false);
      }
    };

    // Start the timer when the component mounts
    interval = setInterval(updateTimer, 1000);

    // Cleanup function
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  useEffect(() => {
    if (values.length === 4) {
      console.log(values.join(""), "DONE");
      // API CALL
    }
  }, [values]);

  return (
    <>
      <HelmetInfo titlePage={"رمز التأكيد"} />
      <BgBody />

      <div className="otp-page">
        <FormComponent>
          <HeaderForm
            title={"رمز التأكيد"}
            isText={true}
            text={"الرجاء التحقق من صندوق الرسائل لتأكيد الكود"}
          />

          {/* ======== START OTP FORM ========= */}
          <div className="otp-form">
            <form action="">
              <div className="all-input-otp d-flex align-items-center justify-content-center gap-2 mt-4">
                <input
                  type="text"
                  name="digit1"
                  className="form-control"
                  maxLength={1}
                  onChange={(e) => handleInputChange(0, e)}
                  onKeyDown={(e) => handleKeyDown(0, e)}
                  ref={inputRefs[0]}
                />
                <input
                  type="text"
                  name="digit2"
                  className="form-control"
                  maxLength={1}
                  onChange={(e) => handleInputChange(1, e)}
                  onKeyDown={(e) => handleKeyDown(1, e)}
                  ref={inputRefs[1]}
                />
                <input
                  type="text"
                  name="digit3"
                  className="form-control"
                  maxLength={1}
                  onChange={(e) => handleInputChange(2, e)}
                  onKeyDown={(e) => handleKeyDown(2, e)}
                  ref={inputRefs[2]}
                />
                <input
                  type="text"
                  name="digit4"
                  className="form-control"
                  maxLength={1}
                  onChange={(e) => handleInputChange(3, e)}
                  onKeyDown={(e) => handleKeyDown(3, e)}
                  ref={inputRefs[3]}
                />
              </div>

              {isTimerRunning && <span className="timer-resend"> {timer} : 00 </span>}

              <button onClick={resetTimer} className="main-btn btn-submit mt-3 w-100">
                اعادة ارسال الرمز
              </button>
            </form>
          </div>
          {/* ======== END OTP FORM ========= */}
        </FormComponent>
      </div>
      <FooterForm />
    </>
  );
};

export default Otp;
