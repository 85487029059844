import CustomModal from "Components/CustomModal/CustomModal";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";
import imgEmployee from "../../../../assets/images/userImg/user.png";
import "./ModalsBookings.css";

const ModalEvaluationRateStar = ({
  showModalEvaluationRate,
  hideModelEvaluationRate,
  services,
  employee,
  handleReview,
}) => {
  const [employeeRate, setEmployeeRate] = useState(0);
  const [ratings, setRatings] = useState([]);
  const [review, setReview] = useState("");

  const handleRating = (rate, serviceId) => {
    setRatings((prev) => [...prev, { id: serviceId, rate }]);
  };

  const sendRateEmployee = () => {
    console.log({ ratings, review, employeeRate });
    const data = {
      service_rate: ratings[0],
      employee_rate: employeeRate,
      service_comment: review,
      employee_comment: review,
    };
    handleReview(data);
  };

  return (
    <CustomModal
      show={showModalEvaluationRate}
      onHide={hideModelEvaluationRate}
      title={"التقيم"}
      newClass={"modal-evaluation"}
    >
      <div className="all-modal-evaluation">
        <div className="header-evaluation d-flex  justify-content-between  align-items-center  flex-wrap gap-3">
          {services.map(({ service }) => (
            <section key={service.id} className="d-flex align-items-center gap-3">
              <h2 className="title">{service.name_ar}</h2>
              <div className="star-rates">
                <Rating onClick={(rate) => handleRating(rate, service.id)} size={"28px"} />
              </div>
            </section>
          ))}
        </div>

        <div className="add-message mt-4">
          <label htmlFor="exampleFormControlTextarea1" className="form-label d-none "></label>
          <textarea
            className="form-control w-100"
            id="exampleFormControlTextarea1"
            value={review}
            onChange={(e) => setReview(e.target.value)}
            rows={4}
            placeholder="كتابة التعليق"
          ></textarea>
        </div>

        <div className="all-rate-employee mt-4">
          <h2 className="title">تقيم الموظفين</h2>
          <>
            <div className="rate-employee-one d-flex  justify-content-between align-items-center flex-wrap gap-3 mt-3">
              <div className="info-employee d-flex align-items-center gap-3">
                <div className="img-employee">
                  <img
                    src={employee.user.image ?? imgEmployee}
                    alt="img employee"
                    width={"55px"}
                    height={"55px"}
                    className="object-fit-cover"
                  />
                </div>
                <h2 className="title name-employee ">{(employee.user.first_name + " ", employee.user.last_name)}</h2>
              </div>

              <div className="star-rates">
                <Rating onClick={(rate) => setEmployeeRate(rate)} size={"25px"} />
              </div>
            </div>
          </>
        </div>
        <button className="main-btn w-100 mt-4" onClick={sendRateEmployee}>
          ارسال
        </button>
      </div>
    </CustomModal>
  );
};

export default ModalEvaluationRateStar;
