import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateBookingDifference } from "store/admin/adminActions";

const ModalAdminBookingDifference = ({ showModalBooking, hideModalBooking, bookingDifference }) => {
  const dispatch = useDispatch();
  const [timeDifference, setTimeDifference] = useState({
    minutes: bookingDifference?.difference_in_min || 0,
  });

  useEffect(() => {
    if (bookingDifference) {
      setTimeDifference({
        minutes: bookingDifference.difference_in_min || 0,
      });
    }
  }, [bookingDifference]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        updateBookingDifference({
          difference_in_min: parseInt(timeDifference.minutes),
        }),
      ).unwrap();
      hideModalBooking();
    } catch (error) {
      console.error("Error updating booking difference:", error);
    }
  };

  return (
    <Modal show={showModalBooking} onHide={hideModalBooking} centered className="modal-custom">
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">تحديث فارق الحجز للخدمة</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row g-4">
            <div className="col-12">
              <div className="alert alert-info">
                <small className="d-block">
                  هذا الإعداد يحدد الفارق الزمني المطلوب بين الحجوزات المتتالية
                </small>
              </div>
            </div>

            <div className="col-md-12">
              <label className="form-label fw-medium">دقائق</label>
              <input
                type="number"
                className="form-control form-control-lg"
                value={timeDifference.minutes}
                onChange={(e) =>
                  setTimeDifference((prev) => ({
                    ...prev,
                    minutes: Math.min(59, Math.max(0, parseInt(e.target.value) || 0)),
                  }))
                }
                min="0"
                max="59"
              />
            </div>
          </div>

          <div className="d-flex justify-content-end gap-2 mt-4">
            <button type="submit" className="main-btn w-100 mt-4">
              حفظ
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAdminBookingDifference;
