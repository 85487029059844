import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import React from "react";
import { Link } from "react-router-dom";

const DetailsNotificationCustomer = () => {
  return (
    <>
    <HelmetInfo titlePage={"تفاصيل"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/admin/homedashboard"}
          titleInfoPage={"تفاصيل"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/settings/settingsNotificationCustomer"
          >
            عودة
          </Link>
        </div>
      </div>
      <div className="basic-information-content account-info-details mt-3" id="all-details-settings" data-aos="fade-up">
        {/* =========== START ALL BASIC INFO ============= */}
        <div className="all-basic-info all-details-branch all-details-settings">
          {/* ======== START HEADER INFO ========== */}
          <div className="header-info-profile ">
            <div className="content-user-info content-info-deails-offer">
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">حالة التنبية</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>نشط</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">حالة الحجز</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>مكتمل</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">حالة الدفع</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>مدفوع</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">توقيت الارسال</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>08:00 م</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">نص التذكير</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>
                    هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن
                    الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات
                    العشوائية إلى النص.
                  </p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
            </div>
          </div>
          {/* ======== END HEADER INFO ========== */}
        </div>
        {/* =========== END ALL BASIC INFO ============= */}
      </div>
    </>
  );
};

export default DetailsNotificationCustomer;
