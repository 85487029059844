import "./Products.css";
const Products = ({ children, newClassection }) => {
  return (
    <div className={`all-products padding-bottom-35 ${newClassection}`}>
      <div className="container">
        <div className="main-content-products">{children}</div>
      </div>
    </div>
  );
};

export default Products;
