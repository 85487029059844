import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PackageOfferCard from "Dashboard/Components/UiDashboard/CardPackage/PackageOfferCard";
import { useState } from "react";
const PlansContent = () => {
  const [planType, setPlanType] = useState("yearly");

  const handleYearlyClick = () => {
    setPlanType("yearly");
  };

  const handleMonthlyClick = () => {
    setPlanType("monthly");
  };

  const itemCardPackage = [
    {
      id: 0,
      namePackage: "إسم الباقة",
      text: "هناك حقيقة مثبتة منذ زمن طويل  وهي أن المحتوى المقروء لصفحة ما",
      price: "1000",
      typePrice: "ر.س",
      dateTimePackage: "لمدة سنة",
      itemsList: [
        { id: 0, textItem: "عمولة + أشتراك", badgeTrue: false },
        {
          id: 1,
          textItem: " %20 عمولة",
          textBadge: "عمولة النظام على الحجوزات",
          badgeTrue: true
        },
        { id: 2, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 3, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 4, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 5, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 6, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false }
      ],
      buttonText: "الأشتراك فى الباقة",
      textEnd: "يستلزم بطاقة دفع"
    },
    {
      id: 0,
      namePackage: "إسم الباقة",
      text: "هناك حقيقة مثبتة منذ زمن طويل  وهي أن المحتوى المقروء لصفحة ما",
      price: "1000",
      typePrice: "ر.س",
      dateTimePackage: "لمدة سنة",
      itemsList: [
        { id: 0, textItem: "عمولة + أشتراك", badgeTrue: false },
        {
          id: 1,
          textItem: " %20 عمولة",
          textBadge: "عمولة النظام على الحجوزات",
          badgeTrue: true
        },
        { id: 2, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 3, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 4, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 5, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 6, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false }
      ],
      buttonText: "الأشتراك فى الباقة",
      textEnd: "يستلزم بطاقة دفع"
    },
    {
      id: 0,
      namePackage: "إسم الباقة",
      text: "هناك حقيقة مثبتة منذ زمن طويل  وهي أن المحتوى المقروء لصفحة ما",
      price: "1000",
      typePrice: "ر.س",
      dateTimePackage: "لمدة سنة",
      itemsList: [
        { id: 0, textItem: "عمولة + أشتراك", badgeTrue: false },
        {
          id: 1,
          textItem: " %20 عمولة",
          textBadge: "عمولة النظام على الحجوزات",
          badgeTrue: true
        },
        { id: 2, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 3, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 4, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 5, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 6, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false }
      ],
      buttonText: "الأشتراك فى الباقة",
      textEnd: "يستلزم بطاقة دفع"
    }
  ];

  const itemCardPackageMonthly = [
    {
      id: 0,
      namePackage: "شهرى",
      text: "هناك حقيقة مثبتة منذ زمن طويل  وهي أن المحتوى المقروء لصفحة ما",
      price: "2000",
      typePrice: "ر.س",
      dateTimePackage: "لمدة شهر",
      itemsList: [
        { id: 0, textItem: "عمولة + أشتراك", badgeTrue: false },
        {
          id: 1,
          textItem: " %20 عمولة",
          textBadge: "عمولة النظام على الحجوزات",
          badgeTrue: true
        },
        { id: 2, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 3, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 4, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 5, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 6, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false }
      ],
      buttonText: "الأشتراك فى الباقة",
      textEnd: "يستلزم بطاقة دفع"
    },

    {
      id: 1,
      namePackage: "شهرى",
      text: "هناك حقيقة مثبتة منذ زمن طويل  وهي أن المحتوى المقروء لصفحة ما",
      price: "2200",
      typePrice: "ر.س",
      dateTimePackage: "لمدة شهر",
      itemsList: [
        { id: 0, textItem: "عمولة + أشتراك", badgeTrue: false },
        {
          id: 1,
          textItem: " %20 عمولة",
          textBadge: "عمولة النظام على الحجوزات",
          badgeTrue: true
        },
        { id: 2, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 3, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 4, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 5, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 6, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false }
      ],
      buttonText: "الأشتراك فى الباقة",
      textEnd: "يستلزم بطاقة دفع"
    },

    {
      id: 2,
      namePackage: "شهرى",
      text: "هناك حقيقة مثبتة منذ زمن طويل  وهي أن المحتوى المقروء لصفحة ما",
      price: "2500",
      typePrice: "ر.س",
      dateTimePackage: "لمدة شهر",
      itemsList: [
        { id: 0, textItem: "عمولة + أشتراك", badgeTrue: false },
        {
          id: 1,
          textItem: " %20 عمولة",
          textBadge: "عمولة النظام على الحجوزات",
          badgeTrue: true
        },
        { id: 2, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 3, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 4, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 5, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 6, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false }
      ],
      buttonText: "الأشتراك فى الباقة",
      textEnd: "يستلزم بطاقة دفع"
    }
  ];

  return (
    <div className="main-plans">
      <div className="butons-filter-plans">
        <button
          onClick={handleYearlyClick}
          className={
            planType === "yearly" ? "button-plan active" : "button-plan"
          }
        >
          سنوي
        </button>
        <button
          onClick={handleMonthlyClick}
          className={
            planType === "monthly" ? "button-plan active" : "button-plan"
          }
        >
          شهرى
        </button>
      </div>
      <div className="row g-3 mt-3">
        {planType === "yearly" && (
          <>
            <h2 className="title-top-plan">سنوي</h2>
            {itemCardPackage.map((item) => {
              return (
                <div className="col-12 col-md-6 col-lg-4" key={item.id}>
                  <PackageOfferCard
                    nameBackage={item.namePackage}
                    textPackage={item.text}
                    pricePackage={item.price}
                    typePackagePrice={item.typePrice}
                    dateTimePackage={item.dateTimePackage}
                    textButtonSubscription={"الأشتراك فى الباقة"}
                    textEndCard={"يستلزم بطاقة دفع"}
                  >
                    {item.itemsList.map((itemL) => {
                      return (
                        <li
                          key={itemL.id}
                          className="item-package-one d-flex  align-items-center  gap-2"
                        >
                          <div className="icon-chaeck-pack">
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                          {itemL.textItem}
                          {itemL.badgeTrue && (
                            <div className="badge-text-card">
                              <h2 className="text-package-color">
                                {itemL.textBadge}
                              </h2>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </PackageOfferCard>
                </div>
              );
            })}
          </>
        )}

        {planType === "monthly" && (
          <>
            <h2 className="title-top-plan">شهرى</h2>
            {itemCardPackageMonthly.map((item) => {
              return (
                <div className="col-12 col-md-6 col-lg-4" key={item.id}>
                  <PackageOfferCard
                    nameBackage={item.namePackage}
                    textPackage={item.text}
                    pricePackage={item.price}
                    typePackagePrice={item.typePrice}
                    dateTimePackage={item.dateTimePackage}
                    textButtonSubscription={"الأشتراك فى الباقة"}
                    textEndCard={"يستلزم بطاقة دفع"}
                  >
                    {item.itemsList.map((itemL) => {
                      return (
                        <li
                          key={itemL.id}
                          className="item-package-one d-flex  align-items-center  gap-2"
                        >
                          <div className="icon-chaeck-pack">
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                          {itemL.textItem}
                          {itemL.badgeTrue && (
                            <div className="badge-text-card">
                              <h2 className="text-package-color">
                                {itemL.textBadge}
                              </h2>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </PackageOfferCard>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default PlansContent;
