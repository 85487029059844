import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { Outlet } from "react-router-dom";

const Profile = () => {
  return (
    <>
      <HelmetInfo titlePage={"الملف الشخصي"} />

      <div className="header-page-1">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={false}
          titleInfoPage={"الملف الشخصي"}
          routeHomePage={"/"}
        />
      </div>

      <main>
        <Outlet />
      </main>
    </>
  );
};

export default Profile;
