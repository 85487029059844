import { unwrapResult } from "@reduxjs/toolkit";
import PageHelmetInfo from "Components/HelmetInfo/HelmetInfo";
import PageTilte from "Components/PageTilte/PageTilte";
import ServiceProvidersList from "Components/ServicesInfo/CardsServices/CardsServices";
import PageHeaderInfo from "Components/ServicesInfo/HeaderServices/HeaderServices";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSearchDetails } from "store/customer/customerActions";

const ServicesProviders = () => {
  const [services, setServices] = useState([]);
  const [vendors, setVendors] = useState([]);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const searchProviders = useCallback(
    async (params) => {
      setIsLoading(true);
      const response = await dispatch(getSearchDetails(params));
      const result = unwrapResult(response);
      const vendors = result.data.vendors.filter((vendor) => vendor.is_active === "1");
      const services = result.data.services.filter((service) => service.is_active === "1");
      setServices(services);
      setVendors(vendors);
      setIsLoading(false);
    },
    [dispatch],
  );

  useEffect(() => {
    window.scrollTo({ top: -1, behavior: "smooth" });
    searchProviders({});
  }, []);

  return (
    <>
      <PageHelmetInfo titlePage={"مزودي الخدمات"} />
      <PageTilte titlePage={"مزودي الخدمات"} />
      <PageHeaderInfo searchProviders={searchProviders} />
      <ServiceProvidersList
        searchProviders={searchProviders}
        vendors={vendors}
        isLoading={isLoading}
      />
    </>
  );
};

export default ServicesProviders;
