import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/logo.svg";
import logoHover from "../../assets/images/logo/logoHover.svg";

import DropMenuUser from "Components/Ui/DropMenuUser/DropMenuUser";
import { useCallback, useEffect, useState } from "react";
import "./NavBar.css";

import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "store/auth/authActions";

const NavBarComponent = ({
  routeLogo,
  isInfoLoginNavBar,
  isLinksNavbar,
  customerLoginRoute,
  customerLoginButtonText,
  vendorLoginRoute,
  routeButtonJoin,
  textButtonJoin,
  vendorLoginButtonText,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLogedIn);

  const [isHovered, setIsHovered] = useState(false);
  const [isMenuFixed, setMenuFixed] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const shouldBeFixed = scrollTop > 100;
    setMenuFixed(shouldBeFixed);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleUserLogout = () => {
    dispatch(logoutAction());
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="navbar-header">
      <Navbar
        expand="lg"
        fixed={isMenuFixed ? "top" : undefined}
        className={`z-3  ${isMenuFixed ? "menu-fixed" : ""}`}>
        <Container>
          <Navbar.Brand
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`logo-all ${isHovered ? "hovered" : ""}`}
            as={Link}
            to={routeLogo}>
            <img src={logo} alt="Original Logo" className={`logo ${isHovered ? "hidden" : ""}`} />
            <img
              src={logoHover}
              alt="Another Logo"
              className={`logo logo-2 ${isHovered ? "" : "hidden"}`}
            />
          </Navbar.Brand>

          <div className="menu-toggle-lang d-flex align-items-center gap-3">
            <>
              <div className="info-login-navbar d-flex gap-2 align-items-center">
                <DropMenuUser handleUserLogout={handleUserLogout} />
              </div>
              <Navbar.Toggle aria-controls="navbarSupportedContent" />
            </>
          </div>

          <Navbar.Collapse id="navbarSupportedContent">
            {isLinksNavbar && (
              <>
                <Nav className="ms-auto mb-2 mb-lg-0">
                  <Nav.Item>
                    <NavLink className="nav-link" aria-current="page" to="/">
                      الرئيسية
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to="about">
                      من نحن
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to="services">
                      خدماتنا
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to="contact">
                      تواصل معنا
                    </NavLink>
                  </Nav.Item>
                </Nav>
              </>
            )}

            <div className="main-nav-left d-flex align-items-center  gap-3 flex-wrap  ms-lg-4">
              <div className="main-buttons d-flex align-items-center  gap-3">
                {isLoggedIn === false && (
                  <div>
                    {customerLoginRoute && (
                      <Link to={customerLoginRoute} className="main-btn main-outline-btn">
                        {customerLoginButtonText}
                      </Link>
                    )}
                  </div>
                )}

                {isInfoLoginNavBar && isLoggedIn && (
                  <div>
                    {" "}
                    <div className="info-login-navbar d-flex  gap-2 align-items-center">
                      <DropMenuUser handleUserLogout={() => handleUserLogout()} />
                    </div>
                  </div>
                )}

                <Link to={vendorLoginRoute || routeButtonJoin} className="main-btn">
                  {vendorLoginButtonText || textButtonJoin}
                </Link>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBarComponent;
