import HeaderHome from "./HeaderHome";
import "./HomeIndex.css";
const HomeIndex = () => {
  return (
    <>
      <HeaderHome />
    </>
  );
};

export default HomeIndex;
