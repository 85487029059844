import iconCard from "../../../assets/images/aboutIcons/01.svg";
import iconCard2 from "../../../assets/images/aboutIcons/02.svg";
import iconCard3 from "../../../assets/images/aboutIcons/03.svg";
import "./CardsAbout.css";

const CardsAbout = () => {
  const cardsAbout = [
    {
      id: 0,
      icon: iconCard,
      title: "الرؤية",
      text: "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما .",
    },
    {
      id: 1,
      icon: iconCard2,
      title: "الرسالة",
      text: "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما .",
    },
    {
      id: 2,
      icon: iconCard3,
      title: "الأهداف",
      text: "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما .",
    },
  ];
  return (
    <div className="banner-cards-about padding-bottom-50">
      <div className="main-banner-cards">
        {/* ============ START CONTAINER ============== */}
        <div className="container">
          {/* ============ START ALLCARDS ABOUT ============= */}
          <div className="all-cards-about" data-aos="fade-up">
            {/* ========== START ROW ============= */}
            <div className="row g-3">
              {cardsAbout.map((item) => {
                return (
                  <div className="col-12 card-col col-md-6 col-lg-4" key={item.id}>
                    {/* =========== START CARD ABOUT ONE ============== */}
                    <div className="card-about-one h-100 d-flex flex-column gap-3">
                      {/* ========== START ICON CARD ========== */}
                      <div className="icon-card" style={{ backgroundImage: `url(${item.icon})` }}></div>
                      {/* ========== END ICON CARD ========== */}
                      {/* ========== START INFO CARD =========== */}
                      <div className="info-card d-flex flex-column gap-3">
                        <h2 className="title">{item.title}</h2>
                        <p className="text">{item.text}</p>
                      </div>
                      {/* ========== END INFO CARD =========== */}
                    </div>
                    {/* =========== END CARD ABOUT ONE ============== */}
                  </div>
                );
              })}
            </div>
            {/* ========== END ROW ============= */}
          </div>
          {/* ============ END ALLCARDS ABOUT ============= */}
        </div>
        {/* ============ END CONTAINER ============== */}
      </div>
    </div>
  );
};

export default CardsAbout;
