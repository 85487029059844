const MainOneInfo = ({ newClass, title, textInfo }) => {
  return (
    <>
      <div className={`main-info-one d-flex flex-column gap-3 ${newClass}`}>
        <h2 className="title text-size">{title}</h2>
        <p className="info-text-1 text-size-02">{textInfo}</p>
      </div>
    </>
  );
};

export default MainOneInfo;
