// @ts-nocheck
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import { useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getVendor } from "store/admin/adminActions";
import { getCities, getCountries, getRegions } from "store/general/generalActions";
import { setCities, setCountries, setRegions } from "store/general/generalSlice";
import imgUser from "../../../assets/images/userImg/user.png";

const DetailsDataUsers = () => {
  const infoBasic = [
    { id: 0, title: "الاسم بالكامل", key: "first_name" },
    { id: 1, title: "رقم الجوال", key: "phone" },
    { id: 2, title: "البريد الالكترونى", key: "email" },
  ];

  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(6);

  const { countries, cities, regions } = useSelector((state) => state.general);
  const { id } = useParams();
  const dispatch = useDispatch();

  const getPaginatedBookings = () => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    return bookings.slice(startIndex, endIndex);
  };

  const loadLocationData = useCallback(async () => {
    try {
      setIsLoading(true);
      const promises = [];

      if (!regions?.length) {
        promises.push(
          dispatch(getRegions())
            .then(unwrapResult)
            .then((res) => dispatch(setRegions(res.data.data))),
        );
      }

      if (!countries?.length) {
        promises.push(
          dispatch(getCountries())
            .then(unwrapResult)
            .then((res) => dispatch(setCountries(res.data.data))),
        );
      }

      if (!cities?.length) {
        promises.push(
          dispatch(getCities())
            .then(unwrapResult)
            .then((res) => dispatch(setCities(res.data.data))),
        );
      }

      if (promises.length > 0) {
        await Promise.all(promises);
      }
    } catch (err) {
      setError("Failed to load location data");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, regions?.length, countries?.length, cities?.length]);

  const fetchVendor = useCallback(async () => {
    if (!id) return;
    try {
      setIsLoading(true);
      const result = await dispatch(getVendor({ id })).then(unwrapResult);
      setUserInfo(result.data);
    } catch (err) {
      setError("Failed to load vendor data");
    } finally {
      setIsLoading(false);
    }
  }, [id, dispatch]);

  useEffect(() => {
    loadLocationData();
  }, [loadLocationData]);

  useEffect(() => {
    fetchVendor();
  }, [fetchVendor]);

  if (isLoading) return <Loader />;
  if (error) return <div className="alert alert-danger">{error}</div>;
  if (!userInfo || !countries || !cities || !regions) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage={"التفاصيل"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/admin/homedashboard"}
          titleInfoPage={"التفاصيل"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/serviceprovideraccounts">
            عودة
          </Link>
        </div>
      </div>
      <div className="basic-information-content account-info-details mt-3" data-aos="fade-up">
        {/* =========== START ALL BASIC INFO ============= */}
        <div className="all-basic-info">
          {/* =========== START TABS RATE ============ */}
          <div className="tabs-rate mt-4">
            <div className="header-info-profile  ">
              {/* ============ START TABS CONTENT ========== */}
              {isLoading ? (
                <Loader />
              ) : (
                <Tabs
                  defaultActiveKey="basicInfo"
                  id="uncontrolled-tab-example"
                  className="tabs-rates-content">
                  {/* ============ START TAB ONE ============ */}
                  <Tab className="tab-one" eventKey="basicInfo" title="المعلومات الرئيسيه">
                    {/* ======== START HEADER INFO ========== */}

                    <div className="image-user d-flex  justify-content-center  align-items-center mb-5 mt-3">
                      <img
                        src={imgUser}
                        alt="img user"
                        className=" object-fit-cover "
                        width="85px"
                        height={"85px"}
                      />
                    </div>

                    <div className="content-user-info">
                      {infoBasic.map((item) => {
                        return (
                          <div className="all-info-content  d-flex" key={item.id}>
                            {/* ========= START MAIN INFO ONE ========= */}
                            <div className="main-info-one d-flex  align-items-center">
                              <h2 className="title">{item.title}</h2>
                            </div>
                            {/* ========= END MAIN INFO ONE ========= */}
                            {/* ========= START MAIN INFO ONE ========= */}
                            <div className="main-info-one d-flex  align-items-center">
                              <p className="text">{userInfo?.user[item.key]}</p>
                            </div>
                            {/* ========= END MAIN INFO ONE ========= */}
                          </div>
                        );
                      })}
                    </div>

                    {/* ======== END HEADER INFO ========== */}
                  </Tab>

                  {/* SERVICES */}
                  <Tab className="tab-one" eventKey="services" title="الخدمات">
                    <div className="table-content mt-3 table-content-2">
                      <TableDataInfo
                        titleTableHeader={false}
                        isHashTrue={false}
                        hashId={false}
                        dateThead={
                          <>
                            <th scope="col">اسم الخدمة</th>
                            <th scope="col">الوصف</th>
                            <th scope="col">حالة الخدمة</th>
                            <th scope="col">السعر</th>
                            <th scope="col">خدمة مميزة</th>
                          </>
                        }
                        dataTbody={
                          <>
                            {userInfo?.services.map((service) => {
                              return (
                                <tr key={service.id}>
                                  <td>{service.name_ar}</td>
                                  <td>{service.description_ar}</td>
                                  <td>
                                    <p className={service.colorStatus}>
                                      {service.is_active ? "مفعل" : "غير مفعل"}
                                    </p>
                                  </td>
                                  {/* <td>{service.sectionServ}</td> */}
                                  <td>{service.price}</td>
                                  <td>{service.featured ? "مميز" : "غير مميز"}</td>
                                </tr>
                              );
                            })}
                          </>
                        }
                      />
                    </div>
                  </Tab>

                  {/* BRANCHES */}
                  <Tab className="tab-one" eventKey="branches" title="الفروع">
                    <div className="table-content mt-3 table-content-2" data-aos="fade-up">
                      <TableDataInfo
                        titleTableHeader={false}
                        isHashTrue={false}
                        hashId={false}
                        dateThead={
                          <>
                            <th scope="col">اسم الفرع</th>
                            <th scope="col">الدولة</th>
                            <th scope="col">المنطقة</th>
                          </>
                        }
                        dataTbody={
                          <>
                            {userInfo?.branches.map((branch) => {
                              return (
                                <tr key={branch.id}>
                                  <td>{branch.name}</td>
                                  <td>
                                    {countries?.find((c) => c.id === branch.country_id)?.name_ar}
                                  </td>
                                  <td>
                                    {regions?.find((r) => r.id === branch.region_id)?.name_ar}
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        }
                      />
                    </div>
                  </Tab>

                  {/* RESERVATIONS */}
                  {/* RESERVATIONS TAB - Temporarily disabled
                  <Tab className="tab-one" eventKey="reservations" title="الحجوزات">
                    <div className="table-content mt-3 table-content-2" data-aos="fade-up">
                      <TableDataInfo
                        titleTableHeader={false}
                        isHashTrue={false}
                        hashId={false}
                        dateThead={
                          <>
                            <th scope="col">اسم العميل</th>
                            <th scope="col">اسم الموظف</th>
                            <th scope="col">اسم الفرع</th>
                            <th scope="col">موعد الحجز</th>
                            <th scope="col">قيمة المعاملة</th>
                          </>
                        }
                        dataTbody={
                          <>
                            {getPaginatedBookings().map((item) => {
                              const details = bookingDetails[item.id];
                              return (
                                <tr key={item.id}>
                                  <td>
                                    {isLoadingDetails && !details
                                      ? "......."
                                      : details?.user?.first_name +
                                          " " +
                                          details?.user?.last_name || "N/A"}
                                  </td>
                                  <td>
                                    {isLoadingDetails && !details
                                      ? "......."
                                      : details?.employee?.user?.first_name +
                                          " " +
                                          details?.employee?.user?.last_name || "N/A"}
                                  </td>
                                  <td>
                                    {isLoadingDetails && !details
                                      ? "......."
                                      : details?.branch?.name || "N/A"}
                                  </td>
                                  <td>
                                    {item.booking_day} {item.booking_time}
                                  </td>
                                  <td>{item.total}</td>
                                </tr>
                              );
                            })}
                          </>
                        }
                      />
                      {!isLoadingDetails && bookings.length > perPage && (
                        <div className="d-flex justify-content-center mt-4">
                          <Pagination size="sm">
                            <Pagination.First
                              onClick={() => setCurrentPage(1)}
                              disabled={currentPage === 1}
                            />
                            <Pagination.Prev
                              onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
                              disabled={currentPage === 1}
                            />
                            {paginationItems}
                            <Pagination.Next
                              onClick={() =>
                                setCurrentPage((prev) => Math.min(totalPages, prev + 1))
                              }
                              disabled={currentPage === totalPages}
                            />
                            <Pagination.Last
                              onClick={() => setCurrentPage(totalPages)}
                              disabled={currentPage === totalPages}
                            />
                          </Pagination>
                        </div>
                      )}
                    </div>
                  </Tab>
                  */}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsDataUsers;
