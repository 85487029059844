import { faEye } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getHelpCenter } from "store/admin/adminActions";
import "./HelpCenter.css";

const MessageDetailsModal = ({ show, handleClose, message }) => {
  if (!message) return null;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      autoFocus
      className="message-modal">
      <Modal.Header closeButton className="border-1">
        <Modal.Title className="fw-bold text-center">{message.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="d-flex flex-column gap-3">
          <p className="message-text lead">{message.message}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const HelpCenter = () => {
  const dispatch = useDispatch();
  const [helpCenterData, setHelpCenterData] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    limit: 10,
    currentPage: 1,
    currentPageItemCounts: 0,
    totalPagesCount: 0,
    totalItemsCount: 0,
  });
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({});

  const fetchHelpCenter = useCallback(
    async (params) => {
      setIsLoading(true);
      try {
        const result = await dispatch(getHelpCenter(params)).then(unwrapResult);
        setHelpCenterData(result.data.data);
        setPagination((prev) => ({
          ...prev,
          currentPageItemCounts: result.data.data?.length || 0,
          totalItemsCount: result.data.total || 0,
          totalPagesCount: result.data.last_page || 0,
        }));
      } catch (error) {
        console.error("Error fetching help center:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  }, [search]);

  useEffect(() => {
    const searchParams = {
      page: pagination.currentPage,
      per_page: pagination.limit,
      field: "id",
      deleted: "all", //all,deleted
      resource: "all", //all,custom
      resource_columns: ["id", "name_ar"],
      columns: ["email"],
      operand: ["like"],
      column_values: [search],
    };
    fetchHelpCenter(searchParams);
  }, [pagination.limit, pagination.currentPage, search, fetchHelpCenter]);

  return (
    <>
      <HelmetInfo titlePage="مركز المساعدة" />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title="مركز المساعدة"
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              onSearchChange={setSearch}
              placeholder={"ادخل البريد الإلكتروني للبحث"}
            />
          }
        />
      </div>

      <div className="table-content mt-3 table-content-2">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">الاسم</th>
                <th scope="col">البريد الإلكتروني</th>
                <th scope="col">الرسالة</th>
                <th scope="col">الحالة</th>
                <th>الاجراءات</th>
              </>
            }
            dataTbody={
              <>
                {helpCenterData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.message}</td>
                    <td>
                      <span className={item.is_active === "1" ? "text-success" : "text-danger"}>
                        {item.is_active === "1" ? "نشط" : "غير نشط"}
                      </span>
                    </td>
                    <td>
                      <IconsTable
                        typeIcon1={faEye}
                        key={item.id}
                        functionEye={() => {
                          setMessage(item);
                          setShow(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        )}
      </div>

      <Pagination
        limit={pagination.limit}
        setLimit={(limit) => setPagination((prev) => ({ ...prev, limit }))}
        currentPage={pagination.currentPage}
        setCurrentPage={(currentPage) => setPagination((prev) => ({ ...prev, currentPage }))}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={pagination.currentPageItemCounts}
        totalItemsCount={pagination.totalItemsCount}
        totalPagesCount={pagination.totalPagesCount}
        isShowTotalPagesCount={true}
      />

      <MessageDetailsModal
        message={message}
        show={show}
        handleClose={() => {
          setShow(false);
          setMessage("");
        }}
      />
    </>
  );
};

export default HelpCenter;
