import { faEye, faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getVendors, updateVendorStatus } from "store/admin/adminActions";
import TableDataInfo from "../../Components/UiDashboard/TablesData/TableDataInfo";
import "./ServicesDetails.css";

const ServiceProviderAccounts = () => {
  const user = useGetAuthUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);

  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchVendors = (params) => {
    setIsLoading(true);
    dispatch(getVendors(params))
      .then(unwrapResult)
      .then((res) => {
        const distinctVendors = res.data.data.filter(
          (vendor, index, self) => index === self.findIndex((t) => t.id === vendor.id),
        );
        setVendors(distinctVendors);
        setCurrentPageItemCounts(distinctVendors?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const params = {
      per_page: limit,
      page: currentPage,
      search,
      field: "id",
      deleted: "all",
      resource: "all",
      resource_columns: ["id", "name"],
    };
    fetchVendors(params);
  }, [limit, currentPage, search]);

  const activateVendor = (vendorId, isActive) => {
    setIsLoading(true);
    dispatch(updateVendorStatus({ vendor_id: vendorId, is_active: isActive ? "1" : "0" }))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم تفعيل المزود بنجاح");
        fetchVendors({ per_page: limit, page: currentPage, search });
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <HelmetInfo titlePage={"حسابات المستخدمين"} />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"حسابات مزودي الخدمة"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={false}
              typeIcon={faPlus}
              textButton={"اضافة مستخدم جديد"}
              functionButton={() => {
                navigate(`${"profileaddedit/profileeditadd"}`);
              }}
              onSearchChange={setSearch}
            />
          }
        />
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="table-content mt-4 table-content-2" data-aos="fade-up">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">اسم مزود الخدمة</th>
                  <th scope="col">عدد الفروع</th>
                  <th scope="col">عدد العروض</th>
                  <th scope="col">عدد الحجوزات</th>
                  <th scope="col">عدد الخدمات</th>
                  <th scope="col">تفعيل/الغاء</th>
                  <th scope="col">الاجراءات</th>
                </>
              }
              dataTbody={
                <>
                  {vendors.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.branches_count}</td>
                        <td>{item.offers_count}</td>
                        <td>{item.bookings_count}</td>
                        <td>{item.services_count}</td>

                        <td>
                          <Toggleswitch
                            switchId={`toggleswitch${item.id}`}
                            nameSwitch={"switch01"}
                            value={item.is_active === "1"}
                            onChange={(checked) => {
                              activateVendor(item.id, checked);
                            }}
                            disabled={user.type !== "admin"}
                          />
                        </td>
                        <div>
                          <td>
                            <IconsTable
                              typeIcon1={faEye}
                              functionEye={() => {
                                navigate(
                                  `/dashboard/admin/serviceprovideraccounts/details/${item.id}`,
                                );
                              }}
                            />
                          </td>
                          <td>
                            <IconsTable
                              typeIcon1={faPenToSquare}
                              functionEye={() => {
                                navigate(
                                  `/dashboard/admin/serviceprovideraccounts/profileaddedit/${item.id}`,
                                );
                              }}
                            />
                          </td>
                        </div>
                      </tr>
                    );
                  })}
                </>
              }
            />
            {vendors?.length === 0 ? (
              <p className="mx-auto w-100 text-center">لا يوجد عناصر لإظهارها</p>
            ) : (
              ""
            )}
          </div>
          <Pagination
            limit={limit}
            setLimit={setLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limitOptions={[5, 10, 20, 30, 40]}
            currentPageItemsCount={currentPageItemCounts}
            totalItemsCount={totalItemsCount}
            totalPagesCount={totalPagesCount}
          />
        </>
      )}
    </>
  );
};

export default ServiceProviderAccounts;
