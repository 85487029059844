import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCountries, getRegions, updateRegion } from "store/general/generalActions";

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";

const Regions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [countries, setCountries] = useState([]);

  const [requestParams, setRequestParams] = useState({
    limit: 10,
    offset: 1,
    sort: "DESC",
    paginate: "true",
    field: "updated_at",
  });

  const fetchRegions = useCallback(() => {
    const params = {
      ...requestParams,
      ...(debouncedSearchText && {
        columns: ["name_ar"],
        operand: ["LIKE"],
        column_values: [`%${debouncedSearchText}%`],
      }),
    };

    setIsLoading(true);
    dispatch(getRegions(params))
      .then(unwrapResult)
      .then((res) => {
        const { data, total, links } = res.data;
        setData(data);
        setCurrentPageItemCounts(data?.length || 0);
        setTotalItemsCount(total || 0);
        setTotalPagesCount(links?.length - 2 || 0);
      })
      .catch((error) => {
        console.error("Error fetching regions:", error);
        toast.error("فشل في جلب المناطق");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, requestParams, debouncedSearchText]);

  const handleToggleActive = (id, newValue) => {
    /* TODO: implement toggle function */
  };

  const fetchCountries = useCallback(() => {
    dispatch(getCountries())
      .then(unwrapResult)
      .then((res) => setCountries(res.data.data))
      .catch((error) => {
        console.error("Error fetching countries:", error);
        toast.error("فشل في جلب الدول");
      });
  }, [dispatch]);

  useEffect(() => {
    fetchCountries();
    fetchRegions();
  }, [fetchRegions, fetchCountries]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const toggleSwitch = async (newValue, itemId) => {
    const region = data.find((r) => r.id === itemId);
    if (!region) return;

    try {
      const res = await dispatch(
        updateRegion({
          ...region,
          is_active: newValue ? "1" : "0",
        }),
      ).unwrap();

      if (res.success) {
        toast.success("تم التعديل بنجاح");
        fetchRegions();
      }
    } catch (error) {
      console.error("Error updating region:", error);
      toast.error("فشل في تحديث المنطقة");
    }
  };

  return (
    <>
      <HelmetInfo titlePage={"المناطق"} />
      <div className="main-header-regions" data-aos="fade-down">
        <HeaderPageInfo
          title={"المناطق"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={true}
              typeIcon={faPlus}
              textButton={"اضافة منطقة جديدة"}
              functionButton={() => navigate("add")}
              onSearchChange={setSearchText}
            />
          }
        />
      </div>

      {isLoading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : (
        <>
          <div className="table-content mt-3" data-aos="fade-up">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">اسم المنطقة</th>
                  <th scope="col">الدولة</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">الاجراءات</th>
                </>
              }
              dataTbody={
                <>
                  {data.map((region) => (
                    <tr key={region.id}>
                      <td>{region.name_ar}</td>
                      <td>{countries.find((c) => c.id === region.country_id)?.name_ar}</td>
                      <td>
                        <Toggleswitch
                          switchId={region.id}
                          nameSwitch={`region-${region.id}`}
                          value={region.is_active === "1"}
                          onChange={(newValue) => toggleSwitch(newValue, region.id)}
                        />
                      </td>
                      <td>
                        <IconsTable
                          typeIcon2={faEdit}
                          functionEdit={() => navigate(`edit/${region.id}`)}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              }
            />
          </div>
          <Pagination
            limit={requestParams.limit}
            setLimit={(limit) => setRequestParams((prev) => ({ ...prev, limit, offset: 1 }))}
            currentPage={requestParams.offset}
            setCurrentPage={(page) => setRequestParams((prev) => ({ ...prev, offset: page }))}
            limitOptions={[5, 10, 20, 30, 40]}
            currentPageItemsCount={currentPageItemCounts}
            totalItemsCount={totalItemsCount}
            totalPagesCount={totalPagesCount}
          />
        </>
      )}
    </>
  );
};

export default Regions;
