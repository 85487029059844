import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeCartService } from "store/customer/customerSlice";
import "./ModalServices.css";

const ModalService = ({ showModalService, hideModalService, modalInfo: serviceInfo, addToCart }) => {
  const [isAddedToCard, setIsAddedToCard] = useState(false);
  const cartServices = useSelector((state) => state.customer.cartDetails.services);

  useEffect(() => {
    if (cartServices.length > 0 && serviceInfo) {
      const isExactServiceAdded = cartServices.find((service) => service.id === serviceInfo.id);
      if (isExactServiceAdded) setIsAddedToCard(true);
    }
    return () => {
      setIsAddedToCard(false);
    };
  }, [cartServices, serviceInfo]);

  const dispatch = useDispatch();

  const handleButtonClick = () => {
    if (isAddedToCard) {
      dispatch(removeCartService(serviceInfo.id));
      setIsAddedToCard(false);
    } else {
      addToCart(serviceInfo);
      setIsAddedToCard(true);
    }
  };

  if (!serviceInfo || !showModalService) return null;

  return (
    <CustomModal show={showModalService} onHide={hideModalService} title={serviceInfo.name_ar} newClass={"modal-offer"}>
      <div className="modal-content-info">
        <div className="header-modal-details border-bottom pb-2">
          <div className="info-price d-flex align-items-center gap-2 mt-2">
            <h2 className="num-price size-text-color-gray">{serviceInfo.price} ر.س</h2>
          </div>
          <p className="text size-text-color-gray pt-2 lh-lg ">{serviceInfo.description}</p>
        </div>
        <div className="main-info-content-modal pt-3 border-bottom pb-2"></div>

        <div className="bottom-modal d-flex  align-items-center gap-2 pt-3">
          <h2 className="title size-text-color-gray">صلاحية الباقة</h2>
          <p className="text-info-conent size-text-color-gray d-flex  align-items-center gap-2">
            <span className="size-text-color-dark">شهر</span>2 خدمات 4 مرات
          </p>
        </div>

        <button type="button" className="main-btn w-100 mt-4" onClick={handleButtonClick}>
          {isAddedToCard ? "إزالة" : "اختيار"}
        </button>
      </div>
    </CustomModal>
  );
};

export default ModalService;
