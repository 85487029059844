import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAdminServiceDetails, updateService } from "store/admin/adminActions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name_ar: Yup.string().required("اسم الخدمة بالعربية مطلوب"),
  name_en: Yup.string().required("اسم الخدمة بالانجليزية مطلوب"),
  description_ar: Yup.string().required("وصف الخدمة بالعربية مطلوب"),
  description_en: Yup.string().required("وصف الخدمة بالانجليزية مطلوب"),
  price: Yup.number().required("السعر مطلوب"),
  service_time: Yup.number().required("وقت الخدمة مطلوب"),
  extra_time: Yup.number().required("الوقت الإضافي مطلوب"),
  discount_type: Yup.string().required("نوع الخصم مطلوب"),
  discount: Yup.number().nullable(),
});

const EditAdminService = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAdminServiceDetails(id))
      .then(unwrapResult)
      .then((res) => {
        setFormValues(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("حدث خطأ في تحميل البيانات");
      });
  }, [dispatch, id]);

  const handleSubmit = (values, { setErrors }) => {
    dispatch(updateService(values))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم تحديث الخدمة بنجاح");
        navigate("/dashboard/admin/servicespage");
      })
      .catch((error) => {
        setErrors(error.errors);
        toast.error("حدث خطأ في تحديث الخدمة");
      });
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage="تعديل الخدمة" />
      <HeaderPageTitle
        titlePage="الرئيسية"
        routeHomePage="/dashboard/admin/homedashboard"
        titleInfoPage="تعديل الخدمة"
      />

      <div className="form-edit-services">
        <FormField
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          <div className="row g-3">
            <div className="col-md-6">
              <InputField name="name_ar" label="اسم الخدمة بالعربية" type="text" />
            </div>
            <div className="col-md-6">
              <InputField name="name_en" label="اسم الخدمة بالانجليزية" type="text" />
            </div>
            <div className="col-12">
              <InputField name="description_ar" label="وصف الخدمة بالعربية" as="textarea" />
            </div>
            <div className="col-12">
              <InputField name="description_en" label="وصف الخدمة بالانجليزية" as="textarea" />
            </div>
            <div className="col-md-6">
              <InputField name="price" label="السعر" type="number" />
            </div>
            <div className="col-md-6">
              <SelectField
                name="discount_type"
                label="نوع الخصم"
                options={[
                  { value: "0", label: "بدون خصم" },
                  { value: "1", label: "نسبة مئوية" },
                  { value: "2", label: "قيمة ثابتة" },
                ]}
              />
            </div>
            <div className="col-md-4">
              <InputField name="discount" label="قيمة الخصم" type="number" />
            </div>
            <div className="col-md-4">
              <InputField name="service_time" label="وقت الخدمة (بالدقائق)" type="number" />
            </div>
            <div className="col-md-4">
              <InputField name="extra_time" label="الوقت الإضافي (بالدقائق)" type="number" />
            </div>
          </div>

          <div className="mt-4">
            <button type="submit" className="main-btn">
              حفظ التغييرات
            </button>
          </div>
        </FormField>
      </div>
    </>
  );
};

export default EditAdminService;
