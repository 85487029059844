import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { formatDateToArabic } from "utlis";
import imgTicket from "../../../assets/images/dashboardImages/icons/ticket3.svg";
import imgUser from "../../../assets/images/userImg/user.png";

const InfoRow = ({ title, value }) => (
  <div className="all-info-content d-flex">
    <div className="main-info-one d-flex align-items-center">
      <h2 className="title">{title}</h2>
    </div>
    <div className="main-info-one d-flex align-items-center">
      <p>{value}</p>
    </div>
  </div>
);

const BookingRow = ({ booking }) => {
  return (
    <tr key={booking.id}>
      <td>{booking.employeeName}</td>
      <td>{booking.branchName}</td>
      <td>
        <img src={imgTicket} alt="img ticket" width={"22px"} height={"22px"} />
        <a href="##" className="text-link text-color-num text-decoration-underline ms-1">
          فاتورة المنصة
        </a>
      </td>
      <td>
        <img src={imgTicket} alt="img ticket" width={"22px"} height={"22px"} />
        <a href="##" className="text-link text-color-num text-decoration-underline ms-1">
          فاتورة العميل
        </a>
      </td>
      <td>{booking.total}</td>
    </tr>
  );
};

const ReviewRow = ({ booking, review }) => (
  <tr key={review.id}>
    <td>{booking.employeeName}</td>
    <td>{booking.branchName}</td>
    <td>{`${booking.booking_day} ${booking.booking_time}`}</td>
    <td>{(review.service_rate + review.employee_rate) / 2}</td>
  </tr>
);

const DataAccountUser = () => {
  const { state } = useLocation();
  const item = state?.item;
  const employeesMap = state?.employeesMap;
  const { type: userType } = useSelector((state) => state.auth.user);

  const { branches } = useSelector((state) => state.vendor); // branches map
  const branchesMap = branches.reduce((acc, b) => {
    acc[b.id] = b.name;
    return acc;
  }, {});

  if (!item) return null;
  return (
    <>
      <HelmetInfo title={"بيانات العميل"} />
      <HeaderPageInfo title={"بيانات العميل"} isShowLeftContent={true} />
      <div className="content-page">
        <Tabs defaultActiveKey="info" id="user-info-tabs" className="mb-4">
          <Tab.Pane eventKey="info" title="البيانات الأساسية">
            <div className="basic-information-content account-info-details mt-3">
              <div className="all-basic-info">
                <div className="header-info-profile">
                  <div className="image-user d-flex justify-content-center align-items-center mb-5">
                    <img
                      src={item.photo || imgUser}
                      alt={`صورة ${item.name}`}
                      className="object-fit-cover rounded-circle"
                      width="85px"
                      height="85px"
                    />
                  </div>

                  <div className="content-user-info">
                    <InfoRow title="الاسم بالكامل" value={item.name} />
                    <InfoRow title="رقم الجوال" value={item.phone} />
                    <InfoRow title="البريد الالكترونى" value={item.email} />
                    <InfoRow title="المدينة" value={item.city_id || "غير محدد"} />
                    <InfoRow title="تاريخ الانشاء" value={formatDateToArabic(item.created_at)} />
                    <InfoRow
                      title="تاريخ تفعيل الحساب"
                      value={
                        item.email_verified_at ? formatDateToArabic(item.email_verified_at) : ""
                      }
                    />
                    <InfoRow title="عدد الحجوزات" value={item?.bookings?.length} />
                    <InfoRow title="عدد التقييمات" value={item?.reviews?.length} />
                  </div>
                </div>
              </div>
            </div>
          </Tab.Pane>

          {userType === "vendor" && (
            <>
              <Tab.Pane className="tab-one" eventKey="bookings" title="الحجوزات">
                <TableDataInfo
                  titleTableHeader={false}
                  isHashTrue={false}
                  hashId={false}
                  dateThead={
                    <>
                      <th scope="col">اسم الموظف</th>
                      <th scope="col">اسم الفرع</th>
                      <th scope="col">فاتورة المنصة</th>
                      <th scope="col">فاتورة العميل</th>
                      <th scope="col">قيمة المعاملة</th>
                    </>
                  }
                  dataTbody={
                    <>
                      {item.bookings?.length &&
                        item.bookings.map((booking, index) => (
                          <BookingRow
                            booking={{
                              ...booking,
                              branchName: branchesMap[booking.branch_id],
                              employeeName: employeesMap[booking.employee_id],
                            }}
                            key={index}
                          />
                        ))}
                    </>
                  }
                />
              </Tab.Pane>
              <Tab.Pane className="tab-one" eventKey="reviews" title="التقييمات">
                <TableDataInfo
                  titleTableHeader={false}
                  isHashTrue={false}
                  hashId={false}
                  dateThead={
                    <>
                      <th scope="col">اسم الموظف</th>
                      <th scope="col">اسم الفرع</th>
                      <th scope="col">موعد الحجز</th>
                      <th scope="col">التقييم</th>
                    </>
                  }
                  dataTbody={
                    <>
                      {item.bookings?.flatMap((booking) =>
                        booking.reviews.map((review, index) => (
                          <ReviewRow
                            booking={{
                              ...booking,
                              branchName: branchesMap[booking.branch_id],
                              employeeName: employeesMap[booking.employee_id],
                            }}
                            review={review}
                            key={index}
                          />
                        )),
                      )}
                    </>
                  }
                />
              </Tab.Pane>
            </>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default DataAccountUser;
