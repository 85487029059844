import "./PrivacyContent.css"
const PrivacyContent = ({ titleContent, textContent }) => {
  return (
    <main>
      {/* ============= START PRIVACY CONTENT =========== */}
      <div className="privacy-content">
          {/* ========== START ALL PRIVACY ========= */}
          <div className="all-privacy" data-aos="fade-up">
            <h2 className="title">{titleContent}</h2>
            <div className="info-content-privacy text">{textContent}</div>
          </div>
          {/* ========== END ALL PRIVACY ========= */}
      
      </div>
      {/* ============= END PRIVACY CONTENT =========== */}
    </main>
  );
};

export default PrivacyContent;
