import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ModalReservationExtent = ({ showModalReservation, hideModalReservation, setSettings }) => {
  const vendorSettings = useSelector((state) => state.vendor.vendorSettings);

  const [bookingRange, setBookingRange] = useState(null);
  const [isSubmitAllowed, setIsSubmitAllowed] = useState(false);
  useEffect(() => {
    if (Object.keys(vendorSettings).length > 0) {
      const { booking_range } = vendorSettings;
      setBookingRange(booking_range);
    }
  }, [vendorSettings]);

  useEffect(() => {
    if (bookingRange) {
      setIsSubmitAllowed(true);
    } else {
      setIsSubmitAllowed(false);
    }
  }, [bookingRange]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSettings = {
      ...vendorSettings,
      booking_range: bookingRange,
    };
    setSettings(newSettings);
    hideModalReservation();
  };
  const months = ["one_month", "two_months", "three_months", "four_months", "five_months", "six_months"];
  return (
    <CustomModal
      show={showModalReservation}
      onHide={hideModalReservation}
      title={"فارق الحجز"}
      newClass={"modal-booking-diff"}>
      <div className="form-modal">
        <form action="">
          <div className="form-input-one mb-3">
            <label htmlFor="bookingRangeSelect" className="form-label">
              مدى الحجز للخدمة
            </label>
            <select
              value={bookingRange}
              onChange={(e) => setBookingRange(e.target.value)}
              className="form-select form-select-lg select-box-option-1"
              id="bookingRangeSelect">
              <option value="">اختر مدى الحجز</option>
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month === "one_month" && "شهر واحد"}
                  {month === "two_months" && "شهرين"}
                  {month === "three_months" && "ثلاثة أشهر"}
                  {month === "four_months" && "أربعة أشهر"}
                  {month === "five_months" && "خمسة أشهر"}
                  {month === "six_months" && "ستة أشهر"}
                </option>
              ))}
            </select>
          </div>

          <button disabled={!isSubmitAllowed} onClick={handleSubmit} className="main-btn w-100 mt-4" type={"submit"}>
            حفظ
          </button>
        </form>
      </div>
    </CustomModal>
  );
};

export default ModalReservationExtent;
