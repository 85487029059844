import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";

const OTPModal = ({
  showOtpModal,
  otpStep,
  formValues,
  otp,
  setOtp,
  handleVerifyOtp,
  setShowOtpModal,
  handleResendOtp,
  isLoading,
}) => {
  const resendOtp = () => {
    setOtp("");
    setShowOtpModal(true);
    handleResendOtp();
  };

  return (
    <Modal show={showOtpModal} centered backdrop="static" onHide={() => setShowOtpModal(false)}>
      <Modal.Body className="text-center p-4">
        {otpStep === 1 ? (
          <VerificationStep
            formValues={formValues}
            otp={otp}
            setOtp={setOtp}
            handleVerifyOtp={handleVerifyOtp}
            resendOtp={resendOtp}
            setShowOtpModal={setShowOtpModal}
            isLoading={isLoading}
          />
        ) : (
          <SuccessStep />
        )}
      </Modal.Body>
    </Modal>
  );
};

const VerificationStep = ({
  formValues,
  otp,
  setOtp,
  handleVerifyOtp,
  resendOtp,
  setShowOtpModal,
  isLoading,
}) => (
  <>
    <h4 className="mb-4">التحقق من البريد الألكتروني</h4>
    <p className="mb-4">
      الرجاء إدخال رمز التحقق المرسل إلى البريد الألكتروني
      <br />
      <strong dir="ltr">{formValues?.email}</strong>
    </p>
    <input
      type="text"
      className="form-control text-center mb-3"
      placeholder="أدخل رمز التحقق"
      value={otp}
      onChange={(e) => setOtp(e.target.value.replace(/\D/g, ""))}
      maxLength={6}
    />
    <button
      className="btn main-btn w-100 mt-5"
      onClick={handleVerifyOtp}
      disabled={otp.length !== 6 || isLoading}>
      تحقق وأكمل التسجيل
    </button>
    <div className="d-flex justify-content-between align-items-center gap-3">
      <button className="btn main-btn w-50 mt-2" onClick={resendOtp} disabled={isLoading}>
        اعادة ارسال الرمز
      </button>
      <button className="btn w-50 mt-2 main-btn" onClick={() => setShowOtpModal(false)}>
        الغاء
      </button>
    </div>
  </>
);

const SuccessStep = () => (
  <>
    <div className="success-animation mb-4">
      <FontAwesomeIcon icon={faCheckCircle} size="4x" className="text-success" />
    </div>
    <h4>تم التحقق بنجاح</h4>
    <p>جاري تحويلك للوحة التحكم...</p>
  </>
);

export default OTPModal;
