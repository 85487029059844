import FormField from "Components/Forms/FormFiled";
import { default as InputField, default as InputFiled } from "Components/Forms/InputField";
import MultiSelect from "Components/Forms/MultiSelect";
import SelectField from "Components/Forms/SelectField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "store/general/generalActions";
import { setCategories } from "store/vendor/vendorSlice";
import * as Yup from "yup";
import Loader from "../../../Components/Loader/Loader";

const validationSchema = Yup.object({
  provider_name: Yup.string().required("اسم المزود مطلوب"),
  description: Yup.string().required("وصف النشاط مطلوب"),
  website_url: Yup.string().url("الرجاء إدخال عنوان URL صحيح"),
  twitter: Yup.string().url("الرجاء إدخال عنوان URL صحيح"),
  instagram: Yup.string().url("الرجاء إدخال عنوان URL صحيح"),
  snapchat: Yup.string().url("الرجاء إدخال عنوان URL صحيح"),
  registered_tax: Yup.string().required("الحل مطلوب"),
  tax_number: Yup.string().when("registered_tax", (registered_tax, schema, context) => {
    return registered_tax[0] === "1"
      ? schema
          .required("الرقم الضريبى مطلوب")
          .min(13, "الرقم الضريبى يجب ان يكون 13 أرقام")
          .max(13, "الرقم الضريبى يجب ان يكون 13 أرقام")
      : schema;
  }),
  category_id: Yup.array().min(1, "يجب اختيار فئة واحدة على الأقل"),
});

const RegisterServicesStepTwo = ({ handlePrev, handleNext, setRegisterData, registerData }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.vendor.categories);

  const [isLoading, setIsLoading] = useState(false);
  const [isTaxRegistered, setIsTaxRegistered] = useState(false);
  const [taxNumber, setTaxNumber] = useState("");

  console.log(" is tax registered", isTaxRegistered);

  useEffect(() => {
    console.log(" is tax registered", isTaxRegistered);
  }, [isTaxRegistered]);

  const initialValues = {
    provider_name: registerData.provider_name,
    description: registerData.description || "",
    website_url: registerData.website_url || "",
    twitter: registerData.twitter || "",
    instagram: registerData.instagram || "",
    snapchat: registerData.snapchat || "",
    registered_tax: registerData.registered_tax || 0,
    tax_number: registerData.tax_number || "",
    category_id: registerData.category_id || [],
  };

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getCategories())
        .unwrap()
        .then(({ data: { data } }) => {
          dispatch(setCategories(data));
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [categories, dispatch]);

  const handleSubmit = (values) => {
    setRegisterData((prevData) => ({
      ...prevData,
      ...values,
    }));
    handleNext();
  };
  if (isLoading) return <Loader />;

  return (
    <div className="all-step-two bg-step">
      <FormField
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        <div className="row g-3">
          <div className="col-12">
            <InputFiled
              label={"اسم المزود"}
              name="provider_name"
              type="text"
              placeholder={"اسم المزود"}
              success
            />
          </div>
          <div className="col-12">
            <InputFiled
              label={"وصف النشاط"}
              name="description"
              type="textarea"
              placeholder={"وصف النشاط"}
              success
            />
          </div>
          <div className="col-12 col-md-6">
            <InputFiled
              label={"الموقع الالكترونى"}
              placeholder={"الموقع الالكترونى"}
              name="website_url"
              type="url"
              success
            />
          </div>
          <section className="row g-3">
            <div className="col-12 col-md-6">
              <InputFiled
                label={"تويتر"}
                name="twitter"
                type="url"
                placeholder={"رابط تويتر"}
                success
              />
            </div>
            <div className="col-12 col-md-6">
              <InputFiled
                label={"انستقرام"}
                name="instagram"
                type="url"
                placeholder={"رابط انستقرام"}
                success
              />
            </div>
            <div className="col-12 col-md-6">
              <InputFiled
                label={"سناب شات"}
                name="snapchat"
                type="url"
                placeholder={"رابط سناب شات"}
                success
              />
            </div>
          </section>

          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-6">
              <div className="select-reg">
                <label htmlFor="registered_tax" className="form-label">
                  هل مسجل بالقيمة الضريبية
                </label>
                <SelectField
                  name="registered_tax"
                  options={[
                    { value: "1", label: "نعم" },
                    { value: "0", label: "لا" },
                  ]}
                  valueSelected="هل مسجل بالقيمة الضريبية"
                  handleOnChange={(value) => {
                    setIsTaxRegistered(value === "1");
                  }}
                />
              </div>
            </div>

            <div
              className="col-12 col-md-6"
              style={{ display: isTaxRegistered ? "block " : "none", marginTop: "4rem" }}>
              <InputField
                label={"الرقم الضريبى"}
                name="tax_number"
                type="number"
                placeholder={"الرقم الضريبى"}
                value={taxNumber}
                disabled={!isTaxRegistered}
              />
            </div>
          </div>

          <div className="col-12">
            <div className="select-reg">
              <label htmlFor="category_id" className="form-label">
                الفئات <span className="fs-5 text-danger ">*</span>
              </label>
              <MultiSelect
                name="category_id"
                isMulti={true}
                options={categories.map((category) => ({
                  value: category.id,
                  label: category.name_ar,
                }))}
                valueSelected={"اختر الفئات"}
                placeholder={"اختر الفئات"}
                handleOnChange={(e) => {
                  setRegisterData((prevData) => ({
                    ...prevData,
                    category_id: e,
                  }));
                }}
                success
                value={registerData.category_id}
              />
            </div>
          </div>
        </div>
        <div className="button-container mt-4">
          <button type="button" className="btn main-btn btn-prev" onClick={handlePrev}>
            السابق
          </button>
          <button type="submit" className="btn main-btn btn-next">
            التالى
          </button>
        </div>
      </FormField>
    </div>
  );
};

export default RegisterServicesStepTwo;
