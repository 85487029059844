import { Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import amiriRegular from "./Amiri-Regular.ttf";

Font.register({
  family: "Amiri",
  src: amiriRegular,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 30,
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    marginTop: 10,
  },
  tableCell: {
    margin: 5,
    marginTop: 5,
    fontSize: 10,
    fontFamily: "Amiri",
  },
  labelCell: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
    textAlign: "right",
  },
  valueCell: {
    textAlign: "left",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: "center",
    fontSize: 10,
    color: "#666",
  },
});

const InvoicePDF = ({ booking, name, vendorDashboardRequest = false }) => {
  let items = [];
  if (vendorDashboardRequest === false) {
    items = [
      { label: "اسم العميل", value: name },
      { label: "اسم التاجر", value: booking.vendor.name },
      { label: "اسم الخدمة", value: booking.servicesList[0].service.name_ar },
      { label: "اسم الموظف", value: booking.employee.user.name },
      { label: "كود الخصم", value: booking.offer ? booking.offer.code : "لا يوجد" },
      { label: "يوم الحجز", value: booking.booking_day },
      { label: "وقت الحجز", value: booking.booking_time },
      { label: "المبلغ قبل الخصم", value: `${booking.total} ر.س` },
      { label: "الخصم", value: `${booking.discount} ر.س` },
      { label: "المبلغ بعد الخصم", value: `${booking.sub_total} ر.س` },
      { label: "الحالة", value: booking.status },
      { label: "وسيلة الدفع", value: booking.payment_way },
    ];
  } else {
    items = [
      { label: "اسم العميل", value: name },
      { label: "اسم التاجر", value: booking.vendor.name },
      { label: "اسم الخدمة", value: booking.booking_service.map((item) => item.service.name_ar) },
      { label: "اسم الموظف", value: booking.employee.user.first_name + " " + booking.employee.user.last_name },
      { label: "كود الخصم", value: booking.offer ? booking?.offer?.code : "لا يوجد" },
      { label: "المبلغ قبل الخصم", value: `${booking.total} ر.س` },
      { label: "الخصم", value: `${booking.discount} ر.س` },
      { label: "المبلغ بعد الخصم", value: `${booking.sub_total} ر.س` },
      { label: "الحالة", value: booking.status },
      { label: "وسيلة الدفع", value: booking.payment_way },
    ];
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          {items.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={[styles.tableCol, styles.valueCell]}>
                <Text style={styles.tableCell}>{item.value}</Text>
              </View>
              <View style={[styles.tableCol, styles.labelCell]}>
                <Text style={styles.tableCell}>{item.label}</Text>
              </View>
            </View>
          ))}
        </View>
        <View style={styles.footer}>
          <Text>شكراً لاستخدامكم خدماتنا</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;
