import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteCustomerThunk,
  getEmployees as getAdminEmployees,
  getCustomersListThunk,
  toggleCustomerStatusThunk,
} from "store/admin/adminActions";
import { getEmployees, getVendorCustomer } from "store/vendor/vendorActions";
import Swal from "sweetalert2";
import "./AccountsUsers.css";

const AccountsUsers = () => {
  const user = useGetAuthUser();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [customers, setCustomers] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [employeesMap, setEmployeesMap] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchVendorCustomers = (params) => {
    setIsLoading(true);
    dispatch(getVendorCustomer(params))
      .then(unwrapResult)
      .then((res) => {
        setCustomers(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchCustomersForAdmin = (params) => {
    dispatch(getCustomersListThunk(params))
      .then(unwrapResult)
      .then((res) => {
        setCustomers(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (user?.type === "admin") {
      fetchCustomersForAdmin({
        with: ["user", "bookings"],
        per_page: limit,
        page: currentPage,
        search,
      });
    } else if (user?.type === "vendor") {
      fetchVendorCustomers({
        per_page: limit,
        page: currentPage,
        search,
        "column[0]": "vendor_id",
        operand: "=",
        "column_values[0]": user?.model_id,
      });
    }
  }, [limit, currentPage, search]);

  const fetchEmployeesForVendor = useCallback(() => {
    dispatch(
      getEmployees({
        per_page: 200,
        with: ["user"],
      }),
    )
      .then(unwrapResult)
      .then((res) => {
        setEmployeesMap(
          res.data.data.reduce((acc, item) => {
            acc[item.id] = item.user.name;
            return acc;
          }, {}),
        );
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      });
  }, [dispatch]);

  const fetchEmployeesForAdmin = useCallback(() => {
    dispatch(getAdminEmployees({ per_page: 200, with: ["user", "branches", "bookings"] }))
      .then(unwrapResult)
      .then((res) => {
        setEmployeesMap(
          res.data.data.reduce((acc, item) => {
            acc[item.id] = item.user.name;
            return acc;
          }, {}),
        );
      });
  }, [dispatch]);

  useEffect(() => {
    if (user?.type === "vendor") {
      fetchEmployeesForVendor();
    } else if (user?.type === "admin") {
      fetchEmployeesForAdmin();
    }
  }, [fetchEmployeesForVendor, fetchEmployeesForAdmin]);

  const handleDeleteCustomer = (id) => {
    Swal.fire({
      title: "هل أنت متأكد؟",
      text: "لن تتمكن من التراجع عن هذا!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "نعم، احذف!",
      cancelButtonText: "إلغاء",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCustomerThunk(id))
          .then(() => {
            Swal.fire("تم الحذف!", "تم حذف المستخدم بنجاح.", "success");
            // Refresh the list
            if (user?.type === "admin") {
              fetchCustomersForAdmin({ page: currentPage, per_page: limit, search });
            } else {
              fetchVendorCustomers({ page: currentPage, per_page: limit, search });
            }
          })
          .catch(() => {
            Swal.fire("خطأ!", "حدث خطأ أثناء الحذف.", "error");
          });
      }
    });
  };

  const handleToggleStatus = (item, checked) => {
    delete item.region_id;
    delete item.country_id;
    delete item.city_id;
    delete item.birthdate;
    delete item.name;
    const newItem = { id: item.id, active: checked ? "1" : "0" };
    dispatch(toggleCustomerStatusThunk(newItem))
      .then(() => {
        if (user?.type === "admin") {
          fetchCustomersForAdmin({ page: currentPage, per_page: limit, search });
        } else {
          fetchVendorCustomers({ page: currentPage, per_page: limit, search });
        }
      })
      .catch((error) => {
        console.error("Error toggling status:", error);
      });
  };

  return (
    <>
      <HelmetInfo titlePage={"حسابات العملاء"} />
      <HeaderPageInfo
        title={"حسابات العملاء"}
        isShowLeftContent={true}
        contentPageLeft={
          <SearchHeaderPage
            typeIcon={false}
            textButton={false}
            functionButton={false}
            isButtonActive={false}
            onSearchChange={setSearch}
          />
        }
      />

      <div className="accounts-users">
        <div className="table-content mt-3 table-content-2">
          {isLoading ? (
            <Loader />
          ) : (
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">الاسم</th>
                  <th scope="col">رقم الجوال</th>
                  <th scope="col">البريد الالكترونى</th>
                  {user?.type === "admin" && (
                    <>
                      <th scope="col">تفعيل</th>
                    </>
                  )}
                  <th scope="col">الاجراءات</th>
                </>
              }
              dataTbody={
                <>
                  {customers.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        {user?.type === "admin" && (
                          <>
                            <td>
                              <Toggleswitch
                                switchId={`toggleswitch${item.id}`}
                                nameSwitch={"switch01"}
                                value={item.active === 1}
                                onChange={(checked) => {
                                  handleToggleStatus(item, checked);
                                }}
                                disabled={user.type !== "admin"}
                              />
                            </td>
                          </>
                        )}
                        <td>
                          <IconsTable
                            typeIcon1={faEye}
                            typeIcon2={false}
                            typeIcon3={user?.type === "admin" ? faTrash : false}
                            functionEye={() => {
                              navigate(`${item.id}`, { state: { item, employeesMap } });
                            }}
                            functionDelete={
                              user?.type === "admin" ? () => handleDeleteCustomer(item.id) : false
                            }
                            isDeleteIcon={user?.type === "admin"}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              }
            />
          )}
        </div>
        <Pagination
          limit={limit}
          setLimit={setLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limitOptions={[5, 10, 20, 30, 40]}
          currentPageItemsCount={currentPageItemCounts}
          totalItemsCount={totalItemsCount}
          totalPagesCount={totalPagesCount}
        />
      </div>
    </>
  );
};

export default AccountsUsers;
