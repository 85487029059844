import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const PaginationSelect = ({
  itemsPageTitle,
  optionsSelect,
  infoItemsPageSelect1,
  infoItemsPageSelect2,
  optionsSelect2
}) => {
  //   SELECT ITEMS
  const [selectedItem, setSelectedItem] = useState("");

  const handleSelectChange = (event) => {
    setSelectedItem(event.target.value);
  };

  //SELECT PAGES
  const [selectedItemPage, setSelectedItemPage] = useState("");

  const handleSelectChangePage = (event) => {
    setSelectedItemPage(event.target.value);
  };
  return (
    <>
      {/* ======= START BOTTOM PAGINATION ======== */}
      <div className="bottom-pagination mt-4 d-flex  justify-content-between  align-items-center  flex-wrap gap-2 ">
        {/* ======= START SELECT ITEMS ======== */}
        <div className="select-items d-flex  ailn-align-items-center gap-3 flex-wrap ">
          <h2 className="title-items">{itemsPageTitle}</h2>
          <select
            className="form-select"
            value={selectedItem}
            onChange={handleSelectChange}
          >
            {optionsSelect}
          </select>
          {selectedItem && (
            <p
              dir="ltr"
              className="text-items d-flex align-items-center  gap-2"
            >
              {selectedItem} {infoItemsPageSelect1}
            </p>
          )}
        </div>
        {/* ======= END SELECT ITEMS ======== */}
        {/* ======= START SELECT ITEMS ======== */}
        <div className="select-page select-items d-flex  align-items-center  gap-3">
          {selectedItemPage && (
            <p
              dir="ltr"
              className="text-items d-flex align-items-center  gap-2"
            >
              {selectedItemPage} {infoItemsPageSelect2}
            </p>
          )}
          <select
            className="form-select"
            value={selectedItemPage}
            onChange={handleSelectChangePage}
          >
            {optionsSelect2}
          </select>

          <div className="page-add-icon">
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </div>
        {/* ======= START SELECT ITEMS ======== */}
      </div>
      {/* ======= END BOTTOM PAGINATION ======== */}
    </>
  );
};

export default PaginationSelect;
