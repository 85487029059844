import InputField from "Components/Forms/InputField";
import { Form, Formik } from "formik"; // Import Formik and Form
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("هذا الحقل مطلوب").min(4, "يجب أن يكون الاسم أكثر من 3 أحرف"),
  last_name: Yup.string().required("هذا الحقل مطلوب").min(4, "يجب أن يكون الاسم أكثر من 3 أحرف"),
  email: Yup.string().email("ايميل خطاء").required("هذا الحقل مطلوب"),
  phone: Yup.string()
    .matches(/^[+]?[(]?\d{1,4}[)]?[-\s.\d]*$[0-9]+$/, "يجب أن تكون أرقامًا فقط")
    .required("رقم الهاتف مطلوب"),
});

const FormUpdateProfile = ({ profileInfo, initialValues, setInitialValues }) => {
  if (!profileInfo) return null;

  return (
    <div className="form-container">
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        <Form>
          <div className="form-row">
            <InputField
              label={"الإسم الأول"}
              name="first_name"
              type="text"
              placeholder={"الإسم الأول"}
              success
              handleOnChange={(value) => setInitialValues((prev) => ({ ...prev, first_name: value }))}
            />
            <InputField
              label={"الإسم الأخير"}
              name="last_name"
              type="text"
              placeholder={"الإسم الأخير"}
              success
              handleOnChange={(value) => setInitialValues((prev) => ({ ...prev, last_name: value }))}
            />
          </div>
          <InputField
            label={"البريد الألكتروني"}
            name="email"
            type="email"
            placeholder={"البريد الألكتروني"}
            success
            handleOnChange={(value) => setInitialValues((prev) => ({ ...prev, email: value }))}
          />

          <div className="select-phone-number mt-3">
            <label htmlFor="phone" className="form-label text-start">
              رقم الجوال
            </label>
            <div className="input-select-phone" dir="ltr">
              <PhoneInput
                country={"sa"}
                value={initialValues.phone}
                onChange={(value) => setInitialValues((prev) => ({ ...prev, phone: value }))}
              />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default FormUpdateProfile;
