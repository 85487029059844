import ChartOne from "./ChartOne";
import ChartTwo from "./ChartTwo";

const Charts = () => {
  return (
    <div className="all-charts mt-4">
      <div className="row g-3">
        <div className="col-12 col-lg-12 col-xl-6">
          <ChartOne />
        </div>
        <div className="col-12 col-lg-12 col-xl-6">
          <ChartTwo />
        </div>
      </div>
    </div>
  );
};

export default Charts;
