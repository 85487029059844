import { faCreditCard, faMoneyBill, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorMessage, Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker"; // Make sure to install this package
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setCart } from "store/customer/customerSlice";
import { addVendorPOSBooking, getEmployees, getVendorCustomer } from "store/vendor/vendorActions";
import {
  addCustomerToCart,
  addEmployeeToCart,
  setPaymentMethod as addPaymentMethodToCart,
  clearCart,
  removeServiceFromCart,
} from "store/vendor/vendorSlice";
import * as Yup from "yup";
import CustomDropdown from "./CustomDropdown";

const validationSchema = Yup.object().shape({
  selectedEmployee: Yup.number().required("يرجى اختيار الموظف"),
  selectedClient: Yup.number().required("يرجى اختيار العميل"),
  selectedPaymentMethod: Yup.string().required("يرجى اختيار طريقة الدفع"),
  bookingDate: Yup.date().required("يرجى اختيار تاريخ الحجز"),
  bookingTime: Yup.string().required("يرجى اختيار وقت الحجز"),
});

const PosCartDetails = ({ detailsPay }) => {
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.vendor.cartDetails);

  const branches = useSelector((state) => state.vendor.branches);
  const user = useSelector((state) => state.auth.user);

  const [allEmployees, setAllEmployees] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [services, setServices] = useState([]);

  const [employee, setEmployee] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [activeButton, setActiveButton] = useState(null);

  const removeSvcFromCart = (serviceId) => dispatch(removeServiceFromCart(serviceId));

  useEffect(() => {
    if (employee) dispatch(addEmployeeToCart(employee));
    if (customer) dispatch(addCustomerToCart(customer));
    if (paymentMethod) dispatch(addPaymentMethodToCart(paymentMethod));
  }, [employee, customer, paymentMethod, dispatch]);

  useEffect(() => {
    if (cartDetails.services) {
      setServices(cartDetails.services);
    }
  }, [cartDetails]);

  const fetchEmployeesAndCustomers = useCallback(async () => {
    try {
      if (allEmployees.length === 0) {
        const params = {
          with: ["user", "vendor"],
          columns: ["is_active", "vendor_id"],
          operand: ["=", "="],
          column_values: ["1", user.model_id],
        };
        const employeesResult = await dispatch(getEmployees(params));
        setAllEmployees(unwrapResult(employeesResult).data.data);
      }

      if (allCustomers.length === 0) {
        const customersResult = await dispatch(getVendorCustomer({}));
        setAllCustomers(unwrapResult(customersResult).data.data);
      }
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  }, [dispatch, allEmployees.length, allCustomers.length, user]);

  useEffect(() => {
    fetchEmployeesAndCustomers();
  }, [fetchEmployeesAndCustomers]);

  const handleSelectPayment = (buttonId, method) => {
    setActiveButton(buttonId);
    setPaymentMethod(method);
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { selectedEmployee, selectedClient, bookingDate, bookingTime } = values;
      const { services, payment } = cartDetails;
      const { method, total, discount } = payment;
      const formattedDate = bookingDate.toISOString().split("T")[0];
      const formattedTime = bookingTime;

      const order = {
        employee_id: selectedEmployee,
        customer_id: selectedClient,
        services: services.map((service) => service.serviceId),
        attendance: 1,
        sub_total: total,
        discount: discount,
        total: total,
        payment_way: method,
        branch_id: branches[0]?.id || 1,
        notes: "no_notes",
        booking_day: formattedDate,
        booking_time: formattedTime,
      };

      dispatch(addVendorPOSBooking(order))
        .then(unwrapResult)
        .then((res) => {
          console.log("res", res);
          setCustomer(null);
          setEmployee(null);
          dispatch(setCart({}));
          toast.success("تم إضافة الطلب بنجاح");
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("حدث خطأ ما");
        });
      resetForm();
      dispatch(clearCart());
    } catch (error) {
      console.error("Booking failed:", error);
      toast.error("حدث خطأ ما");
    }
  };

  return (
    <>
      <div className="details-order">
        <h2 className="title-top">تفاصيل الطلب</h2>
        <div className="all-details-products border-bottom pb-3 border-2 pt-3">
          {services.map((item, index) => (
            <div
              key={index}
              className="details-product-info d-flex justify-content-between align-items-center gap-2 flex-wrap mt-3"
            >
              <div className="right-details">
                <h2 className="title">{item.titleService}</h2>
                <p className="price pt-2">{item.price} ر.س</p>
              </div>
              <div className="buttons-action d-flex align-items-center gap-3">
                <div
                  onClick={() => removeSvcFromCart(item.serviceId)}
                  className="icon-trash text-danger cursor-pointer-1 fs-5"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="details-pay">
          {detailsPay.map((item, index) => (
            <div
              key={index}
              className={`detalis-pay-one d-flex align-items-center justify-content-between gap-3 pb-3 pt-2 ${
                item.newClass || ""
              }`}
            >
              <h2 className={`title ${item.newClassText || ""}`}>{item.title}</h2>
              <p className="num-info">{`${item.price}ر.س`}</p>
            </div>
          ))}
        </div>
        <Formik
          initialValues={{
            selectedEmployee: "",
            selectedClient: "",
            selectedPaymentMethod: "",
            bookingDate: new Date(),
            bookingTime: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, isValid, dirty, values }) => (
            <Form>
              <div className="select-info-content">
                {allEmployees.length === 0 ? (
                  <></>
                ) : (
                  <div className="select-one-content w-100 mb-3">
                    <label htmlFor="selectedEmployee" className="form-label">
                      الموظف
                    </label>
                    <CustomDropdown
                      textSelect={employee ? `${employee.user.first_name} ${employee.user.last_name}` : "-إختر الموظف-"}
                      options={allEmployees}
                      isShowNumberOption={true}
                      isIdShow={true}
                      name="selectedEmployee"
                      onSelect={(employee) => {
                        setFieldValue("selectedEmployee", employee.id);
                        setEmployee(employee);
                      }}
                      selectedOption={employee}
                      userType="employee"
                    />
                    <ErrorMessage
                      name="selectedEmployee"
                      component="div"
                      className="error-message text-danger fs-6 fw-medium"
                    />
                  </div>
                )}

                {allCustomers.length === 0 ? (
                  <> </>
                ) : (
                  <div className="main-select-client mb-3">
                    <label htmlFor="selectedClient" className="form-label">
                      العميل
                    </label>
                    <div className="main-select-option-1 d-flex align-items-center justify-content-between gap-2">
                      <div className="select-one-content w-100">
                        <CustomDropdown
                          options={allCustomers}
                          textSelect={customer ? `${customer.name}  - ${customer.phone}` : "-إختر العميل-"}
                          isShowNumberOption={true}
                          isIdShow={true}
                          selectedOption={customer}
                          name="selectedClient"
                          onSelect={(option) => {
                            setFieldValue("selectedClient", option.id);
                            setCustomer(option);
                          }}
                          userType="customer"
                        />
                        <ErrorMessage
                          name="selectedClient"
                          component="div"
                          className="error-message text-danger fs-6 fw-medium"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="date-time-fields mb-3 d-flex align-items-center gap-3">
                <div className="mb-3 w-100">
                  <label htmlFor="bookingDate" className="form-label">
                    تاريخ الحجز
                  </label>
                  <DatePicker
                    selected={values.bookingDate}
                    onChange={(date) => setFieldValue("bookingDate", date)}
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                  />
                  <ErrorMessage
                    name="bookingDate"
                    component="div"
                    className="error-message text-danger fs-6 fw-medium"
                  />
                </div>
                <div className="mb-3 w-100">
                  <label htmlFor="bookingTime" className="form-label">
                    وقت الحجز
                  </label>
                  <input
                    type="time"
                    id="bookingTime"
                    name="bookingTime"
                    className="form-control"
                    onChange={(e) => setFieldValue("bookingTime", e.target.value)}
                    value={values.bookingTime}
                  />
                  <ErrorMessage
                    name="bookingTime"
                    component="div"
                    className="error-message text-danger fs-6 fw-medium"
                  />
                </div>
              </div>

              <div className="pay-info border-bottom border-2 pb-3 pt-2">
                <h2 className="title">طريقة الدفع</h2>
                <div className="buttons-pay d-flex align-items-center gap-3 mt-4">
                  <button
                    type="button"
                    className={`main-btn main-outline-btn w-100 ${activeButton === 1 ? "active" : ""}`}
                    onClick={() => {
                      handleSelectPayment(1, "online");
                      setFieldValue("selectedPaymentMethod", "online");
                    }}
                  >
                    <FontAwesomeIcon icon={faCreditCard} />
                    كارت
                  </button>
                  <button
                    type="button"
                    className={`main-btn main-outline-btn w-100 ${activeButton === 2 ? "active" : ""}`}
                    onClick={() => {
                      handleSelectPayment(2, "cash");
                      setFieldValue("selectedPaymentMethod", "cash");
                    }}
                  >
                    <FontAwesomeIcon icon={faMoneyBill} />
                    نقدي
                  </button>
                </div>
                <ErrorMessage
                  name="selectedPaymentMethod"
                  component="div"
                  className="error-message text-danger fs-6 fw-medium"
                />
              </div>

              <button type="submit" className="main-btn mt-3 w-100" disabled={!isValid || !dirty}>
                تأكيد الطلب
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PosCartDetails;
