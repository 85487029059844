import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconBars from "../../../assets/images/dashboardImages/toggle/01.svg";
import "./NavBarDashboard.css";

import imgUser from "../../../assets/images/userImg/user.png";

import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import DropMenuNav from "Components/Ui/DropMenuNav/DropMenuNav";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logoutAction } from "store/auth/authActions";

const NavBarDashboard = ({ toggleSidebar }) => {
  const user = useGetAuthUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserLogout = () => {
    dispatch(logoutAction());
    window.location.reload();
    navigate("/logindashboard", { replace: true });
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <div
        className="navbar-menu-dash padding-x d-flex justify-content-between align-items-center flex-wrap gap-2"
        style={{
          padding: "0.7rem 0.4rem",
        }}>
        <div className="toggle-menu" onClick={toggleSidebar}>
          <img
            src={iconBars}
            alt="icon toggle"
            width={"22px"}
            height={"22px"}
            className=" object-fit-cover"
          />
        </div>

        <div className="info-menu-left d-flex align-items-center gap-2">
          {user.type === "vendor" && (
            <NavLink
              to="/dashboard/vendor/pos"
              className="main-btn button-shop main-outline-btn  cursor-pointer-1">
              <FontAwesomeIcon icon={faShoppingBag} />
            </NavLink>
          )}
          <DashboardProfileDropMenu handleUserLogout={handleUserLogout} />
        </div>
      </div>
    </>
  );
};

const DashboardProfileDropMenu = ({ handleUserLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useGetAuthUser();
  const role = user.type;
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <DropMenuNav
        NewClassDrop={"dropmenu-user-nav"}
        iconImg={
          <img
            onClick={toggleMenu}
            src={imgUser}
            className=" object-fit-cover  img-user-nav"
            alt="Dropdown Trigger"
          />
        }
        children={
          <div>
            <li>
              <Link to={`/dashboard/${role}/profileDashboard`} className="nav-item">
                <button className="nav-link">حسابى</button>
              </Link>
            </li>

            <li className="nav-item border-top">
              <button className="nav-link" onClick={handleUserLogout}>
                {" "}
                خروج{" "}
              </button>
            </li>
          </div>
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default NavBarDashboard;
