import axiosClient from "Apis/axiosService";
class Admin {
  static async login(username, password) {
    try {
      return await axiosClient.post("/admin/v1/login", { username, password });
    } catch (error) {
      return Promise.reject(error);
    }
  }
  static async changePassword(body) {
    try {
      return await axiosClient.post("/admin/v1/change-password", { ...body });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateProfile(body) {
    try {
      return await axiosClient.post("/admin/v1/update-profile", { ...body });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getProfile() {
    try {
      return await axiosClient.get("/admin/v1/profile");
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getCustomersList(payload) {
    try {
      return await axiosClient.get("/admin/v1/customer_report_list");
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getCustomerData(id) {
    try {
      return await axiosClient.get(`/admin/v1/customer_report_show/${id}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Admin;
