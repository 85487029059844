import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import PageTilte from "Components/PageTilte/PageTilte";
import PrivacyContent from "Components/Ui/PrivacyContent/PrivacyContent";

const Privacy = () => {
  const content = [
    {
      id: 0,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من ..",
    },
    {
      id: 1,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من ..",
    },
    {
      id: 2,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من ..",
    },
    {
      id: 3,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من ..",
    },
  ];
  return (
    <>
      <HelmetInfo titlePage={"الخصوصية"} />
      <PageTilte titlePage={"الخصوصية"} />
      <main>
        <div className="all-privacy padding-bottom-70 my-5">
          <div className="container">
            <PrivacyContent
              titleContent={"سياسة الخصوصية"}
              textContent={
                <>
                  {content.map((item) => {
                    return <p key={item.id}>{item.text}</p>;
                  })}
                </>
              }
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default Privacy;
