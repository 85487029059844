import PageTilte from "Components/PageTilte/PageTilte";
import PrivacyContent from "Components/Ui/PrivacyContent/PrivacyContent";
import React from "react";

const TermsConditions = () => {
  const content = [
    {
      id: 0,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر.."
    },
    {
      id: 1,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر.."
    },
    {
      id: 2,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر.."
    },
    {
      id: 3,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر.."
    },
    {
      id: 4,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر.."
    }
  ];
  return (
    <>
      <PageTilte titlePage={"الشروط والأحكام"} />
      <main>
        {/* ============= START PRIVACY CONTENT =========== */}
        <div className="privacy-content padding-bottom-70">
          <div className="container">
            <PrivacyContent
              titleContent={"الشروط والأحكام"}
              textContent={
                <>
                  {content.map((item) => {
                    return <p key={item.id}>{item.text}</p>;
                  })}
                </>
              }
            />
          </div>
        </div>
        {/* ============= END PRIVACY CONTENT =========== */}
      </main>
    </>
  );
};

export default TermsConditions;
