import "./AccordionFaq.css";
import { Accordion } from "react-bootstrap";

const AccordionFaq = ({
  eventKey,
  NewClass,
  titleAccordion,
  textAccordion
}) => {
  return (
    <>
      <Accordion.Item eventKey={eventKey} className={NewClass}>
        <Accordion.Header>{titleAccordion}</Accordion.Header>
        <Accordion.Body>{textAccordion}</Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default AccordionFaq;
