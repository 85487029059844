import { faEdit, faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteAdminBranch, getAdminBranches, updateAdminBranch } from "store/admin/adminActions";

const BranchesAdmin = () => {
  const { cities, regions, countries } = useSelector((state) => state.general);

  const [branches, setBranches] = useState([]);
  useEffect(() => {
    console.log("cities", cities);
    console.log("regions", regions);
    console.log("countries", countries);
    for (const branch of branches) {
      console.log("branch", branch);
    }
  }, [cities, regions, countries, branches]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useMemo(
    () => ({
      with: ["manager"],
      deleted: "undeleted", //all,deleted
      resource: "all", //all,custom
      per_page: limit,
      limit: limit,
      offset: 0,
      paginate: true,
      sort: "DESC",
      page: currentPage,
      columns: ["name"],
      operand: ["like"],
      column_values: [searchText],
    }),
    [limit, currentPage, searchText],
  );

  const fetchBranches = useCallback(
    async (params) => {
      setIsLoading(true);
      try {
        const res = await dispatch(getAdminBranches(params)).then(unwrapResult);
        setBranches(res.data.data || []);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links?.length - 2 || 0);
      } catch (error) {
        toast.error("Failed to fetch branches. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  const handleToggleSwitch = useCallback(
    async (newValue, itemId) => {
      setIsLoading(true);
      try {
        const res = await dispatch(
          updateAdminBranch({
            id: itemId,
            is_active: newValue ? "1" : "0",
          }),
        ).unwrap();

        if (res.success) {
          toast.success("تم التعديل بنجاح.");
          fetchBranches(params);
        }
      } catch (error) {
        toast.error("فشل في تحديث الفرع. يرجى المحاولة مرة أخرى.");
      }
      setIsLoading(false);
    },
    [dispatch, params, fetchBranches],
  );

  useEffect(() => {
    fetchBranches(params);
  }, [fetchBranches, params]);

  const handleDeleteBranch = useCallback(
    async (id) => {
      setIsLoading(true);
      try {
        await dispatch(deleteAdminBranch(id)).unwrap();
      } catch (error) {
        toast.error("فشل في حذف الفرع. يرجى المحاولة مرة أخرى.");
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  return (
    <>
      <HelmetInfo titlePage={"الفروع"} />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"الفروع"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={true}
              typeIcon={faPlus}
              textButton={"اضافة فرع جديد"}
              functionButton={() => navigate("/dashboard/admin/admin-branches/add")}
              onSearchChange={setSearchText}
            />
          }
        />
      </div>
      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">اسم الفرع</th>
                <th scope="col">مدير الفرع</th>
                <th scope="col">الدولة</th>
                <th scope="col">المدينة</th>
                <th scope="col">تفعيل/الغاء</th>
                <th scope="col">الاجراءات</th>
              </>
            }
            dataTbody={
              <>
                {branches.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.manager?.name}</td>
                    <td>{countries?.find((country) => country.id === item.country_id)?.name_ar}</td>
                    <td>{cities?.find((city) => city.id === item.city_id)?.name_ar}</td>
                    <td>
                      <Toggleswitch
                        switchId={item.id}
                        nameSwitch={`services-${item.id}`}
                        value={item.is_active === "1"}
                        onChange={(newValue) => handleToggleSwitch(newValue, item.id)}
                      />
                    </td>
                    <td>
                      <IconsTable
                        typeIcon1={faEye}
                        functionEye={() => navigate(`databranches/${item.id}`)}
                        typeIcon2={faEdit}
                        functionEdit={() => navigate(`databranches-edit/${item.id}`)}
                        isDeleteIcon={true}
                        typeIcon3={faTrash}
                        functionDelete={() => handleDeleteBranch(item.id)}
                      />
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default BranchesAdmin;
