// @ts-nocheck
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteAdminService,
  getServices,
  getVendors,
  updateService,
} from "store/admin/adminActions";
import Swal from "sweetalert2";
import "./ServicesPage";

const AdminServicesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  const [vendors, setVendors] = useState({});
  const [paginationData, setPaginationData] = useState({
    currentPageItemCounts: 0,
    totalPagesCount: 0,
    totalItemsCount: 0,
    limit: 10,
    currentPage: 1,
  });
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(
    async (fetchFunction, params, dataHandler) => {
      setIsLoading(true);
      try {
        const result = await dispatch(fetchFunction(params)).then(unwrapResult);
        dataHandler(result.data);
        setPaginationData((prevState) => ({
          ...prevState,
          currentPageItemCounts: result.data.data?.length || 0,
          totalItemsCount: result.data.total || 0,
          totalPagesCount: Math.ceil(result.data.total / prevState.limit) || 0,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  const handleServicesData = useCallback((data) => {
    setServices(data.data);
  }, []);

  const handleVendorsData = useCallback((data) => {
    setVendors(
      data.data.reduce((acc, curr) => {
        acc[curr.id] = { name_ar: curr.name, name_en: curr.name };
        return acc;
      }, {}),
    );
  }, []);

  useEffect(() => {
    setPaginationData((prev) => ({ ...prev, currentPage: 1 }));
  }, [search]);

  useEffect(() => {
    const { limit, currentPage } = paginationData;
    fetchData(getVendors, { per_page: 1000, page: currentPage, search }, handleVendorsData);
    fetchData(getServices, { per_page: limit, page: currentPage, search }, handleServicesData);
  }, [
    fetchData,
    paginationData.limit,
    paginationData.currentPage,
    search,
    handleServicesData,
    handleVendorsData,
  ]);

  const handleToggleSwitch = async (newValue, index) => {
    try {
      await dispatch(
        updateService({
          ...services[index],
          is_active: newValue ? "1" : "0",
        }),
      ).then(unwrapResult);

      const updatedServices = [...services];
      updatedServices[index].is_active = newValue ? "1" : "0";
      setServices(updatedServices);
      toast.success("تم التعديل بنجاح");
    } catch (error) {
      console.error("Error updating service:", error);
      toast.error("فشلت العملية");
    }
  };

  const handleDeleteService = async (id) => {
    try {
      Swal.fire({
        title: "هل أنت متأكد؟",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "نعم، احذف",
        cancelButtonText: "إلغاء",
        confirmButtonColor: "var(--bs-danger)",
        cancelButtonColor: "var(--bs-primary)",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteAdminService(id))
            .then(unwrapResult)
            .then(() => {
              toast.success("تم الحذف بنجاح");
              fetchData(
                getServices,
                { per_page: paginationData.limit, page: paginationData.currentPage, search },
                handleServicesData,
              );
            });
        }
      });
    } catch (error) {
      console.error("Error deleting service:", error);
      toast.error("فشلت العملية");
    }
  };

  const renderTableRow = useCallback(
    (item, index) => (
      <tr key={`service-${item.id}`}>
        <td>{item.name_ar}</td>
        <td>{vendors[item.vendor_id]?.name_ar}</td>
        <td>
          <Toggleswitch
            switchId={item.id}
            nameSwitch={`admin-services-${item.id}`}
            value={item.is_active === "1"}
            onChange={(newValue) => handleToggleSwitch(newValue, index)}
          />
        </td>
        {!item.isEmpty && (
          <td>
            <IconsTable
              typeIcon1={faEye}
              functionEye={() => navigate(`servicesDetails/${item.id}`)}
              typeIcon2={faEdit}
              functionEdit={() => navigate(`editService/${item.id}`)}
              typeIcon3={faTrash}
              isDeleteIcon={true}
              functionDelete={() => {
                handleDeleteService(item.id);
              }}
            />
          </td>
        )}
      </tr>
    ),
    [vendors, navigate],
  );

  return (
    <>
      <HelmetInfo titlePage="الخدمات" />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title="الخدمات"
          isShowLeftContent={true}
          contentPageLeft={
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <SearchHeaderPage onSearchChange={setSearch} />
            </div>
          }
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="table-content mt-3 table-content-2">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">اسم الخدمة</th>
                  <th scope="col">اسم مزود الخدمة</th>
                  <th scope="col">تفعيل</th>
                  <th scope="col">الاجراءات</th>
                </>
              }
              dataTbody={<>{services.map(renderTableRow)}</>}
            />
            {services.length === 0 && (
              <p className="mx-auto w-100 text-center">لا يوجد عناصر لإظهارها</p>
            )}
          </div>
          <Pagination
            limit={paginationData.limit}
            currentPage={paginationData.currentPage}
            setLimit={(limit) => setPaginationData((prev) => ({ ...prev, limit, currentPage: 1 }))}
            setCurrentPage={(currentPage) =>
              setPaginationData((prev) => ({ ...prev, currentPage }))
            }
            limitOptions={[10, 20, 30, 40]}
            currentPageItemsCount={paginationData.currentPageItemCounts}
            totalItemsCount={paginationData.totalItemsCount}
            totalPagesCount={paginationData.totalPagesCount}
            isShowTotalPagesCount={true}
          />
        </>
      )}
    </>
  );
};

export default AdminServicesPage;
