import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import BasicInformation from "Components/ProfileInfo/ProfilePersonly/BasicInformation";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProfileDetails } from "store/customer/customerActions";
import imgBg from "../../../assets/images/bg/profile.png";
import imgUser from "../../../assets/images/userImg/user.png";
import "./PresonalProfile.css";

const PresonalProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState([]);

  const getProfile = () => {
    setIsLoading(true);
    dispatch(getProfileDetails())
      .then(unwrapResult)
      .then((res) => {
        setProfileInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const goToUpdatePersonalData = () => {
    navigate("/profile/updatepersonaldata", {
      state: {
        profileInfo: profileInfo,
      },
    });
  };

  return (
    <section style={{ minHeight: "60vh" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="profile-page-info padding-bottom-70">
          <div className="container">
            <div className="header-page-profile img-bg" style={{ backgroundImage: `url(${imgBg})` }}></div>
            <div className="all-info-profile-content" data-aos="fade-up">
              <div className="header-profile">
                <div className="img-user">
                  <img
                    src={profileInfo.image ?? imgUser}
                    width={"90px"}
                    height={"90px"}
                    style={{ borderRadius: "50%" }}
                    alt="img user"
                  />
                </div>
                <div className="icon-edit" onClick={goToUpdatePersonalData}>
                  <FontAwesomeIcon className="icon" icon={faEdit} />
                </div>
              </div>

              <Tabs defaultActiveKey="basicInformation" id="uncontrolled-tab-example" className="mb-3 tabs-profile">
                <Tab className="tab-one" eventKey="basicInformation" title="البيانات الأساسية">
                  <BasicInformation profileInfo={profileInfo} goToUpdatePersonalData={goToUpdatePersonalData} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PresonalProfile;
