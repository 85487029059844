import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "Components/CustomModal/CustomModal";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logoutAction } from "store/auth/authActions";
import { updatePassword } from "store/customer/customerActions";
import * as Yup from "yup";

const ModalChangePassword = ({ showModalChangePassword, hideModalChangePassword }) => {
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("كلمة السر الحالية مطلوبة")
      .min(8, "يجب ان تكون كلمة السر اكثر من 8 احرف وارقام"),
    password: Yup.string().required("كلمة السر مطلوبة").min(8, "يجب ان تكون كلمة السر اكثر من 8 احرف وارقام"),
    rePassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "كلمة السر غير متطابقة")
      .required("تأكيد كلمة السر"),
  });

  const initialValues = {
    oldPassword: "",
    password: "",
    rePassword: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = (values, { resetForm }) => {
    dispatch(
      updatePassword({
        old_password: values.oldPassword,
        password: values.password,
        current_password: values.oldPassword,
        password_confirmation: values.rePassword,
      })
    ).then((res) => {
      if (res.payload.success === true) {
        resetForm();
        hideModalChangePassword();
        dispatch(logoutAction());
        setTimeout(() => {
          toast.success("تم تغير كلمة المرور بنجاح");
          navigate("/login");
        }, 1000);
      } else {
        console.log(res.payload);
        // toast.error(res.payload.message);
      }
    });
  };

  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "rePassword") {
      setShowRePassword(!showRePassword);
    } else if (field === "oldPassword") {
      setShowPasswordOld(!showPasswordOld);
    }
  };

  return (
    <CustomModal
      show={showModalChangePassword}
      onHide={hideModalChangePassword}
      title={"تغير كلمة المرور"}
      newClass={"modal-change-password"}
    >
      {/* ========== START ALL REMOVE ACCOUNT =========== */}
      <div className="all-change-password">
        {/* ========== START FORM FIELD ========= */}
        <FormField initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          <div className="input-pass position-relative mb-4">
            <InputFiled
              label="كلمة المرور الحالية"
              name="oldPassword"
              placeholder=""
              success
              type={showPasswordOld ? "text" : "password"}
            />

            <button className="icon-eye-button" onClick={() => togglePasswordVisibility("oldPassword")}>
              {showPasswordOld ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
            </button>
          </div>
          <div className="input-pass position-relative mb-4">
            <InputFiled
              label="كلمة المرور الجديدة"
              name="password"
              placeholder=""
              success
              type={showPassword ? "text" : "password"}
            />

            <button className="icon-eye-button" onClick={() => togglePasswordVisibility("password")}>
              {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
            </button>
          </div>
          <div className="input-pass position-relative">
            <InputFiled
              label="تاكيد كلمة المرور "
              name="rePassword"
              placeholder="تأكيد كلمة المرور"
              success
              type={showRePassword ? "text" : "password"}
            />

            <button className="icon-eye-button" onClick={() => togglePasswordVisibility("rePassword")}>
              {showRePassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
            </button>
          </div>

          <button type="submit" className="main-btn btn-submit mt-5 w-100">
            حفظ
          </button>
        </FormField>
        {/* ========== END FORM FIELD ========= */}
      </div>
      {/* ========== END ALL REMOVE ACCOUNT =========== */}
    </CustomModal>
  );
};

export default ModalChangePassword;
