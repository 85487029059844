import { unwrapResult } from "@reduxjs/toolkit";
import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { editCanncelationReasons } from "store/general/generalActions";

const ModalEditReason = ({
  id,
  data,
  showModalEditReason,
  hideModalEditReason,
  fetchData,
}) => {
  const [value, setValue] = useState(data?.reason_ar || "");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(editCanncelationReasons({ id, ...data, reason_ar: value }))
      .then(unwrapResult)
      .then(() => {
        hideModalEditReason();
        toast.success("تم التعديل بنجاح.");
        fetchData();
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    console.log(data, "data");
    if (data) {
      setValue(data.reason_ar);
    }
  }, [data]);

  return (
    <CustomModal
      show={showModalEditReason}
      onHide={hideModalEditReason}
      title={"تعديل السبب"}
      newClass={"modal-edit-reason"}
    >
      <div className="form-modal">
        <form action="">
          <div className="form-input-one mb-3">
            <label htmlFor="exampleInputReasonEdit" className="form-label">
              السبب
            </label>
            <textarea
              value={value}
              className="form-control"
              id="exampleInputReasonEdit"
              rows={5}
              onChange={(e) => setValue(e.target.value)}
            ></textarea>
          </div>

          <button
            onClick={() => handleSubmit()}
            className="main-btn w-100 mt-4"
            type="button"
          >
            حفظ
          </button>
        </form>
      </div>
    </CustomModal>
  );
};

export default ModalEditReason;
