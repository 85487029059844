import { faBox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPackages } from "../../../../store/admin/adminActions";

const AdminReportPackages = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [totalPackagesCount, setTotalPackagesCount] = useState(0);

  const fetchPackagesReport = useCallback(() => {
    setIsLoading(true);
    const params = {
      per_page: 100,
      limit: 100,
      sort: "DESC",
      paginate: "true",
    };
    dispatch(getPackages(params))
      .then(unwrapResult)
      .then((res) => {
        setPackages(res.data.data);
        setTotalPackagesCount(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchPackagesReport();
  }, [fetchPackagesReport]);

  if (isLoading) return <Loader />;
  if (!packages.length) return null;

  // Sort packages by subscriptions count
  const sortedPackages = [...packages].sort(
    (a, b) => b.subscriptions_count - a.subscriptions_count,
  );

  return (
    <div>
      <div className="report-summary">
        <div className="summary-card card-shadow card-hover">
          <div className="card-content">
            <h3>{totalPackagesCount}</h3>
            <p>عدد الباقات</p>
          </div>
          <FontAwesomeIcon icon={faBox} className="icon" />
        </div>
      </div>

      <div className="report-table" data-aos="fade-up">
        <h3 className="mb-4">الباقات</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>الباقة</th>
              <th>نوع الباقة</th>
              <th>المدة</th>
              <th>السعر الشهري</th>
              <th>السعر السنوي</th>
              <th>عدد الفروع</th>
              <th>عدد الموظفين</th>
              <th>عدد العملاء</th>
              <th>عدد الاشتراكات</th>
              <th>العمولة</th>
              <th>الحالة</th>
            </tr>
          </thead>
          <tbody>
            {sortedPackages.map((pkg, index) => (
              <tr key={pkg.id}>
                <td>{index + 1}</td>
                <td>{pkg.name_ar}</td>
                <td>{pkg.type === "public" ? "عامة" : "خاصة"}</td>
                <td>{pkg.days} يوم</td>
                <td>{pkg.month_price}</td>
                <td>{pkg.year_price}</td>
                <td>{pkg.branches}</td>
                <td>{pkg.employees}</td>
                <td>{pkg.customers}</td>
                <td>{pkg.subscriptions_count}</td>
                <td>{pkg.commission}%</td>
                <td>{pkg.is_active === "1" ? "نشط" : "غير نشط"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReportPackages;
