import DetailsOrderBooking from "Components/BookingsInfo/DetailsOrderBooking/DetailsOrderBooking";
import Layout from "Components/Layout/Layout";
import AddEditAddress from "Components/ProfileInfo/AddEditAddress/AddEditAddress";
import PresonalProfile from "Components/ProfileInfo/PresonalProfile/PresonalProfile";
import UpdatePersonalData from "Components/ProfileInfo/UpdatePersonalData/UpdatePersonalData";
import LayoutDasboard from "Dashboard/Components/Layout/LayoutDasboard";
import AccountsUsers from "Dashboard/Pages/AccountsUsers/AccountsUsers";
import DataAccountUser from "Dashboard/Pages/AccountsUsers/DataAccountUser";
import EditAdminOffer from "Dashboard/Pages/Admin/Offers/EditOffer";
import EditAdminService from "Dashboard/Pages/Admin/Services/EditService";
import LoginDashboard from "Dashboard/Pages/Auth/LoginDashboard";
import BookingsDashboard from "Dashboard/Pages/BookingsDashboard/BookingsDashboard";
import DetailsBooking from "Dashboard/Pages/BookingsDashboard/DetailsBooking";
import BranchAdminAdd from "Dashboard/Pages/Branches/Admin/BranchAdminAdd";
import BranchAdminData from "Dashboard/Pages/Branches/Admin/BranchAdminData";
import BranchesAdmin from "Dashboard/Pages/Branches/Admin/BranchesAdmin";
import BranchesAdminEdit from "Dashboard/Pages/Branches/Admin/BranchesAdminEdit";
import BranchData from "Dashboard/Pages/Branches/BranchData";
import BranchEditAdd from "Dashboard/Pages/Branches/BranchEditAdd";
import Branches from "Dashboard/Pages/Branches/Branches";
import Calendar from "Dashboard/Pages/Calendar/Calendar";
import EditCategory from "Dashboard/Pages/Categories/EditCategory";
import ServiceCategories from "Dashboard/Pages/Categories/serviceCategries";
import CommonFaq from "Dashboard/Pages/CommonFaq/CommonFaq";
import AddNewEmployee from "Dashboard/Pages/CompleteAccountActivation/AddNewEmployee";
import CompleteAccountActivation from "Dashboard/Pages/CompleteAccountActivation/CompleteAccountActivation";
import PackageOffers from "Dashboard/Pages/CompleteAccountActivation/PackageOffers";
import ContactUs from "Dashboard/Pages/ContactUs/ContactUs";
import DetailsDiscountCodes from "Dashboard/Pages/DiscountCodes/DetailsDiscountCodes";
import DiscountCodes from "Dashboard/Pages/DiscountCodes/DiscountCodes";
import EditAddDiscountCodes from "Dashboard/Pages/DiscountCodes/EditAddDiscountCodes";
import AdminAddEmployee from "Dashboard/Pages/Employees/AdminAddEmployee";
import AdminEditEmployee from "Dashboard/Pages/Employees/AdminEditEmployee";
import AdminEmployees from "Dashboard/Pages/Employees/AdminEmployees";
import DataEmployee from "Dashboard/Pages/Employees/DataEmployee";
import EditAddEmployee from "Dashboard/Pages/Employees/EditAddEmployee";
import Employees from "Dashboard/Pages/Employees/Employees";
import DetailsHelpCenter from "Dashboard/Pages/HelpCenter/DetailsHelpCenter";
import HelpCenter from "Dashboard/Pages/HelpCenter/HelpCenter";
import HomeDashobard from "Dashboard/Pages/Home/HomeDashobard";
import HomeLandinPage from "Dashboard/Pages/LandingPage/HomeLandinPage";
import Cities from "Dashboard/Pages/Locations/Cities";
import CityForm from "Dashboard/Pages/Locations/CityForm";
import Countries from "Dashboard/Pages/Locations/Countries";
import CountryForm from "Dashboard/Pages/Locations/CountryForm";
import RegionForm from "Dashboard/Pages/Locations/RegionForm";
import Regions from "Dashboard/Pages/Locations/Regions";
import NoticesDash from "Dashboard/Pages/NoticesDash/NoticesDash";
import AdminOffersPage from "Dashboard/Pages/Offers/AdminOffersPage";
import DetailsOffer from "Dashboard/Pages/Offers/DetailsOffer";
import EditAddOffer from "Dashboard/Pages/Offers/EditAddOffer";
import OffersInfo from "Dashboard/Pages/Offers/OffersInfo";
import AddPackage from "Dashboard/Pages/Packages/AddPackage";
import PackageInfo from "Dashboard/Pages/Packages/PackageInfo";
import Packages from "Dashboard/Pages/Packages/Packages";
import Pos from "Dashboard/Pages/Pos/Pos";
import PrivacyPolicy from "Dashboard/Pages/PrivacyPolicy/PrivacyPolicy";
import ChangeEmail from "Dashboard/Pages/ProfileDashboard/ChangeEmail";
import ChangeNumberPhone from "Dashboard/Pages/ProfileDashboard/ChangeNumberPhone";
import ProfileDashboard from "Dashboard/Pages/ProfileDashboard/ProfileDashboard";
import ChangePackage from "Dashboard/Pages/ProviderSubscription/ChangePackage";
import ProviderSubscription from "Dashboard/Pages/ProviderSubscription/ProviderSubscription";
import RatesPage from "Dashboard/Pages/RatesPage/RatesPage";
import ShowRates from "Dashboard/Pages/RatesPage/ShowRates";
import RegisterServiceProviderAccount from "Dashboard/Pages/RegisterServiceProviderAccount/RegisterServiceProviderAccount";
import DetailsRole from "Dashboard/Pages/RolesPowers/DetailsRole";
import EditAddRole from "Dashboard/Pages/RolesPowers/EditAddRole";
import RolesPowers from "Dashboard/Pages/RolesPowers/RolesPowers";
import DetailsDataUsers from "Dashboard/Pages/ServiceProviderAccounts/DetailsDataUsers";
import ProfileEditeAdd from "Dashboard/Pages/ServiceProviderAccounts/ProfileEditeAdd";
import ServiceProviderAccounts from "Dashboard/Pages/ServiceProviderAccounts/ServiceProviderAccounts";
import ServiceProviderSubDetails from "Dashboard/Pages/ServiceProviderSubscriptions/ServiceProviderSubDetails";
import ServiceProviderSubscriptions from "Dashboard/Pages/ServiceProviderSubscriptions/ServiceProviderSubscriptions";
import AdminServicesPage from "Dashboard/Pages/Services/AdminServicePage";
import EditAddServicesDetails from "Dashboard/Pages/Services/EditAddServicesDetails";
import ServicesDetailsInfo from "Dashboard/Pages/Services/ServicesDetailsInfo";
import ServicesPage from "Dashboard/Pages/Services/ServicesPage";
import AdminNotificationSettings from "Dashboard/Pages/Settings/AdminNotificationSettings";
import AdminPaymentSettings from "Dashboard/Pages/Settings/AdminPaymentSettings";
import AdminSettings from "Dashboard/Pages/Settings/AdminSettings";
import ClientReasonsCancelingReservation from "Dashboard/Pages/Settings/ClientReasonsCancelingReservation";
import CompanyActivity from "Dashboard/Pages/Settings/CompanyActivityData/CompanyActivity";
import EditCompanyActivityData from "Dashboard/Pages/Settings/CompanyActivityData/EditCompanyActivityData";
import ReasonsCancelingReservation from "Dashboard/Pages/Settings/ReasonsCancelingReservation";
import ReceiveNotifications from "Dashboard/Pages/Settings/ReceiveNotifications/ReceiveNotifications";
import ServiceProviderTerms from "Dashboard/Pages/Settings/ServiceProviderTerms";
import Settings from "Dashboard/Pages/Settings/Settings";
import AddEditNotificationCustomer from "Dashboard/Pages/Settings/SettingsNotificationCustomer/AddEditNotificationCustomer";
import DetailsNotificationCustomer from "Dashboard/Pages/Settings/SettingsNotificationCustomer/DetailsNotificationCustomer";
import SettingsNotificationCustomer from "Dashboard/Pages/Settings/SettingsNotificationCustomer/SettingsNotificationCustomer";
import SettingsPay from "Dashboard/Pages/Settings/SettingsPay/SettingsPay";
import WebsiteSettings from "Dashboard/Pages/Settings/WebsiteSettings/WebsiteSettings";
import AdminReportDetails from "Dashboard/Pages/StatisticsReports/Admin Reports/AdminReportDetails";
import StatisticsReports from "Dashboard/Pages/StatisticsReports/StatisticsReports";
import ReportDetails from "Dashboard/Pages/StatisticsReports/Vendor Reports/ReportDetails";
import DetailsTaxInvoices from "Dashboard/Pages/TaxInvoices/DetailsTaxInvoices";
import TaxInvoices from "Dashboard/Pages/TaxInvoices/TaxInvoices";
import TermsConditionsDash from "Dashboard/Pages/TermsConditions/TermsConditionsDash";
import ProviderWalletDetails from "Dashboard/Pages/Wallet/ProviderWalletDetails";
import ProviderWallets from "Dashboard/Pages/Wallet/ProviderWallets";
import Wallet from "Dashboard/Pages/Wallet/Wallet";
import About from "Pages/About/About";
import CustomerLogin from "Pages/Auth/Login/Login";
import NewPassword from "Pages/Auth/NewPassword/NewPassword";
import Otp from "Pages/Auth/Otp/Otp";
import Register from "Pages/Auth/Register/Register";
import RegisterNewAccount from "Pages/Auth/RegisterNewAccount/RegisterNewAccount";
import RestorePassword from "Pages/Auth/RestorePassword/RestorePassword";
import Bookings from "Pages/Bookings/Bookings";
import CommonQuestions from "Pages/CommonQuestions/CommonQuestions";
import Contact from "Pages/Contact/Contact";
import FavoriteProducts from "Pages/FavoriteProducts/FavoriteProducts";
import Home from "Pages/Home/Home";
import NotFound from "Pages/NotFound/NotFound";
import Notices from "Pages/Notices/Notices";
import Privacy from "Pages/Privacy/Privacy";
import Profile from "Pages/Profile/Profile";
import Services from "Pages/Services/Services";
import ServiceProviderDetails from "Pages/ServicesProviders/ServiceDetails/ServiceProviderDetails";
import ErrorPay from "Pages/ServicesProviders/ServiceDetails/StepsServices/PagesSuccessErrorPay/ErrorPay";
import SuccessPay from "Pages/ServicesProviders/ServiceDetails/StepsServices/PagesSuccessErrorPay/SuccessPay";
import StepsServicesContent from "Pages/ServicesProviders/ServiceDetails/StepsServices/StepsServicesContent";
import ServicesProviders from "Pages/ServicesProviders/ServicesProviders";
import TermsConditions from "Pages/TermsConditions/TermsConditions";
import { Outlet, createBrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";

let routers = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "about", element: <About /> },
      { path: "contact", element: <Contact /> },
      { path: "services", element: <Services /> },
      {
        path: "servicesproviders",
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          { path: "", element: <ServicesProviders /> },
          { path: ":idCardService", element: <ServiceProviderDetails /> },
        ],
      },
      { path: "stepsservicescontent", element: <StepsServicesContent /> },

      { path: "privacy", element: <Privacy /> },
      { path: "termsconditions", element: <TermsConditions /> },
      { path: "notices", element: <Notices /> },
      { path: "commonquestions", element: <CommonQuestions /> },
      { path: "favoriteproducts", element: <FavoriteProducts /> },
      {
        path: "profile",
        element: <Profile />,
        children: [
          { index: true, element: <PresonalProfile /> },
          { path: "updatepersonaldata", element: <UpdatePersonalData /> },
          { path: "addeditaddress", element: <AddEditAddress /> },
        ],
      },
      {
        path: "bookings",
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          { path: "", element: <Bookings /> },
          { path: ":idCard", element: <DetailsOrderBooking /> },
        ],
      },

      {
        path: "*",
        element: <NotFound newClass={"padding-bottom-100"} routePage={"/"} />,
      },
    ],
  },
  { path: "login", element: <CustomerLogin /> },
  { path: "registernewaccount", element: <RegisterNewAccount /> },
  { path: "register", element: <Register /> },
  { path: "restorepassword", element: <RestorePassword /> },
  { path: "newpassword", element: <NewPassword /> },
  { path: "otp", element: <Otp /> },
  { path: "successpay", element: <SuccessPay /> },
  { path: "errorpay", element: <ErrorPay /> },

  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/dashboard/*",
        children: [
          {
            path: "admin",
            element: <LayoutDasboard />,
            children: [
              //--------- الرئيسه----------
              { path: "homedashboard", element: <HomeDashobard /> },
              //---------  الاحصائيات و التقارير----------
              {
                path: "statisticsreports",
                element: <Outlet />,
                children: [
                  { path: "", element: <StatisticsReports /> },
                  { path: ":reportId", element: <AdminReportDetails /> },
                ],
              },
              //---------الادوار و الصلاحيات---------
              {
                path: "rolespowers",
                element: <Outlet />,
                children: [
                  { path: "", element: <RolesPowers /> },
                  {
                    path: "detailsRole/:detailsrole",
                    element: <DetailsRole />,
                  },
                  {
                    path: "editAddRole/:editaddrole",
                    element: <EditAddRole />,
                  },
                ],
              },
              //----------حسابات مزودي الخدمه-----------
              {
                path: "serviceprovideraccounts",
                element: <Outlet />,
                children: [
                  { path: "", element: <ServiceProviderAccounts /> },
                  {
                    path: "details/:id",
                    element: <DetailsDataUsers />,
                  },
                  {
                    path: "profileaddedit/:id",
                    element: <ProfileEditeAdd />,
                  },
                ],
              },
              //-------------حسابات العملاء-----------------
              {
                path: "accountsusers",
                element: <Outlet />,
                children: [
                  { path: "", element: <AccountsUsers /> },
                  { path: ":id", element: <DataAccountUser /> },
                ],
              },

              // -------- packages
              {
                path: "packages",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                children: [
                  { path: "", element: <Packages /> },
                  { path: ":id", element: <PackageInfo /> },
                  {
                    path: "add",
                    element: <AddPackage />,
                  },
                ],
              },
              //----------اشتراكات مزودي الخدمه-----------
              {
                path: "serviceprovidersubscriptions",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                children: [
                  { path: "", element: <ServiceProviderSubscriptions /> },
                  { path: ":id", element: <ServiceProviderSubDetails /> },
                  { path: ":changepackage", element: <ChangePackage /> },
                ],
              },
              //----------الخدمات------------
              {
                path: "servicespage",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                children: [
                  { path: "", element: <AdminServicesPage /> },
                  {
                    path: "servicesDetails/:idServicesDetails",
                    element: <ServicesDetailsInfo />,
                  },
                  {
                    path: "editService/:id",
                    element: <EditAdminService />,
                  },
                ],
              },
              //----------العروض------------
              {
                path: "offersInfo",
                element: <Outlet />,
                children: [
                  { path: "", element: <AdminOffersPage /> },
                  {
                    path: "detailsOffers/:id",
                    element: <DetailsOffer />,
                  },
                  {
                    path: "editOffer/:id",
                    element: <EditAdminOffer />,
                  },
                ],
              },
              //----------فئات الخدمات------------
              {
                path: "servicesCategories",
                element: <Outlet />,
                children: [
                  { path: "", element: <ServiceCategories /> },
                  { path: ":id/edit", element: <EditCategory /> },
                ],
              },

              //----------الحجوزات------------
              {
                path: "bookingsDashboard",
                element: <Outlet />,
                children: [
                  { path: "", element: <BookingsDashboard /> },
                  {
                    path: ":id",
                    element: <DetailsBooking />,
                  },
                ],
              },
              //----------الاعدادات------------
              {
                path: "adminSettings",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                children: [
                  { path: "", element: <AdminSettings /> },
                  {
                    path: "admin-reasonsCancelReservation",
                    element: <ReasonsCancelingReservation />,
                  },
                  {
                    path: "admin-clientReasonsCancelReservation",
                    element: <ClientReasonsCancelingReservation />,
                  },
                  {
                    path: "admin-serviceProviderTermsPage",
                    element: <ServiceProviderTerms />,
                  },
                  {
                    path: "admin-websiteSettings",
                    element: <WebsiteSettings />,
                  },
                  {
                    path: "admin-receiveNotifications",
                    element: <ReceiveNotifications />,
                  },
                  {
                    path: "admin-settingsNotificationCustomer",
                    element: (
                      <>
                        <Outlet />
                      </>
                    ),
                    children: [
                      { path: "", element: <SettingsNotificationCustomer /> },
                      {
                        path: "detailsNot/:detailsNotificationCustomer",
                        element: <DetailsNotificationCustomer />,
                      },
                      {
                        path: "addEdit/:addEditNotificationCustomer",
                        element: <AddEditNotificationCustomer />,
                      },
                    ],
                  },
                  {
                    path: "companyActivityData",
                    element: (
                      <>
                        <Outlet />
                      </>
                    ),
                    children: [
                      { path: "", element: <CompanyActivity /> },
                      {
                        path: "editCompanyActivityData",
                        element: <EditCompanyActivityData />,
                      },
                    ],
                  },
                  {
                    path: "admin-settingsPay",
                    element: <AdminPaymentSettings />,
                  },
                  {
                    path: "admin-notificationSettings",
                    element: <AdminNotificationSettings />,
                  },
                ],
              },
              { path: "privacyPolicy", element: <PrivacyPolicy /> },
              { path: "termsConditionsDash", element: <TermsConditionsDash /> },
              { path: "contactUs", element: <ContactUs /> },
              { path: "commonFaq", element: <CommonFaq /> },
              {
                path: "helpcenter",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                children: [
                  { path: "", element: <HelpCenter /> },
                  {
                    path: ":idDetailsHelpCenter",
                    element: <DetailsHelpCenter />,
                  },
                ],
              },

              {
                path: "noticesDash",
                element: <NoticesDash />,
              },
              {
                path: "profileDashboard",
                element: <ProfileDashboard />,
              },
              {
                path: "calendar",
                element: <Calendar />,
              },
              { path: "pos", element: <Pos /> },

              {
                path: "*",
                element: <NotFound newClass={"padding-bottom-70"} routePage={"homedashboard"} />,
              },

              {
                path: "providerwallets",
                element: <Outlet />,
                children: [
                  { path: "", element: <ProviderWallets /> },
                  { path: ":id", element: <ProviderWalletDetails /> },
                ],
              },
              {
                path: "systemwallet",
                element: <Wallet />,
              },
              {
                path: "invoices",
                element: <Outlet />,
                // children: [{ path: "", element: <Invoices /> }],
              },
              {
                path: "employees",
                element: <Outlet />,
                children: [
                  { path: "", element: <AdminEmployees /> },
                  { path: ":id", element: <DataEmployee /> },
                  { path: "edit/:id", element: <AdminEditEmployee /> },
                  { path: "add", element: <AdminAddEmployee /> },
                ],
              },
              {
                path: "countries",
                element: <Outlet />,
                children: [
                  { path: "", element: <Countries /> },
                  { path: "add", element: <CountryForm /> },
                  { path: "edit/:id", element: <CountryForm /> },
                ],
              },
              {
                path: "cities",
                element: <Outlet />,
                children: [
                  { path: "", element: <Cities /> },
                  { path: "add", element: <CityForm /> },
                  { path: "edit/:id", element: <CityForm /> },
                ],
              },
              {
                path: "regions",
                element: <Outlet />,
                children: [
                  { path: "", element: <Regions /> },
                  { path: "add", element: <RegionForm /> },
                  { path: "edit/:id", element: <RegionForm /> },
                ],
              },
              {
                path: "admin-branches",
                element: <Outlet />,
                children: [
                  { path: "", element: <BranchesAdmin /> },
                  { path: "databranches/:id", element: <BranchAdminData /> },
                  { path: "databranches-edit/:id", element: <BranchesAdminEdit /> },
                  { path: "add", element: <BranchAdminAdd /> },
                ],
              },
              {
                path: "discountCodes",
                element: <Outlet />,
                children: [
                  { path: "", element: <DiscountCodes /> },
                  { path: "discountDetails/:id", element: <DetailsDiscountCodes /> },
                  { path: "editAddDiscountCode/:id", element: <EditAddDiscountCodes /> },
                ],
              },
              {
                path: "taxinvoices",
                element: <TaxInvoices />,
              },
              {
                path: "settings",
                element: <Settings />,
              },
            ],
          },
          {
            path: "vendor",
            element: <LayoutDasboard />,
            children: [
              { path: "homedashboard", element: <HomeDashobard /> },
              {
                path: "statisticsreports",
                element: <Outlet />,
                children: [
                  { path: "", element: <StatisticsReports /> },
                  { path: ":reportId", element: <ReportDetails /> },
                ],
              },
              {
                path: "providersubscription",
                element: <Outlet />,
                children: [
                  { path: "", element: <ProviderSubscription /> },
                  { path: ":changepackage", element: <ChangePackage /> },
                ],
              },
              {
                path: "branches",
                element: <Outlet />,
                children: [
                  { path: "", element: <Branches /> },
                  { path: "databranches/:id", element: <BranchData /> },
                  { path: "databrancheseditadd/:id", element: <BranchEditAdd /> },
                  { path: "databrancheseditadd", element: <BranchEditAdd /> },
                ],
              },
              { path: "wallet", element: <Wallet /> },
              {
                path: "accountsusers",
                element: <Outlet />,
                children: [
                  { path: "", element: <AccountsUsers /> },
                  { path: ":id", element: <DataAccountUser /> },
                ],
              },
              {
                path: "employees",
                element: <Outlet />,
                children: [
                  { path: "", element: <Employees /> },
                  { path: ":id", element: <DataEmployee /> },
                  { path: "edit/:id", element: <EditAddEmployee /> },
                  { path: "add", element: <EditAddEmployee /> },
                ],
              },
              {
                path: "servicespage",
                element: <Outlet />,
                children: [
                  { path: "", element: <ServicesPage /> },
                  { path: "servicesDetails/:id", element: <ServicesDetailsInfo /> },
                  { path: "editaddServicesDetails", element: <EditAddServicesDetails /> },
                  { path: "editaddServicesDetails/:id", element: <EditAddServicesDetails /> },
                ],
              },
              {
                path: "bookingsDashboard",
                element: <Outlet />,
                children: [
                  { path: "", element: <BookingsDashboard /> },
                  { path: ":detailsBooking", element: <DetailsBooking /> },
                ],
              },
              {
                path: "ratespage",
                element: <Outlet />,
                children: [
                  { path: "", element: <RatesPage /> },
                  { path: "showRatesDetails/:idRatesDetails", element: <ShowRates /> },
                ],
              },
              {
                path: "taxinvoices",
                element: <Outlet />,
                children: [
                  { path: "", element: <TaxInvoices /> },
                  { path: ":idDetailsTaxInvoice", element: <DetailsTaxInvoices /> },
                ],
              },
              {
                path: "offersInfo",
                element: <Outlet />,
                children: [
                  { path: "", element: <OffersInfo /> },
                  { path: "detailsOffers/:idDetailsOffer", element: <DetailsOffer /> },
                  { path: "editAddOffer", element: <EditAddOffer /> },
                ],
              },
              {
                path: "discountCodes",
                element: <Outlet />,
                children: [
                  { path: "", element: <DiscountCodes /> },
                  { path: "discountDetails/:id", element: <DetailsDiscountCodes /> },
                  { path: "editAddDiscountCode/:id", element: <EditAddDiscountCodes /> },
                ],
              },
              { path: "privacyPolicy", element: <PrivacyPolicy /> },
              { path: "termsConditionsDash", element: <TermsConditionsDash /> },
              { path: "contactUs", element: <ContactUs /> },
              { path: "commonFaq", element: <CommonFaq /> },
              {
                path: "helpcenter",
                element: <Outlet />,
                children: [
                  { path: "", element: <HelpCenter /> },
                  { path: ":idDetailsHelpCenter", element: <DetailsHelpCenter /> },
                ],
              },
              {
                path: "settings",
                element: <Outlet />,
                children: [
                  { path: "", element: <Settings /> },
                  { path: "reasonsCancelReservation", element: <ReasonsCancelingReservation /> },
                  { path: "serviceProviderTermsPage", element: <ServiceProviderTerms /> },
                  { path: "websiteSettings", element: <WebsiteSettings /> },
                  { path: "receiveNotifications", element: <ReceiveNotifications /> },
                  {
                    path: "settingsNotificationCustomer",
                    element: <Outlet />,
                    children: [
                      { path: "", element: <SettingsNotificationCustomer /> },
                      {
                        path: "detailsNot/:detailsNotificationCustomer",
                        element: <DetailsNotificationCustomer />,
                      },
                      {
                        path: "addEdit/:addEditNotificationCustomer",
                        element: <AddEditNotificationCustomer />,
                      },
                    ],
                  },
                  {
                    path: "companyActivityData",
                    element: <Outlet />,
                    children: [
                      { path: "", element: <CompanyActivity /> },
                      { path: "editCompanyActivityData", element: <EditCompanyActivityData /> },
                    ],
                  },
                  { path: "settingsPay", element: <SettingsPay /> },
                ],
              },
              { path: "noticesDash", element: <NoticesDash /> },
              { path: "profileDashboard", element: <ProfileDashboard /> },
              { path: "calendar", element: <Calendar /> },
              { path: "pos", element: <Pos /> },
              {
                path: "*",
                element: <NotFound newClass={"padding-bottom-70"} routePage={"homedashboard"} />,
              },
            ],
          },
        ],
      },
    ],
  },
  { path: "changeEmail", element: <ChangeEmail /> },
  { path: "changeNumberPhone", element: <ChangeNumberPhone /> },
  {
    path: "registerserviceprovideraccount",
    element: <RegisterServiceProviderAccount />,
  },
  { path: "logindashboard", element: <LoginDashboard /> },
  {
    path: "completeaccountactivation",
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      { path: "", element: <CompleteAccountActivation /> },
      { path: "addneweployee", element: <AddNewEmployee /> },
      { path: "packagesoffers", element: <PackageOffers /> },
    ],
  },
  { path: "landingPageDashboard", element: <HomeLandinPage /> },
]);

export default routers;
