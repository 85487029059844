import logo from "../../../assets/images/logo/logo.svg";
import { Link } from "react-router-dom";

const Logo = ({ routePage }) => {
  return (
    <>
      <Link to={routePage} className="logo pt-4 d-flex">
        <img src={logo} className=" object-fit-cover " alt="logo" />
      </Link>
    </>
  );
};

export default Logo;
