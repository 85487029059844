import CardDetailsBooking from "Dashboard/Components/UiDashboard/CardDetailsBooking/CardDetailsBooking";
import { useSelector } from "react-redux";
import iconTicket from "../../../../../assets/images/dashboardImages/icons/ticket.svg";
import iconTicket2 from "../../../../../assets/images/dashboardImages/icons/ticket2.svg";

const InfoDetailsBookings = ({ infoDetailsBookings }) => {
  const role = useSelector((state) => state.auth.role);

  const infoBooking = [
    {
      id: 0,
      key: role === "admin" ? "subscription_total" : "booking_total",
      unit: "ريال",
      text: role === "admin" ? "اجمالي قيمة الاشتراكات" : "اجمالي قيمة الحجوزات",
      icon: iconTicket,
    },
    {
      id: 1,
      key: "booking_count",
      text: role === "admin" ? "اجمالى الحجوزات" : "اجمالى عدد الحجوزات ",
      icon: iconTicket2,
    },
    {
      id: 3,
      key: "customer_count",
      text: " اجمالى العملاء",
      icon: iconTicket,
    },
    {
      id: 4,
      key: role === "admin" ? "vendor_count" : "offer_count",
      text: role === "admin" ? "اجمالي مقدمي الخدمات" : "اجمالى عدد العروض",
      icon: iconTicket2,
    },
  ];

  return (
    <div className="info-details-bookings mt-4" data-aos="fade-left">
      {/* =========== START ROW ======== */}
      <div className="row g-3">
        {infoDetailsBookings &&
          infoBooking.map((item) => {
            return (
              <div className="col-12 col-md-6" key={item.id}>
                <CardDetailsBooking
                  numBooking={infoDetailsBookings[item.key]}
                  text={item.text}
                  icon={item.icon}
                  unit={item.unit}
                />
              </div>
            );
          })}
      </div>
      {/* =========== END ROW ======== */}
    </div>
  );
};

export default InfoDetailsBookings;
