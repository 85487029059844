import { unwrapResult } from "@reduxjs/toolkit";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addPackage } from "store/admin/adminActions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name_ar: Yup.string().required("اسم الباقة مطلوب"),
  name_en: Yup.string().required("اسم الباقة مطلوب"),

  description_ar: Yup.string().required("وصف الباقة مطلوب"),
  description_en: Yup.string().required("وصف الباقة مطلوب"),

  days_price: Yup.number().required("سعر الباقة مطلوب"),
  month_price: Yup.number().required("سعر الباقة مطلوب"),
  quarter_price: Yup.number().required("سعر الباقة مطلوب"),
  half_year_price: Yup.number().required("سعر الباقة مطلوب"),
  year_price: Yup.number().required("سعر الباقة مطلوب"),

  period: Yup.string().required("مطلوب"),
  days: Yup.number()
    .required()
    .when("period", {
      is: "days",
      then: Yup.number().min(1, "يجب ان لا يكون اقل من يوم"),
    }),

  policy: Yup.string().required("مطلوب").min(2),

  branches: Yup.number().required("مطلوب"),
  customers: Yup.number().required("مطلوب"),
  employees: Yup.number().required("مطلوب"),
  sms_messages: Yup.number().required("مطلوب"),
  whatsapp_messages: Yup.number().required("مطلوب"),

  payments: Yup.string().required("مطلوب"),
  subscription_type: Yup.string().required("مطلوب"),
  type: Yup.string().required("مطلوب"),

  is_active: Yup.boolean().required("مطلوب"),
  is_default: Yup.boolean().required("مطلوب"),
  remove_copy_right: Yup.boolean().required("مطلوب"),
});

const AddPackage = () => {
  const initialValues = {
    name_ar: "",
    name_en: "",
    description_ar: "",
    description_en: "",
    days_price: null,
    month_price: null,
    quarter_price: null,
    half_year_price: null,
    year_price: null,
    branches: null,
    customers: null,
    employees: null,
    period: null,
    days: null,
    sms_messages: null,
    whatsapp_messages: null,
    payments: "",
    subscription_type: "",
    type: "",
    policy: "",

    is_active: 1,
    is_default: 1,
    remove_copy_right: 1,
  };

  const dispatch = useDispatch();

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    dispatch(addPackage(values))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم الاضافة بنجاح.");
        resetForm();
      })
      .catch((error) => {
        setErrors(error.errors);
        console.log(error, "error");
      });
  };

  return (
    <>
      <HelmetInfo titlePage={"اضافة باقة"} />
      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"اضافة باقة"}
          routeHomePage={"/dashboard/admin/packages"}
          titleInfoPage={"اضافة باقة"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/packages">
            عودة
          </Link>
        </div>
      </div>

      <div className="form-edit-services" data-aos="fade-up">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="row g-1 g-sm-3">
                <div className="col-12 col-md-6">
                  <InputField
                    name="name_ar"
                    type="text"
                    placeholder={"اسم الباقة بالعربية"}
                    label={"اسم الباقة بالعربية"}
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    name="name_en"
                    type="text"
                    placeholder={"اسم الباقة بالانجليزية"}
                    label={"اسم الباقة بالانجليزية"}
                    success
                  />
                </div>
                <div className="col-12 col-md-12">
                  <InputField
                    name="description_ar"
                    placeholder={"وصف الباقة بالعربية"}
                    label={"وصف الباقة بالعربية"}
                    success
                  />
                </div>
                <div className="col-12 col-md-12">
                  <InputField
                    label={"وصف الباقة بالانجليزية"}
                    placeholder={"وصف الباقة بالانجليزية"}
                    name="description_en"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={" سعر الاشتراك اليومي"}
                    name="days_price"
                    type="number"
                    placeholder={" سعر الاشتراك اليومي"}
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={" سعر الاشتراك الشهري"}
                    name="month_price"
                    type="number"
                    placeholder={" سعر الاشتراك الشهري"}
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={" سعر الاشتراك الربع سنوي"}
                    name="quarter_price"
                    type="number"
                    placeholder={" سعر الاشتراك الربع سنوي"}
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={" سعر الاشتراك النصف سنوي"}
                    name="half_year_price"
                    type="number"
                    placeholder={" سعر الاشتراك النصف سنوي"}
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={" سعر الاشتراك سنوي"}
                    name="year_price"
                    type="number"
                    placeholder={" سعر الاشتراك سنوي"}
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={"عدد الفروع"}
                    placeholder={"عدد الفروع"}
                    name="branches"
                    type="number"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={"عدد العملاء"}
                    placeholder={"عدد العملاء"}
                    name="customers"
                    type="number"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={"عدد الموظفين"}
                    placeholder={"عدد الموظفين"}
                    name="employees"
                    type="number"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={"عدد الرسائل"}
                    placeholder={"عدد الرسائل"}
                    name="sms_messages"
                    type="number"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={"عدد رسائل الواتساب"}
                    placeholder={"عدد رسائل الواتساب"}
                    name="whatsapp_messages"
                    type="number"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField label={"policy"} name="policy" type="text" success />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="payments"
                    label="طريقة الدفع"
                    options={[
                      { value: "cash", label: "كاش" },
                      { value: "percentage", label: "نسبة" },
                    ]}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="period"
                    label="المدة"
                    options={[
                      { value: "days", label: "يوم" },
                      { value: "month", label: "شهر" },
                      { value: "quarter", label: "ربع سنة" },
                      { value: "half_year", label: "نصف سنة" },
                      { value: "year", label: "سنة" },
                    ]}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField label={"عدد الايام"} name="days" type="number" success />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="subscription_type"
                    label="نوع الاشتراك"
                    options={[
                      { value: "subscribe", label: "اشتراك" },
                      { value: "commission", label: "عمولة" },
                      {
                        value: "subscribe_and_commission",
                        label: "اشتراك وعمولة",
                      },
                    ]}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="type"
                    label="النوع"
                    options={[
                      { value: "public", label: "عام" },
                      { value: "private", label: "خاص" },
                    ]}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="is_active"
                    label="تفعيل"
                    options={[
                      { value: 1, label: "نعم" },
                      { value: 0, label: "لا" },
                    ]}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="is_default"
                    label="الافتراضي"
                    options={[
                      { value: 1, label: "نعم" },
                      { value: 0, label: "لا" },
                    ]}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="remove_copy_right"
                    label="ازالة الحقوق"
                    options={[
                      { value: 1, label: "نعم" },
                      { value: 0, label: "لا" },
                    ]}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="main-btn btn-submit px-5 mt-4"
                disabled={isSubmitting}>
                {isSubmitting ? "جاري الحفظ..." : "حفظ"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddPackage;
