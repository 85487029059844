import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getOffers as getOffersAdmin } from "store/admin/adminActions";
import { getOffers, updateOffer } from "store/vendor/vendorActions";

import "./OfferInfo.css";

const OffersInfo = () => {
  const user = useGetAuthUser();

  const [offers, setOffers] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleSwitch = (newValue, offerId, index) => {
    dispatch(
      updateOffer({
        id: offerId,
        is_active: `${newValue ? "1" : "0"}`,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        const updatedData = [...offers];
        updatedData[index].is_active = newValue ? "1" : "0";
        toast.success("تم التعديل بنجاح.");
        setOffers(updatedData);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchOffers = (params) => {
    setIsLoading(true);
    dispatch(getOffers(params))
      .then(unwrapResult)
      .then((res) => {
        setOffers(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setCurrentPage(res.data.current_page || 1);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchAdminOffers = (params) => {
    setIsLoading(true);
    dispatch(getOffersAdmin(params))
      .then(unwrapResult)
      .then((res) => {
        setOffers(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const action = user.type === "vendor" ? fetchOffers : fetchAdminOffers;
    const params =
      user.type === "vendor"
        ? {
            has: "vendor",
            with: ["category"],
            limit: 20,
            offset: 0,
            sort: "DESC",
            paginate: "true",
            per_page: limit,
            page: currentPage,
            deleted: "undeleted",
            resource: "all",
            resource_columns: ["id", "name_ar"],
            columns: ["name_ar", "vendor_id"],
            operand: ["like", "="],
            column_values: [search, user?.model_id],
          }
        : {
            limit: 20,
            offset: 0,
            sort: "DESC",
            paginate: "true",
            per_page: limit,
            page: currentPage,
            deleted: "undeleted",
            resource: "all",
            columns: ["name_ar"],
            operand: ["like"],
            column_values: [search],
          };
    action(params);
  }, [limit, currentPage, search, user.model_id, dispatch, user.type]);

  return (
    <>
      <HelmetInfo titlePage={"العروض"} />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"العروض"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={user.type === "vendor"}
              typeIcon={faPlus}
              textButton={"اضافة عرض جديد"}
              functionButton={() => {
                navigate("editAddOffer");
              }}
              onSearchChange={setSearch}
            />
          }
        />
      </div>
      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            isShowTotalPagesCount={true}
            dateThead={
              <>
                <th scope="col" className="text-danger">
                  اسم العرض
                </th>
                <th scope="col">حالة العرض</th>
                <th scope="col">القسم</th>
                <th scope="col">سعر العرض</th>
                <th scope="col">الخصم</th>
                {user.type === "vendor" && <th scope="col">تفعيل/الغاء</th>}
                <th scope="col">الاجراءات</th>
              </>
            }
            dataTbody={
              <>
                {offers.map((offer, index) => {
                  return (
                    <tr key={offer.id}>
                      <td>{offer.name_ar}</td>
                      <td>
                        <p className={offer.is_active === "1" ? "text-success" : "text-danger"}>
                          {offer.is_active === "1" ? "نشط" : "غير نشط"}
                        </p>
                      </td>
                      <td>{offer.category?.name_ar}</td>
                      <td>{offer.offer_price} ر.س</td>
                      <td>{offer.discount_percentage} %</td>

                      {user.type === "vendor" && (
                        <td>
                          <Toggleswitch
                            switchId={offer.id}
                            nameSwitch={`offers-${offer.id}`}
                            value={offer.is_active === "1" ? true : false}
                            onChange={(newValue) => {
                              handleToggleSwitch(newValue, offer.id, index);
                            }}
                          />
                        </td>
                      )}

                      <td>
                        <Link
                          to={`detailsOffers/${offer.id}`}
                          className="icon-eye-offer text-color-num cursor-pointer-1">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={(val) => setLimit(val)}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default OffersInfo;
