// @ts-nocheck
import { useField, useFormikContext } from "formik";
import Select from "react-select";

const MultiSelect = ({ label, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [field, meta, helpers] = useField(props);
  const { handleOnChange } = props;

  const handleChange = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setFieldValue(props.name, values);
    handleOnChange && handleOnChange(values);
    helpers.setValue(values);
  };

  const handleBlur = () => setFieldTouched(props.name, true);

  return (
    <div>
      <Select
        {...props}
        id={props.id || props.name}
        options={props.options}
        isMulti
        value={props.options ? props.options?.filter((option) => field.value?.includes(option.value)) : []}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={props.placeholder}
      />
      {meta.touched && meta.error ? <div className="error">{"اضف عنصر واحد على الأقل"}</div> : null}
    </div>
  );
};

export default MultiSelect;
