import { faCheck, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCartDiscount,
  setCartNotes,
  setCartPaymentWay,
  setCartSubTotalPrice,
  setCartTotalPrice,
} from "store/customer/customerSlice";
import { useDebounce } from "use-debounce";
import { getFormattedDateForBooking } from "utlis";
import img from "../../../../assets/images/booking/01.png";
import iconCalender from "../../../../assets/images/icons/calendar.svg";
import iconLocation from "../../../../assets/images/icons/location.svg";

const buttons = [
  { id: "online", label: "الدفع اون لاين", value: "online" },
  { id: "cash", label: "الدفع عند الخدمة", value: "cash" },
];

const StepFourServices = ({ bookingDetails, setBookingDetails, paymentMethod }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [notes, setNotes] = useState("");
  const [promocode, setPromocode] = useState("");

  const dispatch = useDispatch();

  const [debouncedNotes] = useDebounce(notes, 1000);
  useEffect(() => {
    setBookingDetails((prev) => ({
      ...prev,
      notes: debouncedNotes,
    }));
  }, [debouncedNotes, setBookingDetails]);

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    dispatch(setCartNotes(e.target.value));
  };

  const handleDiscountCodeChange = (e) => {
    setPromocode(e.target.value);
  };

  useEffect(() => {
    if (paymentMethod === "both") {
      handleButtonClick(buttons[0]);
    } else if (paymentMethod === "cash" || paymentMethod === "online") {
      const supportedButton = buttons.find((button) => button.value === paymentMethod);
      if (supportedButton) {
        handleButtonClick(supportedButton);
      }
    }
  }, [paymentMethod]);

  const handleButtonClick = (button) => {
    setActiveButton(button.id);
    setBookingDetails((prev) => ({
      ...prev,
      payment_way: button.value,
    }));
    dispatch(setCartPaymentWay(button.value));
  };

  const renderPaymentMethod = () => {
    return (
      <div className="button-check-pay pt-3 border-bottom pb-3">
        {buttons.map((button) => {
          if (paymentMethod === "both" || paymentMethod === button.value) {
            return (
              <div
                className={`button-one mb-3 d-flex justify-content-between align-items-center ${
                  activeButton === button.id ? "active" : ""
                }`}
                key={button.id}
                onClick={() => handleButtonClick(button)}>
                {button.label}
                {activeButton === button.id && (
                  <div className="icon-check">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                )}
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  return (
    <div className="step-four-content">
      <div className="row g-3">
        <div className="col-12 col-lg-8">
          <div className="right-info-content-step">
            <div className="header-one-step">
              <h2 className="title size-text-color-dark">مراجعة الطلب والدفع</h2>
              <p className="text size-text-color-gray">
                لن يتم تحصيل رسوم منك الآن، سيتم تحصيل الدفعة في المتجر بعد موعدك
              </p>
            </div>

            <div className="right-main-info pt-4">
              <h2 className="title size-text-color-dark pb-3">اختر طريقة الدفع المناسبة لك</h2>

              {renderPaymentMethod()}

              <div className="form-info mt-3">
                <form action="">
                  <>
                    {/* <div className="mb-3">
                      <label htmlFor="codeNumberDis" className="form-label">
                        كود الخصم
                      </label>
                      <div className="input-code position-relative">
                        <input
                          type="text"
                          className="form-control"
                          id="codeNumberDis"
                          placeholder="كود الخصم"
                          onChange={handleDiscountCodeChange}
                        />
                        <button
                          className="btn-code"
                          type={"submit"}
                          onClick={(e) => {
                            e.preventDefault();
                            // dispatch(setCartPromocode(promocode));
                            setPromocode("");
                          }}
                        >
                          تطبيق
                        </button>/
                      </div>
                    </div> */}
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlTextarea1" className="form-label">
                        ملاحظات
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={5}
                        defaultValue={""}
                        placeholder="ملاحظات"
                        onChange={handleNotesChange}
                      />
                    </div>
                  </>
                </form>
              </div>
              <div className="bottom-text-content">
                <h2 className="title size-text-color-dark mb-2">سياسة الحجز</h2>
                <p className="text size-text-color-gray pt-1">
                  يرجى تجنب الإلغاء خلال 6 ساعات من موعدك{" "}
                </p>
                <p className="text size-text-color-gray pt-1">
                  لا يسمح بأعادة الحجز فى حالة عدم الظهور للحجز المؤكد.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <BookingSummary bookingDetails={bookingDetails} />
      </div>
    </div>
  );
};

const BookingSummary = ({ bookingDetails }) => {
  const { branchDetails } = bookingDetails;
  const cartDetails = useSelector((state) => state.customer.cartDetails);
  const allOffers = useSelector((state) => state.customer.offers);

  const [employee, setEmployee] = useState({});
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [offer, setOffer] = useState({});
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(0);

  useEffect(() => {
    const totalPrice = cartDetails.services.reduce(
      (acc, service) => acc + parseInt(service.price),
      0,
    );
    setTotalPrice(totalPrice);
    const offerId = cartDetails.offer_id;
    if (offerId) {
      const offerDetails = allOffers.find((offer) => offer.id === offerId);
      setOffer(offerDetails);
    }
  }, [bookingDetails, cartDetails]); // eslint-disable-line

  const dispatch = useDispatch();
  useEffect(() => {
    if (offer.id) {
      const priceAfterDiscount = totalPrice - (totalPrice * offer.discount_percentage) / 100;
      setPriceAfterDiscount(priceAfterDiscount);
      dispatch(setCartTotalPrice(totalPrice));
      dispatch(setCartSubTotalPrice(priceAfterDiscount));
      dispatch(setCartDiscount(offer.discount_percentage));
    }
  }, [offer]);

  useEffect(() => {
    const date = getFormattedDateForBooking(bookingDetails.booking_day);
    setDate(date);
    setTime(bookingDetails.booking_time);
    setEmployee(bookingDetails.employee_info);
  }, [bookingDetails]);

  if (!bookingDetails) return <></>;
  return (
    <div className="col-12 col-lg-4">
      <div className="cost-details style-card-details">
        <div className="head-details-one d-flex  align-items-center gap-3">
          <div className="img-details">
            <img
              src={img}
              alt="img"
              width={"110px"}
              height={"100px"}
              className=" object-fit-cover rounded-3"
            />
          </div>

          <div className="info-content">
            <h2 className="title-info size-text-color-dark"> {branchDetails?.vendor?.name}</h2>

            <div className="location-details d-flex  align-items-center gap-1">
              <div className="icon-location">
                <img src={iconLocation} alt="icon location" width={"25px"} height={"25px"} />
              </div>
              <p className="text-location size-text-color-gray">{branchDetails?.address}</p>
            </div>
          </div>
        </div>

        <div className="main-info-cost pt-4">
          <div className="title-header">
            <h2 className="title size-text-color-dark main-text-color">تفاصيل</h2>
          </div>

          <div className="header-top-info-content pb-3">
            {cartDetails?.services?.map((service, index) => (
              <div className="head-details d-flex gap-3 justify-content-between  pt-3" key={index}>
                <div className="info-right">
                  <h2 className="title size-text-color-gray">{service.name_ar}</h2>
                  <div className="time-info size-text-color-gray pt-2">
                    {" "}
                    {service.service_time} دقيقة
                  </div>
                </div>
                <div className="num-price size-text-color-gray ">{service.price} ر.س</div>
              </div>
            ))}
          </div>

          <div className="all-head-details border-top border-bottom   pt-3 pb-1 mb-3">
            <div className="head-details d-flex gap-3 justify-content-between mb-3">
              <div className="info-right">
                <h2 className="title size-text-color-gray main-text-color">المجموع</h2>
              </div>
              <div className="num-price size-text-color-gray main-text-color">{totalPrice} ر.س</div>
            </div>
          </div>
          {offer.id && (
            <div className="head-details d-flex gap-3 justify-content-between mb-3">
              <div className="info-right">
                <h2 className="title size-text-color-dark">الخصم</h2>
                <p className="text size-text-color-gray">{offer.name_ar}</p>
              </div>
              <div className="num-price size-text-color-dark">{offer.discount_percentage} %</div>
            </div>
          )}

          <div className="head-details d-flex gap-3 justify-content-between mb-3">
            <div className="info-right">
              <h2 className="title size-text-color-dark">الأجمالي</h2>
            </div>
            <div className="num-price size-text-color-dark">{priceAfterDiscount} ر.س</div>
          </div>

          <div className="info-employee d-flex justify-content-between mt-4 align-items-center flex-wrap gap-3">
            <div className="right-info-emp d-flex  align-items-center gap-3">
              <div className="img-emp">
                <img
                  src={img}
                  alt="img employee"
                  width={"50px"}
                  height={"50px"}
                  className="object-fit-cover rounded-circle "
                />
              </div>
              <div className="info-content">
                <h2 className="name-emp size-text-color-dark">{employee?.user?.name}</h2>
                <p className="text-emp size-text-color-gray">
                  {employee?.job_title ? employee.job_title : "موظف"}
                </p>
              </div>
            </div>

            <div className="left-info">
              <div className="rate-num d-flex align-items-center gap-2 border ">
                <div className="icon-star">
                  <FontAwesomeIcon icon={faStar} />
                </div>
                4.5
              </div>
            </div>
          </div>

          <div className="main-date-info d-flex  align-items-center gap-3 mt-4">
            <div className="icon-date icon-style-01">
              <img src={iconCalender} alt="icon calender" />
            </div>
            <div className="time-info">
              <h2 className="date-day size-text-color-dark">{date}</h2>
              <p className="text size-text-color-gray pt-1">{time}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StepFourServices;
