import AboutInfo from "Components/AboutInfo/AboutInfo";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import PageTilte from "Components/PageTilte/PageTilte";
import { useEffect } from "react";
import "./About";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetInfo titlePage={"من نحن"} />
      <div className="about">
        <PageTilte titlePage={"من نحن"} />
        <AboutInfo />
      </div>
    </>
  );
};

export default About;
