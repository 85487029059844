import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import { useCallback } from "react";
import searchIcon from "../../../assets/images/icons/search.svg";
import "./SearchHeaderPage.css";

const SearchHeaderPage = ({
  typeIcon,
  textButton,
  functionButton,
  isButtonActive,
  onSearchChange,
  showSearch = true,
  placeholder = "بحث",
}) => {
  const request = debounce((value) => {
    if (onSearchChange) {
      onSearchChange(value);
    }
  }, 1000);

  const debouceRequest = useCallback((value) => request(value), []);
  const onChange = (value) => {
    debouceRequest(value);
  };

  return (
    <>
      <div className="left-info-search d-flex align-items-center gap-3 flex-wrap ">
        {showSearch && (
          <div className="form-search position-relative ">
            <label htmlFor="searchInput" className="form-label d-none"></label>
            <input
              type="text"
              className="form-control"
              id="searchInput"
              placeholder={placeholder}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
            <div className="icon-search position-absolute top-50 end-0 translate-middle cursor-pointer-1">
              <img src={searchIcon} alt="icon search" />
            </div>
          </div>
        )}
        {isButtonActive && (
          <button className="main-btn" onClick={functionButton} type="button">
            {typeIcon && <FontAwesomeIcon icon={typeIcon} />}
            {textButton}
          </button>
        )}
      </div>
    </>
  );
};

export default SearchHeaderPage;
