import {
  faInstagram,
  faSnapchat,
  faTiktok,
  faXTwitter
} from "@fortawesome/free-brands-svg-icons";
import iconEmail from "../../../assets/images/dashboardImages/icons/email.svg";
import iconWorld from "../../../assets/images/dashboardImages/icons/world.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactInformation = () => {
  return (
    <div className="contact-information" data-aos="fade-right">
      {/* ======= START TOP INFO ========= */}
      <div className="top-info">
        <h2 className="title pb-3">بيانات التواصل</h2>
        <div className="info-conatct-1 d-flex align-items-center gap-3 mb-3">
          <div className="icon-1">
            <img src={iconEmail} alt="icon email" />
          </div>
          <a href="mailto:example@example.com" className="info-details-conatct">
            <h2 className="text">البريد الإلكتروني</h2>
            <p className="content-2">mail@mail.com</p>
          </a>
        </div>
        <div className="info-conatct-1 d-flex align-items-center gap-3 mb-3">
          <div className="icon-1">
            <img src={iconWorld} alt="icon email" />
          </div>
          <a href="https://www.example.com" className="info-details-conatct">
            <h2 className="text">الموقع الإلكتروني</h2>
            <p className="content-2">www.site.com</p>
          </a>
        </div>
      </div>
      {/* ======= END TOP INFO ========= */}
      {/* ======= START BOTTOM INFO ======= */}
      <div className="bottom-info">
        <h2 className="title">بيانات مواقع التواصل الإجتماعي</h2>
        <div className="icon-social pt-3">
          <ul className="list-social p-0 m-0 d-flex align-items-center gap-4">
            <li className="list-one">
              <a
                href="https://www.instagram.com/"
                target="_blank"
                className="icon-social"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>

            <li className="list-one">
              <a
                href="https://twitter.com/"
                target="_blank"
                className="icon-social"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
            </li>
            <li className="list-one">
              <a
                href="https://www.tiktok.com/"
                target="_blank"
                className="icon-social"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTiktok} />
              </a>
            </li>
            <li className="list-one">
              <a
                href="https://www.snapchat.com/"
                target="_blank"
                className="icon-social"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faSnapchat} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* ======= END BOTTOM INFO ======= */}
    </div>
  );
};

export default ContactInformation;
