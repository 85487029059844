import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import { useState } from "react";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import "../../../../node_modules/filepond/dist/filepond.min.css";
import "../../../../node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

const CompleteAccountData = () => {
  const validationSchema = Yup.object({
    branchName: Yup.string().required("اسم الفرع"),
    country: Yup.string().required("اختار الدولة"),
    region: Yup.string().required("اختار المنطقة"),
    city: Yup.string().required("اختار المدينة"),
    branchAddress: Yup.string().required("عنوان الفرع")
  });

  const initialValues = {
    branchName: "",
    country: "",
    region: "",
    city: "",
    branchAddress: ""
  };
  const navigate = useNavigate();
  const handleSubmit = async (values, { resetForm }) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      resetForm();
      navigate("addneweployee");
    } catch (errors) {
      console.error(errors.errors);
    }
  };

  // FILE UPLOAD
  const [files, setFiles] = useState([]);

  // GET DATE TIME
  const [startDate, setStartDate] = useState(new Date());
  const handleDateChange = (date) => {
    // Ensure date is defined before setting it
    if (date) {
      setStartDate(date);
    }
  };

  return (
  <>
  <HelmetInfo titlePage={"استكمال تفعيل الحساب"} />
      <div className="all-data-com bg-step">
        {/* ========== START FORM FIELD ========= */}
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <div className="row g-3">
            <div className="col-12 col-md-6">
              <InputFiled
                label={"اسم الفرع"}
                name="branchName"
                type="text"
                placeholder={"اسم الفرع"}
                success
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="select-reg">
                <label htmlFor="exampleFormControlInput2" className="form-label">
                  الدولة
                </label>
                <SelectField
                  name="country"
                  label={false}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                    { value: "option3", label: "Option 3" }
                  ]}
                  valueSelected={"الدولة"}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="select-reg">
                <label htmlFor="exampleFormControlInput2" className="form-label">
                  المنطقة
                </label>
                <SelectField
                  name="region"
                  label={false}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                    { value: "option3", label: "Option 3" }
                  ]}
                  valueSelected={"المنطقة"}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="select-reg">
                <label htmlFor="exampleFormControlInput2" className="form-label">
                  المدينة
                </label>
                <SelectField
                  name="city"
                  label={false}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                    { value: "option3", label: "Option 3" }
                  ]}
                  valueSelected={"المدينة"}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <InputFiled
                label={"عنوان الفرع"}
                name="branchAddress"
                type="text"
                placeholder={"عنوان الفرع"}
                success
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label
                  htmlFor="exampleFormControlInputDate"
                  className="form-label"
                >
                  ساعات العمل
                </label>
                <InputGroup className="input-group input-clock">
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="form-control w-100"
                  />
                  <InputGroup.Text className="input-group-text">
                    <FontAwesomeIcon icon={faCalendar} />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <label
                htmlFor="exampleFormControlInputPicFile"
                className="form-label"
              >
                الصورة البارزة
              </label>
              <FilePond
                className="multiple-filepond file-upload-style"
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={true}
                maxFiles={3}
                server="/api"
                name="files"
                labelIdle={`الصورة البارزة`}
              />
            </div>
          </div>
          <button className="main-btn px-5">التالي</button>
        </FormField>
        {/* ========== END FORM FIELD ========= */}
      </div>
  </>
  );
};

export default CompleteAccountData;
