import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAdminBranch } from "store/admin/adminActions";
import imgUser from "../../../../assets/images/userImg/user.png";
import "../Branches.css";

const BranchAdminData = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [branch, setBranch] = useState(null);

  useEffect(() => {
    const fetchBranch = async () => {
      setIsLoading(true);
      try {
        const res = await dispatch(getAdminBranch(id)).then(unwrapResult);
        setBranch(res.data);
      } catch (error) {
        toast.error("فشل في جلب تفاصيل الفرع");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBranch();
  }, [dispatch, id]);

  const branchInfo = [
    { title: "اسم الفرع", value: branch?.name },
    { title: "عنوان الفرع", value: branch?.address },
    { title: "خط العرض", value: branch?.lat },
    { title: "خط الطول", value: branch?.long },
    { title: "المدينة", value: branch?.city?.name_ar },
    {
      title: "الدولة",
      value: branch?.country?.name_ar,
    },
    { title: "عرض التقييمات", value: branch?.show_rates === "1" ? "نعم" : "لا" },
    {
      title: "الحالة",
      value: (
        <span className={`status-badge ${branch?.is_active === "1" ? "active" : "inactive"}`}>
          {branch?.is_active === "1" ? "مفعل" : "غير مفعل"}
        </span>
      ),
    },
  ];

  if (isLoading) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage="تفاصيل الفرع" />
      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          routePage={false}
          routeHomePage="/dashboard/admin/homedashboard"
          titlePage="الرئيسية"
          titleInfoPage="تفاصيل الفرع"
        />
        <Link
          className="size-text-color-dark main-text-color fs-5"
          to="/dashboard/admin/admin-branches">
          عودة
        </Link>
      </div>

      {branch && (
        <div className="basic-information-content account-info-details mt-3">
          <div className="all-basic-info all-details-branch">
            <div className="header-info-profile">
              {/* Manager Info */}
              <div className="info-data-user d-flex justify-content-center align-items-center gap-3 flex-column text-center mb-3">
                <img
                  src={imgUser}
                  alt="manager"
                  className="object-fit-cover"
                  width="85px"
                  height="85px"
                />
                <div>
                  <h2 className="name-user">{branch.manager?.name}</h2>
                  <p className="title-user">مدير الفرع</p>
                </div>
              </div>

              {/* Branch Info */}
              <div className="content-user-info">
                {branchInfo.map((item, index) => (
                  <div className="all-info-content d-flex" key={index}>
                    <div className="main-info-one d-flex align-items-center">
                      <h2 className="title">{item.title}</h2>
                    </div>
                    <div className="main-info-one d-flex align-items-center">{item.value}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BranchAdminData;
