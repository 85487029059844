import "./FormComponent.css";

const FormComponent = ({ children }) => {
  return (
    <div className="form-sign">
      <div className="container">
        <div className="all-form-sign">
          <div className="main-form-sign">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FormComponent;
