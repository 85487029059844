import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import PrivacyContent from "Components/Ui/PrivacyContent/PrivacyContent";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  const content = [
    {
      id: 0,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من ..",
    },
    {
      id: 1,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من ..",
    },
    {
      id: 2,
      text: "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من ..",
    },
  ];
  return (
    <>
      <HelmetInfo titlePage={"سياسة الخصوصية"} />
      <div className="main-privacy-dash my-3">
        <PrivacyContent
          titleContent={"سياسة الخصوصية"}
          textContent={
            <>
              {content.map((item) => {
                return <p key={item.id}>{item.text}</p>;
              })}
            </>
          }
        />
      </div>
    </>
  );
};

export default PrivacyPolicy;
