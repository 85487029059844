import "./Toggleswitch.css";

const Toggleswitch = ({ switchId, nameSwitch, label, value, onChange, disabled }) => {
  // const [checked, setChecked] = useState(initialValue);

  const handleToggle = (e) => {
    const checked = e.target.checked;
    if (onChange) {
      onChange(checked);
    }
  };
  return (
    <div
      className={`custom-toggle-switch ${value ? "checked" : ""} ${disabled ? "opacity-50" : ""}`}>
      <p>{label}</p>
      <input
        id={switchId}
        name={nameSwitch}
        checked={value}
        disabled={disabled}
        type="checkbox"
        onChange={handleToggle}
      />
      <label htmlFor={switchId} />
    </div>
  );
};

export default Toggleswitch;
