// @ts-nocheck
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateProfile } from "store/vendor/vendorActions";
import iconEmail from "../../../assets/images/dashboardImages/icons/email2.svg";
import iconPhone from "../../../assets/images/dashboardImages/icons/phone.svg";

const BasicData = () => {
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [editing, setEditing] = useState(false);
  const [user, setUser] = useState({});
  const [originalValues, setOriginalValues] = useState({});
  const [formValues, setFormValues] = useState(user);
  const dispatch = useDispatch();

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUser(JSON.parse(user));
  }, []);

  useEffect(() => {
    if (user) {
      const cloneFormValues = {
        first_name: user.first_name,
        last_name: user.last_name,
        birthdate: user.birthdate,
        country_id: user.country_id,
        city_id: user.city_id,
        phone: user.phone,
        email: user.email,
      };
      setFormValues(cloneFormValues);
      setOriginalValues(cloneFormValues);
    }
  }, [user]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSubmit = async () => {
    dispatch(updateProfile(formValues))
      .then(unwrapResult)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleInputChange = (name, newValue) => {
    formValues[name] = newValue;
    setFormValues((old) => ({
      ...old,
      [name]: newValue,
    }));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="basic-information-content basic-information-content-dash">
          <div className="all-basic-info">
            <div className="header-info-profile">
              <div className="all-info-content d-flex">
                <div className="main-info-one d-flex align-items-center">
                  <h2 className="title">الاسم الأول</h2>
                </div>
                <div className="main-info-one d-flex align-items-center">
                  {editing ? (
                    <input
                      type="text"
                      className="form-control"
                      value={formValues.first_name}
                      onChange={(e) => handleInputChange("first_name", e.target.value)}
                    />
                  ) : (
                    <p className="text">{formValues.first_name}</p>
                  )}
                </div>
              </div>

              <div className="all-info-content d-flex">
                <div className="main-info-one d-flex align-items-center">
                  <h2 className="title">الاسم الأخير</h2>
                </div>
                <div className="main-info-one d-flex align-items-center">
                  {editing ? (
                    <input
                      type="text"
                      className="form-control"
                      value={formValues.last_name}
                      onChange={(e) => handleInputChange("last_name", e.target.value)}
                    />
                  ) : (
                    <p className="text">{formValues.last_name}</p>
                  )}
                </div>
              </div>
              {/* 
              {editing ? (
                <div className="button-save">
                  <button className="main-btn main-outline-btn" onClick={handleSubmit}>
                    حفظ
                  </button>
                </div>
              ) : (
                <div className="icon-edit-pen" onClick={handleEditClick}>
                  <img src={iconPen} alt="icon pen" />
                </div>
              )} */}
            </div>

            <div className="bottom-basic-info mt-4">
              <div className="info-one-basic d-flex  align-items-center  gap-3 justify-content-between mb-3">
                <div className="content-info d-flex  align-items-center  gap-3">
                  <div className="icon-img">
                    <img src={iconPhone} alt="icon phone" />
                  </div>
                  <div className="info-icon">{formValues.phone}</div>
                </div>
                {/* <Link to="/changeNumberPhone" className="icon-edit-pen">
                  <img src={iconPen} alt="icon pen" />
                </Link> */}
              </div>

              <div className="info-one-basic d-flex  align-items-center  gap-3 justify-content-between ">
                <div className="content-info d-flex  align-items-center  gap-3">
                  <div className="icon-img">
                    <img src={iconEmail} alt="icon email" />
                  </div>
                  <div className="info-icon">{formValues.email}</div>
                </div>
                {/* <Link to="/changeEmail" className="icon-edit-pen">
                  <img src={iconPen} alt="icon pen" />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BasicData;
