import SectionTitle from "Components/SectionTitle/SectionTitle";
import PlansContent from "./PlansContent";
import "./Plans.css";

const Plans = () => {
  return (
    <div className="plans padding-bottom-100 mt-5">
      {/* ========== START CONTAINER ========== */}
      <div className="container">
        {/* ======== START TITLE SECTION ======= */}
        <div className="section-title-landing" data-aos="fade-left">
          <SectionTitle
            title={"اختر الخطة المناسبة لك"}
            text={"اختر الخطة التي تناسبك، فلا تتردد في الاتصال بنا"}
            dataAos={false}
          />
        </div>
        {/* ======== END TITLE SECTION ======= */}
        {/* ========= START ALL PLANS ======== */}
        <div className="all-plans" data-aos="fade-up">
          <PlansContent />
        </div>
        {/* ========= END ALL PLANS ======== */}
      </div>
      {/* ========== END CONTAINER ========== */}
    </div>
  );
};

export default Plans;
