import { createSlice } from "@reduxjs/toolkit";
import { changePassword, customerLogIn, login, logoutAction, sendOtpCode, updateProfile } from "./authActions";

const userString = localStorage.getItem("user");
const user = userString ? JSON.parse(userString) : undefined;

const InitialState = {
  isLogedIn: user ? true : false,
  isLoading: false,
  hasError: false,
  errorMessage: {},
  user: user,
  userPermissions: undefined,
  role: user?.type,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: InitialState,
  reducers: {
    setIsLogedIn: (state, action) => {
      state.isLogedIn = action.payload;
    },
    setUserDetails: (state, action) => {
      state.user = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },
    clearError: (state) => {
      state.hasError = false;
      state.errorMessage = {};
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLogedIn = true;
        state.isLoading = false;
        state.hasError = false;
        state.user = action.payload.user;
        state.userPermissions = action.payload.user_permissions;
        state.role = action.payload.user.type;
        //add token to session storage
        localStorage.setItem("accessToken", action.payload.access_token);
        localStorage.setItem("user", JSON.stringify(action.payload.user));
      })
      .addCase(login.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(customerLogIn.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(customerLogIn.fulfilled, (state, action) => {
        state.isLogedIn = true;
        state.isLoading = false;
        state.hasError = false;
        state.user = action.payload.user;
        state.userPermissions = action.payload.user_permissions;
        state.role = action.payload.user.type;
        //add token to session storage
        localStorage.setItem("accessToken", action.payload.access_token);
        localStorage.setItem("user", JSON.stringify(action.payload.user));
      })
      .addCase(customerLogIn.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(logoutAction.fulfilled, (state, action) => {
        state.isLogedIn = false;
        state.user = null;
        state.role = null;
      })
      .addCase(logoutAction.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(changePassword.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(updateProfile.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(sendOtpCode.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(sendOtpCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(sendOtpCode.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export const { setIsLogedIn, setUserDetails, setUserPermissions, clearError, logout } = AuthSlice.actions;
export default AuthSlice.reducer;
