import "./HeaderPageInfo.css";

const HeaderPageInfo = ({ title, isShowLeftContent, contentPageLeft }) => {
  return (
    <div className="header-page-info-top  d-flex justify-content-between align-items-center gap-3 flex-wrap">
      <div className="title-page-name">
        <h2 className="title-text-1">{title}</h2>
      </div>
      {isShowLeftContent && <div className="left-info-page-content">{contentPageLeft}</div>}
    </div>
  );
};

export default HeaderPageInfo;
