import * as Yup from "yup";

export const registerValidationSchema = Yup.object({
  email: Yup.string().email("ايميل خطاء").required("البريد الالكتروني مطلوب."),
  first_name: Yup.string().required("الاسم الاول مطلوب"),
  last_name: Yup.string().required("الاسم الاخير مطلوب"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "رقم الجوال غير صحيح")
    .required("رقم الهاتف مطلوب"),
  password: Yup.string()
    .required("كلمة السر مطلوبة")
    .min(8, "يجب ان تكون كلمة السر اكثر من 8 احرف وارقام"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "كلمة السر غير متطابقة")
    .required("تأكيد كلمة السر مطلوب"),
});
