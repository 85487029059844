import FooterSocialIcons from "Components/Ui/FooterSocialIcons/FooterSocialIcons";

import "./FooterLanding.css";
import { Link } from "react-router-dom";
import LogoFooter from "Components/LogoFooter/LogoFooter";

const FooterLanding = () => {
  return (
    <footer>
      <div className="footer footer-landing">
        <div className="container">
          {/* ========= START MAIN FOOTER CONTENT ========== */}
          <div className="main-footer-content" data-aos="fade-up">
            <div className="logo">
              <a href="/landingPageDashboard">
                <LogoFooter />
              </a>
            </div>
            <div className="info-text my-3">
              <p className="text">
                هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن
                الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر .
              </p>
            </div>
            <FooterSocialIcons />
          </div>
          {/* ========= END MAIN FOOTER CONTENT ========== */}

          <div className="final-footer d-flex justify-content-between text-center align-items-center flex-wrap gap-3">
            <p className="text-footer">stremline ©2024</p>
            <div className="main-links-bottom d-flex align-items-center gap-4">
              <Link className="link-footer-bottom" to="/privacy">
                الخصوصية
              </Link>
              <Link className="link-footer-bottom" to="/termsconditions">
                الشروط والأحكام
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterLanding;
