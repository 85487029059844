const ReasonCancellation = () => {
  // TODO: Client should add this
  return (
    <>
      <div className="bottom-info-details mt-3 style-card-details">
        <div className="all-info-bottom-details">
          <h2 className="title text-size-02 fs-5 fw-bold ">سبب الألغاء</h2>
          <p className="text text-size  lh-lg pt-3"></p>
        </div>
      </div>
    </>
  );
};

export default ReasonCancellation;
