import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import logo from "../../../assets/images/logo/logoHover.svg";
import imgQr from "../../../assets/images/dashboardImages/main/QR Code.png";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";

const DetailsTaxInvoices = () => {
  const infoClient = [
    { id: 0, title: "الاسم بالكامل", text: "محمد احمد " },
    { id: 1, title: "البريد الالكترونى", text: "ahmed2011@gmail.com" },
    { id: 2, title: "رقم الجوال", text: "+966 1457 456" },
    { id: 3, title: "رقم الحجز", text: "123456" }
  ];
  const infoClient2 = [
    { id: 0, title: "اسم الخدمة" },
    { id: 1, title: "حلاقة وقص شعر" },
    { id: 2, title: "حلاقة وقص شعر" },
    { id: 3, title: "الأجمالي" }
  ];
  return (
    <>
      <HelmetInfo titlePage={"تفاصيل"} />
      {/* =========== START HEADER INFO TOP ============ */}
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/admin/homedashboard"}
          titleInfoPage={"تفاصيل"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/taxinvoices"
          >
            عودة
          </Link>
        </div>
      </div>
      {/* =========== END HEADER INFO TOP ============ */}
      <div
        className="basic-information-content account-info-details mt-3"
        data-aos="fade-up"
      >
        {/* =========== START ALL BASIC INFO ============= */}
        <div className="all-basic-info">
          {/* ======== START HEADER INFO ========== */}
          <div className="header-info-profile  ">
            <div className="all-data-content ">
              <div className="row g-3">
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="content-user-info ">
                    <div className="title-top fs-6 fw-bold pb-3">
                      بيانات العميل
                    </div>
                    {infoClient.map((item) => {
                      return (
                        <div className="all-info-content d-flex" key={item.id}>
                          {/* ========= START MAIN INFO ONE ========= */}
                          <div className="main-info-one d-flex  align-items-center">
                            <h2 className="title">{item.title}</h2>
                          </div>
                          {/* ========= END MAIN INFO ONE ========= */}

                          {/* ========= START MAIN INFO ONE ========= */}
                          <div className="main-info-one d-flex  align-items-center">
                            <p className="text">{item.text}</p>
                          </div>
                          {/* ========= END MAIN INFO ONE ========= */}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="content-user-info">
                    <div className="title-top fs-6 fw-bold pb-3">
                      بيانات الحجز
                    </div>
                    <div className="content-info-booking">
                      {infoClient2.map((item) => {
                        return (
                          <div
                            className="all-info-content d-flex"
                            key={item.id}
                          >
                            {/* ========= START MAIN INFO ONE ========= */}
                            <div className="main-info-one d-flex  align-items-center">
                              <h2 className="title">{item.title}</h2>
                            </div>
                            {/* ========= END MAIN INFO ONE ========= */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ======== END HEADER INFO ========== */}
        </div>
        {/* =========== END ALL BASIC INFO ============= */}
      </div>
      <div className="invoice-content-info-print">
        <div className="top-info d-flex  justify-content-between  align-items-center  gap-3 flex-wrap mb-3">
          <h2 className="title">الفاتورة الضريببة</h2>
          <div className="icons-invoices d-flex   align-items-center  gap-3">
            {/* START ICON PRINT */}
            <div className="icon-print text-color-num fs-4 cursor-pointer-1">
              <FontAwesomeIcon icon={faPrint} />
            </div>
            {/* END ICON PRINT */}
            {/* START ICON PRINT */}
            <div className="icon-download text-color-num fs-4 cursor-pointer-1">
              <FontAwesomeIcon icon={faDownload} />
            </div>
            {/* END ICON PRINT */}
          </div>
        </div>
      
        {/* ======== START INVOICE DETAILS INFO ========== */}
        <div className="invoices-details-info">
          <div className="content-header-top">
            {/* ======== START HEADER DETAILS INVOICE ========== */}
            <div className="header-details-invoice d-flex align-items-center justify-content-between flex-wrap gap-3">
              {/* ======= START RIGHT INFO ======== */}
              <div className="right-info">
                <div className="logo-invoice">
                  <img src={logo} alt="img logo" />
                </div>
                <div className="content-invoice-header">
                  <h2 className="title pb-3">فاتورة ضريبية مبسطة</h2>
                  <div className="main-content d-flex flex-column gap-3 pt-3">
                    <div className="text-info-details d-flex flex-wrap align-items-center gap-3">
                      <h2 className="text">رقم الفاتورة</h2>
                      <h2 className="text">STR123456</h2>
                    </div>
                    <div className="text-info-details d-flex flex-wrap align-items-center gap-3">
                      <h2 className="text-content">التـاريــخ</h2>
                      <h2 className="text-content">080:30 م 2023/11/16</h2>
                    </div>
                  </div>
                </div>
              </div>
              {/* ======= END RIGHT INFO ======== */}
              {/* ======= START LEFT INFO QR ======= */}
              <div className="left-info-qr">
                <img src={imgQr} alt="img qr" />
              </div>
              {/* ======= END LEFT INFO QR ======= */}
            </div>
            {/* ======== END HEADER DETAILS INVOICE ========== */}
            {/* ======== START CARD CONTENT INFO ============ */}
            <div className="card-content-info-details d-flex justify-content-between  flex-wrap gap-3 border rounded-3 bg-white p-4 my-4">
              {/* ======== START CONTENT INVOICE HEADER ======= */}
              <div className="content-invoice-header">
                <div className="main-content d-flex flex-column gap-3">
                  <div className="text-info-details d-flex flex-wrap align-items-center gap-3">
                    <h2 className="text">اسم المتجر</h2>
                    <h2 className="text">ستريم لاين</h2>
                  </div>
                  <div className="text-info-details d-flex flex-wrap align-items-center gap-3">
                    <h2 className="text-content">عنوان المتجر</h2>
                    <h2 className="text-content">الرياض حي الملز</h2>
                  </div>
                </div>
              </div>
              {/* ======== END CONTENT INVOICE HEADER ======= */}
              {/* ======== START CONTENT INVOICE HEADER ======= */}
              <div className="content-invoice-header">
                <div className="main-content d-flex flex-row">
                  <div className="text-info-details d-flex flex-wrap align-items-center gap-3">
                    <h2 className="text">رقم تسجيل ضريبية القمة المضافة </h2>
                    <h2 className="text-content">12345678900012</h2>
                  </div>
                </div>
              </div>
              {/* ======== END CONTENT INVOICE HEADER ======= */}
            </div>
            {/* ======== END CARD CONTENT INFO ============ */}
            {/* ======= START TABLE INVOICE CONTENT ========== */}
            <div className="table-invoice-content">
              <div className="table-content mt-3 table-content-2">
                <TableDataInfo
                  titleTableHeader={false}
                  isHashTrue={false}
                  hashId={false}
                  dateThead={
                    <>
                      <th scope="col">المنتجات</th>
                      <th scope="col">الكمية</th>
                      <th scope="col">سعر الوحدة</th>
                      <th scope="col">ضريبة القيمة المضافة</th>
                      <th scope="col"> السعر شامل ضريبة القيمة المضافة</th>
                    </>
                  }
                  dataTbody={
                    <>
                      <tr className="bg-table-tr">
                        <td>قص شعر</td>
                        <td>1</td>
                        <td>200 ر.س</td>
                        <td>20</td>
                        <td>200 ر.س</td>
                      </tr>
                      <tr>
                        <td>قص شعر</td>
                        <td>1</td>
                        <td>200 ر.س</td>
                        <td>20</td>
                        <td>200 ر.س</td>
                      </tr>
                    </>
                  }
                />
              </div>
            </div>
            {/* ======= END TABLE INVOICE CONTENT ========== */}
            {/* ======= START table-info-details-bookings */}
            <div className="table-info-details-bookings table-all-invoice">
              <div className="table-content mt-3 table-content-2">
                <TableDataInfo
                  titleTableHeader={false}
                  isHashTrue={false}
                  hashId={false}
                  dateThead={false}
                  dataTbody={
                    <>
                      <tr>
                        <td>اجمالى المبلغ الخاضع للضريبة</td>
                        <td>200 ر.س</td>
                      </tr>
                      <tr>
                        <td>ضريبة القيمة المضافة (15%)</td>
                        <td>200 ر.س</td>
                      </tr>
            
                      <tr className="bg-main-color">
                        <td>الأجمالى مع الضريبة (15%)</td>
                        <td>400 ر.س</td>
                      </tr>
                    </>
                  }
                />
              </div>
            </div>
            {/* ======= END table-info-details-bookings ======== */}
          </div>
          <div className="footer-invoice">
          <a href="#" className="text-link">www.stremline.com</a>
          </div>
        </div>
        {/* ======== END INVOICE DETAILS INFO ========== */}
      </div>
    </>
  );
};

export default DetailsTaxInvoices;
