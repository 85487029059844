import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import MultiSelect from "Components/Forms/MultiSelect";
import SelectField from "Components/Forms/SelectField";
import TextAreaInput from "Components/Forms/TextArea";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getServiceCategories } from "store/general/generalActions";
import { addOffer, getSections, getServices } from "store/vendor/vendorActions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name_ar: Yup.string().required("ادخل اسم العرض"),
  description_ar: Yup.string().required("ادخل وصف العرض"),
  category_id: Yup.string().required(" اختار القسم الرئيسيى"),
  // sub_category_id: Yup.string().required("اختار القسم الفرعي"),
  section_id: Yup.string().required("اختار التبويب"),
  service_id: Yup.array().min(1).required("اختار الخدمات بالعرض"),
  price_type: Yup.string().required("اختار نوع التسعير"),
  discount_percentage: Yup.string().required("ادخل الخصم"),
  offer_price: Yup.string().required("ادخل سعر الخدمة"),
  service_price: Yup.string().required("ادخل سعر الخدمة"),
});

const EditAddOffer = () => {
  const navigate = useNavigate();

  const user = useGetAuthUser();
  const [categories, setCategories] = useState([]);
  const [sections, setSections] = useState([]);
  const [services, setServices] = useState([]);

  const [offerValues, setOfferValues] = useState({
    name_ar: "",
    description_ar: "",
    category_id: "",
    section_id: "",
    service_id: [],
    price_type: "",
    discount_percentage: "",
    service_price: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (name, value) => {
    setOfferValues({
      ...offerValues,
      [name]: value,
    });
  };
  const handleSubmit = (values, { resetForm, setErrors }) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const updatedValues = {
      name_en: values.name_ar,
      description_en: values.description_ar,
      sub_category_id: values.category_id,
      ...values,
    };

    dispatch(addOffer(updatedValues))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم الاضافة بنجاح.");
        resetForm();
        navigate(
          user.type === "vendor" ? "/dashboard/vendor/offersInfo" : "/dashboard/admin/offersInfo",
        );
      })
      .catch((error) => {
        setErrors(error.errors);
        console.log(error, "error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const dispatch = useDispatch();

  const fetchCategroies = () => {
    dispatch(getServiceCategories())
      .then(unwrapResult)
      .then((res) => {
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name_ar,
          value: cat.id,
        }));
        setCategories(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchSections = () => {
    dispatch(getSections())
      .then(unwrapResult)
      .then((res) => {
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name_ar,
          value: cat.id,
        }));
        setSections(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchServices = () => {
    dispatch(getServices())
      .then(unwrapResult)
      .then((res) => {
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name_ar,
          value: cat.id,
        }));
        setServices(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchCategroies();
    fetchSections();
    fetchServices();
  }, []);

  return (
    <>
      <HelmetInfo titlePage={"اضافة تعديل عرض جديد"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={
            user.type === "vendor"
              ? "/dashboard/vendor/homedashboard"
              : "/dashboard/admin/homedashboard"
          }
          titleInfoPage={"اضافة / تعديل عرض جديد"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to={
              user.type === "vendor"
                ? "/dashboard/vendor/offersInfo"
                : "/dashboard/admin/offersInfo"
            }>
            عودة
          </Link>
        </div>
      </div>
      <div className="form-edit-add-offer">
        <div className="row g-4">
          <div className="col-12">
            <div className="main-form-offer" data-aos="fade-up">
              <FormField
                initialValues={offerValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                <div className="row g-1 g-sm-3">
                  <div className="col-12 col-md-12">
                    <InputFiled
                      label={"اسم العرض"}
                      name="name_ar"
                      type="text"
                      placeholder={"اسم العرض"}
                      handleOnChange={(value) => handleChange("name_ar", value)}
                      success
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <TextAreaInput
                      label="وصف العرض"
                      name="description_ar"
                      placeholder="وصف العرض"
                      handleOnChange={(value) => handleChange("description_ar", value)}
                      success
                    />
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="select-reg">
                      <label htmlFor="exampleFormControl1" className="form-label">
                        القسم الرئيسيى
                      </label>
                      <SelectField
                        name="category_id"
                        label={false}
                        options={categories}
                        valueSelected={"القسم الرئيسي"}
                        handleOnChange={(value) => handleChange("category_id", value)}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="select-reg">
                      <label htmlFor="exampleFormControl3" className="form-label">
                        التبويب
                      </label>
                      <SelectField
                        name="section_id"
                        label={false}
                        options={sections}
                        valueSelected={"التبويب"}
                        handleOnChange={(value) => handleChange("section_id", value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="exampleFormControlInput28" className="form-label">
                      الخدمات بالعرض
                    </label>
                    <MultiSelect
                      name="service_id"
                      label={false}
                      placeholder={"الخدمات بالعرض"}
                      options={services}
                      handleOnChange={(values) => handleChange("service_id", values)}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="select-reg">
                      <label htmlFor="exampleFormControl5" className="form-label">
                        نوع التسعير
                      </label>
                      <SelectField
                        name="price_type"
                        label={false}
                        options={[
                          { value: "service", label: "خدمة" },
                          { value: "free", label: "مجانية" },
                          { value: "special", label: "مميزة" },
                          { value: "discount", label: "خصم" },
                        ]}
                        valueSelected={"نوع التسعير"}
                        handleOnChange={(value) => {
                          handleChange("price_type", value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <InputFiled
                      label={"الخصم"}
                      name="discount_percentage"
                      type="number"
                      placeholder={"الخصم"}
                      success
                      handleOnChange={(value) => handleChange("discount_percentage", value)}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <InputFiled
                      label={"سعر الخدمة"}
                      name="service_price"
                      type="number"
                      placeholder={"سعر الخدمة"}
                      success
                      handleOnChange={(value) => handleChange("service_price", value)}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <InputFiled
                      label={"سعر خاص بالعرض"}
                      name="offer_price"
                      type="number"
                      placeholder={"سعر خاص بالعرض"}
                      handleOnChange={(value) => handleChange("offer_price", value)}
                      success
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="main-btn btn-submit px-5 mt-4"
                  disabled={isSubmitting}>
                  {isSubmitting ? "جاري الحفظ..." : "حفظ"}
                </button>
              </FormField>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAddOffer;
