import FooterForm from "Pages/Auth/FormComponent/FooterForm";
import React from "react";

const Footer = () => {
  return (
    <div className="mt-auto">
      <FooterForm />
    </div>
  );
};

export default Footer;
