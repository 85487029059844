import React, { useState } from "react";
import FormComponent from "../FormComponent/FormComponent";
import HeaderForm from "../FormComponent/HeaderForm";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import FooterForm from "../FormComponent/FooterForm";
import BgBody from "Components/Ui/BgBody/BgBody";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";

const NewPassword = () => {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("كلمة السر مطلوبة")
      .min(8, "يجب ان تكون كلمة السر اكثر من 8 احرف وارقام"),
    rePassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "كلمة السر غير متطابقة")
      .required("تأكيد كلمة السر")
  });

  const initialValues = {
    password: "",
    rePassword: ""
  };
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    resetForm();
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "rePassword") {
      setShowRePassword(!showRePassword);
    }
  };
  return (
    <>
      <HelmetInfo titlePage={"كلمة المرور الجديدة"} />
      <BgBody />

      <FormComponent>
        <HeaderForm title={"كلمة المرور الجديدة"} isText={false} text={false} />
        {/* ========== START FORM FIELD ========= */}
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <div className="input-pass position-relative mb-4">
            <InputFiled
              label="كلمة المرور"
              name="password"
              placeholder=""
              success
              type={showPassword ? "text" : "password"}
            />

            <button
              className="icon-eye-button"
              onClick={() => togglePasswordVisibility("password")}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </button>
          </div>
          <div className="input-pass position-relative">
            <InputFiled
              label="تاكيد كلمة المرور "
              name="rePassword"
              placeholder="تأكيد كلمة المرور"
              success
              type={showRePassword ? "text" : "password"}
            />

            <button
              className="icon-eye-button"
              onClick={() => togglePasswordVisibility("rePassword")}
            >
              {showRePassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </button>
          </div>

          <button type="submit" className="main-btn btn-submit mt-5 w-100">
            حفظ
          </button>
        </FormField>
        {/* ========== END FORM FIELD ========= */}
      </FormComponent>
      <FooterForm />
    </>
  );
};

export default NewPassword;
