import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const CounterContent = ({

  NumCounter,
  timeDuration,
  isIconPlus,
  iconPlus,
  isIconImg,
  iconSrc,
  textCounter,
  isRate,
  rateNum,
  isView
}) => {
    const [ref, inView] = useInView({
        triggerOnce: false,
        rootMargin: "0px 0px 200px 0px" 
      });
  return (
    <>
      <div className="counter-one d-flex align-items-center gap-2 flex-column text-center">
        {/* ============ START COUNTER NUM ========== */}
        <div className="counter-num d-flex align-items-center  gap-1" ref={ref}>
          {isIconPlus && <span className="icon-plus">{iconPlus}</span>}
          {isIconImg && <img src={iconSrc} width={"22px"} height={"22px"} alt="icon" />}
          {isRate && <span>{rateNum}</span>}
          {}

          {isView && inView && <CountUp end={NumCounter} duration={timeDuration} />}
        </div>
        {/* ============ END COUNTER NUM ========== */}
        <p className="text-counter">{textCounter}</p>
      </div>
    </>
  );
};

export default CounterContent;
