import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getBookingDifference,
  getNotificationSettings,
  getPaymentSettings,
  getTaxSettings,
} from "store/admin/adminActions";
import ModalAdminBookingDifference from "./Modals/ModalAdminBookingDifference";
import ModalTaxSettings from "./Modals/ModalTaxSettings";
import SettingCard from "./SettingCard";

const adminSettingsItems = [
  {
    title: "إعدادات الضرائب",
    text: "إدارة نسب وإعدادات الضرائب",
    path: "",
    modalName: "tax-modal",
    enable: true,
  },
  {
    title: "تحديث فارق الحجز للخدمة",
    text: "تعديل فارق الوقت بين الحجوزات",
    path: "",
    modalName: "booking-modal",
    enable: true,
  },
  {
    title: "إدارة بوابات الدفع",
    text: "إعداد وتكوين بوابات الدفع",
    path: "admin-settingsPay",
    modalName: null,
    enable: true,
  },
  {
    title: "الاشعارات",
    text: "إعداد وتكوين الاشعارات",
    path: "admin-notificationSettings",
    modalName: null,
    enable: true,
  },
];

const AdminSettings = () => {
  const navigate = useNavigate();
  const [paymentSettings, setPaymentSettings] = useState({}); // payment settings
  const [taxSettings, setTaxSettings] = useState({}); // tax settings
  const [serviceSettings, setServiceSettings] = useState({}); // booking difference
  const [notificationSettings, setNotificationSettings] = useState({}); // notification settings

  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const [paymentRes, taxRes, serviceRes, notificationRes] = await Promise.all([
          dispatch(getPaymentSettings()).unwrap(),
          dispatch(getTaxSettings()).unwrap(),
          dispatch(getBookingDifference()).unwrap(),
          dispatch(getNotificationSettings()).unwrap(),
        ]);
        setPaymentSettings(paymentRes.data);
        setTaxSettings(taxRes.data);
        setServiceSettings(serviceRes.data);
        setNotificationSettings(notificationRes.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching settings:", error);
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const [modals, setModals] = useState({
    "tax-modal": false,
    "booking-modal": false,
  });
  const toggleModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }));
  };

  const renderSettingsCards = (items) => {
    return items.map((item, index) => (
      <SettingCard
        key={index}
        title={item.title}
        text={item.text}
        iconArrow={faChevronLeft}
        functionCard={() => {
          if (item.enable) {
            if (item.modalName) {
              toggleModal(item.modalName);
            } else {
              navigate(item.path);
            }
          }
        }}
        newClass="card-setting-content"
        enable={item.enable}
      />
    ));
  };

  return (
    <>
      <HelmetInfo titlePage="إعدادات عامة" />

      <ModalAdminBookingDifference
        showModalBooking={modals["booking-modal"]}
        hideModalBooking={() => setModals({ ...modals, "booking-modal": false })}
        bookingDifference={serviceSettings}
      />
      <ModalTaxSettings
        showModalTax={modals["tax-modal"]}
        hideModalTax={() => setModals({ ...modals, "tax-modal": false })}
        currentTaxSettings={taxSettings}
      />

      <div className="header-page-w" data-aos="fade-left">
        <HeaderPageInfo
          title="إعدادات عامة"
          isShowLeftContent={true}
          contentPageLeft={
            <div className="link-back">
              <Link
                className="size-text-color-dark main-text-color fs-5"
                to="/dashboard/admin/homedashboard">
                عودة
              </Link>
            </div>
          }
        />
      </div>

      {isLoading ? (
        <Loader className="size-text-color-dark main-text-color fs-5" />
      ) : (
        <div className="all-cards-settings my-3" data-aos="fade-up">
          <div className="row g-3">
            <div className="col-md-6">
              <div className="main-cards-settings border p-2 rounded-3">
                {renderSettingsCards(
                  adminSettingsItems.slice(0, Math.ceil(adminSettingsItems.length / 2)),
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="main-cards-settings border p-2 rounded-3">
                {renderSettingsCards(
                  adminSettingsItems.slice(Math.ceil(adminSettingsItems.length / 2)),
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminSettings;
