import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ModalCancellationReservation = ({ showModalCancel, hideModalCancel, setSettings }) => {
  const vendorSettings = useSelector((state) => state.vendor.vendorSettings);
  const [cancellationReservation, setCancellationReservation] = useState(null);
  const [isSubmitAllowed, setIsSubmitAllowed] = useState(false);

  useEffect(() => {
    if (Object.keys(vendorSettings).length > 0) {
      const { cancel_booking } = vendorSettings;
      console.log(cancel_booking);
      setCancellationReservation(cancel_booking);
    }
  }, [vendorSettings]);

  useEffect(() => {
    if (cancellationReservation) {
      setIsSubmitAllowed(true);
    } else {
      setIsSubmitAllowed(false);
    }
  }, [cancellationReservation]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSettings = {
      ...vendorSettings,
      cancel_booking: cancellationReservation,
    };
    setSettings(newSettings);
    hideModalCancel();
  };

  return (
    <CustomModal
      show={showModalCancel}
      onHide={hideModalCancel}
      title={"الغاء الحجز"}
      newClass={"modal-booking-diff"}>
      <div className="form-modal">
        <form action="">
          <div className="form-input-one mb-3">
            <label htmlFor="exampleInput02" className="form-label">
              هل يمكن ألغاء الحجز قبل موعد الحجز؟
            </label>
            <select
              className="form-select form-select-lg select-box-option-1"
              id="exampleInput02"
              onChange={(e) => setCancellationReservation(e.target.value)}>
              <option value="1">نعم</option>
              <option value="0">لا</option>
            </select>
          </div>

          <button onClick={handleSubmit} className="main-btn w-100 mt-4" type={"submit"}>
            حفظ
          </button>
        </form>
      </div>
    </CustomModal>
  );
};

export default ModalCancellationReservation;
