import logo from "../../assets/images/logo/logoFooter.svg";

const LogoFooter = () => {
  return (
    <>
      <img src={logo} alt="logo" />
    </>
  );
};

export default LogoFooter;
