import SectionTitle from "Components/SectionTitle/SectionTitle";
import iconEmail from "../../../../assets/images/dashboardImages/icons/email3.svg";
import "./CardsAdvantages.css";
const CardsAdvantages = () => {
  const cards = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];
  return (
    <div className="cards-advantages">
      {/* ========== START CONTAINER ========== */}
      <div className="container">
        {/* ======== START TITLE SECTION ======= */}
        <div className="section-title-landing" data-aos="fade-down">
          <h2 className="title-top">مميزات عديدة</h2>
          <SectionTitle
            title={"تجعل الصالون يعمل بكل إحترافية وبكفاءة عالية"}
            text={
              "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها."
            }
            dataAos={false}
          />
        </div>
        {/* ======== END TITLE SECTION ======= */}
        {/* ======== START ALL CARDS ADVANTAGES ========= */}
        <div className="all-cards-advantages" data-aos="fade-up">
          {/* ======== START ROW ======== */}
          <div className="row g-3">
            {cards.map((item) => {
              return (
                <div className="col-12 col-md-6 col-lg-4" key={item.id}>
                  {/* ======== START CARD ADVAN ONE ======== */}
                  <div className="card-advantage-one">
                    {/* START ICON */}
                    <div className="icon">
                      <img src={iconEmail} alt="icon" width={"35px"} height={"35px"} />
                    </div>
                    {/* END ICON */}
                    {/* START INFO CONTENT CARD */}
                    <div className="info-content-card">
                      <h2 className="title">إدارة الحجوزات</h2>
                      <p className="text">
                        هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل
                        الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.
                      </p>
                    </div>
                    {/* END INFO CONTENT CARD */}
                  </div>
                  {/* ======== END CARD ADVAN ONE ======== */}
                </div>
              );
            })}
          </div>
          {/* ======== END ROW ======== */}
        </div>
        {/* ======== END ALL CARDS ADVANTAGES ========= */}
      </div>
      {/* ========== END CONTAINER ========== */}
    </div>
  );
};

export default CardsAdvantages;
