import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo/logo.svg";

const HeaderForm = ({ title, isText, text }) => {
  return (
    <>
      {/* ========= START HEADER FORM SIFN =========== */}
      <header>
        <div className="header-form-sign mb-4 d-flex flex-column justify-content-center align-items-center gap-2 text-center">
          {/* ========= START LOGO ======== */}
          <Link className="navbar-logo" to="/">
            <img src={logo} alt="logo" />
          </Link>
          {/* ========= END LOGO ======== */}
          <h2 className="title">{title}</h2>
          {isText && <p className="text">{text}</p>}
        </div>
      </header>
      {/* ========= END HEADER FORM SIFN =========== */}
    </>
  );
};

export default HeaderForm;
