import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BgBody from "Components/Ui/BgBody/BgBody";
import Logo from "Dashboard/Components/Logo/Logo";
import PackageOfferCard from "Dashboard/Components/UiDashboard/CardPackage/PackageOfferCard";

const PackageOffers = () => {
  const itemCardPackage = [
    {
      id: 0,
      namePackage: "إسم الباقة",
      text: "هناك حقيقة مثبتة منذ زمن طويل  وهي أن المحتوى المقروء لصفحة ما",
      price: "1000",
      typePrice: "ر.س",
      dateTimePackage: "لمدة سنة",
      itemsList: [
        { id: 0, textItem: "عمولة + أشتراك", badgeTrue: false },
        {
          id: 1,
          textItem: " %20 عمولة",
          textBadge: "عمولة النظام على الحجوزات",
          badgeTrue: true,
        },
        { id: 2, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 3, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 4, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 5, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 6, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
      ],
      buttonText: "الأشتراك فى الباقة",
      textEnd: "يستلزم بطاقة دفع",
    },
    {
      id: 0,
      namePackage: "إسم الباقة",
      text: "هناك حقيقة مثبتة منذ زمن طويل  وهي أن المحتوى المقروء لصفحة ما",
      price: "1000",
      typePrice: "ر.س",
      dateTimePackage: "لمدة سنة",
      itemsList: [
        { id: 0, textItem: "عمولة + أشتراك", badgeTrue: false },
        {
          id: 1,
          textItem: " %20 عمولة",
          textBadge: "عمولة النظام على الحجوزات",
          badgeTrue: true,
        },
        { id: 2, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 3, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 4, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 5, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 6, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
      ],
      buttonText: "الأشتراك فى الباقة",
      textEnd: "يستلزم بطاقة دفع",
    },
    {
      id: 0,
      namePackage: "إسم الباقة",
      text: "هناك حقيقة مثبتة منذ زمن طويل  وهي أن المحتوى المقروء لصفحة ما",
      price: "1000",
      typePrice: "ر.س",
      dateTimePackage: "لمدة سنة",
      itemsList: [
        { id: 0, textItem: "عمولة + أشتراك", badgeTrue: false },
        {
          id: 1,
          textItem: " %20 عمولة",
          textBadge: "عمولة النظام على الحجوزات",
          badgeTrue: true,
        },
        { id: 2, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 3, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 4, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 5, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
        { id: 6, textItem: "هناك حقيقة مثبتة منذ زمن طويل", badgeTrue: false },
      ],
      buttonText: "الأشتراك فى الباقة",
      textEnd: "يستلزم بطاقة دفع",
    },
  ];
  return (
    <>
      <HelmetInfo titlePage={"الباقات"} />
      <BgBody />
      <div className="packages-offers-page" data-aos="fade-up">
        {/* ========== START CONTAINER ============ */}
        <div className="container">
          <Logo routePage={"/homedashboard"} />
          <div className="all-package my-5">
            {/* =========== START ROW =========== */}
            <div className="row g-3">
              {itemCardPackage.map((item) => {
                return (
                  <div className="col-12 col-md-6 col-lg-4" key={item.id}>
                    <PackageOfferCard
                      nameBackage={item.namePackage}
                      textPackage={item.text}
                      pricePackage={item.price}
                      typePackagePrice={item.typePrice}
                      dateTimePackage={item.dateTimePackage}
                      textButtonSubscription={"الأشتراك فى الباقة"}
                      textEndCard={"يستلزم بطاقة دفع"}
                    >
                      {item.itemsList.map((itemL) => {
                        return (
                          <li key={itemL.id} className="item-package-one d-flex  align-items-center  gap-2">
                            <div className="icon-chaeck-pack">
                              <FontAwesomeIcon icon={faCheck} />
                            </div>
                            {itemL.textItem}
                            {itemL.badgeTrue && (
                              <div className="badge-text-card">
                                <h2 className="text-package-color">{itemL.textBadge}</h2>
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </PackageOfferCard>
                  </div>
                );
              })}
            </div>
            {/* =========== END ROW =========== */}
          </div>
        </div>
        {/* ========== END CONTAINER ============ */}
      </div>
    </>
  );
};

export default PackageOffers;
