import "./TableDataInfo.css";

function TableDataInfo({ titleTableHeader, isHashTrue, hashId, dataTbody, dateThead }) {
  return (
    <div className="table-data-info">
      <h2 className="title">{titleTableHeader}</h2>
      <div className="table-data-content">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                {isHashTrue && (
                  <th className="hash-id" scope="col">
                    {hashId}
                  </th>
                )}
                {dateThead}
              </tr>
            </thead>
            <tbody>{dataTbody}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TableDataInfo;
