import "./SectionTitle.css";
const SectionTitle = ({ title, text, dataAos }) => {
  return (
    <div className="section-title" data-aos={dataAos}>
      <h2 className="title">{title}</h2>
      <p className="text">{text}</p>
    </div>
  );
};

export default SectionTitle;
