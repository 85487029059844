import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BgBody from "Components/Ui/BgBody/BgBody";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPassword, resetPasswordCheckCode, resetPasswordConfirm } from "store/customer/customerActions";
import * as Yup from "yup";
import FooterForm from "../FormComponent/FooterForm";
import FormComponent from "../FormComponent/FormComponent";
import HeaderForm from "../FormComponent/HeaderForm";

const validationSchema = Yup.object({
  username: Yup.string().required("البريد الألكتروني مطلوب"),
  code: Yup.string().when("$step", {
    is: 2,
    then: Yup.string().required("رمز التحقق مطلوب"),
  }),
  password: Yup.string().when("$step", {
    is: 3,
    then: Yup.string().required("كلمة المرور الجديدة مطلوبة"),
  }),
  password_confirmation: Yup.string().when("$step", {
    is: 3,
    then: Yup.string()
      .oneOf([Yup.ref("password"), null], "يجب أن تتطابق كلمات المرور")
      .required("تأكيد كلمة المرور مطلوب"),
  }),
});

const RestorePassword = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    username: "",
    code: "",
    password: "",
    password_confirmation: "",
  };

  const dispatch = useDispatch();
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    setIsSubmitting(true);
    if (step === 1) {
      dispatch(resetPassword({ username: values.username }))
        .then(unwrapResult)
        .then((res) => {
          setStep(2);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else if (step === 2) {
      dispatch(resetPasswordCheckCode({ code: values.code, username: values.username }))
        .then(unwrapResult)
        .then((res) => {
          setStep(3);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else if (step === 3) {
      dispatch(
        resetPasswordConfirm({
          username: values.username,
          password: values.password,
          password_confirmation: values.password_confirmation,
        }),
      )
        .then(unwrapResult)
        .then((res) => {
          toast.success(res.message);
          navigate("/login");
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <HelmetInfo titlePage={"استعادة كلمة المرور"} />
      <BgBody />

      <div className="restore-password">
        <FormComponent>
          <HeaderForm
            title={step === 1 ? "استعادة كلمة المرور" : step === 2 ? "التحقق من الرمز" : "تعيين كلمة مرور جديدة"}
            isText={false}
            text={false}
          />
          <FormField
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}>
            {step === 1 && (
              <InputFiled
                label={"البريد الألكتروني"}
                name="username"
                type="text"
                placeholder={"البريد الألكتروني"}
                success
              />
            )}
            {step === 2 && (
              <InputFiled label={"رمز التحقق"} name="code" type="text" placeholder={"أدخل رمز التحقق"} success />
            )}
            {step === 3 && (
              <>
                <InputFiled
                  label={"كلمة المرور الجديدة"}
                  name="password"
                  type="password"
                  placeholder={"أدخل كلمة المرور الجديدة"}
                  success
                />
                <InputFiled
                  label={"تأكيد كلمة المرور"}
                  name="password_confirmation"
                  type="password"
                  placeholder={"أعد إدخال كلمة المرور الجديدة"}
                  success
                />
              </>
            )}
            { !isSubmitting ? (
              <button type="submit" className="main-btn btn-submit mt-4 w-100" disabled={isSubmitting}>
                {step === 1 ? "إرسال رمز التحقق" : step === 2 ? "التالي" : "تعيين كلمة المرور"}
              </button>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-4">
                <Spinner animation="border" role="status" size="lg" />
              </div>
            )}
          </FormField>
        </FormComponent>
      </div>
      <FooterForm />
    </>
  );
};

export default RestorePassword;