import iconDate from "../../../assets/images/icons/calendar.svg";
import "./CardBooking.css";

const CardBooking = ({ numBooking, title, dateBooking, BgBadgeType, typeBooking }) => {
  return (
    <div className="card-booking-one mb-3">
      <div className="header-card-booking">
        <h2 className="num-booking">{numBooking}</h2>
      </div>

      <div className="info-card-booking d-flex align-items-center  gap-3 flex-wrap justify-content-between ">
        <div className="right-card-booking">
          <h2 className="title">{title}</h2>
          <div className="details-date d-flex  align-items-center  gap-2">
            <div className="icon-date icon-style-01">
              <img src={iconDate} alt="icon calender" width={"26px"} height={"26px"} />
            </div>
            <h2 className="text-date">{dateBooking}</h2>
          </div>
        </div>

        <div className="left-card-booking">
          <div className={`badge-card ${BgBadgeType}`}>{typeBooking}</div>
        </div>
      </div>
    </div>
  );
};

export default CardBooking;
