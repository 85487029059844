import NavBarComponent from "Components/NavBar/NavBarComponent";
import "./NavBarLanding.css";

const NavBarLanding = () => {
  return (
    <div className="navbar-landing">
      <NavBarComponent
        routeLogo={"/"}
        isInfoLoginNavBar={false}
        isLinksNavbar={false}
        vendorLoginRoute={"/loginDashboard"}
        vendorLoginButtonText={" تسجيل دخول مزود الخدمة"}
      />
    </div>
  );
};

export default NavBarLanding;
