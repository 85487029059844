import InputFiled from "Components/Forms/InputField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BgBody from "Components/Ui/BgBody/BgBody";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { registerCustomer } from "../../../store/auth/authActions";
import FooterForm from "../FormComponent/FooterForm";
import FormComponent from "../FormComponent/FormComponent";
import HeaderForm from "../FormComponent/HeaderForm";

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "./Register.css";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("ادخل الأسم الاول").min(4, "يجب ان يكون الاسم الاول اكثر من 3 احرف"),
  last_name: Yup.string().required("ادخل الأسم الاخير").min(4, "يجب ان يكون الاسم الثاني اكثر من 3 احرف"),
  email: Yup.string().email("ايميل خطاء").required("هذا الحقل مطلوب"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "رقم الجوال غير صحيح")
    .required("رقم الهاتف مطلوب"),
  password: Yup.string().required("كلمة السر مطلوبة").min(8, "يجب ان تكون كلمة السر اكثر من 8 احرف وارقام"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "كلمة السر غير متطابقة")
    .required("تأكيد كلمة السر"),
});

const Register = () => {
  const [policyChecked, setPolicyChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
  };
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("handle submit", values);
    dispatch(registerCustomer(values))
      .then(unwrapResult)
      .then((res) => {
        if (res.status === 201) {
          toast.success("تم تسجيلك بنجاح", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        }
      })
      .catch((err) => {
        const errorMessages = Object.values(err.errors);
        console.log({ errorMessages });
        if (errorMessages.length > 0) {
          errorMessages.forEach((msg) => {
            console.log("msg", msg[0]);
            toast.error(msg[0]);
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <HelmetInfo titlePage={"تسجيل حساب جديد"} />
      <BgBody />

      <div className="register-form">
        <FormComponent>
          <HeaderForm
            title={"تسجيل حساب جديد"}
            isText={true}
            text={
              <span className="d-flex align-items-center gap-2">
                تسجيل حساب جديد ?
                <Link to="/login" className="font-main link-form-register text-decoration-underline pt-0">
                  تسجيل الدخول
                </Link>
              </span>
            }
          />
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <InputFiled label={"الاسم الأول"} name="first_name" type="text" placeholder={"الاسم الأول"} success />
                <InputFiled label={"الاسم الأخير"} name="last_name" type="text" placeholder={"الاسم الأخير"} success />
                <InputFiled
                  label={" البريد الألكتروني"}
                  name="email"
                  type="email"
                  placeholder={"example@gmail.com"}
                  success
                />
                <InputFiled label={"رقم الهاتف"} name="phone" type="tel" placeholder={"رقم الهاتف"} success />
                <InputFiled label="كلمة المرور" name="password" type="password" placeholder="كلمة المرور" success />
                <InputFiled
                  label="تاكيد كلمة المرور "
                  name="password_confirmation"
                  type="password"
                  placeholder="تأكيد كلمة المرور"
                  success
                />
                <div className="main-check-form my-3 d-flex  flex-column  gap-2">
                  <div className="form-check d-flex  align-items-center  gap-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                      onChange={(e) => {
                        setPolicyChecked(e.target.checked);
                      }}
                    />
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                      الموافقة على{" "}
                      <Link to="/termsconditions" className="link-ckeck">
                        الشروط والأحكام
                      </Link>
                    </label>
                  </div>
                </div>
                <button
                  disabled={!isValid || !dirty || isSubmitting || !policyChecked}
                  type="submit"
                  className="main-btn btn-submit mt-4 w-100"
                >
                  {isSubmitting ? "جاري التسجيل..." : "تسجيل"}
                </button>
              </Form>
            )}
          </Formik>
        </FormComponent>
      </div>
      <FooterForm />
    </>
  );
};

export default Register;
