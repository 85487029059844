import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BgBody from "Components/Ui/BgBody/BgBody";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import Logo from "Dashboard/Components/Logo/Logo";
import FooterForm from "Pages/Auth/FormComponent/FooterForm";
import React from "react";

const ContentHeader = ({ children }) => {
  return (
    <>
    <HelmetInfo titlePage={"استكمال تفعيل الحساب"} />
      <BgBody />
      <div data-aos="fade-up" className="all-complete-account d-flex flex-column  justify-content-between  vh-100  overflow-auto ">
        <div className="container">
          <Logo routePage={"/dashboard/homedashboard"} />
          <HeaderPageInfo
            title={"استكمال تفعيل الحساب"}
            isShowLeftContent={true}
            contentPageLeft={
              <h2 className="title-page">ادخال بيانات الفروع والموظفين</h2>
            }
          />

          {children}
        </div>
        <FooterForm />
      </div>
    </>
  );
};

export default ContentHeader;
