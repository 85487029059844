import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getServices } from "../../../../store/admin/adminActions";
const AdminReportServices = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [totalServicesCount, setTotalServicesCount] = useState(0);

  const fetchServicesReport = useCallback(() => {
    setIsLoading(true);
    const params = {
      with: ["booking"],
      per_page: 100,
      limit: 100,
      sort: "DESC",
      paginate: "true",
    };
    dispatch(getServices(params))
      .then(unwrapResult)
      .then((res) => {
        setServices(res.data.data);
        setTotalServicesCount(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchServicesReport();
  }, [fetchServicesReport]);

  if (isLoading) return <Loader />;
  if (!services.length) return null;
  const sortedServices = [...services].sort((a, b) => b.bookings_count - a.bookings_count);

  return (
    <div>
      <div className="report-summary">
        <div className="summary-card card-shadow card-hover">
          <div className="card-content">
            <h3>{totalServicesCount}</h3>
            <p>عدد الخدمات</p>
          </div>
          <FontAwesomeIcon icon={faList} className="icon" />
        </div>
      </div>

      <div className="report-table" data-aos="fade-up">
        <h3 className="mb-4">الخدمات الأكثر طلبا</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>الخدمة</th>
              <th>عدد الحجوزات</th>
              <th>السعر</th>
              <th>الحالة</th>
            </tr>
          </thead>
          <tbody>
            {sortedServices.map((service, index) => (
              <tr key={service.id}>
                <td>{index + 1}</td>
                <td>{service.name_ar}</td>
                <td>{service.bookings_count}</td>
                <td>{service.price}</td>
                <td>{service.is_active === "1" ? "نشط" : "غير نشط"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReportServices;
