import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCountries, updateCountry } from "store/general/generalActions";

// Import the same UI components
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";

const Countries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");

  const [requestParams, setRequestParams] = useState({
    limit: 10,
    offset: 1,
    sort: "DESC",
    paginate: "true",
    field: "updated_at",
  });

  const fetchCountries = useCallback(() => {
    const params = {
      ...requestParams,
      ...(debouncedSearchText && {
        columns: ["name_ar"],
        operand: ["LIKE"],
        column_values: [`%${debouncedSearchText}%`],
      }),
    };

    setIsLoading(true);
    dispatch(getCountries(params))
      .then(unwrapResult)
      .then((res) => {
        const { data, total, links } = res.data;
        setData(data);
        setCurrentPageItemCounts(data?.length || 0);
        setTotalItemsCount(total || 0);
        setTotalPagesCount(links?.length - 2 || 0);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
        toast.error("فشل في جلب الدول");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, requestParams, debouncedSearchText]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const toggleSwitch = async (newValue, itemId) => {
    const country = data.find((c) => c.id === itemId);
    if (!country) return;

    try {
      const res = await dispatch(
        updateCountry({
          ...country,
          is_active: newValue ? "1" : "0",
        }),
      ).unwrap();

      if (res.success) {
        toast.success("تم التعديل بنجاح");
        fetchCountries();
      }
    } catch (error) {
      console.error("Error updating country:", error);
      toast.error("فشل في تحديث الدولة");
    }
  };

  return (
    <>
      <>
        <HelmetInfo titlePage={"الدول"} />
        <div className="main-header-countries" data-aos="fade-down">
          <HeaderPageInfo
            title={"الدول"}
            isShowLeftContent={true}
            contentPageLeft={
              <SearchHeaderPage
                isButtonActive={true}
                typeIcon={faPlus}
                textButton={"اضافة دولة جديدة"}
                functionButton={() => navigate("add")}
                onSearchChange={setSearchText}
              />
            }
          />
        </div>
      </>
      {isLoading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : (
        <>
          <div className="table-content mt-3" data-aos="fade-up">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">اسم الدولة</th>
                  <th scope="col">رمز الدولة</th>
                  <th scope="col">رمز الهاتف</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">الاجراءات</th>
                </>
              }
              dataTbody={
                <>
                  {data.map((country, index) => (
                    <tr key={country.id}>
                      <td>{country.name_ar}</td>
                      <td>{country.phone_code ?? "-"}</td>
                      <td>{country.code ?? "-"}</td>
                      <td>
                        <Toggleswitch
                          switchId={country.id}
                          nameSwitch={`country-${country.id}`}
                          value={country.is_active === "1"}
                          onChange={(newValue) => {
                            toggleSwitch(newValue, country.id);
                          }}
                        />
                      </td>
                      <td>
                        <IconsTable
                          typeIcon2={faEdit}
                          functionEdit={() => navigate(`edit/${country.id}`)}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              }
            />
          </div>
        </>
      )}
      <Pagination
        limit={requestParams.limit}
        setLimit={(limit) => setRequestParams((prev) => ({ ...prev, limit, offset: 1 }))}
        currentPage={requestParams.offset}
        setCurrentPage={(page) => setRequestParams((prev) => ({ ...prev, offset: page }))}
        limitOptions={[5, 10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default Countries;
