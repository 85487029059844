import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAdminWallet, getWalletTxns } from "store/general/generalActions";

const ProviderWalletDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [walletData, setWalletData] = useState(null);
  const [walletTxns, setWalletTxns] = useState([]);

  const fetchWalletDetails = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(getAdminWallet(id)).then(unwrapResult);
      setWalletData(response.data);
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      toast.error("فشل في تحميل تفاصيل المحفظة");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchWalletTxns = async () => {
    try {
      const params = {
        with: [],
        limit: 20,
        offset: 0,
        sort: "ASC",
        paginate: "true",
        field: "id",
        deleted: "all",
        resource: "all",
        resource_columns: ["id", "name_ar"],
        columns: ["wallet_id"],
        operand: ["="],
        column_values: [id],
      };
      const response = await dispatch(getWalletTxns(params)).then(unwrapResult);
      console.log("walletTxns", response.data.data);

      setWalletTxns(response.data.data);
    } catch (error) {
      console.error("Error fetching wallet transactions:", error);
      toast.error("فشل في تحميل سجل المعاملات");
    }
  };

  useEffect(() => {
    fetchWalletDetails();
    fetchWalletTxns();
  }, [id]);

  return (
    <>
      <HelmetInfo titlePage={"تفاصيل المحفظة"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/admin/homedashboard"}
          titleInfoPage={"تفاصيل المحفظة"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to={"/dashboard/admin/providerwallets"}>
            عودة
          </Link>
        </div>
      </div>
      <div className="main-header-employee">
        <HeaderPageInfo title={"تفاصيل المحفظة"} isShowLeftContent={false} />

        {isLoading ? (
          <div className="loader-content">
            <Loader />
          </div>
        ) : (
          <div className="table-content mt-3">
            <Card>
              <Card.Body>
                <Row className="mb-4">
                  <Col md={6}>
                    <p>
                      <strong>الرصيد:</strong> {walletData?.balance}
                    </p>
                    <p>
                      <strong>مقدم الخدمة:</strong> {walletData?.user?.name}
                    </p>
                  </Col>
                  <Col md={6}>
                    <p>
                      <strong>الحالة:</strong> {walletData?.is_active === 1 ? "مفعل" : "معطل"}
                    </p>
                    <p>
                      <strong>اخر تحديث:</strong> {walletData?.updated_at}
                    </p>
                  </Col>
                </Row>

                <h5 className="mb-3">سجل المعاملات</h5>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>التاريخ</th>
                      <th>النوع</th>
                      <th>المبلغ</th>
                      <th>وصف</th>
                    </tr>
                  </thead>
                  <tbody>
                    {walletTxns?.map((transaction) => (
                      <tr key={transaction.id}>
                        <td>{transaction.created_at}</td>
                        <td>{transaction.type}</td>
                        <td>{transaction.value}</td>
                        <td>{transaction.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default ProviderWalletDetails;
