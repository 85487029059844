import SerachBar from "Components/Ui/SerachBar/SerachBar";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { default as OwlCarousel } from "react-owl-carousel";
import shapeImg from "../../../assets/images/bg/A01.png";
import imgSlider from "../../../assets/images/main/01.png";
import "./Header.css";

const Header = ({ searchProviders }) => {
  const cardItems = [{ id: 0 }, { id: 1 }, { id: 2 }];
  return (
    <div className="header position-relative">
      <div className="shape-img position-absolute top-0 start-0 w-100 h-100">
        <img src={shapeImg} alt="img shap" />
      </div>

      <div className="container">
        <div className="slider-header">
          <div className="slider-card">
            <div className="row g-3 align-items-center ">
              <div className="col-12 col-md-7">
                <div className="info-slider" data-aos="fade-down">
                  <OwlCarousel className="owl-theme" {..._options}>
                    {cardItems.map((item, index) => {
                      return (
                        <div className="item" key={index}>
                          <div className="main-info-content">
                            <h2 className="head-title">خصم 50%</h2>
                            <h2 className="title">طريقة سهلة وفعالة للحصول على خدماتنا اليومية.</h2>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                  <SerachBar isText={true} searchProviders={searchProviders} />
                </div>
              </div>

              <div className="col-12 col-md-5">
                <div className="image-slider" data-aos="fade-up">
                  <img
                    src={imgSlider}
                    alt="img slider"
                    className="w-100 h-100 object-fit-cover image-anim"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const _options = {
  items: 1, // Number of items to display
  loop: true,
  margin: 10,
  autoplay: false,
  dots: true,
  nav: true,
  rtl: true, // Set RTL direction
  responsive: {
    0: {
      items: 1,
    },
    450: {
      itemd: 1,
    },
    768: {
      items: 1,
    },
  },
};
export default Header;
