import SectionTitle from "Components/SectionTitle/SectionTitle";
import SerachBar from "Components/Ui/SerachBar/SerachBar";
import "./HeaderServices.css";

const HeaderServices = ({ searchProviders }) => {
  return (
    <div className="header-services">
      <div className="container">
        <div className="row g-3">
          <div className="col-12 col-md-4">
            <SectionTitle
              title={"مزودي الخدمات"}
              text={"احجز مع شركاء ذوي تصنيف عالٍ بأسعار تنافسية"}
              dataAos={"fade-left"}
            />
          </div>

          <div className="col-12 col-md-8">
            <div data-aos="fade-right">
              <SerachBar isText={false} searchProviders={searchProviders} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderServices;
