import SelectCountryInput from "Components/Ui/SelectCountryInput/SelectCountryInput";
import React from "react";

const FormEditAddress = () => {
  return (
    <div className="form-inputs mt-2">
      {/* ============== START FORM ============== */}
      <form action="">
        <div className="mb-3">
          <label htmlFor="addressName" className="form-label">
            اسم العنوان
          </label>
          <input
            type="text"
            className="form-control"
            id="addressName"
            placeholder="اسم العنوان"
          />
        </div>
        <div className="mb-3">
          <SelectCountryInput />
        </div>
        <div className="select-type mb-3">
          <label htmlFor="selectRegion" className="form-label">
            المنطقة
          </label>
          <select
            id="selectRegion"
            className="form-select form-select-lg "
            aria-label="Large select example"
          >
            <option selected>المنطقة</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
        </div>
        <div className="select-type mb-3">
          <label htmlFor="selectCity" className="form-label">
            المدينة
          </label>
          <select
            id="selectCity"
            className="form-select form-select-lg "
            aria-label="Large select example"
          >
            <option selected>المدينة</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="addressNameDetails" className="form-label">
            العنوان تفصيلي
          </label>
          <input
            type="text"
            className="form-control"
            id="addressNameDetails"
            placeholder="العنوان تفصيلي"
          />
        </div>
        <button className="main-btn w-100 mt-4">حفظ</button>
      </form>
      {/* ============== END FORM ============== */}
    </div>
  );
};

export default FormEditAddress;
