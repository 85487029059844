import CounterContent from "Components/Ui/CounterContent/CounterContent";
import "./AchievementsInfo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SectionTitle from "Components/SectionTitle/SectionTitle";

const AchievementsInfo = () => {
  const counterInfo = [
    { id: 0, numCounter: "12000", text: "عميل" },
    { id: 1, numCounter: "1000", text: "مزودى الكفاءة عالية المهارة" },
    { id: 2, numCounter: "500", text: "شريك" },
    { id: 3, numCounter: "1800", text: "حجز خدمات" }
  ];
  return (
    <div className="achievements-info">
      {/* ======= START CONTAINER ======= */}
      <div className="container">
        {/* ========= START ROW ========== */}
        <div className="row g-3 align-items-center">
          {/* ======== START COL ======= */}
          <div className="col-12 col-md-6">
            {/* ======== START TITLE SECTION ======= */}
            <div className="section-title-landing" data-aos="fade-right">
              <SectionTitle
                title={"10 اعوامً من الإنجازات"}
                text={
                  "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها."
                }
                dataAos={false}
              />
            </div>
            {/* ======== END TITLE SECTION ======= */}
          </div>
          {/* ======== END COL ======= */}
          {/* ======== START COL ======= */}
          <div className="col-12 col-md-6">
            <div className="counter-home" data-aos="fade-left">
              {/* =========== START AL COUNTER  ============ */}
              <div className="all-counter">
                {/* =========== START MAIN COUNTER ========== */}
                <div className="main-counter">
                  {/* =========== START ROW ============ */}
                  <div className="row g-3">
                    {counterInfo.map((item) => {
                      return (
                        <>
                          {/* ========= START COL ==============*/}
                          <div className="col-12 col-sm-6" key={item.id}>
                            <CounterContent
                              NumCounter={item.numCounter}
                              timeDuration={"2"}
                              isIconPlus={true}
                              iconPlus={<FontAwesomeIcon icon={faPlus} />}
                              isIconImg={false}
                              iconSrc={false}
                              textCounter={item.text}
                              isRate={false}
                              rateNum={false}
                              isView={true}
                            />
                          </div>
                          {/* ========= END COL ==============*/}
                        </>
                      );
                    })}
                  </div>
                  {/* =========== END ROW ============ */}
                </div>
                {/* =========== END MAIN COUNTER ========== */}
              </div>
              {/* =========== START ALL COUNTER ============ */}
            </div>
          </div>
          {/* ======== END COL ======= */}
        </div>
        {/* ========= END ROW ========== */}
      </div>
      {/* ======= END CONTAINER ======= */}
    </div>
  );
};

export default AchievementsInfo;
