import axios from "axios";
const baseURL = "https://api.streamline.com.sa/api/v1";

const generalAxiosService = axios.create({
  baseURL,
});

generalAxiosService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// generalAxiosService.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       localStorage.removeItem("accessToken");
//       window.location.href = "/login";
//     }
//     return Promise.reject(error);
//   }
// );

export default generalAxiosService;
