import Loader from "Components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addServiceToCart, removeServiceFromCart } from "store/vendor/vendorSlice";
import imgProduct from "../../../../assets/images/products/01.png";
import CardProductDetails from "./CardProductDetails";

const CategoryFilter = ({ categories, services }) => {
  const dispatch = useDispatch();
  const addToCart = (service) => dispatch(addServiceToCart(service));
  const removeFromCart = (serviceId) => dispatch(removeServiceFromCart(serviceId));

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredServicesList, setFilteredServicesList] = useState(services);

  useEffect(() => {
    const filterServices = () => {
      if (selectedCategory) {
        return services.filter((item) => item.category_id === selectedCategory.id);
      }
      return services;
    };

    setFilteredServicesList(filterServices());
  }, [selectedCategory, services, setSelectedCategory]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  if (!categories || categories.length === 0 || services.length === 0) return <Loader />;
  return (
    <div className="main-filter-category">
      <h2 className="title-top-1">الفئات</h2>
      <div className="all-filter-buttons">
        <div className="filetr-buttons d-flex align-items-center gap-2">
          <button
            className={`main-btn main-outline-btn ${selectedCategory === null ? "active" : ""}`}
            onClick={() => handleCategoryChange(null)}
          >
            جميع المنتجات
          </button>
          {categories.length &&
            categories.map((category, index) => (
              <button
                className={`main-btn main-outline-btn ${selectedCategory === category ? "active" : ""}`}
                key={index}
                onClick={() => handleCategoryChange(category)}
              >
                {category.name_ar}
              </button>
            ))}
        </div>
      </div>

      <div className="all-products mt-3">
        <div className="row g-3">
          {filteredServicesList?.length === 0 ? (
            <div className="col-12 text-center">
              <p className="text-muted">لا توجد منتجات في هذه الفئة.</p>
            </div>
          ) : (
            filteredServicesList?.map((service, index) => (
              <div className="col-12 col-sm-6 col-xl-4" key={index}>
                <CardProductDetails
                  serviceId={service.id}
                  imgProduct={imgProduct}
                  titleService={service.name_ar}
                  timeServ={`${service.service_time} دقيقة`}
                  price={service.price}
                  handleAddToCart={addToCart}
                  handleRemoveFromCart={removeFromCart}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryFilter;
