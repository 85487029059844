import { unwrapResult } from "@reduxjs/toolkit";
import PaymentModal from "Pages/ServicesProviders/ServiceDetails/StepsServices/PaymentModal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PaySubscription, SubscripeToPackage } from "store/vendor/vendorActions";
import "./PackageOfferCard.css";

const PackageOfferCard = ({
  nameBackage,
  textPackage,
  pricePackage,
  typePackagePrice,
  dateTimePackage,
  children,
  textButtonSubscription,
  textEndCard,
  packageId,
}) => {
  const [isLoader, setLoaderButtonSub] = useState(false);

  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [isPaid, setIsPaid] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (isPaid) {
      toast.success("تم الاشتراك بنجاح");
      setIsModalOpen(false);
      navigate("/dashboard/vendor/providersubscription");
    }
  }, [isPaid, navigate]);

  const buttonSub = async () => {
    setLoaderButtonSub(true);
    try {
      const subscriptionResult = await dispatch(
        SubscripeToPackage({
          auto_renew: true,
          package_id: packageId,
        }),
      ).then(unwrapResult);

      const subscription_id = subscriptionResult.data.id;
      if (subscription_id) {
        setSubscriptionId(subscription_id);
        const paymentResult = await dispatch(PaySubscription(subscription_id)).then(unwrapResult);
        if (paymentResult.message === "success") {
          const payment_url = paymentResult.data;
          setIsModalOpen(true);
          setPaymentUrl(payment_url);
        }
      } else {
        toast.error("في الاشتراك حدث خطأ ما");
      }
    } catch (error) {
      console.log("Error in subscription process:", error);
      toast.error("حدث خطأ أثناء عملية الاشتراك");
    } finally {
      setLoaderButtonSub(false);
    }
  };

  return (
    <>
      <div className="package-offer-card">
        <div className="name-package">{nameBackage}</div>
        <p className="text-package">{textPackage}</p>

        <div className="pricing-package">
          <h2 className="num-price">
            {pricePackage} <span className="type-pay">{typePackagePrice}</span>
          </h2>
          <div className="text-date">{dateTimePackage}</div>
        </div>

        <div className="info-package-card">
          <ul className="list-pakage-info d-flex  flex-column  gap-3 p-0 m-0">{children}</ul>
        </div>

        <button onClick={buttonSub} className="main-btn main-outline-btn w-100 mt-3">
          {isLoader === true ? (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            textButtonSubscription
          )}
        </button>
      </div>

      <PaymentModal
        show={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        paymentUrl={paymentUrl}
        fromVendor={true}
        bookingId={subscriptionId}
        setIsPaid={setIsPaid}
      />
    </>
  );
};

export default PackageOfferCard;
