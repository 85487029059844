import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import NoticeCard from "Components/Ui/NoticeCard/NoticeCard";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import iconBell from "../../../assets/images/icons/bellNav.svg";

const NoticesDash = () => {
  const cardNotices = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
  return (
    <>
      <HelmetInfo titlePage={"الأشعارات"} />
      <div data-aos="fade-left">
        <HeaderPageInfo title={"الأشعارات"} isShowLeftContent={false} contentPageLeft={false} />
      </div>
      <main>
        <div className="notices my-3" data-aos="fade-up">
          <div className="all-notices" data-aos="fade-up">
            <div className="row g-3">
              {cardNotices.map((item) => {
                return (
                  <div className="col-12 col-card" key={item.id}>
                    {/* ========= START CARD NOTICE ============ */}
                    <NoticeCard
                      title={"تم قبول طلب M-12345"}
                      routeLink={"##"}
                      linkText={"06/06/2023 - 01:00 PM"}
                      textInfoDate={false}
                      icon={iconBell}
                    />
                    {/* ========= EN CARD NOTICE ============ */}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default NoticesDash;
