import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import AchievementsInfo from "Dashboard/Components/LandingPageContent/AchievementsInfo/AchievementsInfo";
import CardsAdvantages from "Dashboard/Components/LandingPageContent/CardsAdvantages/CardsAdvantages";
import FaqLanding from "Dashboard/Components/LandingPageContent/FaqLanding/FaqLanding";
import FooterLanding from "Dashboard/Components/LandingPageContent/Footer/FooterLanding";
import HeroContent from "Dashboard/Components/LandingPageContent/HeroContent/HeroContent";
import NavBarLanding from "Dashboard/Components/LandingPageContent/NavBarLanding/NavBarLanding";
import Plans from "Dashboard/Components/LandingPageContent/Plans/Plans";
import Supporters from "Dashboard/Components/LandingPageContent/Supporters/Supporters";
import "./HomeLanding.css";

const HomeLandinPage = () => {
  return (
    <section>
      <HelmetInfo titlePage={"الرئيسية"} />
      <NavBarLanding />
      <HeroContent />
      <Supporters />
      <CardsAdvantages />
      <Plans />
      <FaqLanding />
      <AchievementsInfo />
      <FooterLanding />
    </section>
  );
};

export default HomeLandinPage;
