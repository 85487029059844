import { unwrapResult } from "@reduxjs/toolkit";
import CardBooking from "Components/BookingsInfo/CardBooking/CardBooking";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderInfoPage from "Components/Ui/HeaderInfoPage/HeaderInfoPage";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getFormattedDateForBooking } from "utlis";
import { listBookings } from "../../store/customer/customerActions";
import "./Bookings.css";

export const statusList = [
  { value: "confirmed", text: "مؤكد", badge: "bg-01" },
  { value: "notconfirmed", text: "غير مؤكد", badge: "bg-02" },
  { value: "completed", text: "مكتمل", badge: "bg-03" },
  { value: "cancelled", text: "ملغي", badge: "bg-04" },
  { value: "notshown", text: "غير معروض", badge: "bg-05" },
];

const Bookings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const providersMap = useSelector((state) =>
    state.customer.serviceProviders.reduce((acc, provider) => {
      acc[provider.id] = {
        name: provider.name,
      };
      return acc;
    }, {})
  );

  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [bookingsList, setBookingsList] = useState([]);
  const [status, setStatus] = useState("notconfirmed");
  const [isLoading, setIsLoading] = useState(true);

  const getCustomerBookings = useCallback(
    (param) => {
      setIsLoading(true);
      dispatch(listBookings(param))
        .then(unwrapResult)
        .then((result) => {
          setBookingsList(result.data.data);
          setCurrentPageItemCounts(result.data.data?.length || 0);
          setTotalItemsCount(result.data.total || 0);
          setTotalPagesCount(result.data.links.length - 2 || 0);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch]
  );

  const params = useMemo(() => {
    return {
      columns: ["status", "user_id"],
      operand: ["=", "="],
      column_values: [status, user.id],
      paginate: true,
      sort: "DESC",
      limit: limit,
      offset: currentPage,
    };
  }, [status, user.id, currentPage, limit]);

  useEffect(() => {
    getCustomerBookings(params);
  }, [getCustomerBookings, params, currentPage, limit, status]);

  return (
    <>
      <HelmetInfo titlePage={"حجوزاتي"} />
      <div className="main-bookings padding-bottom-70">
        <HeaderPageTitle isActiveLinkTwo={false} routePage={false} titlePage={false} titleInfoPage={"حجوزاتى"} />
        <div className="container">
          <HeaderInfoPage
            title={"حجوزاتي"}
            isActiveLink={false}
            routePage={false}
            textLink={false}
            isNewContent={true}
            Newcontent={
              <div className="select-booking">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    e.preventDefault();
                    setStatus(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="confirmed">مؤكد</option>
                  <option selected value="notconfirmed">
                    غير مؤكد
                  </option>
                  <option value="completed">مكتمل</option>
                  <option value="cancelled">ملغي</option>
                </select>
              </div>
            }
          />
          {/*  */}
          <div className="all-cards-bookings" data-aos="fade-up" style={{ marginTop: "20px", minHeight: "500px" }}>
            {isLoading ? (
              <Loader />
            ) : (
              bookingsList.map((item, index) => (
                <Link key={item.id} to={`/bookings/${item.id}`}>
                  <CardBooking
                    numBooking={`حجز رقم#` + item.id}
                    title={providersMap[item.vendor_id]?.name}
                    dateBooking={getFormattedDateForBooking(item.booking_day)}
                    BgBadgeType={statusList.find((status) => status.value === item.status)?.badge}
                    typeBooking={statusList.find((status) => status.value === item.status)?.text}
                  />
                </Link>
              ))
            )}
          </div>
          {/*  */}
          <Pagination
            limit={limit}
            setLimit={setLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limitOptions={[5, 10, 20, 30, 40]}
            currentPageItemsCount={currentPageItemCounts}
            totalItemsCount={totalItemsCount}
            totalPagesCount={totalPagesCount}
            isShowTotalPagesCount={true}
          />
        </div>
      </div>
    </>
  );
};

export default Bookings;
