import Loader from "Components/Loader/Loader";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCartBranchId, setCartOffer, setCartServices } from "store/customer/customerSlice";
import CardHeadDetails from "./CardHeadDetails";
import ModalService from "./ModalsServices/MoalService";
import ModalOffer from "./ModalsServices/ModalOffer";

const ServicesNameFilter = ({
  currentServices,
  categories,
  isLoadingCategories,
  currentBranch,
  isLoading,
}) => {
  const navigate = useNavigate();

  const offers = useSelector((state) => state.customer.offers);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [servicesList, setServicesList] = useState([]);
  const [selectedServiceOffersList, setSelectedServiceOffersList] = useState([]);

  const [showModalOffer, setShowModalOffer] = useState(false);
  const [showModalService, setShowModalService] = useState(false);

  const [selectedItemInfo, setSelectedItemInfo] = useState(null); // used for modal info

  useEffect(() => {
    if (showModalOffer === true || showModalService === true) {
      setSelectedItemInfo(selectedItemInfo);
    }
    return () => {
      setSelectedItemInfo(null);
      if (showModalOffer) setShowModalOffer(false);
      if (showModalService) setShowModalService(false);
    };
  }, [showModalOffer, showModalService]); //eslint-disable-line

  useEffect(() => {
    if (currentServices && currentServices.length > 0) {
      setServicesList(currentServices);
    }
  }, [currentServices, navigate]); //eslint-disable-line

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    const serviceOffers = offers.filter((offer) => offer?.offerService?.id === category.id);
    setSelectedServiceOffersList(serviceOffers);
    const categoryServices = currentServices.filter(
      (service) => service?.category_id === category.id,
    );
    console.log("cat services", categoryServices);
    setServicesList(categoryServices);
  };

  useEffect(() => {
    setShowAll(false);
  }, [selectedItemInfo]);

  const dispatch = useDispatch();

  const addItemToCart = (item, type) => {
    if (type === "offer") {
      dispatch(setCartOffer(item.id));
    } else {
      dispatch(setCartServices(item));
    }
    dispatch(setCartBranchId(currentBranch.id));
  };

  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll((prev) => !prev);

  if (isLoadingCategories) return <Loader />;
  return (
    <section>
      {isLoading ? (
        <div className="text-center" style={{ marginTop: "20px" }}>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div>
            {showModalOffer && (
              <ModalOffer
                showModalOffer={showModalOffer}
                hideModalOffer={() => setShowModalOffer(false)}
                modalInfo={selectedItemInfo}
                addToCart={(item) => addItemToCart(item, "offer")}
              />
            )}

            {showModalService && (
              <ModalService
                showModalService={showModalService}
                hideModalService={() => setShowModalService(false)}
                modalInfo={selectedItemInfo}
                addToCart={(item) => addItemToCart(item, "service")}
              />
            )}
          </div>

          <div className="services-filter-name" data-aos="fade-left">
            <h2 className="title size-text-color-dark fs-3">الخدمات</h2>
            <div className="main-buttons-serv">
              <OwlCarousel dir="rtl" className="owl-theme" {...carouselOptions}>
                {categories.map((category) => (
                  <div
                    key={category.id}
                    className="item"
                    onClick={() => handleCategorySelect(category)}>
                    <button
                      className="btn-filter-one active main-btn main-outline-btn"
                      style={{
                        width: "100%",
                        padding: "10px 20px",
                        fontWeight: "bold",
                        borderRadius: "10px",
                      }}>
                      {category.name_ar}
                    </button>
                  </div>
                ))}
              </OwlCarousel>

              {servicesList.length > 0 ? (
                <div className="cards-bottom mt-4">
                  <div className="all-cards-details">
                    {servicesList.length > 0 &&
                      servicesList.map((service, index) => (
                        <ServiceCard
                          key={`svc-${index}`}
                          item={service}
                          handleCardClick={() => {
                            setSelectedItemInfo(service);
                            setShowModalService(true);
                          }}
                          isOffer={false}
                        />
                      ))}

                    {(showAll
                      ? selectedServiceOffersList
                      : selectedServiceOffersList.slice(0, 3)
                    ).map((offer, index) => (
                      <ServiceCard
                        key={`offer-${index}`}
                        item={offer}
                        handleCardClick={() => {
                          setSelectedItemInfo(offer);
                          setShowModalOffer(true);
                        }}
                        isOffer={true}
                      />
                    ))}
                  </div>
                  {servicesList.length > 4 && (
                    <button onClick={toggleShowAll} className="main-btn main-outline-btn">
                      {showAll ? "أقل" : "كل الخدمات"}
                    </button>
                  )}
                </div>
              ) : (
                <div className="text-center" style={{ marginTop: "30px" }}>
                  <h2>لا توجد خدمات مضافة </h2>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </section>
  );
};

const ServiceCard = ({ item, handleCardClick, isOffer }) => {
  const handleClick = () => {
    handleCardClick();
  };

  return (
    <div className="card-serv-one" onClick={handleClick}>
      <CardHeadDetails
        item={item}
        isOffer={isOffer}
        titleServ={isOffer ? `${item?.name_ar} - ${item?.price_type}` : item?.name_ar}
        timeServ={isOffer ? null : `${item?.service_time} دقيقة`}
        priceServ={isOffer ? `${item.discount_percentage}% خصم` : item?.price}
      />
    </div>
  );
};

const carouselOptions = {
  margin: 10,
  autoplay: false,
  dots: false,
  nav: true,
  rtl: true,
  responsive: {
    0: { items: 2 },
    450: { items: 3 },
    768: { items: 4 },
  },
};

export default ServicesNameFilter;
