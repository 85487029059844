import { faEdit, faEllipsisVertical, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";

import { useCallback, useEffect, useState } from "react";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addSection,
  getSections,
  getServices,
  updateSection,
  updateService,
} from "store/vendor/vendorActions";
import { createImageBlob } from "utlis";
import SectionModal from "./SectionModal";
import "./ServicesPage.css";

const ServicesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.vendor.isLoading);

  const [services, setServices] = useState([]);
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    limit: 10,
    currentPage: 1,
    currentPageItemCounts: 0,
    totalPagesCount: 0,
    totalItemsCount: 0,
  });
  const [activeTab, setActiveTab] = useState("services");
  const [sections, setSections] = useState([]);

  const handleShowAddSectionModal = () => setShowSectionModal(true);
  const handleCloseAddSectionModal = () => setShowSectionModal(false);

  const handleSaveNewSection = async (sectionData) => {
    const { name, description, colors } = sectionData;
    try {
      const imageBlob = await createImageBlob(colors[0]);
      const imageFile = new File([imageBlob], `section-${name}.jpg`, { type: "image/jpeg" });
      const formData = new FormData();
      formData.append("name_ar", name);
      formData.append("name_en", name);
      formData.append("description_ar", description);
      formData.append("description_en", JSON.stringify(colors));
      formData.append("image", imageFile);
      await dispatch(addSection(formData)).then(unwrapResult);
      toast.success("تم إضافة القسم الجديد بنجاح");
      handleCloseAddSectionModal();
    } catch (error) {
      console.error("Error adding new section:", error);
      toast.error("فشل في إضافة القسم الجديد");
    }
  };

  const handleToggleSwitch = async (newValue, index) => {
    try {
      await dispatch(
        updateService({
          ...services[index],
          is_active: newValue ? "1" : "0",
        }),
      ).then(unwrapResult);

      const updatedServices = [...services];
      updatedServices[index].is_active = newValue ? "1" : "0";
      setServices(updatedServices);
      toast.success("تم التعديل بنجاح");
    } catch (error) {
      console.error("Error updating service:", error);
      toast.error("فشلت العملية");
    }
  };

  const fetchServices = useCallback(
    async (params) => {
      try {
        const result = await dispatch(getServices(params)).then(unwrapResult);
        setServices(result.data.data);
        setPagination((prev) => ({
          ...prev,
          currentPageItemCounts: result.data.data?.length || 0,
          totalItemsCount: result.data.total || 0,
          totalPagesCount: result.data.links.length - 2 || 0,
        }));
      } catch (error) {
        console.error("Error fetching services:", error);
        toast.error("فشل في تحميل الخدمات");
      }
    },
    [dispatch],
  );

  const fetchSections = useCallback(async () => {
    try {
      const params = {
        with: [],
        limit: pagination.limit,
        offset: 0,
        sort: "ASC",
        paginate: "true",
        field: "id",
        deleted: "all", //all,deleted
        resource: "all", //all,custom
        resource_columns: ["id", "name_ar"],
        columns: ["is_active", "vendor_id"],
        operand: ["="],
        column_values: ["1", user.model_id],
      };
      const result = await dispatch(getSections(params)).then(unwrapResult);
      setSections(result.data.data || []);
    } catch (error) {
      console.error("Error fetching sections:", error);
      toast.error("فشل في تحميل التبويبات");
    }
  }, [dispatch, user.model_id]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  }, [search]);

  useEffect(() => {
    const searchParams = {
      with: [
        "category",
        "vendor",
        "createdBy",
        "branches",
        "section",
        "employees",
        "employees.user",
      ],
      limit: pagination.limit,
      offset: pagination.currentPage,
      sort: "DESC",
      paginate: "true",
      field: "id",
      deleted: "all",
      resource: "all",
      resource_columns: ["id", "name_ar"],
      columns: ["name_ar", "vendor_id"],
      operand: ["like", "="],
      column_values: [`%${search}%`, user.model_id],
    };

    fetchServices(searchParams);
  }, [pagination.limit, pagination.currentPage, search, fetchServices, user.model_id]);

  useEffect(() => {
    if (activeTab === "sections") {
      fetchSections();
    }
  }, [activeTab, fetchSections]);

  const handleSectionToggleSwitch = async (newValue, index) => {
    try {
      await dispatch(updateSection({ ...sections[index], is_active: newValue ? "1" : "0" })).then(
        unwrapResult,
      );
      toast.success("تم التعديل بنجاح");
    } catch (error) {
      console.error("Error updating section:", error);
      toast.error("فشلت العملية");
    }
  };
  return (
    <>
      <HelmetInfo titlePage={"الخدمات"} />
      <SectionModal
        showModalAddTab={showSectionModal}
        hideModalAddTab={handleCloseAddSectionModal}
        onClose={handleCloseAddSectionModal}
        onSave={handleSaveNewSection}
      />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"الخدمات"}
          isShowLeftContent={true}
          contentPageLeft={
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <SearchHeaderPage
                typeIcon={false}
                textButton={false}
                functionButton={false}
                isButtonActive={false}
                onSearchChange={setSearch}
              />
              <Dropdown>
                <Dropdown.Toggle className="main-btn mx-2">اضافة جديد</Dropdown.Toggle>
                <Dropdown.Menu className="drop-menu-head">
                  <Dropdown.Item onClick={() => navigate("editaddServicesDetails")}>
                    خدمة جديدة
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleShowAddSectionModal}>تبويب جديد</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
        />
      </div>

      <div className="mt-3">
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3 custom-tabs">
          <Tab eventKey="services" title="الخدمات">
            <div className="table-content table-content-2">
              {isLoading ? (
                <Loader />
              ) : (
                <TableDataInfo
                  titleTableHeader={false}
                  isHashTrue={false}
                  hashId={false}
                  dateThead={
                    <>
                      <th scope="col">اسم الخدمة</th>
                      <th scope="col">حالة الخدمة</th>
                      <th scope="col">القسم</th>
                      <th scope="col">السعر</th>
                      <th scope="col">خدمة مميزة</th>
                      <th scope="col">تفعيل/الغاء</th>
                      <th scope="col">الاجراءات</th>
                    </>
                  }
                  dataTbody={
                    <>
                      {services.length > 0 &&
                        services.map((item, index) => (
                          <tr key={`${item.id}-${index}`}>
                            <td>{item?.name_ar}</td>
                            <td>
                              <p className={item?.is_active ? "text-success" : "text-danger"}>
                                {item?.is_active ? "نشط" : "غير نشط"}
                              </p>
                            </td>
                            <td>{item.category.name_ar}</td>
                            <td>{item.price}</td>
                            <td>{item.featured ? "نعم" : "لا"}</td>
                            {!item.isEmpty && (
                              <>
                                <td>
                                  <Toggleswitch
                                    switchId={item.id}
                                    nameSwitch={`services-${item.id}`}
                                    value={item.is_active === "1"}
                                    onChange={(newValue) => handleToggleSwitch(newValue, index)}
                                  />
                                </td>
                                <td>
                                  <IconsTable
                                    typeIcon1={faEye}
                                    functionEye={() => navigate(`servicesDetails/${item.id}`)}
                                    typeIcon2={faEdit}
                                    functionEdit={() =>
                                      navigate(`editaddServicesDetails/${item.id}`)
                                    }
                                    isDeleteIcon={false}
                                    functionDelete={false}
                                    typeIcon3={false}
                                  />
                                </td>
                              </>
                            )}
                            {item.isEmpty && (
                              <>
                                <td></td>
                                <td>
                                  <Dropdown className="menu-drop-1">
                                    <Dropdown.Toggle variant="transparent">
                                      <FontAwesomeIcon icon={faEllipsisVertical} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() =>
                                          navigate(`editaddServicesDetails/${item.id}`)
                                        }>
                                        تعديل
                                      </Dropdown.Item>
                                      <Dropdown.Item>حذف</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td></td>
                              </>
                            )}
                          </tr>
                        ))}
                    </>
                  }
                />
              )}
            </div>
          </Tab>
          {/* sections tab */}
          <Tab eventKey="sections" title="التبويبات">
            <div className="table-content table-content-2">
              {isLoading ? (
                <Loader />
              ) : (
                <TableDataInfo
                  titleTableHeader={false}
                  isHashTrue={false}
                  dateThead={
                    <>
                      <th scope="col">اسم التبويب</th>
                      <th scope="col">الوصف</th>
                      {/* <th scope="col">الإجراءات</th> */}
                    </>
                  }
                  dataTbody={
                    <>
                      {sections.map((section, index) => (
                        <tr key={`section-${section.id}-${index}`}>
                          <td>{section.name_ar}</td>
                          <td>{section.description_ar}</td>
                          {/* <td>
                            <Toggleswitch
                              switchId={section.id}
                              nameSwitch={`section-${section.id}`}
                              value={section.is_active === "1"}
                              onChange={(newValue) => handleSectionToggleSwitch(newValue, index)}
                            />
                          </td> */}
                        </tr>
                      ))}
                    </>
                  }
                />
              )}
            </div>
          </Tab>
        </Tabs>
      </div>

      <Pagination
        limit={pagination.limit}
        setLimit={(limit) => setPagination((prev) => ({ ...prev, limit }))}
        currentPage={pagination.currentPage}
        setCurrentPage={(currentPage) => setPagination((prev) => ({ ...prev, currentPage }))}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={pagination.currentPageItemCounts}
        totalItemsCount={pagination.totalItemsCount}
        totalPagesCount={pagination.totalPagesCount}
        isShowTotalPagesCount={true}
      />
    </>
  );
};

export default ServicesPage;
