import {
  faDownload,
  faEye,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import PaginationSelect from "Dashboard/Components/UiDashboard/PaginationSelect/PaginationSelect";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import { useNavigate } from "react-router-dom";
import "./TaxInvoices.css"
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
const TaxInvoices = () => {
  const cardItems = [
    {
      id: 1,
      numInvoice: "1234569",
      textServ: "خدمة",
      dateInvoice: "2023/11/16",
      price: "200"
    }
  ];
  const navigate = useNavigate();
  return (
    <>
    <HelmetInfo titlePage={"الفواتير الضريبية"} />

    <div data-aos="fade-left">
      <HeaderPageInfo
        title={"الفواتير الضريبية"}
        isShowLeftContent={true}
        contentPageLeft={
          <SearchHeaderPage
            isButtonActive={false}
            typeIcon={false}
            textButton={false}
            functionButton={false}
          />
        }
      />
      </div>
      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        <TableDataInfo
          titleTableHeader={false}
          isHashTrue={false}
          hashId={false}
          dateThead={
            <>
              <th scope="col">كود الفاتورة</th>
              <th scope="col">نوع الفاتورة</th>
              <th scope="col">تاريخ الفاتورة</th>
              <th scope="col"> اجمالى قيمة الفاتورة</th>
              <th scope="col"> الاجراءات</th>
            </>
          }
          dataTbody={
            <>
              {cardItems.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.numInvoice}</td>
                    <td>{item.textServ}</td>
                    <td>{item.dateInvoice}</td>
                    <td>{item.price}</td>

                    <td>
                      <IconsTable
                        typeIcon1={faEye}
                        functionEye={() => {
                          navigate(`${item.id}`);
                        }}
                        typeIcon2={faDownload}
                        functionEdit={(e) => {
                          e.preventDefault();
                        }}
                        isDeleteIcon={true}
                        functionDelete={(e) => {
                          e.preventDefault();
                        }}
                        typeIcon3={faPrint}
                      />
                    </td>
                  </tr>
                );
              })}
            </>
          }
        />
      </div>
      <PaginationSelect
        itemsPageTitle={"عدد العناصر بالصفحة"}
        optionsSelect={
          <>
            <option value="">اختار</option>
            <option value="1"> 1</option>
            <option value="2"> 2</option>
            <option value="3"> 3</option>
          </>
        }
        infoItemsPageSelect1={"of 200 items"}
        infoItemsPageSelect2={"of 44 pages"}
        optionsSelect2={
          <>
            <option value="">اختار</option>
            <option value="1"> 1</option>
            <option value="2"> 2</option>
            <option value="3"> 3</option>
          </>
        }
      />
    </>
  );
};

export default TaxInvoices;
