import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { logoutAction } from "store/auth/authActions";

const useGetAuthUser = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const localUser = useMemo(() => {
    try {
      const storedUser = localStorage.getItem("user");
      return storedUser ? JSON.parse(storedUser) : null;
    } catch (error) {
      console.error("Error parsing local user:", error);
      return null;
    }
  }, []);

  useEffect(() => {
    const checkUserAndLogout = () => {
      if (localUser && localUser.type !== user?.type) {
        dispatch(logoutAction());
        return;
      }

      const isAdminRoute = pathname.includes("/dashboard/admin");
      const isVendorRoute = pathname.includes("/dashboard/vendor");

      if ((isAdminRoute && user?.type !== "admin") || (isVendorRoute && user?.type !== "vendor")) {
        dispatch(logoutAction());
      }
    };

    checkUserAndLogout();
  }, [localUser, dispatch, user, pathname]);

  return user;
};

export default useGetAuthUser;
