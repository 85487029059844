import { Link } from "react-router-dom";
import imgBanner from "../../../assets/images/main/02.png";
import "./BannerHome.css";

const BannerHome = () => {
  return (
    <div className="banner-home">
      <div className="banner-info">
        <div className="row gx-3">
          <div className="col-md-12 col-lg-6">
            <div className="content-banner-info" data-aos="fade-left">
              <h2 className="head-title">من نحن</h2>
              <h2 className="title">الوجهة الأعلى تقييمًا للجمال والعافية</h2>
              <p className="text">
                هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل
                ما عبر إدخال بعض
              </p>
              <p className="text">هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم،</p>
              <Link to="/about" className="main-btn main-outline-btn">
                اكتشف المزيد
              </Link>
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <div className="image-banner">
              <img src={imgBanner} alt="img banner" className="w-100 h-100 object-fit-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerHome;
