import { faClock as regularIcon } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeCartOffer } from "store/customer/customerSlice";
import "./ModalServices.css";

const ModalOffer = ({ showModalOffer, hideModalOffer, modalInfo: offerInfo, addToCart }) => {
  const [isAddedToCard, setIsAddedToCard] = useState(false);
  const cartDetails = useSelector((state) => state.customer.cartDetails);

  useEffect(() => {
    if (cartDetails && offerInfo) {
      const isAdded = cartDetails.offer_id === offerInfo.id;
      if (isAdded) {
        setIsAddedToCard(true);
      }
    }
  }, [cartDetails, offerInfo]);

  const dispatch = useDispatch();

  const handleButtonClick = () => {
    if (isAddedToCard) {
      dispatch(removeCartOffer(offerInfo.id));
      setIsAddedToCard(false);
    } else {
      addToCart(offerInfo);
      setIsAddedToCard(true);
    }
  };

  if (!offerInfo || !showModalOffer) return null;
  return (
    <CustomModal show={showModalOffer} onHide={hideModalOffer} title={offerInfo.name_ar} newClass={"modal-offer"}>
      <div className="modal-content-info">
        <div className="header-modal-details border-bottom pb-2">
          <p className="text size-text-color-gray pt-2 lh-lg ">{offerInfo?.description ?? "لا يوجد وصف"}</p>
        </div>
        {offerInfo.offerService && (
          <div className="main-info-content-modal pt-3">
            <h2 className="title-head size-text-color-dark mb-3">العرض يشمل</h2>
            <div className="all-info-right">
              <div className="info-right mb-3">
                <h2 className="title size-text-color-dark"> {offerInfo.offerService.name_ar}</h2>
                <div className="time-info size-text-color-gray d-flex gap-2 align-items-center pt-2">
                  <div className="icon size-text-color-gray">
                    <FontAwesomeIcon icon={regularIcon} />
                  </div>
                  {offerInfo.offerService.service_time} دقيقة
                </div>
              </div>
            </div>
          </div>
        )}

        <button type="button" className="main-btn w-100 mt-4" onClick={handleButtonClick}>
          {isAddedToCard ? "إزالة" : "اختيار"}
        </button>
      </div>
    </CustomModal>
  );
};

export default ModalOffer;
