import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderInfoPage from "Components/Ui/HeaderInfoPage/HeaderInfoPage";
import { useState } from "react";
// import { updateProfileImage } from "store/customer/customerActions"; // You'll need to create this action
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateProfileDetails } from "store/customer/customerActions";
import { setProfileInfo } from "store/customer/customerSlice";
import FormUpdateProfile from "./FormUpdateProfile";
import "./UpdatePersonalData.css";

const UpdatePersonalData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileInfo = useSelector((state) => state.customer.profileInfo);
  const [uploadedImage, setUploadedImage] = useState();

  const [initialValues, setInitialValues] = useState({
    first_name: profileInfo.first_name,
    last_name: profileInfo.last_name,
    email: profileInfo.email,
    phone: profileInfo.phone,
    image: profileInfo.image,
  });

  const handleSubmit = async () => {
    try {
      const { image, ...rest } = initialValues;
      const formData = new FormData();

      Object.keys(rest).forEach((key) => {
        formData.append(key, initialValues[key]);
      });

      if (uploadedImage) {
        formData.delete("image");
        formData.append("image", uploadedImage);
      }

      const res = await dispatch(updateProfileDetails(formData)).unwrap();
      if (res.success) {
        toast.success("تم تحديث البيانات بنجاح");
        dispatch(setProfileInfo(initialValues));
        navigate("/profile");
      }
    } catch (error) {
      console.log("error", error);
      const errors = Object.values(error.errors).map((err) => err);
      if (errors.length > 0) {
        errors.forEach((err) => {
          err.forEach((errorMessage) => {
            toast.error(errorMessage);
          });
        });
      }
    }
  };

  const isDisabled = () => {
    const { first_name, last_name, email, phone } = initialValues;

    const isNameValid = (name) => name && name.length >= 4;
    const areFieldsFilled = first_name && last_name && email && phone;
    const areNamesValid = isNameValid(first_name) && isNameValid(last_name);

    return !areFieldsFilled || !areNamesValid;
  };

  const handleImageUpdate = (e) => {
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setUploadedImage(file);
    setInitialValues((prev) => ({ ...prev, image: imageUrl }));
  };

  if (!profileInfo) return <> </>;

  return (
    <>
      <HelmetInfo titlePage={"تعديل البيانات الشخصية"} />
      <div className="update-profile-data">
        <div className="container">
          <HeaderInfoPage
            title={"تعديل البيانات الشخصية"}
            isActiveLink={true}
            routePage={"/profile"}
            textLink={"عودة"}
            isNewContent={false}
            Newcontent={false}
          />

          <div className="profile-update-content">
            <div className="image-update-section">
              <img src={initialValues.image} alt="Profile" className="profile-image" />
              <input
                type="file"
                id="profile-image-input"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpdate}
              />
              <label htmlFor="profile-image-input" className="main-btn">
                تحديث الصورة
              </label>
            </div>

            <div className="form-profile-update">
              <FormUpdateProfile
                profileInfo={profileInfo}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={handleSubmit}
            className="main-btn btn-submit mt-5 w-30"
            disabled={isDisabled()}
            style={{
              backgroundColor: isDisabled() && "gray",
              cursor: isDisabled() && "not-allowed",
            }}
          >
            حفظ
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdatePersonalData;
