import { unwrapResult } from "@reduxjs/toolkit";
import CustomModal from "Components/CustomModal/CustomModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addClientCanncelationReasons } from "store/general/generalActions";

const ModalAddNewClientReason = ({
  showModalAddReason,
  hideModalAddReason,
  fetchData,
}) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const data = {
      reason_ar: value,
      reason_en: value,
      is_active: "1",
    };
    dispatch(addClientCanncelationReasons(data))
      .then(unwrapResult)
      .then(() => {
        hideModalAddReason();
        toast.success("تم الاضافة بنجاح.");
        fetchData();
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <CustomModal
      show={showModalAddReason}
      onHide={hideModalAddReason}
      title={"إضافة سبب جديد"}
      newClass={"modal-add-reason"}
    >
      <div className="form-modal">
        <form action="">
          <div className="form-input-one mb-3">
            <label htmlFor="exampleInputReason" className="form-label">
              {/* السبب بالعربية */}
              السبب
            </label>
            <textarea
              className="form-control"
              id="exampleInputReason"
              rows={5}
              onChange={(e) => setValue(e.target.value)}
            ></textarea>
          </div>
          {/* <div className="form-input-one mb-3">
            <label htmlFor="exampleInputReason" className="form-label">
              السبب بالانجليزية
            </label>
            <textarea
              className="form-control"
              id="exampleInputReason"
              rows={5}
              onChange={(e) => setValue(e.target.value)}
            ></textarea>
          </div> */}

          <button
            onClick={handleSubmit}
            className="main-btn w-100 mt-4"
            type={"button"}
          >
            حفظ
          </button>
        </form>
      </div>
    </CustomModal>
  );
};

export default ModalAddNewClientReason;
