import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getVendorSettings } from "store/customer/customerActions";
import { clearCart, setCategories, setVendorSettings } from "store/customer/customerSlice";
import { getCategories } from "store/general/generalActions";
import { useCustomerStoreValue } from "store/hooks";
import BranchWokringDays from "./BranchWorkingDays/BranchWorkingDays";
import CostDetails from "./CostDetails/CostDetails";
import HeaderServicesName from "./HeaderServicesName";
import ProviderImages from "./ProviderImages";
import "./ServiceName.css";
import ServicesNameFilter from "./ServicesNameFilter/ServicesNameFilter";
import EmployeesInfo from "./TeamRate/TeamRate";

const ServiceProviderDetails = () => {
  const dispatch = useDispatch();
  const { idCardService: id } = useParams();
  console.log({ id });

  const branches = useSelector((state) => state.customer.vendorsBranches);
  const cartDetails = useSelector((state) => state.customer.cartDetails);
  const services = useSelector((state) => state.customer.servicesList);

  const [currentBranch, setCurrentBranch] = useState(null);
  const [currentServices, setCurrentServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const vendorSettings = useSelector((state) => state.customer.vendorSettings);
  const [categories, isLoadingCategories] = useCustomerStoreValue(
    {
      storeName: "customer",
      storeKey: "categories",
    },
    getCategories,
    setCategories,
  );

  useEffect(() => {
    if (!id || !branches.length) {
      console.warn("no id or branches");
      return;
    }
    setIsLoading(true);
    const branchDetails = branches.find((branch) => branch.id === parseInt(id));
    setCurrentBranch(branchDetails || null);

    if (cartDetails.branch_id !== branchDetails?.id) {
      dispatch(clearCart());
    }

    if (Object.keys(vendorSettings).length === 0) {
      dispatch(getVendorSettings(branchDetails.vendor_id))
        .unwrap()
        .then((response) => {
          dispatch(setVendorSettings(response.data));
        });
    }
    setIsLoading(false);
  }, [id, branches, cartDetails.branch_id, vendorSettings, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    if (!currentBranch) return;
    let branchServices = [];
    services.forEach((service) => {
      service.branches.forEach((branch) => {
        if (branch.id === currentBranch.id && branch.is_active === "1") {
          branchServices.push(service);
        }
      });
    });
    setCurrentServices(branchServices);
    setIsLoading(false);
  }, [currentBranch, services]);

  const memoizedServicesNameFilter = useMemo(
    () => (
      <ServicesNameFilter
        currentServices={currentServices}
        categories={categories}
        isLoadingCategories={isLoadingCategories}
        currentBranch={currentBranch}
        isLoading={isLoading}
      />
    ),
    [currentServices, categories, isLoadingCategories, currentBranch, isLoading],
  );

  const memoizedCostDetails = useMemo(
    () => (
      <CostDetails
        currentBranch={currentBranch}
        currentCartDetails={cartDetails}
        vendorSettings={vendorSettings}
      />
    ),
    [currentBranch, cartDetails, vendorSettings],
  );

  if (!currentBranch || !categories) return null;

  return (
    <section>
      <HelmetInfo titlePage={"الخدمة"} />
      <div className="all-services-name padding-bottom-70">
        <HeaderPageTitle
          isActiveLinkTwo={true}
          routePage={"/servicesproviders"}
          titlePage={"الخدمات"}
          titleInfoPage={currentBranch.name}
          routeHomePage={"/"}
        />
        <div className="container">
          <div className="main-services-page-name">
            <HeaderServicesName currentBranch={currentBranch} />
            <ProviderImages />
            <div className="main-all-serv mt-5">
              <div className="row g-3">
                <div className="col-12 col-lg-12 col-xl-8">{memoizedServicesNameFilter}</div>
                <div className="col-12 col-lg-12 col-xl-4">{memoizedCostDetails}</div>
              </div>
            </div>
            <BranchWokringDays currentBranch={currentBranch} vendorSettings={vendorSettings} />
          </div>
        </div>
        <div className="container">
          <EmployeesInfo currentBranch={currentBranch} />
        </div>
      </div>
    </section>
  );
};

export default React.memo(ServiceProviderDetails);
