import { useEffect, useRef } from "react";
import "./DropMenuNav.css";

const DropMenuNav = ({ NewClassDrop, iconImg, isOpen, setIsOpen, children }) => {
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div className={`dropdown-1 ${NewClassDrop}`} ref={dropdownRef}>
        <div className="icon-img">{iconImg}</div>
        {isOpen && (
          <div className="dropdown-menu-1">
            <ul className="navbar-nav list-menu-drop p-0 m-0 pb-2 d-flex flex-column">{children}</ul>
          </div>
        )}
      </div>
    </>
  );
};

export default DropMenuNav;
