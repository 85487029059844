import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { getVendor, updateVendor } from "store/admin/adminActions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  provider_name: Yup.string().required("اسم المزود مطلوب"),
  commercial_no: Yup.string().required("رقم السجل التجاري مطلوب"),
  description: Yup.string(),
  website_url: Yup.string().url("رابط غير صالح"),
  twitter: Yup.string(),
  instagram: Yup.string(),
  snapchat: Yup.string(),
  registered_tax: Yup.boolean(),
  tax_number: Yup.string(),
  is_active: Yup.boolean(),
});

const ProfileEditeAdd = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    provider_name: formValues?.name || "",
    commercial_no: formValues?.commercial_no || "",
    description: formValues?.description || "",
    website_url: formValues?.website_url || "",
    twitter: formValues?.twitter || "",
    instagram: formValues?.instagram || "",
    snapchat: formValues?.snapchat || "",
    tax_number: formValues?.tax_number || "",
    registered_tax: formValues?.tax_number ? true : false,
    is_active: formValues?.is_active === "1" || false,
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      const mappedValues = {
        ...values,
        provider_name: values.provider_name,
        is_active: values.is_active ? "1" : "0",
        category_id: formValues?.category_id,
        email: formValues?.user?.email,
        first_name: formValues?.user?.first_name,
        last_name: formValues?.user?.last_name,
        phone: formValues?.user?.phone,
        registered_tax: formValues?.tax_number ? true : false,
      };

      if (!mappedValues.registered_tax) {
        delete mappedValues.tax_number;
      }
      delete mappedValues.registered_tax;

      Object.entries(mappedValues).forEach(([key, value]) => {
        if (key === "category_id") {
          formData.append("category_id", [formValues?.category_id]);
        } else if (value !== null && value !== "") {
          formData.append(key, value);
        }
      });
      delete formData.category_id;
      const result = await dispatch(updateVendor({ id, data: formData })).then(unwrapResult);
      console.log({ result });
      navigate("/dashboard/admin/serviceprovideraccounts");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const getServiceProviderData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const result = await dispatch(getVendor(id)).then(unwrapResult);
        setFormValues(result.data);
      } catch (error) {
        console.error("Error loading vendor:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getServiceProviderData();
  }, [id]);

  const handleChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <>
        <HelmetInfo titlePage={id ? "تعديل مستخدم" : "اضافة مستخدم جديد"} />
        <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
          <HeaderPageTitle
            isActiveLinkTwo={false}
            routePage={false}
            titlePage="حسابات مزود الخدمة"
            titleInfoPage={id ? "تعديل مستخدم" : "اضافة مستخدم جديد"}
            routeHomePage="/dashboard/homedashboard"
          />
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/serviceprovideraccounts">
            عودة
          </Link>
        </div>
      </>

      <div className="info-user-edit">
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          <div className="row g-1 g-sm-3">
            <div className="col-sm-6">
              <InputField
                name="provider_name"
                label="اسم المزود"
                placeholder="ادخل اسم المزود"
                success
                value={initialValues.provider_name}
                handleOnChange={(value) => handleChange("provider_name", value)}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                name="commercial_no"
                label="رقم السجل التجاري"
                placeholder="ادخل رقم السجل التجاري"
                value={initialValues.commercial_no}
                handleOnChange={(value) => handleChange("commercial_no", value)}
              />
            </div>
            <div className="col-12">
              <InputField
                name="description"
                label="الوصف"
                placeholder="ادخل الوصف"
                value={initialValues.description}
                handleOnChange={(value) => handleChange("description", value)}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                name="website_url"
                label="الموقع الإلكتروني"
                placeholder="ادخل رابط الموقع"
                value={initialValues.website_url}
                handleOnChange={(value) => handleChange("website_url", value)}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                name="twitter"
                label="تويتر"
                placeholder="حساب تويتر"
                value={initialValues.twitter}
                handleOnChange={(value) => handleChange("twitter", value)}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                name="instagram"
                label="انستغرام"
                placeholder="حساب انستغرام"
                value={initialValues.instagram}
                handleOnChange={(value) => handleChange("instagram", value)}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                name="snapchat"
                label="سناب شات"
                placeholder="حساب سناب شات"
                value={initialValues.snapchat}
                handleOnChange={(value) => handleChange("snapchat", value)}
              />
            </div>

            {initialValues.registered_tax && (
              <div className="col-sm-6">
                <InputField
                  name="tax_number"
                  label="الرقم الضريبي"
                  placeholder="ادخل الرقم الضريبي"
                  value={initialValues.tax_number}
                  handleOnChange={(value) => handleChange("tax_number", value)}
                />
              </div>
            )}

            <div className="col-sm-6">
              <SelectField
                name="is_active"
                label="نشط"
                options={[
                  { label: "نشط", value: "1" },
                  { label: "غير نشط", value: "0" },
                ]}
                handleOnChange={(value) => handleChange("is_active", value)}
                valueSelected={initialValues.is_active === "1" ? "نشط" : "غير نشط"}
              />
            </div>
            <div className="col-12">
              <button type="submit" className="main-btn btn-submit px-5 mt-4">
                {"حفظ"}
              </button>
            </div>
          </div>
        </FormField>
      </div>
    </>
  );
};

export default ProfileEditeAdd;
