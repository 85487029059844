import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import PageTilte from "Components/PageTilte/PageTilte";
import CardProduct from "Components/Ui/CardProduct/CardProduct";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { listWishList } from "store/customer/customerActions";
import locationIcon from "../../assets/images/icons/location.svg";
import starIcon from "../../assets/images/icons/star.svg";
import imgCard from "../../assets/images/products/01.png";

const FavoriteProducts = () => {
  const dispatch = useDispatch();
  const [favoriteProducts, setFavoriteProducts] = useState([]);

  const getFavoriteProducts = useCallback(
    (params) => {
      dispatch(listWishList(params))
        .then(unwrapResult)
        .then((res) => {
          let vendorsListFav = res.data.data;
          vendorsListFav = vendorsListFav.map((item) => {
            return {
              productId: item.id,
              isProuctActive: item.is_active,
              vendor_id: item.vendor_id,
              productName: item.vendor.name,
              proructImage: item.vendor.image,
              productBranchAddress: item.vendor.branches[0].address,
            };
          });

          setFavoriteProducts(vendorsListFav);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const params = {
      with: ["vendor", "vendor.branches"],
      limit: 20,
      offset: 0,
      sort: "DESC",
      paginate: "true",
      field: "id",
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name_ar"],
    };
    getFavoriteProducts(params);
  }, [getFavoriteProducts]);
  const navigate = useNavigate();

  return (
    <>
      <HelmetInfo titlePage={"المفضلة"} />
      <div className="favorite-products padding-bottom-70">
        <PageTilte titlePage={"المفضلة"} />
        <div className="container" style={{ minHeight: "80vh" }}>
          <div className="all-favorite-products" data-aos="fade-up">
            <div className="row g-3">
              {favoriteProducts.map((product, index) => {
                return (
                  <div
                    key={index}
                    className="col-12 col-sm-6 col-md-4 col-lg-3"
                    onClick={() => {
                      console.log("product", product);
                      const providerId = product.vendor_id;
                      navigate(`/servicesproviders/${providerId}`);
                    }}>
                    <CardProduct
                      bgColorBadge={false}
                      textBadge={false}
                      newClassBadge={"badge-product-fav d-none"}
                      isIconFavorite={true}
                      imgCard={product.proructImage ? product.proructImage : imgCard}
                      cardTitle={product.productName}
                      cardSubTitle={product.productBranchAddress}
                      cardPrice={product.isProuctActive ? "متاح" : "غير متاح"}
                      locationIcon={locationIcon}
                      textLocation={product.productBranchAddress}
                      starIcon={starIcon}
                      numRate={"4.5"}
                      totalRate={"(120 تقيم)"}
                      routeCard={`/serviceproviders/${product.productId}`}
                      textButton={"القسم"}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FavoriteProducts;
