import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAdminEmployee, updateAdminEmployee } from "store/admin/adminActions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("الاسم الأول مطلوب"),
  last_name: Yup.string().required("الاسم الأخير مطلوب"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "يجب أن يكون الرقم بالأرقام فقط")
    .required("رقم الهاتف مطلوب"),
  email: Yup.string().email("البريد الإلكتروني غير صالح").required("البريد الإلكتروني مطلوب"),
  salary: Yup.number().required("الراتب مطلوب"),
  job_title: Yup.string().required("المسمى الوظيفي مطلوب"),
  start_date: Yup.date().required("تاريخ البدء مطلوب"),
  end_date: Yup.date().required("تاريخ الانتهاء مطلوب"),
  is_active: Yup.string().required("الحالة حالة مطلوبة"),
});

const AdminEditEmployee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [employeeData, setEmployeeData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    salary: "",
    job_title: "",
    start_date: "",
    end_date: "",
    is_active: "1",
  });

  useEffect(() => {
    if (id) {
      setIsDataLoading(true);
      dispatch(getAdminEmployee(id))
        .then(unwrapResult)
        .then((response) => {
          if (response) {
            const formatDate = (dateString) => {
              if (!dateString) return "";
              const date = new Date(dateString);
              return date.toISOString().split("T")[0];
            };

            setEmployeeData({
              first_name: response?.data?.user?.first_name || "",
              last_name: response?.data?.user?.last_name || "",
              email: response?.data?.user?.email || "",
              phone: response?.data?.user?.phone || "",
              salary: response?.data?.salary?.toString() || "",
              job_title: response?.data?.job_title || "",
              start_date: formatDate(response?.data?.start_date) || "",
              end_date: formatDate(response?.data?.end_date) || "",
              is_active: response?.data?.is_active?.toString() || "1",
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching employee:", error);
          toast.error("حدث خطأ أثناء تحميل بيانات الموظف");
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    }
  }, [id, dispatch]);

  const handleSubmit = async (values) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      await dispatch(updateAdminEmployee({ id, ...values })).then(unwrapResult);
      toast.success("تم تحديث بيانات الموظف بنجاح");
      navigate("/dashboard/admin/employees");
    } catch (error) {
      console.error("Error updating employee:", error);
      toast.error("حدث خطأ أثناء تحديث بيانات الموظف");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (key, value) => {
    setEmployeeData((prev) => ({ ...prev, [key]: value }));
  };

  if (isDataLoading) return <Loader />;

  return (
    <>
      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle titleInfoPage="تعديل الموظف" routeHomePage="/admin/dashboard" />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/employees">
            عودة
          </Link>
        </div>
      </div>

      <div className="details-add-edite-PROFILE mt-3" data-aos="fade-up">
        <div className="info-user-edit">
          <FormField
            initialValues={employeeData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            <div className="row g-1 g-sm-3">
              <div className="col-12 col-md-6">
                <InputField
                  label="الاسم الأول"
                  name="first_name"
                  type="text"
                  placeholder="الاسم الأول"
                  handleOnChange={(value) => handleChange("first_name", value)}
                  success
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label="اسم العائلة"
                  name="last_name"
                  type="text"
                  placeholder="اسم العائلة"
                  handleOnChange={(value) => handleChange("last_name", value)}
                  success
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label="البريد الإلكتروني"
                  name="email"
                  type="email"
                  placeholder="البريد الإلكتروني"
                  success
                  handleOnChange={(value) => handleChange("email", value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label="رقم الهاتف"
                  name="phone"
                  type="text"
                  placeholder="رقم الهاتف"
                  success
                  handleOnChange={(value) => handleChange("phone", value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label="المسمى الوظيفي"
                  name="job_title"
                  type="text"
                  placeholder="المسمى الوظيفي"
                  success
                  handleOnChange={(value) => handleChange("job_title", value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label="الراتب"
                  name="salary"
                  type="number"
                  placeholder="الراتب"
                  success
                  handleOnChange={(value) => handleChange("salary", value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label="تاريخ البدء"
                  name="start_date"
                  type="date"
                  success
                  handleOnChange={(value) => handleChange("start_date", value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label="تاريخ الانتهاء"
                  name="end_date"
                  type="date"
                  success
                  handleOnChange={(value) => handleChange("end_date", value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <SelectField
                  name="is_active"
                  label="الحالة"
                  options={[
                    { value: "1", label: "نشط" },
                    { value: "0", label: "غير نشط" },
                  ]}
                  success
                  handleOnChange={(value) => handleChange("is_active", value)}
                />
              </div>
            </div>

            <button type="submit" className="main-btn btn-submit px-5 mt-4" disabled={isSubmitting}>
              {isSubmitting ? "جاري الحفظ..." : "حفظ"}
            </button>
          </FormField>
        </div>
      </div>
    </>
  );
};

export default AdminEditEmployee;
