import FormField from "Components/Forms/FormFiled";
import SelectField from "Components/Forms/SelectField";
import TextAreaInput from "Components/Forms/TextArea";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const AddEditNotificationCustomer = () => {
  const validationSchema = Yup.object().shape({
    statusBook: Yup.string().required("اختار حالة الحجز"),
    statusPay: Yup.string().required("اختار حالة الدفع"),
    timeSend: Yup.string().required("اختار توقيت الارسال"),
    message: Yup.string().required("اكتب نص التذكير"),
  });

  const initialValues = {
    statusBook: "",
    statusPay: "",
    timeSend: "",
    message: "",
  };
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    resetForm();
  };
  return (
    <>
      <HelmetInfo titlePage={"اضافة / تعديل "} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={"/dashboard/settings"}
          titlePage={"الاعدادات"}
          titleInfoPage={"اضافة / تعديل"}
          routeHomePage={"/dashboard/homedashboard"}
        />
        <div className="link-back">
          <Link className="size-text-color-dark main-text-color fs-5" to="/dashboard/settings">
            عودة
          </Link>
        </div>
      </div>

      <div className="form-edit-services" data-aos="fade-up">
        {/* ========== START FORM FIELD ========= */}
        <FormField initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          <div className="row g-1 g-sm-3">
            <div className="col-12 col-md-6">
              <div className="select-reg">
                <label htmlFor="exampleFormControlInput001" className="form-label">
                  حالة الحجز
                </label>
                <SelectField
                  name="statusBook"
                  label={false}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                    { value: "option3", label: "Option 3" },
                  ]}
                  valueSelected={"حالة الحجز"}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="select-reg">
                <label htmlFor="exampleFormControlInput002" className="form-label">
                  حالة الدفع
                </label>
                <SelectField
                  name="statusPay"
                  label={false}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                    { value: "option3", label: "Option 3" },
                  ]}
                  valueSelected={"حالة الدفع"}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="select-reg">
                <label htmlFor="exampleFormControlInput003" className="form-label">
                  توقيت الارسال
                </label>
                <SelectField
                  name="timeSend"
                  label={false}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                    { value: "option3", label: "Option 3" },
                  ]}
                  valueSelected={"توقيت الارسال"}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="select-reg">
                <label htmlFor="exampleFormControlInput004" className="form-label">
                  طريقة الارسال
                </label>
                <SelectField
                  name="methodSend"
                  label={false}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                    { value: "option3", label: "Option 3" },
                  ]}
                  valueSelected={"طريقة الارسال"}
                />
              </div>
            </div>
            <div className="col-12 col-md-12">
              <TextAreaInput label="نص التذكير" name="message" placeholder="نص التذكير" success />
            </div>
          </div>

          <button type="submit" className="main-btn btn-submit px-5 mt-4">
            حفظ
          </button>
        </FormField>
        {/* ========== END FORM FIELD ========= */}
      </div>
    </>
  );
};

export default AddEditNotificationCustomer;
