export const formatDateToArabic = (dateTimeString) => {
  // Convert the input string to a Date object
  const date = new Date(dateTimeString);

  // Define options for formatting the date and time in Arabic
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    localeMatcher: "best fit",
    numberingSystem: "latn", // Arabic numerals
    calendar: "kobtic-civil", // Islamic Civil calendar
  };

  // Create a formatted date string in Arabic
  const formattedDate = new Intl.DateTimeFormat("ar", options).format(date);
  return formattedDate;
};

export const formatDateToTime24 = (dateTimeString) => {
  const date = new Date(dateTimeString);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
};

export const objectToQueryParams = (obj) => {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        params.append(`${key}[${index}]`, item);
      });
    } else {
      params.append(key, value);
    }
  }

  return params.toString();
};

export const convertTimeToDate = (timeString) => {
  const [hours, minutes] = timeString?.split(":").map(Number) || [0, 0];
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
};

export const getFormattedDateForBooking = (booking_day) => {
  const day = new Date(booking_day);
  const month = day.toLocaleString("ar-EG", { month: "long" });
  const dayOfMonth = day.getDate();
  const year = day.getFullYear();
  const date = `${dayOfMonth} ${month} ${year}`;
  return date;
};

export const extractOffers = (serviceProviders, servicesList) => {
  const vendorsOffers = [];
  serviceProviders.forEach(({ offers }) => {
    offers.forEach((offer) => {
      if (offer.is_active === "1") {
        const svc = servicesList.find((service) => {
          return (
            service.section_id === offer.section_id &&
            service.category_id === offer.category_id &&
            service.sub_category_id === offer.sub_category_id &&
            service.vendor_id === offer.vendor_id
          );
        });

        vendorsOffers.push({
          ...offer,
          offerService: svc,
        });
      }
    });
  });
  return vendorsOffers;
};

export const extractEmployees = (services) => {
  let employeesMap = {};
  services.forEach((service) => {
    const serviceEmployees = service.employees;
    if (serviceEmployees && serviceEmployees.length > 0) {
      serviceEmployees.forEach((employee) => {
        if (!employeesMap[employee.id]) {
          employeesMap[employee.id] = {
            ...employee,
          };
        }
      });
    }
  });
  return employeesMap;
};

export const extractCustomerOffers = (allOffers) => {
  const customerOffers = [];
  allOffers.forEach((offer) => {
    const branch = offer.offerService.branches.find((branch) => {
      return branch.vendor_id === offer.vendor_id && branch.is_active === "1";
    });

    const newOfferObj = {
      name: offer.name_ar,
      branch_name: branch?.name,
      branch_address: branch.address,
      long: branch.long,
      lat: branch.lat,
    };
    customerOffers.push(newOfferObj);
  });
  return customerOffers;
};

export const extractBranchesFromVendors = (vendors) => {
  const branchWithVendor = vendors.flatMap((vendor) =>
    vendor.branches
      .filter((branch) => branch.is_active === "1")
      .map((branch) => ({
        ...branch,
        vendor: {
          ...vendor,
        },
      })),
  );
  return branchWithVendor;
};

export const createImageBlob = (color) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;
    const ctx = canvas.getContext("2d");

    ctx.fillStyle = color || "#000000";
    ctx.fillRect(0, 0, 100, 100);

    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      "image/jpeg",
      0.95,
    ); // JPEG format with 0.95 quality
  });
};

// force use of vendor access token for admin dashboard
export const forceVendorAccessToken = () => {
  // check if an admin user is logged in
  // if yes, then use the vendor access token constant value
  //
};

// TODO just use local storage for the vendor access token
