import "./NavBar.css";
import NavBarComponent from "./NavBarComponent";

const NavBar = () => {
  return (
    <>
      <div className="navbar-home">
        <NavBarComponent
          routeLogo={"/"}
          isInfoLoginNavBar={true}
          isLinksNavbar={true}
          customerLoginRoute={"/login"}
          customerLoginButtonText={"تسجيل دخول"}
          vendorLoginButtonText={"الإنضمام كشريك"}
          vendorLoginRoute={"/landingPageDashboard"}
        />
      </div>
    </>
  );
};

export default NavBar;
