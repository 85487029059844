// @ts-nocheck
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "Components/Forms/InputField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BgBody from "Components/Ui/BgBody/BgBody";
import FooterForm from "Pages/Auth/FormComponent/FooterForm";
import FormComponent from "Pages/Auth/FormComponent/FormComponent";
import HeaderForm from "Pages/Auth/FormComponent/HeaderForm";
import { sendOtpCode } from "store/auth/authActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

const ChangeEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("البريد الإلكتروني غير صحيح")
      .required("البريد الإلكتروني مطلوب"),
  });

  const initialValues = {
    email: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    dispatch(sendOtpCode(values.email))
      .then(unwrapResult)
      .then(() => {
        navigate("/otp");
      })
      .catch((error) => {
        navigate("/otp");
        console.log(error, "error");
      });

    resetForm();
  };

  return (
    <>
      <HelmetInfo titlePage={"تغيير البريد الألكتروني"} />
      <BgBody />

      <div className="restore-password">
        <FormComponent>
          <HeaderForm
            title={"تغيير البريد الألكتروني"}
            isText={false}
            text={false}
          />
          {/* ========== START FORM FIELD ========= */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty }) => (
              <Form>
                <InputField
                  type="email"
                  name="email"
                  placeholder="البريد الألكتروني"
                  label={"البريد الألكتروني"}
                  success
                />
                <button
                  type="submit"
                  className="main-btn btn-submit mt-4 w-100"
                  disabled={!(dirty && isValid)}
                >
                  التالي
                </button>
              </Form>
            )}
          </Formik>
          {/* ========== END FORM FIELD ========= */}
        </FormComponent>
      </div>
      <FooterForm />
    </>
  );
};

export default ChangeEmail;
