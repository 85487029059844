import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import FormField from "Components/Forms/FormFiled";
import SelectField from "Components/Forms/SelectField";
import InputFiled from "Components/Forms/InputField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";

const EditCompanyActivityData = () => {
  const validationSchema = Yup.object().shape({
    select_1: Yup.string().required(
      "اختار هل النشاط مسجل بضريبة القيمة المضافة"
    ),
    services_2: Yup.string().required("اختار الخدمات الرئيسية المقدمة"),
    taxNumber: Yup.string()
      .matches(/^\d{9}$/, "يرجى إدخال رقم ضريبي صحيح مكون من تسعة أرقام")
      .required("يرجى إدخال رقم ضريبي"),
    website: Yup.string()
      .url("يرجى إدخال رابط صحيح للموقع الإلكتروني")
      .required("يرجى إدخال رابط الموقع الإلكتروني"),
    twitterUrl: Yup.string()
      .url("يرجى إدخال رابط صحيح لحساب تويتر")
      .required("يرجى إدخال رابط حساب تويتر"),
    instaUrl: Yup.string()
      .url("يرجى إدخال رابط صحيح لحساب إنستجرام")
      .required("يرجى إدخال رابط حساب إنستجرام"),
    snapUrl: Yup.string()
      .url("يرجى إدخال رابط صحيح لحساب سناب شات")
      .required("يرجى إدخال رابط حساب سناب شات")
  });

  const initialValues = {
    select_1: "",
    services_2: "",
    taxNumber: "",
    website: "",
    twitterUrl: "",
    instaUrl: "",
    snapUrl: ""
  };
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    resetForm();
  };
  return (
    <>
      <HelmetInfo titlePage={"تعديل بيانات الشركة والنشاط"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={false}
          titleInfoPage={"تعديل بيانات الشركة والنشاط"}
          routeHomePage={"/dashboard/admin/homedashboard"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/settings/companyActivityData"
          >
            عودة
          </Link>
        </div>
      </div>

      <div className="form-edit-services" data-aos="fade-up">
        {/* ========== START FORM FIELD ========= */}
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <div className="row g-1 g-sm-3">
            <div className="col-12 col-md-6">
              <div className="select-reg">
                <label
                  htmlFor="exampleFormControlInput001"
                  className="form-label"
                >
                  هل النشاط مسجل بضريبة القيمة المضافة
                </label>
                <SelectField
                  name="select_1"
                  label={false}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                    { value: "option3", label: "Option 3" }
                  ]}
                  valueSelected={"هل النشاط مسجل بضريبة القيمة المضافة"}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="select-reg">
                <label
                  htmlFor="exampleFormControlInput002"
                  className="form-label"
                >
                  الخدمات الرئيسية المقدمة
                </label>
                <SelectField
                  name="services_2"
                  label={false}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                    { value: "option3", label: "Option 3" }
                  ]}
                  valueSelected={"الخدمات الرئيسية المقدمة"}
                />
              </div>
            </div>
            <div className="col-12 col-md-12">
              <InputFiled
                label={"الرقم الضريبيى"}
                name="taxNumber"
                type="number"
                placeholder={"الرقم الضريبيى"}
                success
              />
            </div>
            <div className="col-12 col-md-6">
              <InputFiled
                label={"رابط الموقع الألكتروني"}
                name="website"
                type="text"
                placeholder={"رابط الموقع الألكتروني"}
                success
              />
            </div>
            <div className="col-12 col-md-6">
              <InputFiled
                label={"رابط تويتر"}
                name="twitterUrl"
                type="text"
                placeholder={"رابط تويتر"}
                success
              />
            </div>
            <div className="col-12 col-md-6">
              <InputFiled
                label={"رابط انستجرام"}
                name="instaUrl"
                type="text"
                placeholder={"رابط انستجرام"}
                success
              />
            </div>
            <div className="col-12 col-md-6">
              <InputFiled
                label={"رابط سناب شات"}
                name="snapUrl"
                type="text"
                placeholder={"رابط سناب شات"}
                success
              />
            </div>
          </div>

          <button type="submit" className="main-btn btn-submit px-5 mt-4">
            حفظ
          </button>
        </FormField>
        {/* ========== END FORM FIELD ========= */}
      </div>
    </>
  );
};

export default EditCompanyActivityData;
