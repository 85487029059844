import img_1 from "assets/images/dashboardImages/pay/01.svg";
import img_2 from "assets/images/dashboardImages/pay/02.svg";
import img_3 from "assets/images/dashboardImages/pay/03.svg";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getPaymentSettings, updatePaymentSettings } from "store/admin/adminActions";

const AdminPaymentSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState({
    online_payment: "0",
    online_on_delivery_payment: "0",
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(getPaymentSettings()).unwrap();
      setSettings({
        online_payment: response.data.online_payment,
        online_on_delivery_payment: response.data.online_on_delivery_payment,
      });
    } catch (error) {
      toast.error("حدث خطأ في جلب الإعدادات");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await dispatch(updatePaymentSettings(settings)).unwrap();
      toast.success("تم تحديث إعدادات الدفع بنجاح");
      navigate("/dashboard/admin/adminSettings");
    } catch (error) {
      toast.error("حدث خطأ في تحديث الإعدادات");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <HelmetInfo titlePage="إعدادات الدفع" />

      <div className="header-page-w" data-aos="fade-left">
        <HeaderPageInfo
          title="إعدادات الدفع"
          isShowLeftContent={true}
          contentPageLeft={
            <div className="link-back">
              <Link
                className="size-text-color-dark main-text-color fs-5"
                to="/dashboard/admin/adminSettings">
                عودة
              </Link>
            </div>
          }
        />
      </div>

      <form onSubmit={handleSubmit} className="all-cards-pay mt-3" data-aos="fade-up">
        <label className="card-pay-1 mb-3 d-flex align-items-center gap-3" htmlFor="onlinePayment">
          <input
            type="checkbox"
            className="form-check-input"
            id="onlinePayment"
            checked={settings.online_payment === "1"}
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                online_payment: e.target.checked ? "1" : "0",
              }))
            }
          />
          <div className="card-content d-flex align-items-center flex-wrap gap-3">
            <h2 className="title">الدفع الإلكتروني</h2>
            <div className="img-pays d-flex align-items-center gap-2 flex-wrap">
              <div className="img-1">
                <img src={img_1} alt="payment method" />
              </div>
              <div className="img-1">
                <img src={img_2} alt="payment method" />
              </div>
              <div className="img-1">
                <img src={img_3} alt="payment method" />
              </div>
            </div>
          </div>
        </label>

        <label
          className="card-pay-1 mb-3 d-flex align-items-center gap-3"
          htmlFor="onDeliveryPayment">
          <input
            type="checkbox"
            className="form-check-input"
            id="onDeliveryPayment"
            checked={settings.online_on_delivery_payment === "1"}
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                online_on_delivery_payment: e.target.checked ? "1" : "0",
              }))
            }
          />
          <div className="card-content d-flex align-items-center flex-wrap gap-3">
            <h2 className="title">الدفع عند الاستلام</h2>
          </div>
        </label>

        <button
          type="submit"
          className="main-btn w-20 mt-4"
          disabled={
            isLoading ||
            (settings.online_payment === "0" && settings.online_on_delivery_payment === "0")
          }>
          {isLoading ? "جاري الحفظ..." : "حفظ التغييرات"}
        </button>
      </form>
    </>
  );
};

export default AdminPaymentSettings;
