import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";

const DatePickerField = ({ name, placeholder, handleOnChange }) => {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field, meta] = useField(name);
  const hasError = errors[name] && touched[name]; // Check if field has an error and has been touched

  const handleChange = (value) => {
    handleOnChange && handleOnChange(value);
    setFieldValue(name, value);
  };

  return (
    <div className="input-date-info">
      <DatePicker
        selected={field.value}
        onChange={handleChange}
        name={name}
        className="form-control"
        placeholderText={placeholder}
      />
      <div className="input-icon">
        <FontAwesomeIcon icon={faCalendar} />
      </div>

      {meta.error ? <div className="error">{meta.error}</div> : null}
      {hasError ? <div className="error">{errors[name]}</div> : null}
    </div>
  );
};

export default DatePickerField;
