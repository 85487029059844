import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addCountry, getCountry, updateCountry } from "store/general/generalActions";
import * as Yup from "yup";

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";

const validationSchema = Yup.object().shape({
  name_ar: Yup.string().required("اسم الدولة مطلوب"),
  code: Yup.string(),
  phone_code: Yup.string(),
});

const CountryForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = Boolean(id);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name_ar: "",
    code: "",
    phone_code: "",
    is_active: "1",
  });

  useEffect(() => {
    if (isEditMode) {
      setIsLoading(true);
      dispatch(getCountry(id))
        .then(unwrapResult)
        .then((res) => {
          if (res.success) {
            setFormData(res.data);
          }
        })
        .catch(() => {
          toast.error("فشل في جلب بيانات الدولة");
          navigate(-1);
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, id, isEditMode, navigate]);

  const handleSubmit = useCallback(async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const action = isEditMode ? updateCountry : addCountry;
      delete formData.phone_code;
      formData.name_en = formData.name_ar ?? "-";
      const res = await dispatch(action(formData)).unwrap();

      if (res.success) {
        toast.success(isEditMode ? "تم التعديل بنجاح" : "تمت الإضافة بنجاح");
        navigate(-1);
      }
    } catch (error) {
      toast.error(isEditMode ? "فشل في تعديل الدولة" : "فشل في إضافة الدولة");
    } finally {
      setIsSubmitting(false);
    }
  }, [dispatch, formData, isEditMode, navigate, isSubmitting]);

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const statusOptions = [
    { value: "1", label: "نشط" },
    { value: "0", label: "غير نشط" },
  ];

  if (isLoading) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage={isEditMode ? "تعديل دولة" : "إضافة دولة"} />

      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          titleInfoPage={isEditMode ? "تعديل دولة" : "إضافة دولة"}
          routeHomePage="/dashboard/admin/homedashboard"
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/countries">
            عودة
          </Link>
        </div>
      </div>

      <div className="details-add-edite-PROFILE mt-3" data-aos="fade-up">
        <div className="info-user-edit">
          <FormField
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            <div className="row g-3">
              <div className="col-md-6">
                <InputField
                  label="اسم الدولة"
                  name="name_ar"
                  type="text"
                  placeholder="اسم الدولة"
                  success
                  handleOnChange={(value) => handleChange("name_ar", value)}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label="رمز الهاتف"
                  name="code"
                  type="text"
                  placeholder="رمز الهاتف"
                  success
                  handleOnChange={(value) => handleChange("code", value)}
                />
              </div>
              <div className="col-md-6">
                <SelectField
                  label="الحالة"
                  name="is_active"
                  options={statusOptions}
                  value={formData.is_active}
                  success
                  handleOnChange={(value) => handleChange("is_active", value)}
                />
              </div>
            </div>

            <button type="submit" className="main-btn btn-submit px-5 mt-4" disabled={isSubmitting}>
              {isSubmitting ? "جاري الحفظ..." : "حفظ"}
            </button>
          </FormField>
        </div>
      </div>
    </>
  );
};

export default CountryForm;
