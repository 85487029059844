import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sendContactUs } from "store/customer/customerActions";
import * as Yup from "yup";

const FormContact = () => {
  const validationSchema = Yup.object({
    name: Yup.string().required("الاسم بالكامل").min(3, "الاسم بالكامل يجب ان يكون اكثر من 3 احرف"),
    email: Yup.string().email("البريد الالكترونى غير صحيح").required("البريد الالكترونى مطلوب"),
    message: Yup.string().required("الرجاء كتابة سؤالك.").min(10, "الرجاء كتابة سؤالك."),
  });

  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(sendContactUs(values))
      .then(unwrapResult)
      .then((res) => {
        console.log(res);
        toast.success("تم ارسال الرسالة بنجاح");
        resetForm();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="form-inputs" data-aos="fade-up">
        <div className="header-contact">
          <p className="text">
            نحن هنا لمساعدتك! إذا كان لديك أي استفسارات أو أسئلة، فلا تتردد في التواصل معنا. سنقوم بالرد عليك في أقرب
            وقت ممكن.
          </p>
        </div>

        <FormField initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          <InputFiled label={"الاسم بالكامل"} name="name" type="text" placeholder={"الاسم بالكامل"} success />
          <InputFiled label={"البريد الألكتروني"} name="email" type="email" placeholder={"mail@exmaple.com"} success />
          <InputFiled label={"السؤال"} name="message" type="text" placeholder={"السؤال"} success />
          {/* <InputFiled label={"التصنيف"} name="category" type="text" placeholder={"التصنيف"} success /> */}

          <button type="submit" className="main-btn btn-submit mt-4">
            ارسال
          </button>
        </FormField>
      </div>
    </>
  );
};

export default FormContact;
