import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderInfoPage from "Components/Ui/HeaderInfoPage/HeaderInfoPage";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBookingById } from "../../../store/customer/customerActions";
import BookingCoastDetails from "./BookingCoastDetails/BookingCoastDetails";
import BottomInfoDetails from "./BottomInfoDetails/BottomInfoDetails";
import ReasonCancellation from "./BottomInfoDetails/ReasonCancellation";
import HeaderInfoBookingsDetails from "./InfoBookingsDetails/HeaderInfoBookingsDetails";
import ReservationInformation from "./ReservationInformation/ReservationInformation";

const DetailsOrderBooking = () => {
  const { idCard: bookingId } = useParams();
  const [booking, setBooking] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const employees = useSelector((state) => state.customer.employees);
  const services = useSelector((state) => state.customer.servicesList);
  const vendors = useSelector((state) => state.customer.serviceProviders);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchBooking = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const result = await dispatch(getBookingById(bookingId)).then(unwrapResult);
        const { employee_id: employeeId, booking_service: servicesList, vendor_id } = result.data;
        const employee = employees[employeeId];
        const vendor = vendors.find((vendor) => vendor.id === vendor_id);

        if (!employee || !servicesList.length || !vendor) {
          throw new Error("Missing required data for booking");
        }

        setBooking({
          ...result.data,
          employee,
          servicesList,
          vendor,
        });
        setIsLoading(false);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBooking();
  }, [bookingId, dispatch, employees, services, vendors]);

  if (!booking)
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
        <Loader />;
      </div>
    );

  return (
    <>
      <HelmetInfo titlePage={"تفاصيل الطلب"} />

      <div className="details-order-booking padding-bottom-70">
        <HeaderPageTitle
          isActiveLinkTwo={true}
          routePage={"/bookings"}
          titlePage={"حجوزاتى"}
          titleInfoPage={"تفاصيل الطلب"}
          routeHomePage={"/"}
        />

        <div className="container" style={{ minHeight: "80vh" }}>
          <HeaderInfoPage
            title={"تفاصيل الطلب"}
            isActiveLink={true}
            routePage={"/bookings"}
            textLink={"عودة"}
            isNewContent={false}
            Newcontent={false}
          />

          <div className="all-bookings-details pt-4">
            <div className="row g-3">
              <div className="col-12 col-lg-12 col-xl-8">
                <div className="info-booking-details" data-aos="fade-down">
                  <HeaderInfoBookingsDetails booking={booking} />
                  <ReservationInformation booking={booking} />
                  <BottomInfoDetails />
                  <ReasonCancellation />
                </div>
              </div>

              <div className="col-12  col-lg-12 col-xl-4">
                <div className="booking-coast-details" data-aos="fade-up">
                  <BookingCoastDetails booking={booking} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsOrderBooking;
