import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVendorToWishList } from "store/customer/customerActions";
import iconLocation from "../../../assets/images/icons/location.svg";

const HeaderServicesName = ({ currentBranch }) => {
  const [branchInfo, setBranchInfo] = useState({});
  const wishlist = useSelector((state) => state.customer.wishList);
  const assignedRandmonRatings = useSelector((state) => state.customer.assignedRandmonRatings);
  const rating = assignedRandmonRatings.find((rating) => rating.id === currentBranch.id)?.rating;
  const totalRatings = assignedRandmonRatings.find((rating) => rating.id === currentBranch.id)?.totalRatings;

  useEffect(() => {
    if (!currentBranch) return;
    setBranchInfo(currentBranch);
  }, [currentBranch]);

  const [isFavorite, setIsFavorite] = useState();
  useEffect(() => {
    if (!wishlist || !branchInfo) return;
    setIsFavorite(wishlist.some((item) => item.vendor_id === branchInfo.vendor_id));
  }, [wishlist, branchInfo]);

  const dispatch = useDispatch();

  const addBranchToFavorite = (branchInfo) => {
    if (isFavorite) return;
    dispatch(
      addVendorToWishList({
        vendor_id: branchInfo.vendor_id,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setIsFavorite(true);
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  if (!branchInfo) return null;
  return (
    <section>
      <div
        data-aos="fade-up"
        className="main-header-services d-flex  justify-content-between  align-items-center gap-3 flex-wrap"
        style={{
          marginTop: "10px",
        }}
      >
        <div className="header-right">
          <h2 className="title size-text-color-dark">{branchInfo.name}</h2>
          <div className="info-head-serv d-flex align-items-center gap-4 flex-wrap">
            <div className="rates d-flex align-items-center gap-2">
              <div className="stars-rate d-flex  align-items-center gap-1">
                {Array.from({ length: Math.floor(rating) }).map((_, index) => (
                  <div key={index} className="icon-star size-text-color-dark">
                    <FontAwesomeIcon icon={solidStar} />
                  </div>
                ))}
              </div>

              <div className="info-rate-details d-flex  align-items-center gap-1">
                <div className="num-rate size-text-color-gray">{rating}</div>
                <div className="all-rates main-text-color size-text-color-gray">({totalRatings} تقييم)</div>
              </div>
            </div>

            <div className="location-details d-flex  align-items-center gap-1">
              <div className="icon-location">
                <img src={iconLocation} alt="icon location" width={"25px"} height={"25px"} />
              </div>
              <p className="text-location size-text-color-dark">{branchInfo.address}</p>
            </div>
          </div>
        </div>

        <div className="header-left d-flex  align-items-center gap-3">
          <div className={`icon-one ${isFavorite ? "active" : " "}`}>
            <FontAwesomeIcon
              icon={faHeart}
              onClick={() => {
                addBranchToFavorite(branchInfo);
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderServicesName;
