import { faClock as regularIcon } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faMoneyBill, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CardHeadDetails = ({ item, isOffer, titleServ, timeServ, priceServ }) => {
  const cartDetails = useSelector((state) => state.customer.cartDetails);
  const [isAddedToCard, setIsAddedToCard] = useState(false);

  useEffect(() => {
    if (cartDetails && item) {
      let isAdded = false;
      if (isOffer) {
        isAdded = cartDetails.offer_id === item.id;
      } else {
        isAdded = cartDetails.services.some((service) => service.id === item.id);
      }
      setIsAddedToCard(isAdded);
    }
  }, [cartDetails, item, isOffer]);

  return (
    <section>
      <div className="head-details style-card-details d-flex gap-3 justify-content-between align-items-center  mb-3">
        <div className="info-right">
          <h2 className="title size-text-color-dark">{titleServ}</h2>

          {timeServ && (
            <div className="time-info size-text-color-gray d-flex gap-2 align-items-center pt-2">
              <div className="icon size-text-color-gray">
                <FontAwesomeIcon icon={regularIcon} />
              </div>
              {timeServ}
            </div>
          )}
          <div className="time-info size-text-color-gray d-flex gap-2 align-items-center pt-2">
            <div className="icon size-text-color-gray">
              <FontAwesomeIcon icon={faMoneyBill} />
            </div>
            {priceServ}
          </div>
        </div>

        <div className={`icon-plus size-text-color-gray ${isAddedToCard ? "active" : ""}`}>
          {isAddedToCard ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faPlus} />}
        </div>
      </div>
    </section>
  );
};

export default CardHeadDetails;
