import { useState } from "react";

const TeamInfo = ({ imgEmp, nameEmployee, titleJob, handleSelectEmployee, isActive }) => {
  const [activeTeam, setActiveTeam] = useState(false);

  const addActiveTeam = () => {
    setActiveTeam(!activeTeam);
    handleSelectEmployee && handleSelectEmployee();
  };

  return (
    <div
      className={`team-info-one position-relative ${isActive ? "active" : ""}`}
      onClick={addActiveTeam}>
      <div className="img-employee">
        <img src={imgEmp} alt="img" width={"85px"} height={"85px"} className="object-fit-cover" />
      </div>

      <div className="info-text">
        <h2 className="title">{nameEmployee}</h2>
        <p className="text-title">{titleJob}</p>
      </div>
    </div>
  );
};

export default TeamInfo;
