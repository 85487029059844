import SectionTitle from "Components/SectionTitle/SectionTitle";
import imgSup from "../../../../assets/images/dashboardImages/supporters/01.svg";
import imgSup2 from "../../../../assets/images/dashboardImages/supporters/02.svg";
import imgSup3 from "../../../../assets/images/dashboardImages/supporters/03.svg";
import imgSup4 from "../../../../assets/images/dashboardImages/supporters/04.svg";
import imgSup5 from "../../../../assets/images/dashboardImages/supporters/05.svg";

const Supporters = () => {
  const imgSupporters = [
    { id: 0, img: imgSup },
    { id: 1, img: imgSup2 },
    { id: 2, img: imgSup3 },
    { id: 3, img: imgSup4 },
    { id: 4, img: imgSup5 },
  ];
  return (
    <div className="supporters-content padding-bottom-70">
      <div className="container">
        <div className="section-title-landing">
          <SectionTitle title={"يثق بنا الآلاف من العملاء "} text={false} dataAos={"fade-down"} />
        </div>
        <div className="all-supporters d-flex align-items-center gap-3 justify-content-center flex-wrap">
          {imgSupporters.map((item) => (
            <div className="support-one" key={item.id}>
              <img src={item.img} alt="img" className="w-100 h-100 object-fit-cover" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Supporters;
