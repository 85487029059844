import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import PaginationSelect from "Dashboard/Components/UiDashboard/PaginationSelect/PaginationSelect";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SettingsNotificationCustomer = () => {
  // DATA OF TABLE AND SAVE IN LOCAL STORGE
  const [tableDataSet, setTableDataSet] = useState(() => {
    const storedData1 = localStorage.getItem("dataSet");
    return storedData1
      ? JSON.parse(storedData1)
      : [
          {
            id: 1,
            time: "08:00 م",
            dataSend: "البريد الألكتروني",
            alertStatus: "نشط",
            reservationStatus: "مؤكد",
            classType: "text-warning",
          },
          {
            id: 2,
            time: "08:00 م",
            dataSend: "البريد الألكتروني",
            alertStatus: "غير نشط",
            reservationStatus: "مكتمل",
            classType: "text-success",
          },
          {
            id: 3,
            time: "08:00 م",
            dataSend: "البريد الألكتروني",
            alertStatus: "نشط",
            reservationStatus: "عدم الظهور",
            classType: "text-secondary",
          },
          {
            id: 4,
            time: "08:00 م",
            dataSend: "البريد الألكتروني",
            alertStatus: "غير نشط",
            reservationStatus: "ملغي",
            classType: "text-danger",
          },
        ];
  });
  const navigate = useNavigate();
  return (
    <>
      <HelmetInfo titlePage={"اعدادات ارسال الاشعار للعميل"} />
      <div className="header-page-w" data-aos="fade-right">
        <HeaderPageInfo
          title={"اعدادات ارسال الاشعار للعميل"}
          isShowLeftContent={true}
          contentPageLeft={
            <div className="button-add">
              <button
                className="main-btn"
                onClick={() => {
                  navigate("addEdit/addEditNotificationCustomer");
                }}>
                <FontAwesomeIcon icon={faPlus} />
                اضافة تنبية
              </button>
            </div>
          }
        />
      </div>

      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        <TableDataInfo
          titleTableHeader={false}
          isHashTrue={false}
          hashId={false}
          dateThead={
            <>
              <th scope="col">ملغي</th>
              <th scope="col">طريقة الارسال</th>
              <th scope="col">حالة التنبية</th>
              <th scope="col">حالة الحجز</th>
              <th scope="col">تفعيل/الغاء</th>
              <th scope="col">الاجراءات</th>
            </>
          }
          dataTbody={
            <>
              {tableDataSet.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.time}</td>
                    <td>{item.dataSend}</td>
                    <td>{item.alertStatus}</td>
                    <td>
                      <div className={`${item.classType}`}>{item.reservationStatus}</div>
                    </td>
                    <td>
                      <Toggleswitch switchId={item.id} nameSwitch={"switch01"} />
                    </td>
                    <td>
                      <IconsTable
                        typeIcon1={faEye}
                        functionEye={() => {
                          navigate(`detailsNot/${item.id}`);
                        }}
                        typeIcon2={faEdit}
                        functionEdit={() => {
                          navigate(`addEdit/${item.id}`);
                        }}
                        isDeleteIcon={false}
                        functionDelete={false}
                        typeIcon3={false}
                      />
                    </td>
                  </tr>
                );
              })}
            </>
          }
        />
      </div>
      <PaginationSelect
        itemsPageTitle={"عدد العناصر بالصفحة"}
        optionsSelect={
          <>
            <option value="">اختار</option>
            <option value="1"> 1</option>
            <option value="2"> 2</option>
            <option value="3"> 3</option>
          </>
        }
        infoItemsPageSelect1={"of 200 items"}
        infoItemsPageSelect2={"of 44 pages"}
        optionsSelect2={
          <>
            <option value="">اختار</option>
            <option value="1"> 1</option>
            <option value="2"> 2</option>
            <option value="3"> 3</option>
          </>
        }
      />
    </>
  );
};

export default SettingsNotificationCustomer;
