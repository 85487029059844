import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useCustomerStoreValue = (storeInfo, refetchFunc, setStoreValueFunc) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const value = useSelector((state) => state[storeInfo.storeName][storeInfo.storeKey]);
  useEffect(() => {
    if (
      !value ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" && Object.keys(value).length === 0)
    ) {
      setIsLoading(true);
      dispatch(refetchFunc())
        .unwrap()
        .then((res) => {
          dispatch(setStoreValueFunc(res.data.data));
        })
        .catch((error) => {
          console.error("Failed to fetch data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [dispatch, value, refetchFunc, setStoreValueFunc, storeInfo.storeKey, storeInfo.storeName]);

  return [value, isLoading];
};
