import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getOffer } from "store/admin/adminActions";
import { getOffer as getVendorOffer } from "store/vendor/vendorActions";

const DetailsOffer = () => {
  const isLoading = useSelector((state) => state.admin.isLoading);
  const isVendorLoading = useSelector((state) => state.vendor.isLoading);

  const [data, setData] = useState({});

  const dispatch = useDispatch();
  const { id, idDetailsOffer } = useParams();
  const user = useGetAuthUser();
  const fetchData = () => {
    dispatch(getOffer(id))
      .then(unwrapResult)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchVendorData = () => {
    dispatch(getVendorOffer(idDetailsOffer))
      .then(unwrapResult)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    if (idDetailsOffer) {
      fetchVendorData();
    }
  }, [id, idDetailsOffer]);
  return (
    <>
      <HelmetInfo titlePage={"تفاصيل العرض"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/vendor/homedashboard"}
          titleInfoPage={"تفاصيل العرض"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to={
              user.type === "vendor"
                ? "/dashboard/vendor/offersInfo"
                : "/dashboard/admin/offersInfo"
            }>
            عودة
          </Link>
        </div>
      </div>
      {isLoading || isVendorLoading ? (
        <Loader />
      ) : (
        <div className="basic-information-content account-info-details mt-3" data-aos="fade-up">
          {/* =========== START ALL BASIC INFO ============= */}
          <div className="all-basic-info all-details-branch">
            {/* ======== START HEADER INFO ========== */}
            <div className="header-info-profile ">
              <div className="content-user-info content-info-deails-offer">
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">مقدم الخدمه</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data.vendor?.name}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">اسم العرض</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data.name_ar}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">حالة العرض</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>
                      <div className={`${data.is_active === "0" ? "text-danger" : "text-success"}`}>
                        {data.is_active === "0" ? "متوقف " : "مستمر"}
                      </div>
                    </p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">القسم</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data.section?.name_ar}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">التبويب</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}> {data.category?.name_ar}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">الخدمات بالعرض</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>
                      {data.services?.map((service) => service.name_ar).join(",")}
                    </p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
              </div>

              <div className="content-user-info content-info-deails-offer border-top  pt-2">
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">سعر العرض</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data.service_price} ر.س</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">مدة العرض</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data.duration}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">نوع التسعير</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data.price_type}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">الخصم</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data.discount_percentage} %</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">وصف العرض </h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data.description_ar}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
              </div>
            </div>
            {/* ======== END HEADER INFO ========== */}
          </div>
          {/* =========== END ALL BASIC INFO ============= */}
        </div>
      )}
    </>
  );
};

export default DetailsOffer;
