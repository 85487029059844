import { Helmet } from "react-helmet-async";

const HelmetInfo = ({ titlePage }) => {
  return (
    <Helmet>
      <title>{titlePage}</title>
      <link rel="shortcut icon" type="image/png" href="/icon/ico.svg" />

      {/* description */}
      <meta
        name="description"
        content="Streamline Discription Content info Streamline  Discription Content info Streamline Discription Content info"
      />

      {/* keywords */}
      <meta name="keywords" content="Streamline  KeyWords Streamline  KeyWords Streamline  KeyWords" />

      {/*  <!-- #author --> */}
      <meta name="author" content="Streamline Website" />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content="Streamline Link Url" />
      <meta property="og:type" content="Streamline website" />
      <meta property="og:title" content="Streamline Services" />
      <meta property="og:description" content="Streamline A concise description of your page content" />
      <meta property="og:image" content="Streamline Image" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="Streamline Domain Link Url" />
      <meta property="twitter:url" content="Streamline Url" />
      <meta name="twitter:title" content="Streamline" />
      <meta name="twitter:description" content="Streamline description of your page content" />
      <meta name="twitter:image" content="Streamline  Image" />

      {/* LINK URL WEBSITE Streamline */}
      <link rel="canonical" href="https://www.example.com/your-page" />
    </Helmet>
  );
};

export default HelmetInfo;
