import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { getSubscription } from "store/admin/adminActions";
import SubscriptionCard from "../ProviderSubscription/SubscriptionCard";
const ServiceProviderSubDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log({ id });

  const { state } = useLocation();
  const { subscriptionInfo } = state || {};
  const [subscription, setSubscription] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!subscriptionInfo || Object.keys(subscriptionInfo).length === 0) {
      setLoading(true);
      dispatch(getSubscription(id))
        .unwrap()
        .then((res) => {
          setSubscription(res.data);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSubscription(subscriptionInfo);
    }
  }, [id]);

  return (
    <>
      <HelmetInfo titlePage="تفاصيل الاشتراك" />
      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage="اشتراكات مزود الخدمة"
          titleInfoPage="تفاصيل الاشتراك"
          routeHomePage="/dashboard/homedashboard"
        />
        <Link
          className="size-text-color-dark main-text-color fs-5"
          to="/dashboard/admin/serviceprovidersubscriptions">
          عودة
        </Link>
      </div>

      <div className="service-provider-sub-details p-4">
        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">
                <Loader />
              </span>
            </div>
          </div>
        ) : (
          <div className="provider-subscription mt-4">
            <SubscriptionCard isAdmin={true} subscriptionInfo={subscription} />
          </div>
        )}
      </div>
    </>
  );
};

export default ServiceProviderSubDetails;
