import "./PageTilte.css";
import imgBg from "../../assets/images/bg/A02.png";
const PageTilte = ({ titlePage }) => {
  return (
    <header>
      <div className="page-title" style={{ backgroundImage: `url(${imgBg})` }}>
        <h2 className="title" data-aos={"fade-left"}>
          {titlePage}
        </h2>
      </div>
    </header>
  );
};

export default PageTilte;
