const StepTwoServices = () => {
  return (
    <div className="step-two-info">
      <h2 className="title-header-step mb-3">اختيار نوع الحجز</h2>
      <div className="all-info-step pt-3">
        <div className="confirm-one mt-3">
          <div className="form-check d-flex  align-items-center  gap-3">
            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
            <label className="form-check-label title-text" htmlFor="flexRadioDefault1">
              مؤكد
            </label>
          </div>
          <label className="form-check-label content-label" htmlFor="flexRadioDefault1">
            <div className="header-top d-flex align-items-center gap-2">
              <span className="icon-a">*</span> <h2 className="title-text">الحجز المؤكد</h2>
            </div>
            <p className="text">
              بيظهر كل المواعيد المتاحة للحجز بمافيها مواعيد الحجوزات الغير مؤكدة للآخرين ، مع توفر اكثر من طريقة للدفع.
            </p>
          </label>
        </div>

        <div className="confirm-one mt-3">
          <div className="form-check d-flex  align-items-center  gap-3">
            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
            <label className="form-check-label title-text" htmlFor="flexRadioDefault1">
              غير مؤكد
            </label>
          </div>
          <label className="form-check-label content-label" htmlFor="flexRadioDefault2">
            <div className="header-top d-flex align-items-center gap-2">
              <span className="icon-a">*</span> <h2 className="title-text">الحجز الغير مؤكد</h2>
            </div>
            <p className="text">
              بيظهر كل المواعيد المتاحة للحجز بمافيها مواعيد الحجوزات الغير مؤكدة للآخرين ، مع توفر اكثر من طريقة للدفع.
            </p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default StepTwoServices;
