import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCategoryById as getCategory, updateCategory } from "store/general/generalActions";

const EditCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [category, setCategory] = useState({ name_ar: "", name_en: "", is_active: "1" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCategory = async () => {
      setIsLoading(true);
      try {
        const result = await dispatch(getCategory(id)).unwrap();
        setCategory(result.data);
      } catch (error) {
        console.error("Error fetching category:", error);
        toast.error("فشل في جلب بيانات الفئة");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategory();
  }, [id, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const updates = {
        id,
        name_ar: category.name_ar,
        name_en: category.name_en,
        is_active: category.is_active,
      };
      await dispatch(updateCategory(updates)).unwrap();
      toast.success("تم تحديث الفئة بنجاح");
      navigate("/dashboard/admin/servicesCategories");
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error("فشل في تحديث الفئة");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage="تعديل الفئة" />
      <HeaderPageInfo title="تعديل الفئة" />
      <div className="edit-category-form" data-aos="fade-up">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name_ar" className="form-label">
              الاسم بالعربية
            </label>
            <input
              type="text"
              className="form-control"
              id="name_ar"
              name="name_ar"
              value={category.name_ar}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="name_en" className="form-label">
              الاسم بالإنجليزية
            </label>
            <input
              type="text"
              className="form-control"
              id="name_en"
              name="name_en"
              value={category.name_en}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="is_active" className="form-label">
              الحالة
            </label>
            <select
              className="form-select"
              id="is_active"
              name="is_active"
              value={category.is_active}
              onChange={handleInputChange}>
              <option value="1">مفعل</option>
              <option value="0">غير مفعل</option>
            </select>
          </div>
          <button type="submit" className="main-btn  mt-5">
            حفظ التغييرات
          </button>
        </form>
      </div>
    </>
  );
};

export default EditCategory;
