import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import PasswordInput from "Components/Forms/PasswordInput";
import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { registerServiceProvider, sendRegisterOtp } from "store/vendor/vendorActions";
import OTPModal from "./OTPModal";
import { registerValidationSchema } from "./validationSchema";

const RegisterServicesStepThree = ({ registerData, setRegisterData, handlePrev }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const otpValue = useRef(null);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpStep, setOtpStep] = useState(1);
  const [otp, setOtp] = useState("");

  const initialValues = {
    email: registerData.email || "",
    first_name: registerData.first_name || "",
    last_name: registerData.last_name || "",
    phone: registerData.phone || "",
    password: "",
    password_confirmation: "",
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    setFormValues({ ...registerData, ...values });

    try {
      const response = await dispatch(sendRegisterOtp(values.email)).unwrap();
      if (response.success) {
        setShowOtpModal(true);
        otpValue.current = response.data[0];
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (error) => {
    setShowOtpModal(false);
    const errors = Object.values(error.errors).map((error) => error[0]);
    errors.forEach((error) => toast.error(error));
  };

  const handleResendOtp = useCallback(async () => {
    try {
      setIsLoading(true);
      console.log({ formValues });
      const responseOtp = await dispatch(sendRegisterOtp(formValues.email)).unwrap();
      if (responseOtp.success) {
        setOtpStep(1);
        toast.success("تم إرسال رمز التحقق مرة أخرى");
        otpValue.current = responseOtp.data[0];
      }
    } catch (error) {
      setShowOtpModal(false);
      const errors = Object.values(error.errors).map((error) => error[0]);
      errors.forEach((error) => toast.error(error));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, formValues.email]);

  const handleSubmitFinalDetails = async () => {
    try {
      if (otpValue.current === otp || otp === "") {
        const response = await dispatch(registerServiceProvider(formValues)).unwrap();
        if (response.success) {
          setOtpStep(2);
          setTimeout(() => {
            setShowOtpModal(false);
            toast.success("تم تسجيل مزود الخدمة بنجاح");
            navigate("/dashboard/homedashboard");
          }, 2000);
        }
      } else {
        toast.error("رمز التحقق غير صحيح");
      }
    } catch (error) {
      const errors = Object.values(error.errors).map((error) => error[0]);
      console.log("errors", errors);

      errors.forEach((error) => toast.error(error));
    }
  };

  return (
    <>
      <OTPModal
        {...{
          showOtpModal,
          otpStep,
          formValues,
          otp,
          setOtp,
          handleVerifyOtp: handleSubmitFinalDetails,
          handleResendOtp,
          setShowOtpModal,
          isLoading,
        }}
      />

      <div className="all-step-three bg-step">
        <FormField
          initialValues={initialValues}
          validationSchema={registerValidationSchema}
          onSubmit={handleSubmit}>
          <div className="row g-2">
            <div className="col-12 col-md-6">
              <InputField
                label={"الاسم الأول"}
                name="first_name"
                type="text"
                placeholder={"الاسم الأول"}
                success
                handleOnChange={(value) => {
                  setRegisterData((prevData) => ({ ...prevData, first_name: value }));
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputField
                label={"الاسم الأخير"}
                name="last_name"
                type="text"
                placeholder={"الاسم الأخير"}
                success
                handleOnChange={(value) => {
                  setRegisterData((prevData) => ({ ...prevData, last_name: value }));
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputField
                label={"الرقم الجوال"}
                name="phone"
                type="tel"
                placeholder={"الرقم الجوال"}
                success
                handleOnChange={(value) => {
                  setRegisterData((prevData) => ({ ...prevData, phone: value }));
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputField
                label={"البريد الألكتروني"}
                name="email"
                type="email"
                placeholder={"البريد الألكتروني"}
                success
                handleOnChange={(value) => {
                  setRegisterData((prevData) => ({ ...prevData, email: value }));
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <PasswordInput
                label="كلمة المرور"
                name="password"
                placeholder="كلمة المرور"
                handleOnChange={(value) => {
                  setRegisterData((prev) => ({ ...prev, password: value }));
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <PasswordInput
                label="تاكيد كلمة المرور"
                name="password_confirmation"
                placeholder="تأكيد كلمة المرور"
                handleOnChange={(value) => {
                  setRegisterData((prev) => ({ ...prev, password_confirmation: value }));
                }}
              />
            </div>
          </div>

          <div className="button-container mt-4">
            <button type="button" className="btn main-btn btn-prev" onClick={handlePrev}>
              السابق
            </button>
            <button
              type="submit"
              className="btn main-btn btn-submit"
              disabled={!isFormValid(registerData)}>
              تسجيل
            </button>
          </div>
        </FormField>
      </div>
    </>
  );
};

const isFormValid = (data) => {
  return (
    data.email &&
    data.first_name &&
    data.last_name &&
    data.phone &&
    data.password &&
    data.password_confirmation
  );
};

export default RegisterServicesStepThree;
