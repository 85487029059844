import "./CardDetailsBooking.css";

const CardDetailsBooking = ({ numBooking, text, icon, unit }) => {
  return (
    <>
      <div className="info-details-booking-one h-100 d-flex align-items-center justify-content-between gap-3 flex-wrap-reverse">
        <div className="right-info-booking">
          <h2 className="num-booking">
            {numBooking} {unit}
          </h2>
          <p className="text">{text}</p>
        </div>
        <div className="icon-booking icon-style-1">
          <img src={icon} alt="icon ticket" />
        </div>
      </div>
    </>
  );
};

export default CardDetailsBooking;
