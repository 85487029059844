import { unwrapResult } from "@reduxjs/toolkit";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { updateSubscription } from "store/vendor/vendorActions";
import iconImg from "../../../assets/images/dashboardImages/icons/providerSubscription.svg";

const SubscriptionCard = ({
  isAdmin,
  subscriptionInfo,
  vendorSubscriptions,
  allSubscriptions,
  allPackages,
}) => {
  const dispatch = useDispatch();

  const handleToggleSwitch = (subscriptionId, newValue) => {
    dispatch(
      updateSubscription({
        id: subscriptionId,
        auto_renew: newValue ? "1" : "0",
      }),
    )
      .then(unwrapResult)
      .then(() => {
        toast.success("تم التعديل بنجاح.");
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.message);
      });
  };

  const renderDataInfo = (title, text) => (
    <div className="data-info d-flex align-items-center gap-3">
      <h2 className="title">{title}</h2>
      <p className="text">{text}</p>
    </div>
  );

  const subscriptions = isAdmin ? [subscriptionInfo] : vendorSubscriptions || [];

  return (
    <div className="subscription-cards-container">
      {subscriptions.map((subscription) => (
        <div key={subscription.id} className="card-provider-subscription mb-4">
          <div className="header-card-provider d-flex justify-content-between align-items-center flex-wrap gap-2">
            <div className="left-card d-flex align-items-center flex-wrap gap-3">
              <div className="icon">
                <img src={iconImg} alt="icon" width="50px" height="50px" />
              </div>
              <div className="info-content-head d-flex flex-column gap-2">
                <h2 className="title">{subscription.name_ar}</h2>
                <p className="text">{subscription.package?.description_ar}</p>
              </div>
            </div>
            {!isAdmin && (
              <div className="right-card-head d-flex align-items-center gap-3">
                تجديد تلقائي
                <Toggleswitch
                  switchId={subscription.id}
                  nameSwitch={`services-${subscription.id}`}
                  value={subscription.auto_renew === "1"}
                  onChange={(newValue) => handleToggleSwitch(subscription.id, newValue)}
                />
              </div>
            )}
          </div>

          <div className="main-card-provider mt-4 d-flex justify-content-between flex-wrap gap-1">
            <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
              {renderDataInfo("اسم الاشتراك", subscription.name_ar)}
              {renderDataInfo("سعر الباقة", `${subscription.price} ر.س`)}
              {renderDataInfo("طريقة الدفع", subscription.payments)}
              {renderDataInfo("عدد الموظفين", subscription.employee)}
            </div>

            <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
              {renderDataInfo("نوع الاشتراك", subscription.subscription_type)}
              {renderDataInfo("مدة الاشتراك", subscription.period)}
              {renderDataInfo("عدد رسائل الواتس", subscription.whatsapp_messages)}
              {renderDataInfo("عدد الفروع", subscription.branches)}
            </div>

            <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
              {renderDataInfo("تاريخ الاشتراك", subscription.payment_date)}
              {renderDataInfo("تاريخ التجديد", `الباقة تنتهي في ${subscription.end_date}`)}
              {renderDataInfo("عدد الرسائل النصية", subscription.sms_messages)}
            </div>

            {!isAdmin && (
              <div className="button-change-package d-flex align-items-center justify-content-center h-100">
                <Link
                  to="changepackage"
                  state={{ allSubscriptions, allPackages }}
                  className="main-btn main-outline-btn">
                  تغير / تجديد الباقة
                </Link>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SubscriptionCard;
