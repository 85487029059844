import { useState } from "react";
import iconPen from "../../../assets/images/icons/pen-line.svg";
import ModalChangePassword from "../ModalProfile/ModalChangePassword";
import "./ProfilePersonly.css";

const titleMapper = {
  firstName: "الاسم الأول",
  lastName: "الاسم الأخير",
  name: "اسم المستخدم",
  phone: "رقم الجوال",
  email: "البريد الألكتروني",
  // image: "صورة الملف الشخصي",
};

const BasicInformation = ({ profileInfo, goToUpdatePersonalData }) => {
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const showModalAccountRemove = () => {
    setShowModalChangePassword(true);
  };

  const hideModalChangePassword = () => {
    setShowModalChangePassword(false);
  };

  return (
    <>
      <ModalChangePassword
        showModalChangePassword={showModalChangePassword}
        hideModalChangePassword={hideModalChangePassword}
      />
      <div className="basic-information-content">
        <div className="all-basic-info">
          <div className="header-info-profile  ">
            {profileInfo &&
              Object.entries(profileInfo)
                .filter((item) => item[0] !== "id" && item[0] !== "image")
                .map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="all-info-content  d-flex" key={index}>
                        <div className="main-info-one d-flex  align-items-center">
                          <h2 className="title">{titleMapper[item[0]]}</h2>
                        </div>

                        <div className="main-info-one d-flex  align-items-center">
                          <p className="text">{item[1]}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}

            <div className="icon-edit-pen" title="تعديل البيانات الشخصية" onClick={() => goToUpdatePersonalData()}>
              <img src={iconPen} alt="icon pen" />
            </div>
          </div>

          <div className="bottom-basic-info mt-4 d-flex  align-items-center  gap-3 justify-content-between flex-wrap ">
            <h2 className="title">كلمة المرور</h2>
            <button onClick={showModalAccountRemove} className="main-btn main-outline-btn gap-2 ">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.8125 8.25663V7.26113C5.8125 4.73993 7.81964 2.70996 10.3125 2.70996C12.8054 2.70996 14.8125 4.73993 14.8125 7.26113V8.75442M10.3125 13.7812V11.7812M16.3125 12.7367C16.3125 16.0357 13.6262 18.7101 10.3125 18.7101C6.99879 18.7101 4.3125 16.0357 4.3125 12.7367C4.3125 9.43766 6.99879 6.76328 10.3125 6.76328C13.6262 6.76328 16.3125 9.43766 16.3125 12.7367Z"
                  stroke="#093A4E"
                />
              </svg>
              تغير كلمة المرور
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicInformation;
