import CustomModal from "Components/CustomModal/CustomModal";
import { useState } from "react";
import Select from "react-select";

const options = [
  { value: "red", color: "#FF0000" },
  { value: "green", color: "#00FF00" },
  { value: "blue", color: "#0000FF" },
  { value: "yellow", color: "#FFFF00" },
  { value: "orange", color: "#FFA500" },
  { value: "purple", color: "#800080" },
  { value: "gray", color: "#808080" },
  { value: "pink", color: "#FFC0CB" },
  { value: "brown", color: "#A52A2A" },
  { value: "cyan", color: "#00FFFF" },
];

const SectionModal = ({ showModalAddTab, hideModalAddTab, onSave, onClose }) => {
  const [tabName, setTabName] = useState("");
  const [tabDescription, setTabDescription] = useState("");
  const [selectedColors, setSelectedColors] = useState([]);

  const handleChange = (selectedOptions) => {
    console.log("selectedOptions", selectedOptions);
    setSelectedColors(selectedOptions);
  };

  const formatOptionLabel = ({ value, color }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: color,
          borderRadius: "50%",
          marginRight: "10px",
        }}
      />
      <span>{value}</span>
    </div>
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const newSection = {
      name: tabName,
      description: tabDescription,
      colors: selectedColors.map((color) => color.value),
    };

    onSave(newSection);
    hideModalAddTab();
  };

  return (
    <CustomModal
      show={showModalAddTab}
      onHide={hideModalAddTab}
      onClose={onClose}
      title={"اضافة تبويب جديد"}
      newClass={"modal-add-tap"}>
      <form onSubmit={handleSubmit}>
        <div className="form-one mb-3">
          <label htmlFor="inputTab" className="form-label">
            اسم التبويب
          </label>
          <input
            type="text"
            className="form-control"
            id="inputTab"
            placeholder="اسم التبويب"
            value={tabName}
            onChange={(e) => setTabName(e.target.value)}
            required
          />
        </div>
        <div className="select-colors mb-3">
          <label htmlFor="selectColors" className="form-label">
            لون التبويب
          </label>
          <Select
            options={options}
            getOptionLabel={(option) => option.value}
            getOptionValue={(option) => option.color}
            formatOptionLabel={formatOptionLabel}
            placeholder={"لون التبويب"}
            value={selectedColors}
            onChange={handleChange}
            isMulti
            className="select-colors-input"
            menuPosition="fixed"
          />

          <div className="d-flex gap-2 align-items-center flex-wrap mt-2">
            {selectedColors.map((colorOption, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f0f0f0",
                  padding: "5px 10px",
                  borderRadius: "15px",
                }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: colorOption.color,
                    marginRight: "5px",
                  }}
                />
                <span>{colorOption.value}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="form-one mb-3">
          <label htmlFor="textAreaTab" className="form-label">
            وصف التبويب
          </label>
          <textarea
            className="form-control"
            id="textAreaTab"
            rows={5}
            placeholder="وصف التبويب"
            value={tabDescription}
            onChange={(e) => setTabDescription(e.target.value)}
            required></textarea>
        </div>
        <button type="submit" className="main-btn w-100 mt-3">
          حفظ
        </button>
      </form>
    </CustomModal>
  );
};

export default SectionModal;
