import { faClock as regularIcon } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import { InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";

const AddDateTime = ({ name, handleOnChange }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = (index, key, value) => {
    handleOnChange && handleOnChange(index, key, value);
    setFieldValue(`officialHours.${index}.${key}`, value);
  };

  return (
    <div className="row g-3">
      <div className="col-12">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <>
              {values.officialHours.length > 0 &&
                values.officialHours?.map((item, index) => (
                  <div key={index} className="mt-3">
                    <div className="row align-items-center g-3">
                      <div className={`col`}>
                        {/*  */}
                        <div className="form-group">
                          <InputGroup className="input-group input-clock">
                            <DatePicker
                              selected={values.officialHours[index] && values.officialHours[index].start_time}
                              onChange={(date) => handleChange(index, "start_time", date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className="form-control w-100"
                              placeholderText="بداية الشفت"
                              name={`officialHours.${index}.start_time`}
                              onBlur={() => setFieldTouched(`officialHours.${index}.start_time`, true)}
                            />
                            <InputGroup.Text className="input-group-text">
                              <FontAwesomeIcon icon={regularIcon} />
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage name={`officialHours.${index}.start_time`}>
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      {/*  */}
                      <div className={`col`}>
                        <div className="form-group">
                          <InputGroup className="input-group input-clock">
                            <DatePicker
                              selected={values.officialHours[index] && values.officialHours[index].end_time}
                              onChange={(date) => handleChange(index, "end_time", date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className="form-control w-100"
                              name={`officialHours.${index}.end_time`}
                              placeholderText="نهاية الشفت"
                              onBlur={() => setFieldTouched(`officialHours.${index}.end_time`, true)}
                            />
                            <InputGroup.Text className="input-group-text">
                              <FontAwesomeIcon icon={regularIcon} />
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage name={`officialHours.${index}.end_time`}>
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      {/*  */}
                      <div className={`col`}>
                        <div className="form-group select-one">
                          <select
                            className="form-select form-select-lg"
                            name={`officialHours.${index}.day`}
                            onChange={(e) => {
                              handleChange(index, "day", e.target.value);
                            }}
                            value={values.officialHours[index].day}>
                            {[
                              { id: undefined, label: "اليوم" },
                              { id: "sat", label: "السبت" },
                              { id: "sun", label: "الاحد" },
                              { id: "mon", label: "الاثنين" },
                              { id: "tue", label: "الثلاثاء" },
                              { id: "wed", label: "الاربعاء" },
                              { id: "thu", label: "الخميس" },
                              { id: "fri", label: "الجمعه" },
                            ].map((option, index) => (
                              <option key={index} value={option.id}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <ErrorMessage name={`officialHours.${index}.day`}>
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      {/*  */}
                      {index >= 1 ? (
                        <div className="col-2">
                          <div
                            className="remove-inputs text-color-num cursor-pointer-1 d-flex align-items-center gap-2"
                            onClick={() => remove(index)}>
                            <FontAwesomeIcon icon={faTimesCircle} /> حذف
                          </div>
                        </div>
                      ) : (
                        <div className="col-2">
                          <div
                            className="add-new-inputs mt-3 text-color-num cursor-pointer-1 d-flex align-items-center gap-2"
                            onClick={() => push({})}>
                            <FontAwesomeIcon icon={faPlus} /> اضافة
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default AddDateTime;
