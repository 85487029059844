import PageTilte from "Components/PageTilte/PageTilte";
import AccordionFaq from "Components/Ui/AccordionFaq/AccordionFaq";
import "./CommonQuestions.css";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";

const CommonQuestions = () => {
  const accordionCard = [
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
  ];
  return (
    <>
      <HelmetInfo titlePage={"الأسئلة الشائعة"} />
      <div className="common-faq padding-bottom-70">
        <PageTilte titlePage={"الأسئلة الشائعة"} />
        {/* ========== START CONTAINER =========== */}
        <div className="container">
          {/* ========== START TAB CONTENT =========== */}
          <div className="all-tabs-content" data-aos="fade-up">
            <Tabs
              defaultActiveKey="tab-one"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {/* =========== START TAB CONTENT ONE =========== */}
              <Tab eventKey="tab-one" title="عام">
                {/* ========== START ALL COMMOMN FAQ =========== */}
                <div className="all-common-faq">
                  <Accordion>
                    {accordionCard.map((item) => {
                      return (
                        <AccordionFaq
                          key={item.id}
                          eventKey={item.id}
                          titleAccordion={
                            "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي؟ "
                          }
                          textAccordion={
                            "ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم الرقمي في منتصف الثمانينات من القرن الماضي، عندما استخدمته شركة ألدس في قوالب رسومية ومعالجة نصوص لبرنامجها للنشر المكتبي باغ ماكر.."
                          }
                          NewClass={"accordion-faq"}
                        />
                      );
                    })}
                  </Accordion>
                </div>
                {/* ========== END ALL COMMOMN FAQ =========== */}
              </Tab>
              {/* =========== END TAB CONTENT ONE =========== */}

              {/* =========== START TAB CONTENT ONE =========== */}
              <Tab eventKey="tab-two" title="الدفع">
                Tab content
              </Tab>
              {/* =========== END TAB CONTENT ONE =========== */}
              {/* =========== START TAB CONTENT ONE =========== */}
              <Tab eventKey="tab-three" title="الحجوزات">
                Tab content
              </Tab>
              {/* =========== END TAB CONTENT ONE =========== */}
            </Tabs>
          </div>
          {/* ========== END TAB CONTENT =========== */}
        </div>
        {/* ========== END CONTAINER =========== */}
      </div>
    </>
  );
};

export default CommonQuestions;
