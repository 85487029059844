import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import iconExport from "../../../../../assets/images/dashboardImages/icons/export.svg";

const HeaderHome = () => {
  // TACKE SCREEN SHOT FOR PAGE
  const takeScreenshot = () => {
    const element = document.querySelector(`.${"content-info-screen"}`);

    html2canvas(element).then((canvas) => {
      // Convert the canvas to a data URL
      const screenshot = canvas.toDataURL();

      // Create a new jsPDF instance
      const pdf = new jsPDF();

      // Calculate the width and height of the image
      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;

      // Add the image to the PDF document
      pdf.addImage(screenshot, "PNG", 0, 0, width, height);

      // Save the PDF document
      pdf.save("screenshot.pdf");

      // Create a download link for the image
      const link = document.createElement("a");
      link.href = screenshot;
      link.download = "screenshot.png";

      // Trigger the download
      link.click();
    });
  };

  return (
    <div className="header-home" data-aos="fade-left">
      <HeaderPageInfo
        title={"الصفحة الرئيسية"}
        isShowLeftContent={true}
        contentPageLeft={
          <div className="info-content-head d-flex align-items-center gap-3">
            {/* <div className="select-box-option">
              <select
                className="form-select form-select-lg form-select-ltr"
                aria-label="Large select example"
              >
                <option selected>المدة</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div> */}
            <button className="main-btn" onClick={takeScreenshot}>
              <div className="icon-export">
                <img src={iconExport} alt="icon export" width={"25px"} height={"25px"} />
              </div>
              تصدير التقرير
            </button>
          </div>
        }
      />
    </div>
  );
};

export default HeaderHome;
