import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateTaxSettings } from "store/admin/adminActions";

const ModalTaxSettings = ({ showModalTax, hideModalTax, currentTaxSettings }) => {
  const dispatch = useDispatch();

  const [taxRate, setTaxRate] = useState(Number(currentTaxSettings?.tax_percentage) || 0);
  const [isEnabled, setIsEnabled] = useState(currentTaxSettings?.is_active === "1");

  const user = useSelector((state) => state.auth.user);
  const isAdmin = user?.type === "admin";

  useEffect(() => {
    if (currentTaxSettings && isAdmin) {
      setTaxRate(Number(currentTaxSettings?.tax_percentage) || 0);
      setIsEnabled(currentTaxSettings?.is_active === "1");
    }
  }, [currentTaxSettings, isAdmin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        updateTaxSettings({
          tax_percentage: taxRate,
          is_active: isEnabled ? "1" : "0",
        }),
      ).unwrap();
      hideModalTax();
    } catch (error) {
      console.error("Error updating tax settings:", error);
    }
  };

  return (
    <Modal show={showModalTax} onHide={hideModalTax} centered className="modal-custom">
      <Modal.Header closeButton>
        <Modal.Title>إعدادات الضرائب</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">نسبة الضريبة (%)</label>
            <input
              type="number"
              className="form-control"
              value={taxRate}
              onChange={(e) => setTaxRate(Number(e.target.value))}
              min="0"
              max="100"
              step="0.01"
            />
          </div>
          <div className="mb-3 d-flex align-items-center gap-2 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={isEnabled}
              onChange={(e) => setIsEnabled(e.target.checked)}
              id="taxEnabled"
            />
            <label className="form-check-label" htmlFor="taxEnabled">
              تفعيل الضريبة
            </label>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <button type="submit" className="main-btn w-100 mt-4">
              حفظ
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalTaxSettings;
