const CardRate = ({
  imgUser,
  nameUser,
  rateStar,
  numRates,
  timeRate,
  isHaveContentComment,
  contentRate,
  serviceComment,
}) => {
  return (
    <>
      {/* START RATE CARD USER ONE  */}
      <div className="rate-card-user-one">
        {/* ========== START INFO USER ======== */}
        <div className="info-user d-flex  align-items-center gap-2">
          {imgUser && (
            <div className="img-user">
              <img
                src={imgUser}
                alt="img user"
                className="object-fit-cover  rounded-circle"
                width={"60px"}
                height={"60px"}
              />
            </div>
          )}

          <div className="content-info">
            {nameUser && <h2 className="title-name">{nameUser}</h2>}
            <div className="date-star-rate d-flex  align-items-center gap-3">
              <div className="rate-user  d-flex  align-items-center gap-2">
                <div className="icons-rate-1">
                  <div className="icon-star d-flex  align-items-center gap-1">
                    {rateStar}
                  </div>
                </div>
                <div className="num-rate fs-6 fw-bold ">{numRates} </div>
              </div>
              <p className="date-info">{timeRate}</p>
            </div>
          </div>
        </div>
        {/* ========== END INFO USER ======== */}
        {/* {isHaveContentComment && (
          <div className="content-text-user">{contentRate}</div>
        )} */}
        <div className="main-info-book">
          <div className="info-content d-flex gap-2 ">
            <div className="main-info-one d-flex  align-items-center">
              <h2 className="title">تقييم الموظف</h2>
            </div>
            <div className="main-info-one d-flex  align-items-center">
              <p className={`text`}>{contentRate}</p>
            </div>
          </div>
        </div>
        <div className="main-info-book">
          <div className="info-content d-flex gap-2 ">
            <div className="main-info-one d-flex  align-items-center">
              <h2 className="title">تقييم الخدمة</h2>
            </div>
            <div className="main-info-one d-flex  align-items-center">
              <p className={`text`}>{serviceComment}</p>
            </div>
          </div>
        </div>
      </div>
      {/* END RATE CARD USER ONE  */}
    </>
  );
};

export default CardRate;
