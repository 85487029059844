import { faPercentage, faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "Components/Loader/Loader";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getVendorCustomer as getClients } from "store/vendor/vendorActions";
import "./ReportDetails.css";

const ClientsReport = () => {
  const user = useGetAuthUser();
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const fetchClientsData = useCallback(async () => {
    setIsLoading(true);
    const clientsParams = {
      per_page: 100,
      limit: 100,
      offset: 0,
      vendor_id: user.model_id,
    };
    const clientsResponse = await dispatch(getClients(clientsParams)).unwrap();
    setClients(clientsResponse.data.data);
    setIsLoading(false);
  }, [user.model_id, dispatch]);

  useEffect(() => {
    fetchClientsData();
  }, [fetchClientsData]);

  const totalClients = clients.length;
  const oneMonthAgo = new Date(new Date().setDate(new Date().getDate() - 30));
  const newClients = clients.filter((client) => new Date(client.created_at) > oneMonthAgo).length;
  const activeClients = clients.filter((client) => {
    const lastBooking = client.bookings.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at),
    )[0];
    return lastBooking && new Date(lastBooking.created_at) > oneMonthAgo;
  }).length;

  const reportData = {
    totalClients,
    newClients,
    newClientsPercentage: ((newClients / totalClients) * 100).toFixed(2),
    activeClientsPercentage: ((activeClients / totalClients) * 100).toFixed(2),
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="clients-report">
      <div className="report-summary">
        <div className="summary-card">
          <div className="card-content">
            <h3>{reportData.totalClients}</h3>
            <p>عدد العملاء الكلي</p>
          </div>
          <FontAwesomeIcon icon={faUsers} className="icon" />
        </div>
        <div className="summary-card">
          <FontAwesomeIcon icon={faPercentage} className="icon" />
          <div className="card-content">
            <h3>{reportData.activeClientsPercentage}%</h3>
            <p>نسبة العملاء النشطين</p>
          </div>
        </div>
        <div className="summary-card">
          <FontAwesomeIcon icon={faUserPlus} className="icon" />
          <div className="card-content">
            <h3>{reportData.newClients}</h3>
            <p>عدد العملاء الجدد</p>
          </div>
        </div>
        <div className="summary-card">
          <FontAwesomeIcon icon={faPercentage} className="icon" />
          <div className="card-content">
            <h3>{reportData.newClientsPercentage}%</h3>
            <p>نسبة العملاء الجدد</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsReport;
