// @ts-nocheck
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "Components/Forms/InputField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BgBody from "Components/Ui/BgBody/BgBody";
import FooterForm from "Pages/Auth/FormComponent/FooterForm";
import FormComponent from "Pages/Auth/FormComponent/FormComponent";
import HeaderForm from "Pages/Auth/FormComponent/HeaderForm";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendOtpCode } from "store/auth/authActions";
import { unwrapResult } from "@reduxjs/toolkit";

const ChangePhoneNumber = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    phone: Yup.string()
      .matches(/^[0-9]*$/, "يجب أن يحتوي رقم الجوال على أرقام فقط")
      .min(10, "يجب أن يحتوي رقم الجوال على الأقل 10 أرقام")
      .max(15, "يجب أن يحتوي رقم الجوال على الأكثر 15 رقمًا")
      .required("رقم الجوال مطلوب"),
  });

  const initialValues = {
    phone: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    dispatch(sendOtpCode(values.phone))
      .then(unwrapResult)
      .then(() => {
        navigate("/otp");
      })
      .catch((error) => {
        navigate("/otp");
        console.log(error, "error");
      });

    resetForm();
  };

  return (
    <>
      <HelmetInfo titlePage={"تغيير رقم الجوال"} />
      <BgBody />

      <div className="restore-password">
        <FormComponent>
          <HeaderForm title={"تغيير رقم الجوال"} isText={false} text={false} />
          {/* ========== START FORM FIELD ========= */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty }) => (
              <Form>
                <InputField
                  type="tel"
                  name="phone"
                  placeholder="رقم الجوال الجديد"
                  success
                  label={"رقم الجوال الجديد"}
                />

                <button
                  type="submit"
                  className="main-btn btn-submit mt-4 w-100"
                  disabled={!(dirty && isValid)}
                >
                  التالي
                </button>
              </Form>
            )}
          </Formik>
          {/* ========== END FORM FIELD ========= */}
        </FormComponent>
      </div>
      <FooterForm />
    </>
  );
};

export default ChangePhoneNumber;
