import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import FormContact from "./FormContact";
import ContactInformation from "./ContactInformation";
import "./ContactUs.css"
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
const ContactUs = () => {
  return (
    <>
    <HelmetInfo titlePage={"تواصل معنا"} />
      <div data-aos="fade-left">
      <HeaderPageInfo
        title={"تواصل معنا"}
        isShowLeftContent={false}
        contentPageLeft={false}
      />
      </div>

      <div className="all-info-contact my-3">
        {/* ========= START ROW ========= */}
        <div className="row flex-wrap-reverse   g-3">
          {/* ======== START COL ======== */}
          <div className="col-12 col-md-7">
            <FormContact />
          </div>
          {/* ======== END COL ======== */}
          {/* ======== START COL ======== */}
          <div className="col-12 col-md-5">
            <ContactInformation />
          </div>
          {/* ======== END COL ======== */}
        </div>
        {/* ========= END ROW ========= */}
      </div>
    </>
  );
};

export default ContactUs;
