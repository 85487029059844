import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCartEmployeeId } from "store/customer/customerSlice";
import imgEmp from "../../../../assets/images/userImg/user.png";
import TeamInfo from "../TeamRate/TeamInfo";
import "./StepsStyle.css";

const StepOneServices = ({ employees, setBookingDetails }) => {
  const dispatch = useDispatch();

  const employeesList = Object.values(employees);
  const [selctedEmployee, setSelectedEmployee] = useState(null);
  useEffect(() => {
    if (!selctedEmployee && employeesList.length > 0) {
      handleSelectEmployee(employeesList[0].id);
    }
  }, []);

  const handleSelectEmployee = (id) => {
    const employee = employeesList.find((employee) => employee.id === id);
    dispatch(setCartEmployeeId(id));
    setBookingDetails((prev) => {
      return {
        ...prev,
        employee_id: id,
        employee_info: employee,
      };
    });

    setSelectedEmployee(id);
  };

  if (employeesList & (employeesList.length === 0)) return null;

  return (
    <div className="step-one-info" data-aos="fade-left">
      <h2 className="title-header-step mb-3">اختيار الموظف</h2>
      <div className="all-employees-info mt-4 d-flex  align-items-center flex-wrap   gap-3">
        {employeesList.map((employee, indx) => {
          return (
            <TeamInfo
              key={indx}
              imgEmp={imgEmp}
              numRate={"4.5"}
              nameEmployee={employee.user.name}
              titleJob={employee.job_title ?? "موظف في الفرع"}
              handleSelectEmployee={() => handleSelectEmployee(employee.id)}
              isActive={selctedEmployee === employee.id}
            />
          );
        })}
      </div>
      <div className="error-title">
        *في حالة الرغبة بعدم تحديد موظف معين يتم تعين اقرب موظف متاح
      </div>
    </div>
  );
};

export default StepOneServices;
