import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ButtonCounter = ({ serviceId, addToCart, removeCart }) => {
  const cartServices = useSelector((state) => state.vendor.cartDetails.services);
  const [isInCart, setIsInCart] = useState(false);

  useEffect(() => {
    const serviceInCart = cartServices.some((service) => service.serviceId === serviceId);
    setIsInCart(serviceInCart);
  }, [cartServices, serviceId]);

  const handleClick = useCallback(() => {
    if (isInCart) {
      removeCart();
    } else {
      addToCart();
    }
  }, [isInCart, addToCart, removeCart]);

  return (
    <>
      <div className="main-btn main-outline-btn py-1 " onClick={handleClick}>
        {isInCart ? "ازالة" : "اضافة"}
      </div>
    </>
  );
};

export default ButtonCounter;
