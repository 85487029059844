import { createSlice } from "@reduxjs/toolkit";
import {
  addCanncelationReasons,
  addClientCanncelationReasons,
  addPromo,
  editCanncelationReasons,
  editClientCanncelationReasons,
  editTermConditions,
  getCanncelationReasons,
  getCategories,
  getCities,
  getClientCanncelationReasons,
  getCountries,
  getPromo,
  getPromos,
  getRegions,
  getServiceCategories,
  getTermConditions,
  getVendorWallet,
  updatePromo,
} from "./generalActions";

const InitialState = {
  isLoading: false,
  hasError: false,
  errorMessage: {},

  countries: [],
  regions: [],
  cities: [],
};

const GeneralSlice = createSlice({
  name: "general",
  initialState: InitialState,
  reducers: {
    clearError: (state) => {
      state.hasError = false;
      state.errorMessage = {};
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setHasError: (state, action) => {
      state.hasError = action.payload;
    },

    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },

    setCountries: (state, action) => {
      state.countries = action.payload;
    },

    setRegions: (state, action) => {
      state.regions = action.payload;
    },

    setCities: (state, action) => {
      state.cities = action.payload;
    },

    setIsLogedIn: (state, action) => {
      state.isLogedIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countries = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getCountries.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getRegions.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getRegions.fulfilled, (state, action) => {
        state.regions = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getRegions.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getCities.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getCities.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getCanncelationReasons.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCanncelationReasons.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getCanncelationReasons.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getClientCanncelationReasons.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getClientCanncelationReasons.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getClientCanncelationReasons.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(editCanncelationReasons.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(editCanncelationReasons.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(editCanncelationReasons.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(editClientCanncelationReasons.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(editClientCanncelationReasons.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(editClientCanncelationReasons.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(addCanncelationReasons.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addCanncelationReasons.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(addCanncelationReasons.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(addClientCanncelationReasons.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addClientCanncelationReasons.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(addClientCanncelationReasons.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getTermConditions.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getTermConditions.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getTermConditions.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(editTermConditions.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(editTermConditions.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(editTermConditions.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getCategories.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getServiceCategories.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getServiceCategories.fulfilled, (state, action) => {
        state.cities = action.payload?.entities;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getServiceCategories.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getPromos.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getPromos.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getPromos.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(addPromo.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addPromo.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(addPromo.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(updatePromo.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updatePromo.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updatePromo.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getPromo.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getPromo.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getPromo.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getVendorWallet.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getVendorWallet.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getVendorWallet.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});
export const { setCities, setCountries, setRegions } = GeneralSlice.actions;
export default GeneralSlice.reducer;
