import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";
import clockIcon from "../../assets/images/icons/clock.svg";
import locationIcon from "../../assets/images/icons/location.svg";
import imgCardSlider from "../../assets/images/main/04.png";

import { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper/modules";

const CardsFilter = ({ servicesProviders }) => {
  const [selectedServiceProviderIdx, setSelectedServiceProviderIdx] = useState(0);
  const providerDetails = servicesProviders[selectedServiceProviderIdx];

  useEffect(() => {
    if (selectedServiceProviderIdx >= servicesProviders.length) {
      setSelectedServiceProviderIdx(0);
    }
  }, [servicesProviders, selectedServiceProviderIdx]);

  return (
    <div className="card-filter-one h-100 mb-4">
      <div className="slider-card">
        <Swiper
          navigation={true}
          loop={true}
          spaceBetween={15}
          modules={[Pagination, Navigation]}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
          onSlideChange={(swiper) => {
            setSelectedServiceProviderIdx(swiper.realIndex);
          }}
        >
          {servicesProviders.map((provider, index) => (
            <SwiperSlide key={index}>
              <div className="img-slider">
                <img src={imgCardSlider} alt="img card slider" className="w-100 h-100 object-fit-cover " />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="info-content-card-slider">
        <div className="header-card">
          <h2 className="title">{providerDetails?.name}</h2>
          {providerDetails?.branches[0]?.address && (
            <div className="main-text-info d-flex align-items-center gap-2">
              <div className="icon">
                <img src={locationIcon} alt="icon location" width={"22px"} height={"22px"} />
              </div>
              <h2 className="text-info">{providerDetails?.branches[0]?.address}</h2>
            </div>
          )}

          {/* <div className="main-text-info d-flex align-items-center gap-2">


          {/* <div className="main-text-info d-flex align-items-center gap-2">
            <div className="icon">
              <img src={starIcon} alt="icon star" width={"22px"} height={"22px"} />
            </div>
            <h2 className="rate-num">4.5</h2>
            <h2 className="total-info-rate">(120 تقيم)</h2>
          </div>
           */}
        </div>

        <div className="bottom-card">
          {providerDetails?.services.map((service, index) => (
            <div key={index} className="main-bottom-card mb-3 d-flex justify-content-between ">
              <div className="right-bottom-card">
                <h2 className="title">{service.name_ar}</h2>
                <div className="info-time d-flex  align-items-center  gap-2">
                  <div className="icon">
                    <img src={clockIcon} alt="icon star" width={"22px"} height={"22px"} />
                  </div>
                  <span className="text-time">{service.service_time} دقيقة</span>
                </div>
              </div>

              <div className="left-bottom-card">
                <h2 className="num-price">{service.price} ر.س</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardsFilter;
