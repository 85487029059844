import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import imgCard from "../../../assets/images/dashboardImages/main/help.png";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
const DetailsHelpCenter = () => {
  return (
    <>
      <HelmetInfo titlePage={"مركز المساعدة"} />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"مركز المساعدة"}
          isShowLeftContent={false}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={false}
              typeIcon={false}
              textButton={""}
              functionButton={false}
            />
          }
        />
      </div>
      <div className="details-help-center mt-3" data-aos="fade-up">
        {/* ======== START HELP CARD ONE ========= */}
        <div className="help-card-one">
          <div className="img-card">
            <img
              src={imgCard}
              alt="img card"
              className="w-100 h-100 object-fit-cover"
            />
          </div>
          <div className="info-content-card pt-3">
            <div className="text-title d-flex align-items-center gap-4 pt-3">
              <h2 className="title">اسم الفيديو</h2>
              <h2 className="title">محمد احمد عمر</h2>
            </div>
            <div className="text-title d-flex align-items-center gap-4 pt-3 flex-wrap">
              <h2 className="title">وصف الفيديو</h2>
              <p className="text lh-lg">
                يسمى هذا التبويب بهذا الاسم حيث انه لا يمكن حيث انه ملى بالعديد
                من الاقسام يمكن توافرها والمسميات الخاصة بهذا المشروع
              </p>
            </div>
          </div>
        </div>
        {/* ======== END HELP CARD ONE ========= */}
      </div>
    </>
  );
};

export default DetailsHelpCenter;
