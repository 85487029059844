import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BgBody from "Components/Ui/BgBody/BgBody";
import { Link } from "react-router-dom";
import iconApple from "../../../assets/images/iconSocial/apple2.svg";
import iconFace from "../../../assets/images/iconSocial/facebook.svg";
import iconGoogle from "../../../assets/images/iconSocial/google.svg";
import FooterForm from "../FormComponent/FooterForm";
import FormComponent from "../FormComponent/FormComponent";
import HeaderForm from "../FormComponent/HeaderForm";
import "./RegisterNewAccount.css";

const RegisterNewAccount = () => {
  return (
    <>
      <HelmetInfo titlePage={"تسجيل حساب جديد"} />
      <BgBody />

      <FormComponent>
        <HeaderForm title={" حساب جديد"} isText={true} text={"الرجاء اختيار طريقة التسجيل"} />
        <div className="all-main-new-register d-flex  flex-column  gap-3">
          <Link to="/register" className="w-100 main-btn btn-register py-3">
            من خلال رقم الجوال او البريد الألكتروني
          </Link>
          <Link to="/" className="w-100 main-btn main-outline-btn d-flex  align-items-center  gap-2">
            <img src={iconGoogle} alt="icon google" width={"32px"} height={"32px"} />
            حساب جوجل
          </Link>
          <Link to="/" className="w-100 btn-apple main-btn    d-flex  align-items-center  gap-2">
            <img src={iconApple} alt="icon apple" width={"32px"} height={"32px"} />
            حساب آبل
          </Link>
          <Link to="/" className="w-100 btn-face main-btn    d-flex  align-items-center  gap-2">
            <img src={iconFace} alt="icon apple" width={"32px"} height={"32px"} />
            حساب الفيس بوك
          </Link>
        </div>
      </FormComponent>
      <FooterForm />
    </>
  );
};

export default RegisterNewAccount;
