import {
  faCircleExclamation,
  faEye,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { faStar as starReg } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import PaginationSelect from "Dashboard/Components/UiDashboard/PaginationSelect/PaginationSelect";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import { useNavigate } from "react-router-dom";
import "./RatesPage.css";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
const RatesPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <HelmetInfo titlePage={"التقيمات"} />

      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"التقيمات"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={false}
              typeIcon={false}
              textButton={false}
              functionButton={false}
            />
          }
        />
      </div>

      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        <TableDataInfo
          titleTableHeader={false}
          isHashTrue={false}
          hashId={false}
          dateThead={
            <>
              <th scope="col">رقم الحجز</th>
              <th scope="col"> تاريخ التقيم</th>
              <th scope="col"> اسم العميل</th>
              <th scope="col"> التقيم</th>
              <th scope="col"> الاجراءات</th>
            </>
          }
          dataTbody={
            <>
              <tr>
                <td>1234569</td>
                <td>2023/11/16</td>
                <td>محمد احمد عمر</td>
                <td>
                  <div className="rates-stars d-flex  align-items-center gap-3">
                    <div className="icons-rate-1 d-flex  align-items-center gap-2">
                      <div className="icon-star">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-star">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-star">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-star">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-star">
                        <FontAwesomeIcon icon={starReg} />
                      </div>
                    </div>
                    <div className="num-rate fs-5 fw-bold ">4.5</div>
                  </div>
                </td>

                <td>
                  <IconsTable
                    typeIcon1={faEye}
                    functionEye={() => {
                      navigate(`${"showRatesDetails/idRatesDetails"}`);
                    }}
                    typeIcon2={faCircleExclamation}
                    functionEdit={(e) => {
                      e.preventDefault();
                    }}
                    isDeleteIcon={true}
                    functionDelete={false}
                    typeIcon3={false}
                  />
                </td>
              </tr>
            </>
          }
        />
      </div>
      <PaginationSelect
        itemsPageTitle={"عدد العناصر بالصفحة"}
        optionsSelect={
          <>
            <option value="">اختار</option>
            <option value="1"> 1</option>
            <option value="2"> 2</option>
            <option value="3"> 3</option>
          </>
        }
        infoItemsPageSelect1={"of 200 items"}
        infoItemsPageSelect2={"of 44 pages"}
        optionsSelect2={
          <>
            <option value="">اختار</option>
            <option value="1"> 1</option>
            <option value="2"> 2</option>
            <option value="3"> 3</option>
          </>
        }
      />
    </>
  );
};

export default RatesPage;
