import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ModalBookingDifference = ({ showModalBooking, hideModalBooking, setSettings }) => {
  const vendorSettings = useSelector((state) => state.vendor.vendorSettings);

  const [isSubmitAllowed, setIsSubmitAllowed] = useState(false);
  const [bookingDifferance, setBookingDifferance] = useState(null);
  const [calendarDifferanceType, setCalendarDifferanceType] = useState(null);

  useEffect(() => {
    if (Object.keys(vendorSettings).length > 0) {
      const { booking_differance, calender_differance_type } = vendorSettings;
      setBookingDifferance(booking_differance);
      setCalendarDifferanceType(calender_differance_type);
    }
  }, [vendorSettings]);

  useEffect(() => {
    if (bookingDifferance && calendarDifferanceType) {
      setIsSubmitAllowed(true);
    } else {
      setIsSubmitAllowed(false);
    }
  }, [bookingDifferance, calendarDifferanceType]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSettings = {
      ...vendorSettings,
      booking_differance: bookingDifferance,
      calender_differance_type: calendarDifferanceType,
    };
    console.log(newSettings);
    setSettings(newSettings);
    hideModalBooking();
  };

  return (
    <CustomModal
      show={showModalBooking}
      onHide={hideModalBooking}
      title={"فارق الحجز"}
      newClass={"modal-booking-diff"}>
      <div className="form-modal">
        <form action="">
          <div className="form-input-one mb-3">
            <label htmlFor="exampleInput1" className="form-label">
              فارق التقويم(بالدقيقة)
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleInput1"
              placeholder="فارق التقويم(بالدقيقة)"
              value={bookingDifferance ?? ""}
              onChange={(e) => {
                setBookingDifferance(e.target.value);
              }}
            />
          </div>
          <div className="form-input-one mb-3">
            <label htmlFor="exampleInput2" className="form-label">
              نوع فارق التوقيت
            </label>
            <select
              className="form-select form-select-lg select-box-option-1"
              id="exampleInput2"
              value={calendarDifferanceType ?? ""}
              onChange={(e) => {
                setCalendarDifferanceType(e.target.value);
              }}>
              <option selected=""> نوع فارق التوقيت</option>
              <option value={"fixed"}>ثابت</option>
              <option value={"vary"}>متغير</option>
            </select>
          </div>

          <button
            onClick={handleSubmit}
            className="main-btn w-100 mt-4"
            type={"submit"}
            disabled={!isSubmitAllowed}>
            حفظ
          </button>
        </form>
      </div>
    </CustomModal>
  );
};

export default ModalBookingDifference;
