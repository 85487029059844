import { faEye } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBookings as getAdminBookings } from "store/admin/adminActions";
import { getBookings } from "store/vendor/vendorActions";
import { formatDateToArabic } from "utlis";
import "./BookingDash.css";

const BookingsDashboard = () => {
  const user = useGetAuthUser();
  const [bookings, setBookings] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchVendorBookings = (params) => {
    setIsLoading(true);
    dispatch(getBookings(params))
      .then(unwrapResult)
      .then((res) => {
        setBookings(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchAdminBookings = (params) => {
    setIsLoading(true);
    dispatch(getAdminBookings(params))
      .then(unwrapResult)
      .then((res) => {
        setBookings(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const action = user.type === "vendor" ? fetchVendorBookings : fetchAdminBookings;
    const paramsObject =
      user.type === "vendor"
        ? {
            with: ["vendor"],
            limit: limit,
            offset: (currentPage - 1) * limit,
            sort: "DESC",
            per_page: limit,
            page: currentPage,
            paginate: "true",
            field: "id",
            deleted: "all",
            resource: "all",
            resource_columns: ["id", "name_ar"],
            columns: ["vendor_id"],
            operand: ["="],
            column_values: [user?.model_id],
          }
        : {
            with: ["vendor"],
            limit: limit,
            offset: (currentPage - 1) * limit,
            sort: "DESC",
            per_page: limit,
            page: currentPage,
            paginate: "true",
            field: "id",
            deleted: "all",
            resource: "all",
          };
    action(paramsObject);
  }, [limit, currentPage, user]); //eslint-disable-line

  if (isLoading) return <Loader />;
  return (
    <>
      <HelmetInfo titlePage={"الحجوزات"} />
      <div data-aos="fade-down">
        <HeaderPageInfo title={"الحجوزات"} isShowLeftContent={true} />
      </div>

      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">رقم الحجز</th>
                <th scope="col">تاريخ انشاء الحجز</th>
                <th scope="col">حالة الحجز</th>
                <th scope="col">حالة الدفع</th>
                <th scope="col">الاجراءات</th>
              </>
            }
            dataTbody={
              <>
                {bookings.length > 0 &&
                  bookings?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{formatDateToArabic(item?.created_at)}</td>
                        <td>
                          <p className={item.classStatusBook}>
                            {item.is_active === "1" ? "نشط" : "غير نشط"}
                          </p>
                        </td>
                        <td>{item.payment_status === "0" ? "لم يتم" : "تم"}</td>

                        <td>
                          <div className="all-icons-info d-flex align-items-center gap-0">
                            <IconsTable
                              typeIcon1={faEye}
                              functionEye={() => {
                                navigate(`${item.id}`);
                              }}
                              typeIcon2={false}
                              functionEdit={false}
                              isDeleteIcon={false}
                              functionDelete={false}
                              typeIcon3={false}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={(value) => setLimit(value)}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[5, 10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default BookingsDashboard;
