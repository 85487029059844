import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addRole, getRoles, updateRole } from "store/admin/adminActions";
import TableDataInfo from "../../Components/UiDashboard/TablesData/TableDataInfo";
import AddRoleModal from "./AddRoleModal";
import "./RolesPowers.css";

const RolesPowers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getRoles({
        with: ["permissions"],
        per_page: itemsPerPage,
        page: currentPage,
        search: searchText,
      }),
    )
      .then(unwrapResult)
      .then((res) => {
        setIsLoading(false);
        setRoles(res.data.data);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const addNewRole = async (values) => {
    setIsLoading(true);
    dispatch(addRole(values))
      .then(unwrapResult)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const toggleRoleActivation = (id, isActive) => {
    dispatch(updateRole({ id, is_active: !isActive }))
      .then(unwrapResult)
      .then((res) => {
        toast.success("تم تفعيل الدور بنجاح");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const paginatedRoles = roles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  console.log("paginatedRoles", paginatedRoles);

  if (isLoading) return <Loader />;

  return (
    <section>
      <HelmetInfo titlePage={"الادوار والصلاحيات"} />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={" الادوار والصلاحيات  "}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              showSearch={false}
              typeIcon={faPlus}
              textButton={"إضافة دور"}
              functionButton={() => setIsModalOpen(true)}
              isButtonActive={true}
            />
          }
        />
      </div>

      <div className="table-content mt-3 table-content-2" data-aos="fade-up" data-aos-delay="300">
        <TableDataInfo
          titleTableHeader={false}
          isHashTrue={true}
          hashId={"#"}
          dateThead={
            <>
              <th scope="col"> الدور</th>
              <th> الوصف</th>
              <th scope="col">تفعيل/الغاء</th>
              <th scope="col">الاجراءات</th>
            </>
          }
          dataTbody={
            <>
              {paginatedRoles.map((role, index) => (
                <tr key={index}>
                  <th scope="row">{role.id}</th>
                  <td>{role.name}</td>
                  <td>{role.display_name}</td>
                  <td>
                    <Toggleswitch
                      switchId={`toggleswitch-role- ${role.id}`}
                      nameSwitch={"switch01"}
                      value={role.is_active === "1"}
                      onChange={() => toggleRoleActivation(role.id, role.is_active)}
                    />
                  </td>
                  <td>
                    <IconsTable
                      typeIcon1={faEye}
                      functionEye={() => navigate(`detailsRole/${role.id}`)}
                      typeIcon2={faEdit}
                      functionEdit={() => navigate(`editAddRole/${role.id}`)}
                    />
                  </td>
                </tr>
              ))}
            </>
          }
        />
      </div>
      {/* Add Role Modal */}
      <AddRoleModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={addNewRole}
      />
    </section>
  );
};

export default RolesPowers;
