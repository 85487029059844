import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkPaymentStatus } from "store/customer/customerActions";
import { checkIsSubscriptionPaid } from "store/vendor/vendorActions";

const PaymentModal = ({ show, closeModal, paymentUrl, bookingId, setIsPaid, fromVendor = false }) => {
  const iframeRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let checkPaymentInterval;
    let checkIsPaidInterval;
    if (show) {
      if (!fromVendor) {
        checkPaymentInterval = setInterval(async () => {
          try {
            const response = await dispatch(checkPaymentStatus(bookingId));
            const paymentStatus = unwrapResult(response);
            if (paymentStatus.data.payment_status === "1") {
              clearInterval(checkPaymentInterval);
              setIsPaid(true);
              setTimeout(() => {
                navigate("/successpay", { state: { bookingId } });
              }, 800);
              closeModal();
            }
          } catch (error) {
            console.error("Error checking payment status:", error);
          }
        }, 5000); // Check every 5 seconds
      } else {
        checkIsPaidInterval = setInterval(async () => {
          console.log("checkIsPaidInterval");
          try {
            const response = await dispatch(checkIsSubscriptionPaid(bookingId));
            const paymentStatus = unwrapResult(response);
            if (paymentStatus.data.payment_status === "active") {
              clearInterval(checkIsPaidInterval);
              setIsPaid(true);
            }
          } catch (error) {
            console.error("Error checking payment status:", error);
          }
        }, 5000);
      }
    }

    return () => {
      clearInterval(checkPaymentInterval);
      clearInterval(checkIsPaidInterval);
    };
  }, [bookingId, show, dispatch]); //eslint-disable-line

  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      style={{ display: show ? "block" : "none", zIndex: "9999", position: "fixed" }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="modal-title">دفع الخدمة</h5>
              <button type="button" className="btn-close btn-close-white" onClick={closeModal}></button>
            </div>
          </div>
          <div className="modal-body">
            {paymentUrl && (
              <iframe
                ref={iframeRef}
                src={paymentUrl}
                width="100%"
                height="700px"
                frameBorder="2"
                allowFullScreen
                title="Payment Page"
              ></iframe>
            )}
          </div>
          <div
            className="modal-footer d-flex justify-content-end align-items-center"
            style={{
              backgroundColor: "#f8d7da",
              color: "#721c24",
              border: "none",
              padding: "24px",
              borderRadius: "0 0 8px 8px",
              boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <p className="text-right w-100" style={{ direction: "rtl" }}>
              من فضلك لا تغلق هذه النافذة
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
