import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import notFoundPage from "../../assets/images/404/404.json";
import "./NotFound.css";

const NotFound = ({ newClass, routePage }) => {
  return (
    <div
      className={`not-found-page  d-flex align-items-center  justify-content-center  flex-column ${newClass}`}>
      <div className="animtion-lottie">
        <Lottie animationData={notFoundPage} />
      </div>
      <Link to={routePage} className="main-btn">
        الصفحة الرئيسية
        <FontAwesomeIcon icon={faHome} />
      </Link>
    </div>
  );
};

export default NotFound;
