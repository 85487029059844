import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const Pagination = ({
  limitOptions,
  currentPageItemsCount,
  totalItemsCount,
  totalPagesCount,
  limit,
  currentPage,
  setCurrentPage,
  setLimit,
}) => {
  const handleSelectChange = (event) => {
    setLimit(event.target.value);
  };

  //SELECT PAGES
  const [pages, setPages] = useState(Array.from({ length: Math.ceil(totalPagesCount) }, (_, i) => i + 1));
  useEffect(() => {
    const newPages = Array.from({ length: Math.ceil(totalPagesCount) }, (_, i) => i + 1);
    setPages(newPages);
    if (!currentPage) {
      setCurrentPage(newPages[0]);
    }
  }, [totalPagesCount, currentPage, setCurrentPage]);

  const handleSelectChangePage = (event) => {
    setCurrentPage(event.target.value);
  };

  return (
    <>
      <div className="bottom-pagination mt-4 d-flex  justify-content-between  align-items-center  flex-wrap gap-2 ">
        <div className="select-items d-flex  ailn-align-items-center gap-3 flex-wrap ">
          <h2 className="title-items">عدد العناصر بالصفحة</h2>
          <select className="form-select" value={limit} onChange={handleSelectChange}>
            {limitOptions?.map((limit, index) => (
              <option value={limit} key={index}>
                {" "}
                {limit}
              </option>
            ))}
          </select>
          {limit && (
            <p dir="rtl" className="text-items d-flex align-items-center  gap-2">
              {`${currentPageItemsCount} من ${totalItemsCount} عنصر`}
            </p>
          )}
        </div>
        <div className="select-page select-items d-flex  align-items-center  gap-3">
          {currentPage && (
            <p dir="rtl" className="text-items d-flex align-items-center  gap-2">
              {`${currentPage} من ${pages.length} صفحات`}
            </p>
          )}
          <select className="form-select" value={currentPage} onChange={handleSelectChangePage}>
            {pages.map((page, index) => (
              <option key={index} value={page}>
                {" "}
                {page}
              </option>
            ))}
          </select>

          <Button
            className="page-add-icon bg-transparent border-0"
            type="button"
            onClick={() => {
              setCurrentPage((currentPage + 1) % pages.length || pages.length);
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Pagination;
