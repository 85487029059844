import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputFiled from "Components/Forms/InputField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BgBody from "Components/Ui/BgBody/BgBody";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import FooterForm from "../FormComponent/FooterForm";
import FormComponent from "../FormComponent/FormComponent";
import HeaderForm from "../FormComponent/HeaderForm";
import "./Login.css";

const validationSchema = Yup.object({
  // email: Yup.string().required("هذا الحقل مطلوب").email("يجب أن يكون البريد الألكتروني صحيح"),
  email: Yup.string().required("هذا الحقل مطلوب"),
  password: Yup.string()
    .required("كلمة المرور مطلوبة")
    .min(8, "يجب أن تكون كلمة المرور أكثر من 8 أحرف وأرقام"),
});

const LoginComponent = ({ isWebsite, isDashboard, loginAndNavigate }) => {
  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = (values) => {
    try {
      loginAndNavigate(values.email, values.password);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-component">
      <HelmetInfo titlePage={isWebsite ? "تسجيل الدخول" : "تسجيل دخول مقدمي الخدمات"} />
      <BgBody />
      <FormComponent>
        <HeaderForm
          title={isWebsite ? "تسجيل دخول" : "تسجيل دخول مقدمي الخدمات"}
          isText={false}
          text={false}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ values, isValid, dirty }) => (
            <>
              <Form>
                <InputFiled
                  label={"البريد الالكتروني"}
                  name="email"
                  type="text"
                  placeholder={"ادخل البريد الالكتروني"}
                  success
                />
                <div className="input-pass position-relative">
                  <InputFiled
                    label="كلمة المرور"
                    name="password"
                    placeholder={"ادخل كلمة المرور"}
                    type={showPassword ? "text" : "password"}
                    success
                  />

                  <button
                    type="button"
                    className="icon-eye-button"
                    onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </button>
                </div>

                <button
                  type="submit"
                  className="main-btn btn-submit mt-5 w-100"
                  disabled={!isValid || !dirty}>
                  تسجيل دخول
                </button>
              </Form>

              <div className="bottom-form d-flex flex-column align-items-center gap-1 text-decoration-underline">
                <Link className="link-forget-pass font-main mt-2" to={"/restorepassword"}>
                  نسيت كلمة المرور؟
                </Link>

                <Link
                  to={isDashboard ? "/registerserviceprovideraccount" : "/register"}
                  className="font-main link-form-register text-decoration-underline">
                  تسجيل حساب جديد
                </Link>

                <div className="d-flex justify-content-center gap-4 mt-4">
                  <Link
                    to="/login"
                    className={`btn px-3 py-2 rounded-3 fw-bold ${!isWebsite ? "main-btn" : "btn-outline-secondary"}`}>
                    دخول عميل
                  </Link>
                  <Link
                    to="/loginDashboard"
                    className={`btn px-3 py-2 rounded-3 fw-bold ${isWebsite ? "main-btn" : "btn-outline-secondary"}`}>
                    دخول مقدمي الخدمات
                  </Link>
                </div>
              </div>
            </>
          )}
        </Formik>
      </FormComponent>
      <FooterForm />
    </div>
  );
};

export default LoginComponent;
