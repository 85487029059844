import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import TextAreaInput from "Components/Forms/TextArea";
import * as Yup from "yup";

const FormContact = () => {
  const validationSchema = Yup.object().shape({
    faqOrder: Yup.string().required("ادخل السؤال او الطلب"),
    category: Yup.string().required("ادخل التصنيف"),
    name: Yup.string().required("ادخل الاسم بالكامل"),
    email: Yup.string()
      .email("البريد الالكترونى غير صحيح")
      .required("البريد الالكترونى مطلوب"),

    message: Yup.string().required("ادخل الرسالة")
  });

  const initialValues = {
    faqOrder: "",
    category: "",
    name: "",
    email: "",
    message: ""
  };
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    resetForm();
  };
  return (
    <>
      <div className="form-contact-1   mb-3 mb-sm-0" data-aos="fade-left">
        {/* ========== START FORM FIELD ========= */}
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <div className="row g-1 g-sm-3">
            <div className="col-12 col-md-12">
              <InputFiled
                label={"السؤال او الطلب"}
                name="faqOrder"
                type="text"
                placeholder={"السؤال او الطلب"}
                success
              />
            </div>

            <div className="col-12 col-md-12">
              <div className="select-reg">
                <label htmlFor="exampleFormControl1" className="form-label">
                  التصنيف
                </label>
                <SelectField
                  name="category"
                  label={false}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                    { value: "option3", label: "Option 3" }
                  ]}
                  valueSelected={"التصنيف"}
                />
              </div>
            </div>
            <div className="col-12 col-md-12">
              <InputFiled
                label={"الاسم"}
                name="name"
                type="text"
                placeholder={"الاسم"}
                success
              />
            </div>
            <div className="col-12 col-md-12">
              <InputFiled
                label={"البريد الألكتروني"}
                name="email"
                type="eamil"
                placeholder={"البريد الألكتروني"}
                success
              />
            </div>
            <div className="col-12 col-md-12">
              <div className="col-12 col-md-12">
                <TextAreaInput
                  label="الرسالة"
                  name="message"
                  placeholder="الرسالة"
                  success
                />
              </div>
            </div>
          </div>

          <button type="submit" className="main-btn btn-submit px-5 mt-4">
          أرسال
          </button>
        </FormField>
        {/* ========== END FORM FIELD ========= */}
      </div>
    </>
  );
};

export default FormContact;
