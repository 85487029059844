import { unwrapResult } from "@reduxjs/toolkit";
import CardDetailsBooking from "Dashboard/Components/UiDashboard/CardDetailsBooking/CardDetailsBooking";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPosTotals } from "store/vendor/vendorActions";
import icon3 from "../../../assets/images/dashboardImages/icons/cart.svg";
import icon2 from "../../../assets/images/dashboardImages/icons/ticket.svg";
import icon1 from "../../../assets/images/dashboardImages/icons/ticket2.svg";

const CardsHeaderPos = () => {
  const [cardsPos, setCardsPos] = useState([
    {
      id: "booking_count",
      icon: icon1,
      num: 0,
      text: "اجمالى طلبات اليوم",
    },
    { id: "booking_cash_total", icon: icon2, num: 0, text: "اجمالى الكاش" },
    { id: "booking_online_total", icon: icon3, num: 0, text: "اجمالى الفيزا" },
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosTotals())
      .then(unwrapResult)
      .then((res) => {
        setCardsPos((prev) => {
          return prev.map((item) => {
            return { ...item, num: res.data[item.id] };
          });
        });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []); //eslint-disable-line

  return (
    <div className="all-cards-header-pos">
      <div className="row g-3">
        {cardsPos.map((item, index) => {
          return (
            <div className="col-12 col-md-6 col-lg-6 col-xl-4" key={index}>
              <CardDetailsBooking numBooking={item.num} text={item.text} icon={item.icon} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardsHeaderPos;
