import { Link } from "react-router-dom";
import "./NoticeCard.css";
const NoticeCard = ({ icon, title, routeLink, linkText, textInfoDate }) => {
  return (
    <div className="notice-card d-flex justify-content-between flex-wrap gap-3">
      <div className="right-card-info d-flex align-items-center gap-3">
        <div className="icon-bell ">
          <div className="icon-img img-bg" style={{ backgroundImage: `url(${icon})` }}></div>
        </div>

        <div className="content-info">
          <h2 className="title">{title}</h2>
          <Link to={routeLink} className="text-link">
            {linkText}
          </Link>
        </div>
      </div>

      <div className="left-card-info">
        <h2 className="info-text-date">{textInfoDate}</h2>
      </div>
    </div>
  );
};

export default NoticeCard;
