import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { Link, useParams } from "react-router-dom";

const DetailsRole = () => {
  const idRole = useParams();
  const checkBoxIcon = [
    { id: "check1", title: "اسم الصلاحية" },
    { id: "check2", title: "اسم الصلاحية" },
    { id: "check3", title: "اسم الصلاحية" },
    { id: "check4", title: "اسم الصلاحية" },
    { id: "check5", title: "اسم الصلاحية" },
    { id: "check6", title: "اسم الصلاحية" },
    { id: "check7", title: "اسم الصلاحية" },
    { id: "check8", title: "اسم الصلاحية" },
  ];
  const checkBoxIcon2 = [
    { id: "check01", title: "اسم الصلاحية" },
    { id: "check02", title: "اسم الصلاحية" },
    { id: "check03", title: "اسم الصلاحية" },
    { id: "check04", title: "اسم الصلاحية" },
    { id: "check05", title: "اسم الصلاحية" },
    { id: "check06", title: "اسم الصلاحية" },
    { id: "check07", title: "اسم الصلاحية" },
    { id: "check08", title: "اسم الصلاحية" },
  ];
  return (
    <>
      <HelmetInfo titlePage={"تفاصيل"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/admin/homedashboard"}
          titleInfoPage={"تفاصيل"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/rolespowers">
            عودة
          </Link>
        </div>
      </div>
      <div className="all-details-roles" data-aos="fade-up">
        {/* =========== START MAIN INFO DETAILS ROLE ======== */}
        <div className="main-info-details-role">
          {/* ======== START TOP HEADER ROLE ======== */}
          <div className="header-role d-flex     gap-5">
            <h2 className="title-1">اسم الدور</h2>

            <h2 className="title-1">مشرف</h2>
          </div>
          {/* ======== END TOP HEADER ROLE ======== */}
          <div className="right-info-det d-flex  flex-column  gap-5 mt-4">
            <div className="bottom-info-role d-flex  align-items-center gap-5">
              <h2 className="title-1">الصلاحية</h2>

              <div className="all-check-boxs d-flex gap-3  flex-wrap ">
                {checkBoxIcon.map((item) => {
                  return (
                    <div className="form-check d-flex  align-items-center  gap-2" key={item.id}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id={item.id}
                      />
                      <label className="form-check-label" htmlFor={item.id}>
                        {item.title}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="bottom-info-role d-flex  align-items-center gap-5 ">
              <h2 className="title-1">المستخدم</h2>

              <div className="all-check-boxs d-flex  flex-wrap gap-3">
                {checkBoxIcon2.map((item) => {
                  return (
                    <div className="form-check d-flex  align-items-center  gap-2" key={item.id}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id={item.id}
                      />
                      <label className="form-check-label" htmlFor={item.id}>
                        {item.title}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* =========== END MAIN INFO DETAILS ROLE ======== */}
      </div>
    </>
  );
};

export default DetailsRole;
