// @ts-nocheck
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import AccordionFaq from "Components/Ui/AccordionFaq/AccordionFaq";
import { Accordion } from "react-bootstrap";
import "./CommonFaq.css";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAddEditFaq from "./ModalAddEditFaq";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "Components/Loader/Loader";
import { getFAQs, updateFAQ } from "store/admin/adminActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const CommonFaq = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showModalFaq, setShowModalFaq] = useState(false);
  const [search, setSearch] = useState("");
  const [reload, setReload] = useState(false);
  const [currentEditItemIndex, setCurrentEditItemIndex] = useState(undefined);
  const isLoading = useSelector((state) => state.admin.isLoading);

  const hideModalEditAddFaq = () => {
    setShowModalFaq(false);
  };
  const openModalFaq = (e) => {
    setShowModalFaq(true);
  };
  const fetchData = (params) => {
    dispatch(getFAQs(params))
      .then(unwrapResult)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const handleToggleSwitch = (newValue, index) => {
    dispatch(
      updateFAQ({ ...data[index], is_active: `${newValue ? "1" : "0"}` })
    )
      .then(unwrapResult)
      .then(() => {
        const updatedData = [...data];
        updatedData[index].is_active = newValue ? "1" : "0";
        toast.success("تم التعديل بنجاح.");
        setData(updatedData);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchData({ search });
  }, [search]);

  useEffect(() => {
    if (reload) {
      fetchData({ search });
      setReload(false);
    }
  }, [reload]);

  return (
    <>
      <HelmetInfo titlePage={"الأسئلة الشائعة"} />
      <ModalAddEditFaq
        showModalEditAddFaq={showModalFaq}
        hideModalEditAddFaq={() => {
          hideModalEditAddFaq();
          setCurrentEditItemIndex(undefined);
        }}
        onSubmit={() => {
          setReload(true);
          hideModalEditAddFaq();
          setCurrentEditItemIndex(undefined);
        }}
        data={
          currentEditItemIndex !== undefined
            ? data[currentEditItemIndex]
            : undefined
        }
      />
      <div data-aos="fade-down">
        <HeaderPageInfo
          title={"الأسئلة الشائعة"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={true}
              typeIcon={faPlus}
              textButton={"اضافة سؤال جديد"}
              functionButton={openModalFaq}
              onSearchChange={setSearch}
            />
          }
        />
      </div>
      <div className="common-faq common-faq-dash mt-3" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="all-tabs-content" data-aos="fade-up">
            {/* <Tabs
            defaultActiveKey="tab-1"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="tab-1" title="المحفظة"> */}
            <div className="all-common-faq">
              <Accordion>
                {data.map((item, index) => {
                  return (
                    <AccordionFaq
                      key={item.id}
                      eventKey={item.id}
                      titleAccordion={
                        <div className="title-faq d-flex justify-content-between w-100">
                          <p>{item.question}</p>
                          <div className="main-icon d-flex align-items-center gap-4 me-4">
                            <div className="icon-toggle">
                              <Toggleswitch
                                switchId={item.id}
                                nameSwitch={`question-${item.id}`}
                                value={item.is_active === "1" ? true : false}
                                onChange={(newValue) => {
                                  handleToggleSwitch(newValue, index);
                                }}
                              />
                            </div>

                            <div
                              onClick={() => {
                                setCurrentEditItemIndex(index);
                                openModalFaq();
                              }}
                              className="icon-edit text-color-num cursor-pointer-1 fs-5"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </div>
                          </div>
                        </div>
                      }
                      textAccordion={item.answer}
                      NewClass={"accordion-faq"}
                    />
                  );
                })}
              </Accordion>
            </div>
            {/* </Tab>
          </Tabs> */}
          </div>
        )}
      </div>
    </>
  );
};

export default CommonFaq;
