import { unwrapResult } from "@reduxjs/toolkit";
import CustomModal from "Components/CustomModal/CustomModal";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import TextAreaInput from "Components/Forms/TextArea";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addFAQ, updateFAQ } from "store/admin/adminActions";
import * as Yup from "yup";

const ModalAddEditFaq = ({
  showModalEditAddFaq,
  hideModalEditAddFaq,
  data,
  onSubmit,
}) => {
  const validationSchema = Yup.object().shape({
    question: Yup.string().required("ادخل السؤال"),
    answer: Yup.string().required("ادخل الاجابة"),
  });
  const dispatch = useDispatch();

  const initialValues = {
    question: data?.question || "",
    answer: data?.answer || "",
  };
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    if (data) {
      dispatch(
        updateFAQ({ ...data, question: values.question, answer: values.answer })
      )
        .then(unwrapResult)
        .then(() => {
          if (onSubmit) {
            onSubmit();
          }
          console.log(values);
          toast.success("تم التعديل بنجاح.");
          resetForm();
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } else {
      dispatch(
        addFAQ({
          question: values.question,
          answer: values.answer,
          is_active: "1",
        })
      )
        .then(unwrapResult)
        .then(() => {
          if (onSubmit) {
            onSubmit();
            toast.success("تم الاضافة بنجاح.");
          }
          console.log(values);
          resetForm();
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };
  return (
    <CustomModal
      show={showModalEditAddFaq}
      onHide={hideModalEditAddFaq}
      title={"اضافة/تعديل سؤال"}
      newClass={"add-edit-faq"}
    >
      <div className="form-modal">
        {/* ========== START FORM FIELD ========= */}
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <div className="row g-1 g-sm-3">
            <div className="col-12 col-md-12">
              <InputFiled
                label={"السؤال"}
                name="question"
                type="text"
                placeholder={"السؤال او الطلب"}
                success
              />
            </div>

            <div className="col-12 col-md-12">
              <div className="col-12 col-md-12">
                <TextAreaInput
                  label="الأجابة"
                  name="answer"
                  placeholder="الأجابة"
                  success
                />
              </div>
            </div>
          </div>

          <button type="submit" className="main-btn btn-submit w-100 mt-4">
            حفظ
          </button>
        </FormField>
        {/* ========== END FORM FIELD ========= */}
      </div>
    </CustomModal>
  );
};

export default ModalAddEditFaq;
