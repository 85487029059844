import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import icon2 from "../../../../assets/images/icons/bank-note-04.svg";
import icon1 from "../../../../assets/images/icons/card-01.svg";

import BranchMap from "Dashboard/Components/maps/BranchMap";
const arabicDays = {
  sun: "الأحد",
  mon: "الاثنين",
  tue: "الثلاثاء",
  wed: "الأربعاء",
  thu: "الخميس",
  fri: "الجمعة",
  sat: "السبت",
};

const BranchWorkingDays = ({ currentBranch, vendorSettings }) => {
  const [employees, setEmployees] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);
  const paymentMethod = vendorSettings.accept_payment;
  const customerEmployees = useSelector((state) => state.customer.employees);

  useEffect(() => {
    if (currentBranch && customerEmployees) {
      const filtered = Object.values(customerEmployees).filter(
        (employee) => employee.vendor_id === currentBranch.vendor_id,
      );
      setEmployees(filtered);
    }
  }, [currentBranch, customerEmployees]);

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    let period = "ص";
    let formattedHours = parseInt(hours);
    if (formattedHours >= 12) {
      period = "م";
      if (formattedHours > 12) formattedHours -= 12;
    }
    if (formattedHours === 0) formattedHours = 12;
    return `${formattedHours}:${minutes} ${period}`;
  };

  useEffect(() => {
    if (employees.length > 0) {
      const workingDaysWithHours = [];
      employees.forEach((emp) => {
        emp.official_hours.forEach((day) => {
          if (!workingDaysWithHours.find((d) => d.day === day.day)) {
            workingDaysWithHours.push({
              day: day.day,
              from: day.start_time,
              to: day.end_time,
            });
          }
        });
      });
      setWorkingDays(workingDaysWithHours);
    }
  }, [employees]);

  return (
    <div className="all-banner-serv">
      <div className="banner-home">
        <div className="banner-info">
          <div className="row gx-3 flex-wrap-reverse">
            <div className="col-md-12 col-lg-7">
              <div className="content-banner-info">
                <div className="heade-one" data-aos="fade-up">
                  <h2 className="head-title">مواعيد العمل</h2>
                  <div className="main-content-banner mt-3">
                    <div className="row g-3">
                      {workingDays.map((item, index) => {
                        return (
                          <div className="col-12 col-sm-6 col-md-4 col-lg-6" key={index}>
                            <div className="info-content-one d-flex  align-items-center gap-3">
                              <h2 className="name-day size-text-color-gray">
                                {arabicDays[item.day]}
                              </h2>
                              <p className="date-time size-text-color-gray text-white">
                                {formatTime(item.from)}
                              </p>
                              <span className="size-text-color-gray text-white">:</span>
                              <p className="date-time size-text-color-gray text-white">
                                {formatTime(item.to)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="bootom-info-banner mt-4" data-aos="fade-up">
                  <h2 className="head-title size-text-color-gray text-white">معلومات إضافية</h2>
                  <div className="all-info-bottom flex-wrap style-card-details d-flex align-items-center gap-3">
                    {paymentMethod != null && (
                      <>
                        {(paymentMethod === "online" || paymentMethod === "both") && (
                          <div className="info-one d-flex align-items-center gap-2">
                            <div className="icon">
                              <img src={icon1} alt="icon" width={"31px"} height={"31px"} />
                            </div>
                            <p className="text-info-1 size-text-color-gray text-white">
                              اقبل الدفع اون لاين
                            </p>
                          </div>
                        )}

                        {(paymentMethod === "cash" || paymentMethod === "both") && (
                          <div className="info-one d-flex align-items-center gap-2">
                            <div className="icon">
                              <img src={icon2} alt="icon" width={"31px"} height={"31px"} />
                            </div>
                            <p className="text-info-1 size-text-color-gray text-white">
                              اقبل الدفع كاش
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-5">
              <div className="image-banner-1">
                <BranchMap lat={currentBranch?.lat} long={currentBranch?.long} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BranchWorkingDays);
