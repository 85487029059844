import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ModalRescheduleReservation = ({ showModalResch, hideModalResch, setSettings }) => {
  const vendorSettings = useSelector((state) => state.vendor.vendorSettings);
  const [rescheduleReservation, setRescheduleReservation] = useState(null);
  const [isSubmitAllowed, setIsSubmitAllowed] = useState(false);

  useEffect(() => {
    if (Object.keys(vendorSettings).length > 0) {
      const { reschedule_booking } = vendorSettings;
      console.log(reschedule_booking);
      setRescheduleReservation(reschedule_booking);
    }
  }, [vendorSettings]);

  useEffect(() => {
    if (rescheduleReservation) {
      setIsSubmitAllowed(true);
    } else {
      setIsSubmitAllowed(false);
    }
  }, [rescheduleReservation]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSettings = {
      ...vendorSettings,
      reschedule_booking: rescheduleReservation,
    };
    setSettings(newSettings);
    hideModalResch();
  };

  return (
    <CustomModal
      show={showModalResch}
      onHide={hideModalResch}
      title={"اعادة جدولة الحجز"}
      newClass={"modal-booking-diff"}
    >
      <div className="form-modal">
        <form action="">
          <div className="form-input-one mb-3">
            <label htmlFor="exampleInput03" className="form-label">
              الحد الأدنى للأعادة الجدولة قبل موعد الحجز
            </label>
            <select className="form-select" aria-label="Default select example">
              <option value="1">نعم</option>
              <option value="0">لا</option>
            </select>
          </div>

          <button onClick={handleSubmit} className="main-btn w-100 mt-4" type={"submit"}>
            حفظ
          </button>
        </form>
      </div>
    </CustomModal>
  );
};

export default ModalRescheduleReservation;
