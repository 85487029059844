import SectionTitle from "Components/SectionTitle/SectionTitle";
import { Accordion } from "react-bootstrap";
import "./FaqLanding.css";

const FaqLanding = () => {
  const faq = [
    {
      id: 0,
      title: "إدارة خدمات الصالون؟",
      text: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.",
    },
    {
      id: 1,
      title: "إدارة العملاء؟",
      text: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.",
    },
    {
      id: 2,
      title: "إدارة الموظفين؟",
      text: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.",
    },
    {
      id: 3,
      title: "الإشعارات والتذكيرات؟",
      text: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.",
    },
    {
      id: 4,
      title: "استقبال الحجز الإلكتروني؟",
      text: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.",
    },
  ];
  return (
    <div className="faq-landing">
      {/* ========== START CONTAINER =========== */}
      <div className="container">
        {/* ======== START TITLE SECTION ======= */}
        <div className="section-title-landing" data-aos="fade-right">
          <SectionTitle
            title={"اهم الأسئلة"}
            text={
              "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها."
            }
            dataAos={false}
          />
        </div>
        {/* ======== END TITLE SECTION ======= */}
        {/* ======== START ALL FAQ LANDING ========= */}
        <div className="all-faq-landing" data-aos="fade-up">
          <Accordion>
            {faq.map((item, index) => {
              return (
                <div key={index}>
                  <Accordion.Item eventKey={`${item.id}`}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>{item.text}</Accordion.Body>
                  </Accordion.Item>
                </div>
              );
            })}
          </Accordion>
        </div>
        {/* ======== END ALL FAQ LANDING ========= */}
      </div>
      {/* ========== END CONTAINER =========== */}
    </div>
  );
};

export default FaqLanding;
