import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getPackage } from "store/admin/adminActions";
import iconImg from "../../../assets/images/dashboardImages/icons/providerSubscription.svg";

const PackageInfo = () => {
  const isLoading = useSelector((state) => state.admin.isLoading);
  const [packageInfo, setPackageInfo] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchPackage = () => {
    dispatch(getPackage(id))
      .then(unwrapResult)
      .then((res) => {
        setPackageInfo(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (id) {
      fetchPackage();
    }
  }, [id]);

  return (
    <>
      <HelmetInfo titlePage={"تفاصيل الباقة"} />
      <div className=" d-flex  justify-content-between  align-items-center flex-wrap">
        <div />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/packages">
            عودة
          </Link>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="provider-subscription mt-4" data-aos="fade-up">
          <div
            className="card-provider-subscription"
            style={{
              transition: "all 0.3s ease",
              cursor: "pointer",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: "10px 10px 10px rgba(0,0,0,0.1)",
              },
            }}>
            <div className="header-card-provider d-flex  justify-content-between align-items-center flex-wrap  gap-2">
              <div className="left-card d-flex  align-items-center flex-wrap   gap-3">
                <div className="icon">
                  <img src={iconImg} alt="icon" width={"60px"} height={"60px"} />
                </div>
                <div className="info-content-head d-flex  flex-column gap-2">
                  <h2 className="title">{packageInfo?.name_ar}</h2>
                  <p className="text">{packageInfo?.description_ar}</p>
                </div>
              </div>
            </div>

            <div className="main-card-provider mt-4 d-flex flex-wrap gap-5">
              <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
                <div className="data-info d-flex  align-items-center  gap-3">
                  <h2 className="title"> سعر الاشتراك اليومي</h2>
                  <p className="text">{packageInfo?.days_price} ر.س</p>
                </div>

                <div className="data-info d-flex  align-items-center  gap-3">
                  <h2 className="title"> سعر الاشتراك الربع سنوي</h2>
                  <p className="text">{packageInfo?.quarter_price} ر.س</p>
                </div>
              </div>

              <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
                <div className="data-info d-flex  align-items-center  gap-3">
                  <h2 className="title"> سعر الاشتراك الشهري</h2>
                  <p className="text">{packageInfo?.month_price} ر.س</p>
                </div>
                <div className="data-info d-flex  align-items-center  gap-3">
                  <h2 className="title"> سعر الاشتراك النصف سنوي</h2>
                  <p className="text">{packageInfo?.half_year_price} ر.س</p>
                </div>
              </div>
              <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
                <div className="data-info d-flex  align-items-center  gap-3">
                  <h2 className="title"> سعر الاشتراك سنوي</h2>
                  <p className="text">{packageInfo?.year_price} ر.س</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PackageInfo;
