import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getClients } from "../../../../store/admin/adminActions";

const AdminReportClients = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [totalClientsCount, setTotalClientsCount] = useState(0);

  const fetchClientsReport = useCallback(() => {
    setIsLoading(true);
    const params = {
      per_page: 100,
      limit: 100,
      sort: "DESC",
      paginate: "true",
    };
    dispatch(getClients(params))
      .then(unwrapResult)
      .then((res) => {
        setClients(res.data.data);
        setTotalClientsCount(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchClientsReport();
  }, [fetchClientsReport]);

  if (isLoading) return <Loader />;
  if (!clients.length) return null;

  // Sort clients by bookings count
  const sortedClients = [...clients].sort((a, b) => b.bookings_count - a.bookings_count);

  return (
    <div>
      <div className="report-summary">
        <div className="summary-card card-shadow card-hover">
          <div className="card-content">
            <h3>{totalClientsCount}</h3>
            <p>عدد العملاء</p>
          </div>
          <FontAwesomeIcon icon={faUsers} className="icon" />
        </div>
      </div>

      <div className="report-table" data-aos="fade-up">
        <h3 className="mb-4">العملاء</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>الاسم</th>
              <th>البريد الإلكتروني</th>
              <th>رقم الهاتف</th>
              <th>تاريخ الميلاد</th>
              <th>عدد الحجوزات</th>
              <th>عدد التقييمات</th>
              <th>آخر تسجيل دخول</th>
              <th>تاريخ التسجيل</th>
              <th>الحالة</th>
            </tr>
          </thead>
          <tbody>
            {sortedClients.map((client, index) => (
              <tr key={client.id}>
                <td>{index + 1}</td>
                <td>{client.name}</td>
                <td>{client.email}</td>
                <td>{client.phone}</td>
                <td>{client.birthdate || "-"}</td>
                <td>{client.bookings_count}</td>
                <td>{client.reviews_count}</td>
                <td>{client.last_login || "-"}</td>
                <td>{new Date(client.created_at).toLocaleDateString()}</td>
                <td>{client.active === 1 ? "نشط" : "غير نشط"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReportClients;
