import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import PageTilte from "Components/PageTilte/PageTilte";
import contactImg from "../../assets/images/contact/01.png";
import "./Contact.css";
import FormContact from "./FormContact";
const Contact = () => {
  return (
    <>
      <HelmetInfo titlePage={"تواصل معنا"} />
      <div className="contact padding-bottom-70">
        <PageTilte titlePage={"تواصل معنا"} />
        <div className="container">
          <div className="all-contact">
            <div className="row g-3 flex-wrap-reverse ">
              <div className="col-12  col-md-6 col-lg-7">
                <FormContact />
              </div>
              <div className="col-12 col-md-6 col-lg-5">
                <div className="img-contact" data-aos="fade-down">
                  <img src={contactImg} alt="img contact" className="w-100 h-100 object-fit-cover image-anim" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
