import { useField } from "formik";

const InputField = ({ label = "", success, disabled = false, handleOnChange, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const isError = meta.touched && meta.error;
  const isSuccess = success && meta.touched && !meta.error;

  const handleChange = (e) => {
    const { value } = e.target;
    if (handleOnChange) handleOnChange(value);
    helpers.setValue(value);
  };

  return (
    <div
      className={`form-group input-field-info d-flex flex-column gap-1 position-relative form-one ${
        isError ? "has-error" : ""
      }`}>
      {label && (
        <label htmlFor={props.id || props.name} className="form-label">
          {label}
        </label>
      )}
      <input
        {...field}
        {...props}
        value={field.value || ""}
        onChange={handleChange}
        style={{ borderColor: isError ? "#dc3545" : isSuccess ? "green" : "" }}
        className={`input-field form-control ${isError ? "is-invalid" : ""}`}
        autoComplete="off"
        disabled={disabled}
      />
      {isError && <div className="error">{meta.error}</div>}
    </div>
  );
};

export default InputField;
