import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";

const SelectCountryInput = () => {
  //   SELECT COUNTRY
  const [selected, setSelected] = useState("");
  return (
    <>
      <div className="select-country mt-3">
        <label htmlFor="exampleFormControlInputDate" className="form-label">
          الدولة
        </label>
        <ReactFlagsSelect
          selected={selected}
          onSelect={(code) => setSelected(code)}
          className="menu-flags"
          placeholder="اختار دولتك"
        />
      </div>
    </>
  );
};

export default SelectCountryInput;
