import imgBg from "../../../assets/images/bg/bg-bg.png"
import "./BgBody.css"
const BgBody = () => {
  return (
    <div className="bg-body">
    <img src={imgBg} alt="img" className="w-100 h-100 object-fit-cover" />
    </div>
  )
}

export default BgBody