import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "Apis/axiosService";

export const login = createAsyncThunk("login", async (body, { rejectWithValue }) => {
  try {
    const response = await axiosClient.post("/api/v1/login", {
      username: body.username,
      password: body.password,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const changePassword = createAsyncThunk(
  "changePassword",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/api/v1/change-password", { ...body });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const register = createAsyncThunk("register", async (body, { rejectWithValue }) => {
  try {
    return await axiosClient.post("/api/v1/register", { ...body });
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateProfile = createAsyncThunk(
  "updateProfile",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/api/v1/update-profile", { ...body });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const sendOtpCode = createAsyncThunk("sendOtp", async (value, { rejectWithValue }) => {
  try {
    return await axiosClient.post("/api/v1/send-code", {
      username: value,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const customerLogIn = createAsyncThunk(
  "customer/login",
  async (credentials, { rejectWithValue }) => {
    const { username, password } = credentials;
    try {
      const response = await axiosClient.post("/customer/v1/login", {
        username,
        password,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const registerCustomer = createAsyncThunk(
  "customer/register",
  async (body, { rejectWithValue }) => {
    try {
      return await axiosClient.post("/customer/v1/register", { ...body });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const logoutAction = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");
  localStorage.setItem("last_logout", new Date().toISOString());
});
