import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalFilter from "Components/Ui/ModalFilter/ModalFilter";
import { useState } from "react";
import CardsFilter from "./CardsFilter";

const FilterContent = ({ servicesProviders, setSearchText }) => {
  const [showModalFilter, setShowModalFilter] = useState(false);
  const hideModelFilter = () => setShowModalFilter(false);
  const handleShowModal = () => setShowModalFilter(true);

  return (
    <>
      <ModalFilter showModalFilter={showModalFilter} hideModelFilter={hideModelFilter} setSearchText={setSearchText} />

      <div className="filter-content">
        <div className="header-filter d-flex  align-items-center  gap-3 flex-wrap  justify-content-between">
          <h2 className="num-results-filter">{servicesProviders.length} نتيجة</h2>
          <div className="button-modal-filter main-btn main-outline-btn gap-2" onClick={handleShowModal}>
            <FontAwesomeIcon icon={faSliders} />
            فلترة النتائج
          </div>
        </div>

        <div className="all-cards-filter mt-4">
          <CardsFilter servicesProviders={servicesProviders} />
        </div>
      </div>
    </>
  );
};

export default FilterContent;
