import ContentHeader from "./ContentHeader";
import iconEmployee from "../../../assets/images/dashboardImages/icons/profile-add.svg";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddNewEmployee = () => {
  // THIS CODE FOR ADD NEW EMPLOYEE
  const [contents, setContents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const addContent = () => {
    setIsLoading(true);
    setTimeout(() => {
      const newContent = {
        id: contents.length + 1,
        title: `اضافة الموظف ${contents.length + 1}`,
        inputs: ["", "", "", "", "", ""]
      };
      setContents([...contents, newContent]);
      setIsLoading(false);
    }, 800);
    setTimeout(() => {
      toast.success("تم اضافة بنجاح");
    }, 900);
  };

  const removeContent = (id) => {
    setContents((prevContents) =>
      prevContents.map((content) =>
        content.id === id
          ? { ...content, isLoadingRemove: true } // Set loading state to true for the clicked content item
          : content
      )
    );

    // ِadd loader And frome after 1 sec
    setTimeout(() => {
      setContents((prevContents) =>
        prevContents.filter((content) => content.id !== id)
      );
    }, 1000);
    // after remove add toast to success remove
    setTimeout(() => {
      toast.success("تم الحذف بنجاح");
    }, 1100);
  };

  const handleInputChange = (index, inputIndex, value) => {
    const updatedContents = [...contents];
    updatedContents[index].inputs[inputIndex] = value;
    setContents(updatedContents);
  };

  //   THIS CODE TO ACTIVE ACCOUNT
  const navigate = useNavigate();
  const [activeAccount, setActiveAccount] = useState(false);
  const activeAccountButton = () => {
    setActiveAccount(true);
    setTimeout(() => {
      setActiveAccount(false);
      navigate("/completeaccountactivation/packagesoffers");
    }, 1000);
  };

  return (
    <ContentHeader>
      <div className="all-data-com all-add-employee bg-step">
        {contents.length === 0 && (
          <div className="add-new-employee d-flex  flex-column  justify-content-center  align-items-center gap-3">
            <div className="icon-employee">
              <img
                src={iconEmployee}
                alt="icon employee"
                width={"45px"}
                height={"45px"}
              />
            </div>
            <button
              onClick={addContent}
              className="button-add-new-employee main-btn"
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? (
                <>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              ) : (
                "اضافة موظف"
              )}
            </button>
          </div>
        )}
        <div className="px-3 px-sm-5">
          {contents.map((content, index) => (
            <div key={content.id} className="mb-4">
              <div className="d-flex align-items-center  gap-3 mb-3">
                <button
                  className="btn btn-danger"
                  onClick={() => removeContent(content.id)}
                  disabled={content.isLoadingRemove}
                >
                  {content.isLoadingRemove ? (
                    <>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </>
                  ) : (
                    <span>
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  )}
                </button>
                <h2 className="title-head">{content.title}</h2>
              </div>

              <div className="row g-3">
                {content.inputs.map((inputValue, inputIndex) => (
                  <div className="col-12 col-md-4" key={inputIndex}>
                    <input
                      type={
                        inputIndex === 0 || inputIndex === 1
                          ? "text"
                          : inputIndex === 2
                          ? "number"
                          : inputIndex === 3
                          ? "number"
                          : inputIndex === 4
                          ? "email"
                          : "time"
                      }
                      value={inputValue}
                      onChange={(e) =>
                        handleInputChange(index, inputIndex, e.target.value)
                      }
                      placeholder={
                        inputIndex === 0
                          ? "الأسم الأول"
                          : inputIndex === 1
                          ? "الأسم الأخير"
                          : inputIndex === 2
                          ? "الراتب"
                          : inputIndex === 3
                          ? "رقم الجوال"
                          : inputIndex === 4
                          ? "البريد الألكتروني"
                          : "ساعات العمل"
                      }
                      className="form-control"
                    />
                  </div>
                ))}
              </div>

              {index === contents.length - 1 && (
                <button
                  className="button-add-new-employee main-btn mt-3 main-outline-btn"
                  onClick={addContent}
                >
                  {isLoading ? (
                    <>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </>
                  ) : (
                    <span>
                      {" "}
                      <FontAwesomeIcon icon={faPlus} /> اضافة موظف
                    </span>
                  )}
                </button>
              )}
            </div>
          ))}
          {contents.length >= 1 && (
            <button onClick={activeAccountButton} className="main-btn px-5">
              {activeAccount ? (
                <>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              ) : (
                "تفعيل الحساب"
              )}
            </button>
          )}
        </div>
      </div>
    </ContentHeader>
  );
};

export default AddNewEmployee;
