import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { Link } from "react-router-dom";
import iconSuccess from "../../../../../assets/images/animation/Success.gif";
import logo from "../../../../../assets/images/logo/logo.svg";
import "./PageSuccessError.css";

const SuccessPay = ({ bookingId }) => {
  return (
    <>
      <HelmetInfo titlePage={"تم الحجز بنجاح"} />
      <div className="logo-header pt-3">
        <div className="container">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </div>

      <div className="all-page-success-error d-flex justify-content-center align-items-center">
        <div className="content-info">
          <div>
            <div className="icon-sucess">
              <img src={iconSuccess} alt="icon" width={"200px"} height={"200px"} className=" object-fit-cover " />
            </div>
            <h2 className="title mt-5 text-center">تم الحجز بنجاح</h2>
          </div>

          <Link to="/" className="main-btn mt-5">
            الصفحة الرئيسية
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default SuccessPay;
