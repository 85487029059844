import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarReg } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import imgUser from "../../../assets/images/userImg/user.png";
import CardRate from "./CardRate";
import CustomModal from "Components/CustomModal/CustomModal";
import { toast } from "react-toastify";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";

const ShowRates = () => {
  const infoBasic = [
    { id: 0, title: "رقم الحجز", text: "58795" },
    { id: 1, title: "اسم العميل", text: "محمد محمود" },
    { id: 2, title: "تاريخ التقييم", text: "15/10/2023" }
  ];

  //   SHOW MODAL COMPLAINT
  const [isShowModal, setShowModal] = useState(false);
  const showModalComplaint = () => {
    setShowModal(true);
  };
  //   HIDE MODAL
  const hideModalComplaint = () => {
    setShowModal(false);
  };

  //   TOAST SUCCESS

  const [value, setValue] = useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const successSend = (e) => {
    e.preventDefault();
    if (value.trim() === "") {
      toast.error("برجاء ادخال الشكوى.");
    } else {
      hideModalComplaint();
      toast.success("تم ارسال الشكوى وجارى المراجعة .");
    }
  };

  return (
    <>
    <HelmetInfo titlePage={"عرض التقيمات"} />
      {/* =========== START HEADER INFO TOP ============ */}
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/homedashboard"}
          titleInfoPage={"عرض التقيمات"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/ratespage"
          >
            عودة
          </Link>
        </div>
      </div>
      {/* =========== END HEADER INFO TOP ============ */}
     <div data-aos="fade-up">
       {/* =========== START CONTENT INFO ============ */}
       <div className="basic-information-content account-info-details mt-3">
         {/* =========== START ALL BASIC INFO ============= */}
         <div className="all-basic-info all-show-rates">
           {/* ======== START HEADER INFO ========== */}
           <div className="header-info-profile ">
             <div className="content-user-info">
               {infoBasic.map((item) => {
                 return (
                   <div className="all-info-content  d-flex" key={item.id}>
                     {/* ========= START MAIN INFO ONE ========= */}
                     <div className="main-info-one d-flex  align-items-center">
                       <h2 className="title">{item.title}</h2>
                     </div>
                     {/* ========= END MAIN INFO ONE ========= */}
                     {/* ========= START MAIN INFO ONE ========= */}
                     <div className="main-info-one d-flex  align-items-center">
                       <p className="text">{item.text}</p>
                     </div>
                     {/* ========= END MAIN INFO ONE ========= */}
                   </div>
                 );
               })}
             </div>
           </div>
           {/* ======== END HEADER INFO ========== */}
         </div>
         {/* =========== END ALL BASIC INFO ============= */}
       </div>
       {/* =========== END CONTENT INFO ============ */}
       {/* =========== START TABS RATE ============ */}
       <div className="tabs-rate mt-4">
         {/* ============ START TABS CONTENT ========== */}
         <Tabs
           defaultActiveKey="rateStore"
           id="uncontrolled-tab-example"
           className="tabs-rates-content"
         >
           {/* ============ START TAB ONE ============ */}
           <Tab
             className="tab-one"
             eventKey="rateStore"
             title="التقيمات للصالون"
           >
             {/* START RATE CARD USER ONE  */}
             <CardRate
               imgUser={imgUser}
               nameUser={"اسم العميل"}
               rateStar={
                 <>
                   <FontAwesomeIcon icon={faStar} />
                   <FontAwesomeIcon icon={faStar} />
                   <FontAwesomeIcon icon={faStar} />
                   <FontAwesomeIcon icon={faStar} />
                   <FontAwesomeIcon icon={faStarReg} />
                 </>
               }
               numRates={"(4.5)"}
               timeRate={"16 Oct 2023 8:27pm"}
               isHaveContentComment={true}
               contentRate={
                 "   لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في     إدانة السرور ومدح الألم ، وسأقدم لك وصفًا كاملاً للنظام ، وأشرح    التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع.        السعادة البشرية. لا أحد يرفض أو يكره أو يتجنب المتعة نفسها ، لأنها متعة ، ولكن لأن أولئك الذين لا يعرفون كيفية السعي وراء المتعة يواجهون عواقب مؤلمة للغاية. "
               }
             />
             {/* END RATE CARD USER ONE  */}
           </Tab>
           {/* ============ END TAB ONE ============ */}
           {/* ============ START TAB ONE ============ */}
           <Tab
             className="tab-one"
             eventKey="ratesEmployee"
             title="التقيمات للموظف"
           >
             {/* START RATE CARD USER ONE  */}
             <CardRate
               imgUser={imgUser}
               nameUser={"اسم العميل"}
               rateStar={
                 <>
                   <FontAwesomeIcon icon={faStar} />
                   <FontAwesomeIcon icon={faStar} />
                   <FontAwesomeIcon icon={faStar} />
                   <FontAwesomeIcon icon={faStar} />
                   <FontAwesomeIcon icon={faStarReg} />
                 </>
               }
               numRates={"(4.5)"}
               timeRate={"16 Oct 2023 8:27pm"}
               isHaveContentComment={false}
               contentRate={false}
             />
             {/* END RATE CARD USER ONE  */}
           </Tab>
           {/* ============ END TAB ONE ============ */}
         </Tabs>
         {/* ============ END TABS CONTENT ========== */}
       </div>
       {/* =========== END TABS RATE ============ */}
       <div className="bottom-button mt-3">
         <button onClick={showModalComplaint} className="main-btn px-5">
           تقديم شكوي
         </button>
       </div>
     </div>

      <CustomModal
        show={isShowModal}
        onHide={hideModalComplaint}
        title={"تقديم شكوى"}
        newClass={"modal-complaint"}
      >
        <form>
          <div className="mb-3">
            <label htmlFor="reasonComplaint" className="form-label">
              السبب
            </label>
            <textarea
              className="form-control"
              id="reasonComplaint"
              rows={5}
              placeholder="اكتب السبب...."
              value={value}
              onChange={handleChange}
            ></textarea>
          </div>
          <button onClick={successSend} className="main-btn w-100 mt-3">
            ارسال
          </button>
        </form>
      </CustomModal>
    </>
  );
};

export default ShowRates;
