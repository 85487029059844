import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getEmployees as getEmployeesVendor,
  updateEmployee as updateEmployeeVendor,
} from "store/vendor/vendorActions";
import "./Employees.css";

const Employees = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]); // employees list
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  const [requestParams, setRequestParams] = useState({
    with: ["user", "vendor", "bookings", "branches", "services", "breakHours", "officialHours"],
    limit: 10,
    offset: 1,
    sort: "DESC",
    paginate: "true",
    field: "updated_at",
    deleted: "undeleted",
    resource: "all",
    resource_columns: ["id", "name_ar"],
  });

  const fetchEmployees = useCallback(() => {
    const params = {
      ...requestParams,
      columns: ["is_active", "vendor_id", "user.first_name"],
      operand: ["=", "=", "like"],
      column_values: ["1", user?.model_id, searchText ? `%${searchText}%` : ""],
    };

    setIsLoading(true);
    dispatch(getEmployeesVendor(params))
      .then(unwrapResult)
      .then((res) => {
        setData(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
        toast.error("Failed to fetch employees");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, requestParams, searchText, user?.model_id]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchEmployees();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText, fetchEmployees]);

  const toggleActivation = (newValue, userId, index) => {
    setIsLoading(true);
    dispatch(updateEmployeeVendor({ ...data[index], is_active: newValue ? "1" : "0" }))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم التعديل بنجاح.");
      })
      .catch((error) => {
        console.error("Error updating employee:", error);
        toast.error("Failed to update employee");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearchChange = (value) => {
    setSearchText(value);
    setRequestParams((prev) => ({
      ...prev,
      offset: 1,
    }));
  };

  return (
    <>
      <HelmetInfo titlePage={"طاقم العمل"} />
      <div className="main-header-employee" data-aos="fade-down">
        <HeaderPageInfo
          title={"طاقم العمل"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={true}
              typeIcon={faPlus}
              textButton={"اضافة موظف جديد"}
              functionButton={() => navigate("add")}
              onSearchChange={handleSearchChange}
            />
          }
        />
      </div>
      {isLoading ? (
        <div className="loader-content">
          <Loader />
        </div>
      ) : (
        <>
          <div className="table-content mt-3 table-content-2" data-aos="fade-up">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">الاسم بالكامل</th>
                  <th scope="col">الفرع</th>
                  <th scope="col">عدد الحجوزات</th>
                  <th scope="col">تفعيل/الغاء</th>
                  <th scope="col">الاجراءات</th>
                </>
              }
              dataTbody={
                <>
                  {data.map((empUser, index) => (
                    <tr key={empUser.id}>
                      <td>{empUser.user?.first_name}</td>
                      <td>{empUser.branches[0]?.name || "-"}</td>
                      <td>{empUser.bookings?.length || 0}</td>
                      <td>
                        <Toggleswitch
                          switchId={empUser.id}
                          nameSwitch={`question-${empUser.id}`}
                          value={empUser.is_active === "1"}
                          onChange={(newValue) => toggleActivation(newValue, empUser.id, index)}
                        />
                      </td>
                      <td>
                        <IconsTable
                          typeIcon1={faEye}
                          functionEye={() => navigate(`${empUser.id}`)}
                          typeIcon2={faEdit}
                          functionEdit={() => navigate(`edit/${empUser.id}`)}
                          // isDeleteIcon={true}
                          // functionDelete={() => deleteRow(empUser.id, index)}
                          // typeIcon3={faTrash}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              }
            />
          </div>
          <Pagination
            limit={requestParams.limit}
            setLimit={(limit) => setRequestParams((prev) => ({ ...prev, limit, offset: 1 }))}
            currentPage={requestParams.offset}
            setCurrentPage={(page) => setRequestParams((prev) => ({ ...prev, offset: page }))}
            limitOptions={[5, 10, 20, 30, 40]}
            currentPageItemsCount={currentPageItemCounts}
            totalItemsCount={totalItemsCount}
            totalPagesCount={totalPagesCount}
          />
        </>
      )}
    </>
  );
};

export default Employees;
