import { Link } from "react-router-dom";
import "./HeaderPageTitle.css";

const HeaderPageTitle = ({ isActiveLinkTwo, routeHomePage, routePage, titlePage, titleInfoPage }) => {
  return (
    <header>
      <div className="all-header-page-title" data-aos="fade-left">
        <div className="container">
          <div className="main-header-page-title d-flex  align-items-center  gap-2">
            <Link to={routeHomePage} className="link-home-page title-text">
              الرئيسية
            </Link>

            {isActiveLinkTwo && (
              <div className="link-two d-flex  align-items-center gap-2 ">
                <span className="dot-page"></span>

                <Link to={routePage} className="link-page-info title-text">
                  {titlePage}
                </Link>
              </div>
            )}

            <span className="dot-page"></span>
            <p className="text title-text">{titleInfoPage}</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderPageTitle;
