import React from 'react'

const FooterForm = () => {
  return (
    <footer>
    <div className="footer-content">
      <div className="container">
        <div className="all-main-footer-content">
          <h2 className="text-footer">
            جميع الحقوق محفوظة لمنصة ستريم لاين
          </h2>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default FooterForm
