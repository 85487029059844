import { faFacebookF, faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FooterSocialIcons.css";
const FooterSocialIcons = () => {
  return (
    <>
      <div className="follow-news">
        <ul className="list-social p-0 m-0 d-flex align-items-center justify-content-center gap-3">
          <li className="list-one">
            <a href="#" target="_blank" className="icon-social" rel="noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>

          <li className="list-one">
            <a href="#" target="_blank" className="icon-social" rel="noreferrer">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
          </li>
          <li className="list-one">
            <a href="#" target="_blank" className="icon-social" rel="noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default FooterSocialIcons;
