import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import AddDateTime from "Dashboard/Components/UiDashboard/AddDateTime/AddDateTime";

import BranchMap from "Dashboard/Components/maps/BranchMap"; // Import the new component
import { getCities, getCountries, getRegions } from "store/general/generalActions";
import { setCities, setCountries, setRegions } from "store/general/generalSlice";
import { addBranch, getBranch, updateBranch } from "store/vendor/vendorActions";
import { convertTimeToDate, formatDateToTime24 } from "utlis";

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required("ادخل اسم الفرع"),
  country_id: Yup.string().required("اختار الدولة"),
  city_id: Yup.string().required("اختار المدينة"),
  region_id: Yup.string().required("اختار المنطقة"),
  address: Yup.string().required("ادخل العنوان "),
  show_rates: Yup.string().required("تقييم الموظفين"),
  officialHours: Yup.array().of(
    Yup.object().shape({
      start_time: Yup.mixed().nullable().required("هذا الحقل مطلوب"),
      end_time: Yup.mixed().nullable().required("هذا الحقل مطلوب"),
      day: Yup.mixed().nullable().required("هذا الحقل مطلوب"),
    }),
  ),
});

const BranchEditAdd = () => {
  const { id } = useParams();
  const DEFAULT_COORDINATES = {
    lat: "21.553435878238922",
    long: "39.18274357399476",
  };

  const initialFormValues = {
    name: "",
    country_id: "",
    city_id: "",
    region_id: "",
    address: "",
    show_rates: "",
    officialHours: [{ day: null, end_time: null, start_time: null }],
    image: "",
    images: [],
    lat: DEFAULT_COORDINATES.lat,
    long: DEFAULT_COORDINATES.long,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { cities, regions, countries } = useSelector((state) => state.general);

  const [formValues, setFormValues] = useState(initialFormValues);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [imageUploaded, setImageUploaded] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: name.includes("date")
        ? new Date(value).toISOString().split("T")[0]
        : name === "service_id"
          ? [value]
          : value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUploaded(URL.createObjectURL(file));
      setFormValues((prev) => ({
        ...prev,
        image: file,
      }));
    }
  };

  const handleSubmit = useCallback(
    async (values) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      const formData = new FormData();
      const image = formValues.image;
      const updatedValues = {
        ...values,
        manager_id: user.id,
        lat: formValues.lat || "21.553435878238922",
        long: formValues.long || "39.18274357399476",
        image: image,
      };

      Object.entries(updatedValues).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (typeof item === "object") {
              Object.entries(item).forEach(([subKey, subValue]) => {
                if (subKey.includes("time")) {
                  const valueInFormat = formatDateToTime24(subValue);
                  formData.append(`${key}[${index}][${subKey}]`, valueInFormat);
                } else {
                  formData.append(`${key}[${index}][${subKey}]`, subValue);
                }
              });
            } else {
              formData.append(`${key}[${index}]`, item);
            }
          });
        } else {
          formData.append(key, value);
        }
      });
      const action = id ? updateBranch({ id, formDataObject: formData }) : addBranch(formData);

      dispatch(action)
        .then(unwrapResult)
        .then(() => {
          toast.success(id ? "تم التعديل بنجاح." : "تم الاضافه بنجاح.");
          navigate("/dashboard/vendor/branches");
        })
        .catch((error) => {
          if (error.message === "You Do not have active subscription ") {
            toast.error("لا يوجد باقة نشطة");
          } else {
            Object.values(error.errors || {})
              .flat()
              .forEach(toast.error);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [dispatch, id, navigate, formValues, imageUploaded, isSubmitting],
  );

  const fetchBranchData = useCallback(() => {
    if (!id) {
      setIsDataLoading(false);
      return;
    }

    setIsDataLoading(true);
    dispatch(getBranch(id))
      .then(unwrapResult)
      .then((res) => {
        const { data } = res;
        setFormValues({
          id,
          name: data.name,
          manager_id: data.manager.id,
          country_id: data.country_id,
          city_id: data.city_id,
          region_id: data.region_id,
          address: data.address,
          show_rates: data.show_rates,
          officialHours:
            data.official_hours.length > 0
              ? data.official_hours.map((item) => ({
                  start_time: convertTimeToDate(item.start_time),
                  end_time: convertTimeToDate(item.end_time),
                  day: item.day,
                }))
              : [{ start_time: null, end_time: null, day: null }],
          image: data.photo,
          lat: data.lat ?? "21.553435878238922",
          long: data.long ?? "39.18274357399476",
        });
        setImageUploaded(data.photo);
      })
      .catch((error) => toast.error(error.message))
      .finally(() => setIsDataLoading(false));
  }, [dispatch, id]);

  useEffect(() => {
    fetchBranchData();
  }, [fetchBranchData]);

  const fetchGeneralLocationData = useCallback(() => {
    const fetchData = async () => {
      try {
        if (regions?.length === 0) {
          const regionsRes = await dispatch(getRegions()).unwrap();
          dispatch(setRegions(regionsRes.data.data));
        }
        if (cities?.length === 0) {
          const citiesRes = await dispatch(getCities()).unwrap();
          dispatch(setCities(citiesRes.data.data));
        }
        if (countries?.length === 0) {
          const countriesRes = await dispatch(getCountries()).unwrap();
          dispatch(setCountries(countriesRes.data.data));
        }
      } catch (error) {
        toast.error("Error fetching location data");
      }
    };
    fetchData();
  }, [dispatch, regions?.length, cities?.length, countries?.length]);

  useEffect(() => {
    fetchGeneralLocationData();
  }, [fetchGeneralLocationData]);

  const handleMapClick = (lat, long) => {
    if (!lat || !long) {
      toast.error("Invalid coordinates selected");
      return;
    }

    setFormValues((prev) => ({
      ...prev,
      lat: lat.toString(),
      long: long.toString(),
    }));
  };

  if (isDataLoading || cities?.length === 0 || regions?.length === 0 || countries?.length === 0)
    return <Loader />;

  return (
    <>
      <HelmetInfo titlePage="اضافة / تعديل بيانات فرع" />
      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage="الرئيسية"
          routeHomePage="/dashboard/vendor/homedashboard"
          titleInfoPage="اضافة / تعديل بيانات فرع"
        />
        <Link className="size-text-color-dark main-text-color fs-5" to="/dashboard/vendor/branches">
          عودة
        </Link>
      </div>

      <div className="form-edit-add-branches" data-aos="fade-up">
        <FormField
          initialValues={formValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}>
          <div className="row g-1 g-sm-3">
            <InputField
              label="اسم الفرع"
              name="name"
              type="text"
              placeholder="اسم الفرع"
              success
              handleOnChange={(value) => handleInputChange("name", value)}
            />

            <SelectField
              name="country_id"
              label="الدولة"
              options={countries?.map((item) => ({
                value: item.id,
                label: item.name_ar,
              }))}
              valueSelected="الدولة"
              handleOnChange={(value) => handleInputChange("country_id", value)}
            />
            <SelectField
              name="city_id"
              label="المدينة"
              options={cities?.map((item) => ({
                value: item.id,
                label: item.name_ar,
              }))}
              valueSelected="المدينة"
              handleOnChange={(value) => handleInputChange("city_id", value)}
            />

            <SelectField
              name="region_id"
              label="العنوان الوطني"
              options={regions?.map((item) => ({
                value: item.id,
                label: item.name_ar,
              }))}
              valueSelected="العنوان الوطني"
              handleOnChange={(value) => handleInputChange("region_id", value)}
            />

            <SelectField
              name="show_rates"
              label="اظهار تقيمات الموظفين"
              options={[
                { value: 1, label: "نعم" },
                { value: 0, label: "لا" },
              ]}
              valueSelected="اظهار تقيمات الموظفين"
              handleOnChange={(value) => handleInputChange("show_rates", value)}
            />
            <InputField
              label="عنوان الفرع"
              name="address"
              type="text"
              placeholder="عنوان الفرع"
              success
              handleOnChange={(value) => handleInputChange("address", value)}
            />
            <div className="col-12 col-md-12">
              <div className="main-time-work">
                <label htmlFor="exampleFormControlInputDate_1" className="form-label">
                  ساعات العمل الرسمية
                </label>
                <AddDateTime
                  name="officialHours"
                  handleOnChange={(index, key, value) => {
                    setFormValues((prev) => {
                      const updatedOfficialHours = [...prev.officialHours];
                      updatedOfficialHours[index] = {
                        ...updatedOfficialHours[index],
                        [key]: key.includes("time") ? formatDateToTime24(value) : value,
                      };
                      return { ...prev, officialHours: updatedOfficialHours };
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 mt-4">
              <label htmlFor="exampleFormControlInputDate_1" className="form-label">
                الصورة الرئيسية
              </label>
              <input
                type="file"
                title="تحميل الصورة"
                accept="image/*"
                onChange={handleFileChange}
              />
              {imageUploaded && (
                <img
                  src={imageUploaded}
                  alt="Uploaded main branch image"
                  width="170px"
                  height="120px"
                  style={{ display: "block" }}
                />
              )}
            </div>
            <BranchMap lat={formValues.lat} long={formValues.long} onMapClick={handleMapClick} />
          </div>
          <button type="submit" className="main-btn btn-submit px-5 mt-4" disabled={isSubmitting}>
            {isSubmitting ? "جاري الحفظ..." : "حفظ"}
          </button>
        </FormField>
      </div>
    </>
  );
};

export default BranchEditAdd;
