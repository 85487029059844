import CustomModal from "Components/CustomModal/CustomModal";
import { toast } from "react-toastify";
import "./ModalsBookings.css";

const ModalReservationConfirmation = ({
  showModalReservation,
  hideModelReservation,
  onConfirm,
  title,
  confirmText,
}) => {
  const confirmAction = () => {
    onConfirm();
    hideModelReservation();
  };

  const closeAction = () => {
    hideModelReservation();
    toast.info("تم الإلغاء");
  };

  return (
    <CustomModal show={showModalReservation} onHide={hideModelReservation} title={""} newClass={"modal-reservation"}>
      <div className="all-modal-reservation">
        <h2 className="title">{title}</h2>
        <p className="text">هل أنت متأكد أنك تريد {title.toLowerCase()}؟</p>
        <div className="buttons-main d-flex align-items-center gap-2 mt-3">
          <button onClick={confirmAction} className="main-btn w-100">
            {confirmText}
          </button>
          <button onClick={closeAction} className="main-btn main-outline-btn w-100">
            إلغاء
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ModalReservationConfirmation;
