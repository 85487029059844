import CompleteAccountData from "./CompleteAccountData";
import "./CompleteAccountActivation.css";
import ContentHeader from "./ContentHeader";
const CompleteAccountActivation = () => {
  return (
    <ContentHeader>
      <CompleteAccountData />
    </ContentHeader>
  );
};

export default CompleteAccountActivation;
