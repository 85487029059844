import { useParams } from "react-router-dom";
import AdminReportClients from "./AdminReportClients";
import AdminReportEmployees from "./AdminReportEmployees";
import AdminReportOffers from "./AdminReportOffers";
import AdminReportPackages from "./AdminReportPackages";
import AdminReportServices from "./AdminReportServices";
import AdminReportVendors from "./AdminReportVendors";

const adminReportsTranslation = {
  services: "تقرير الخدمات",
  offers: "تقرير العروض",
  packages: "تقرير الباقات",
  employees: "تقرير الموظفين",
  vendors: "تقرير الموردين",
  clients: "تقرير العملاء",
};

function renderReport(reportId) {
  switch (reportId) {
    case "services-admin":
      return <AdminReportServices />;
    case "offers-admin":
      return <AdminReportOffers />;
    case "packages-admin":
      return <AdminReportPackages />;
    case "employees-admin":
      return <AdminReportEmployees />;
    case "vendors-admin":
      return <AdminReportVendors />;
    case "clients-admin":
      return <AdminReportClients />;
    default:
      return null;
  }
}

const AdminReportDetails = () => {
  const { reportId } = useParams();
  return (
    <div className="report-details p-3">
      <div className="report-header">
        <div className="report-title mb-5">{adminReportsTranslation[reportId]}</div>
      </div>
      {renderReport(reportId)}
    </div>
  );
};

export default AdminReportDetails;
