import CustomModal from "Components/CustomModal/CustomModal";
import { useState } from "react";

const AddRoleModal = ({ isOpen, onClose, onSave }) => {
  const [newRoleName, setNewRoleName] = useState("");

  const handleSave = () => {
    onSave({ name: newRoleName });
    onClose();
  };

  return (
    <>
      <CustomModal
        show={isOpen}
        onHide={onClose}
        title={"إضافة دور جديد"}
        newClass={"modal-new-role"}>
        <div className="modal-body">
          <div className="mb-3">
            <label htmlFor="newRoleName" className="form-label">
              اسم الدور
            </label>
            <input
              type="text"
              className="form-control"
              id="newRoleName"
              value={newRoleName}
              onChange={(e) => setNewRoleName(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="main-btn main-outline-btn" onClick={onClose}>
            إلغاء
          </button>
          <button
            type="button"
            className="main-btn"
            onClick={handleSave}
            disabled={!newRoleName || !newRoleName.trim()}>
            حفظ
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default AddRoleModal;
