import LogoFooter from "Components/LogoFooter/LogoFooter";
import FooterSocialIcons from "Components/Ui/FooterSocialIcons/FooterSocialIcons";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="row g-4 g-lg-3" data-aos="fade-up">
            <div className="col-12 col-md-5 col-lg-5">
              <div className="logo">
                <a href="/">
                  <LogoFooter />
                </a>
              </div>
              <p className="text">
                هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض
                النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن
                ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم
                إيبسوم على الإنترنت
              </p>
            </div>

            <div className="col-12 col-md-3 col-lg-2 offset-md-1">
              <div className="footer-one">
                <h2 className="title"> الشركة</h2>
                <ul className="list-link  p-0 m-0">
                  <li className="nav-item">
                    <Link to="about" className="nav-link">
                      من نحن
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="contact" className="nav-link">
                      تواصل معنا
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="commonquestions" className="nav-link">
                      الأسئلة الشائعة
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="termsconditions" className="nav-link">
                      الشروط والأحكام
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="privacy" className="nav-link">
                      الخصوصية
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-md-3 col-lg-2 offset-lg-1 d-flex justify-content-center align-items-center">
              <div className="footer-one">
                <h2 className="title">تواصل معنا</h2>
                <FooterSocialIcons />
              </div>
            </div>
          </div>
          <div className="final-footer d-flex justify-content-center text-center align-items-center">
            <p className="text-footer">stremline ©2024</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
