import CounterContent from "Components/Ui/CounterContent/CounterContent";
import iconStar from "../../../assets/images/icons/star.svg";
import "./CounterHome.css";
const CounterHome = () => {
  return (
    <div className="counter-home padding-bottom-50">
      <div className="container">
        <div className="all-counter">
          <div className="main-counter" data-aos="fade-up">
            <div className="row g-3 align-items-center">
              <div className="col-12 col-sm-6 col-md-12">
                <div className="header-counter">
                  <CounterContent
                    NumCounter={"4500"}
                    timeDuration={"2"}
                    isIconPlus={true}
                    iconPlus={"+"}
                    isIconImg={false}
                    iconSrc={false}
                    textCounter={"تم حجز المواعيد من على الموقع"}
                    isRate={false}
                    rateNum={false}
                    isView={true}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <CounterContent
                  NumCounter={"4500"}
                  timeDuration={"2"}
                  isIconPlus={true}
                  iconPlus={"+"}
                  isIconImg={false}
                  iconSrc={false}
                  textCounter={"عميل"}
                  isRate={false}
                  rateNum={false}
                  isView={true}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <CounterContent
                  NumCounter={false}
                  timeDuration={"0"}
                  isIconPlus={false}
                  iconPlus={false}
                  isIconImg={true}
                  iconSrc={iconStar}
                  textCounter={"الوجهة الأعلى تقييمًا لخدمات الجمال"}
                  isRate={true}
                  rateNum={"4.8"}
                  isView={false}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <CounterContent
                  NumCounter={"1200"}
                  timeDuration={"2"}
                  isIconPlus={true}
                  iconPlus={"+"}
                  isIconImg={false}
                  iconSrc={false}
                  textCounter={"مقدم خدمة"}
                  isRate={false}
                  rateNum={false}
                  isView={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterHome;
