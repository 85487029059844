import { useField } from "formik";

const TextAreaInput = ({ label, success, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const isError = meta.touched && meta.error;
  const isSuccess = success && meta.touched && !meta.error;
  const { handleOnChange } = props;

  // Add a comment to explain the custom styles
  const inputStyles = {
    borderColor: isError ? "#dc3545" : isSuccess ? "green" : "",
    height: "160px",
    // Add other styles as needed
  };

  return (
    <div
      className={`input-field-info d-flex flex-column gap-1 mt-3 form-one  ${
        meta.touched && meta.error ? "is-invalid" : ""
      }`}>
      <label htmlFor={props.id || props.name} className="form-label">
        {label}
      </label>
      <textarea
        {...field}
        {...props}
        onChange={(e) => {
          handleOnChange && handleOnChange(e.target.value);
          helpers.setValue(e.target.value);
        }}
        style={inputStyles}
        className={`input-field form-control ${meta.touched && meta.error ? "is-invalid" : ""}`}
      />

      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};

export default TextAreaInput;
