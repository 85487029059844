import blueImage from "assets/images/others/blue.jpg";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addCategory } from "store/general/generalActions";

const AddCategoryModal = ({ show, handleClose, refreshCategories }) => {
  const dispatch = useDispatch();

  const [category, setCategory] = useState({
    name_ar: "",
    name_en: "",
    is_active: "1",
    icon: null,
  });

  useEffect(() => {
    setDefaultIcon();
  }, []);

  const setDefaultIcon = async () => {
    try {
      const response = await fetch(blueImage);
      const blob = await response.blob();
      const file = new File([blob], "blue-category.jpg", { type: "image/jpeg" });
      setCategory((prev) => ({ ...prev, icon: file }));
    } catch (error) {
      console.error("Error setting default icon:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "icon" && files.length > 0) {
      setCategory({ ...category, [name]: files[0] });
    } else {
      setCategory({ ...category, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (const key in category) {
        formData.append(key, category[key]);
      }
      await dispatch(addCategory(formData)).unwrap();
      toast.success("تمت إضافة الفئة بنجاح");
      handleClose();
      refreshCategories();
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("فشل في إضافة الفئة");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static" autoFocus>
      <Modal.Header closeButton className="border-1">
        <Modal.Title>إضافة فئة جديدة</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>الاسم بالعربية</Form.Label>
            <Form.Control
              type="text"
              name="name_ar"
              value={category.name_ar}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>الاسم بالإنجليزية</Form.Label>
            <Form.Control
              type="text"
              name="name_en"
              value={category.name_en}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>الحالة</Form.Label>
            <Form.Select name="is_active" value={category.is_active} onChange={handleInputChange}>
              <option value="1">مفعل</option>
              <option value="0">غير مفعل</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" hidden={true}>
            <Form.Label>الصورة</Form.Label>
            <Form.Control type="file" name="icon" onChange={handleInputChange} accept="image/*" />
          </Form.Group>
          <Button type="submit" className="main-btn w-100 mt-4" style={{ height: "45px" }}>
            إضافة
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCategoryModal;
