import { unwrapResult } from "@reduxjs/toolkit";
import LoginComponent from "Pages/Auth/Login/LoginComponent";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "store/auth/authActions";
import { setIsLogedIn } from "store/auth/authSlice";

const USER_TYPES = {
  CUSTOMER: "customer",
  ADMIN: "admin",
  VENDOR: "vendor",
};

const LoginDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState(null);

  const loginForDashboard = useCallback(
    async (username, password) => {
      try {
        const resultAction = await dispatch(login({ username, password }));
        const { user } = unwrapResult(resultAction);
        const userType = user.type;
        if (userType === USER_TYPES.CUSTOMER) {
          toast.error("يرجى تسجيل الدخول كمورد خدمة");
          return;
        }
        setIsLogedIn(true);
        setUserRole(userType);
      } catch (error) {
        console.error("Login error:", error);
        toast.error("هناك خطأ في تسجيل الدخول");
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (userRole) {
      switch (userRole) {
        case USER_TYPES.ADMIN:
          navigate("/dashboard/admin/homedashboard");
          break;
        case USER_TYPES.VENDOR:
          navigate("/dashboard/vendor/homedashboard");
          break;
        default:
          break;
      }
    }
  }, [navigate, userRole]);

  return (
    <LoginComponent
      key={2}
      isWebsite={false}
      isDashboard={true}
      loginAndNavigate={loginForDashboard}
    />
  );
};

export default LoginDashboard;
