import { useCallback, useEffect, useState } from "react";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { setCartBookingDay, setCartBookingTime } from "store/customer/customerSlice";
import DateTime from "./DateTime";

const StepThreeServices = ({ setBookingDetails, employeeInfo }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [employeeOfficialHours, setEmployeeOfficialHours] = useState([]);
  const [employeeDays, setEmployeeDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);

  const generateTimeSlots = useCallback((start, end) => {
    const slots = [];
    let current = new Date(`2000-01-01T${start}`);
    const endTime = new Date(`2000-01-01T${end}`);

    while (current < endTime) {
      slots.push(current.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true }));
      current.setMinutes(current.getMinutes() + 30);
    }
    if (current.getTime() === endTime.getTime()) {
      slots.push(endTime.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true }));
    }
    return slots;
  }, []);

  useEffect(() => {
    if (employeeInfo && employeeInfo.official_hours) {
      setEmployeeOfficialHours(employeeInfo.official_hours);
      setEmployeeDays(employeeInfo.official_hours.map((item) => item.day));
    }
  }, [employeeInfo]);

  const dispatch = useDispatch();

  const handleDaySelect = (dateInfo) => {
    const [dayDate, dayOfWeek] = dateInfo.split("*");
    setSelectedDay(dateInfo);
    const dateObj = new Date(dayDate);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = dateObj.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    setBookingDetails((prev) => ({
      ...prev,
      booking_day: formattedDate,
    }));
    dispatch(setCartBookingDay(formattedDate));
    const selectedDayHours = employeeOfficialHours.find((hours) => hours.day === dayOfWeek);
    if (selectedDayHours) {
      const slots = generateTimeSlots(selectedDayHours.start_time, selectedDayHours.end_time);
      setTimeSlots(slots);
    } else {
      setTimeSlots([]);
    }
  };

  const handleTimeSlotSelect = (buttonId, time) => {
    const convertedTime = convertTo24Hour(time);
    setActiveButton(buttonId);
    setBookingDetails((prev) => {
      return {
        ...prev,
        booking_time: convertedTime,
      };
    });
    dispatch(setCartBookingTime(convertedTime));
  };

  return (
    <div className="step-three-content">
      <div className="row g-3">
        <div className="col-12 col-lg-8">
          <div className="right-info-content-step">
            <div className="header-one-step">
              <h2 className="title size-text-color-dark">اختر التاريخ والوقت</h2>
            </div>

            <DateTime employeeDays={employeeDays} setSelectedDay={handleDaySelect} />

            <div className="right-main-info pt-4">
              <div className="button-check-pay pt-3 border-bottom pb-3">
                {timeSlots.map((timeSlot, index) => (
                  <div
                    className={`button-one mb-3 d-flex  justify-content-between  align-items-center ${
                      activeButton === index ? "active" : ""
                    }`}
                    key={index}
                    onClick={() => handleTimeSlotSelect(index, timeSlot)}
                  >
                    {timeSlot}
                    {activeButton === index && (
                      <div className="icon-check">
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const convertTo24Hour = (time) => {
  const [timePart, modifier] = time.split(" ");
  let [hours, minutes] = timePart.split(":").map(Number);
  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }
  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};

export default StepThreeServices;
