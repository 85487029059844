import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAdminOffer, updateAdminOffer } from "store/admin/adminActions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name_ar: Yup.string().required("اسم العرض بالعربية مطلوب"),
  name_en: Yup.string().required("اسم العرض بالانجليزية مطلوب"),
  description_ar: Yup.string().required("وصف العرض بالعربية مطلوب"),
  description_en: Yup.string().required("وصف العرض بالانجليزية مطلوب"),
  service_price: Yup.number().required("سعر الخدمة مطلوب"),
  price_type: Yup.string().required("نوع السعر مطلوب"),
  discount_percentage: Yup.number().required("نسبة الخصم مطلوبة"),
  offer_price: Yup.number().required("سعر العرض مطلوب"),
  is_active: Yup.number().required("حالة العرض مطلوبة"),
});

const EditAdminOffer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState(null);
  const [offerInfo, setOfferInfo] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAdminOffer(id))
      .then(unwrapResult)
      .then((res) => {
        setFormValues({
          name_ar: res.data.name_ar,
          name_en: res.data.name_en,
          description_en: res.data.description_en,
          description_ar: res.data.description_ar,
          vendor_id: res.data.vendor_id,
          section_id: res.data.section_id,
          category_id: res.data.category_id,
          sub_category_id: res.data.sub_category_id,
          service_price: res.data.service_price,
          price_type: res.data.price_type,
          discount_percentage: res.data.discount_percentage,
          offer_price: res.data.offer_price,
          is_active: res.data.is_active,
        });
        setOfferInfo(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("حدث خطأ في تحميل بيانات العرض");
      });
  }, [dispatch, id]);

  const handleSubmit = (values, { setSubmitting }) => {
    const formattedValues = {
      ...values,
      section_id: offerInfo.section_id,
      category_id: offerInfo.category_id,
      service_id: offerInfo.service_id,
    };

    dispatch(updateAdminOffer({ id, ...formattedValues }))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم تحديث العرض بنجاح");
        navigate("/dashboard/admin/offersInfo");
      })
      .catch((error) => {
        toast.error("حدث خطأ في تحديث العرض");
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage="تعديل العرض" />
      <HeaderPageTitle
        titlePage="الرئيسية"
        routeHomePage="/dashboard/admin/homedashboard"
        titleInfoPage="تعديل العرض"
      />

      <div className="form-edit-offer">
        <FormField
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          <div className="row g-3">
            <div className="col-md-6">
              <InputField name="name_ar" label="اسم العرض بالعربية" type="text" />
            </div>
            <div className="col-md-6">
              <InputField name="name_en" label="اسم العرض بالانجليزية" type="text" />
            </div>

            <div className="col-12">
              <InputField name="description_ar" label="وصف العرض بالعربية" as="textarea" />
            </div>
            <div className="col-12">
              <InputField name="description_en" label="وصف العرض بالانجليزية" as="textarea" />
            </div>

            <div className="col-md-3">
              <InputField name="service_price" label="سعر الخدمة" type="number" />
            </div>
            <div className="col-md-3">
              <InputField name="discount_percentage" label="نسبة الخصم (%)" type="number" />
            </div>
            <div className="col-md-3">
              <InputField name="offer_price" label="سعر العرض" type="number" />
            </div>
            <div className="col-md-3">
              <SelectField
                name="price_type"
                label="نوع السعر"
                options={[
                  { value: "special", label: "سعر خاص" },
                  { value: "normal", label: "سعر عادي" },
                ]}
              />
            </div>

            <div className="col-md-12">
              <SelectField
                name="is_active"
                label="حالة العرض"
                options={[
                  { value: "1", label: "نشط" },
                  { value: "0", label: "غير نشط" },
                ]}
              />
            </div>
          </div>

          <div className="mt-4 d-flex gap-2">
            <button type="submit" className="main-btn">
              حفظ التغييرات
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/dashboard/admin/offersInfo")}>
              إلغاء
            </button>
          </div>
        </FormField>
      </div>
    </>
  );
};

export default EditAdminOffer;
