import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deletePackage, getPackages, updatePackage } from "store/admin/adminActions";

const Packages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const params = useMemo(() => {
    return {
      with: [],
      limit,
      offset: currentPage,
      paginate: "true",
      sort: "DESC",
      field: "id",
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["name_ar"],
      columns: ["name_ar"],
      operand: ["like"],
      column_values: [search],
    };
  }, [limit, currentPage, search]);

  const handleDeletePackage = async (id) => {
    const packageItem = packages.find((item) => item.id === id);
    if (!packageItem) return;
    await dispatch(deletePackage(id))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم حذف الباقة بنجاح");
        fetchPackages(params);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const activatePackage = async (packageItem, isActive) => {
    setIsLoading(true);
    const updates = {
      ...packageItem,
      id: packageItem.id,
      is_active: isActive ? "1" : "0",
    };
    await dispatch(updatePackage(updates))
      .then(unwrapResult)
      .then(() => {
        toast.success(isActive ? "تم تفعيل الباقة بنجاح" : "تم تعطيل الباقة بنجاح");
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
        fetchPackages(params);
      });
  };

  const fetchPackages = useCallback(
    async (params) => {
      setIsLoading(true);
      await dispatch(getPackages(params))
        .then(unwrapResult)
        .then((res) => {
          setPackages(res.data.data);
          setCurrentPageItemCounts(res.data.data?.length || 0);
          setTotalItemsCount(res.data.total || 0);
          setTotalPagesCount(res.data.links.length - 2 || 0);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error, "error");
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch, params],
  );

  useEffect(() => {
    fetchPackages(params);
  }, [fetchPackages, params]); // eslint-disable-line

  return (
    <>
      <HelmetInfo titlePage={"الباقات"} />
      <HeaderPageInfo
        title={"الباقات"}
        isShowLeftContent={true}
        contentPageLeft={
          <SearchHeaderPage
            typeIcon={false}
            textButton="اضافة باقة"
            functionButton={() => navigate("/dashboard/admin/packages/add")}
            isButtonActive={true}
            onSearchChange={(value) => setSearch(value)}
          />
        }
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="accounts-users">
          <div className="table-content mt-3 table-content-2">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">الاسم</th>
                  <th scope="col">عدد المشتركين</th>
                  <th scope="col">السعر اليومي</th>
                  <th scope="col">السعر الشهري</th>
                  <th scope="col">السعر الربع سنوي</th>
                  <th scope="col">السعر النصف سنوي</th>
                  <th scope="col">السعر سنوي</th>
                  <th scope="col">تفعيل</th>
                  <th scope="col"> الاجراءات</th>
                </>
              }
              dataTbody={
                <>
                  {packages?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name_ar}</td>
                        <td>{item.customers}</td>
                        <td>{item.days_price}</td>
                        <td>{item.month_price}</td>
                        <td>{item.quarter_price}</td>
                        <td>{item.half_year_price}</td>
                        <td>{item.year_price}</td>
                        <td>
                          <Toggleswitch
                            switchId={`toggleswitch-package-${item.id}`}
                            nameSwitch={item.name_ar}
                            value={item.is_active === "1"}
                            onChange={() => {
                              activatePackage(item, item.is_active !== "1");
                            }}
                          />
                        </td>
                        <td>
                          <IconsTable
                            typeIcon1={faEye}
                            functionEye={() => {
                              navigate(`${item.id}`);
                            }}
                            functionEdit={() => {
                              navigate(`${item.id}`);
                            }}
                            typeIcon3={faTrash}
                            isDeleteIcon={true}
                            functionDelete={() => handleDeletePackage(item.id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              }
            />
          </div>

          <Pagination
            limit={limit}
            setLimit={setLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limitOptions={[10, 20, 30, 40]}
            currentPageItemsCount={currentPageItemCounts}
            totalItemsCount={totalItemsCount}
            totalPagesCount={totalPagesCount}
          />
        </div>
      )}
    </>
  );
};

export default Packages;
