import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as starReg } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { Link, useNavigate } from "react-router-dom";
import {
  faFacebookF,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import imgPen from "../../../../assets/images/icons/pen-line.svg";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
const CompanyActivity = () => {
  const navigate = useNavigate();
  return (
    <>
      <HelmetInfo titlePage={"بيانات الشركة والنشاط"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={true}
          routePage={"/dashboard/settings"}
          titlePage={"الاعدادات"}
          titleInfoPage={"بيانات الشركة والنشاط"}
          routeHomePage={"/dashboard/homedashboard"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/settings"
          >
            عودة
          </Link>
        </div>
      </div>
      <div className="basic-information-content account-info-details mt-3">
        {/* =========== START ALL BASIC INFO ============= */}
        <div className="all-basic-info all-details-branch">
          {/* ======== START HEADER INFO ========== */}
          <div className="header-info-profile ">
            <div className="content-user-info content-info-deails-offer">
              <h2 className="title fs-4 pb-3 fw-medium text-dark ">
                بيانات الشركة{" "}
              </h2>
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">الاسم التجارى </h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>محمد احمد للحلاقة</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">نوع المنشاه </h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>منشأة فردية</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">مدة الشركة</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>5 سنوات</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">تاريخ اصدار السجل</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>13/5/2021</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">تاريخ انتهاء السجل</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>13/5/2021</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title"> حالة السجل</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>نص كتابى</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title"> مصدر السجل </h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>نص كتابى</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">الرقم الموحد </h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>58794</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title"> رقم السجل التجارى</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>58794</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title"> تاريخ بداية الشركة </h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>25/11/2010</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
            </div>
            {/**======== START CONTENT TWO ========= */}
            <div className="content-user-info content-info-deails-offer border-top  pt-3">
              <div
                className="icon-edit-pen"
                onClick={() => {
                  navigate("editCompanyActivityData");
                }}
              >
                <img src={imgPen} alt="" />
              </div>
              <h2 className="title fs-4 pb-3 fw-medium text-dark ">
                {" "}
                بيانات النشاط{" "}
              </h2>
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">اسم النشاط</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>محمد احمد للحلاقة</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title"> كود النشاط</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>13/5/2021 </p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">الرقم الضريبى</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>89785</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">نبذة عن النشاط</h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <p className={`text`}>نص كتابى</p>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title">التواصل الأجتماعى </h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <div className="icons-social-com">
                    <ul className="list-social p-0 m-0 d-flex align-items-center gap-3">
                      <li className="list-one">
                        <a
                          href="https://www.instagram.com/"
                          target="_blank"
                          className="icon-social"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faInstagram} />
                        </a>
                      </li>

                      <li className="list-one">
                        <a
                          href="https://twitter.com/"
                          target="_blank"
                          className="icon-social"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faXTwitter} />
                        </a>
                      </li>
                      <li className="list-one">
                        <a
                          href="https://www.facebook.com/"
                          target="_blank"
                          className="icon-social"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title"> الخدمات الرئيسية المقدمة </h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  نص كتابى
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title"> الموقع الالكترونى </h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <a href="##" className="fs-6 text-color-num">
                    www.mysite.com
                  </a>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
              {/* ======== START ALL INFO CONTENT ONE ========= */}
              <div className="all-info-content  d-flex">
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <h2 className="title"> التقيم </h2>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
                {/* ========= START MAIN INFO ONE ========= */}
                <div className="main-info-one d-flex  align-items-center">
                  <div className="rates-stars d-flex  align-items-center gap-3">
                    <div className="icons-rate-1 d-flex  align-items-center gap-2">
                      <div className="icon-star">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-star">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-star">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-star">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-star">
                        <FontAwesomeIcon icon={starReg} />
                      </div>
                    </div>
                    <div className="num-rate fs-6 fw-medium ">
                      {" "}
                      4.5 (120 تقيم)
                    </div>
                  </div>
                </div>
                {/* ========= END MAIN INFO ONE ========= */}
              </div>
              {/* ======== END ALL INFO CONTENT ONE ========= */}
            </div>
            {/**======== END CONTENT TWO ========= */}
          </div>
          {/* ======== END HEADER INFO ========== */}
        </div>
        {/* =========== END ALL BASIC INFO ============= */}
      </div>
    </>
  );
};

export default CompanyActivity;
