import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import icon from "../../../assets/images/map/marker.svg";

const BranchMap = ({ lat, long, onMapClick = null }) => {
  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        onMapClick(e.latlng.lat, e.latlng.lng);
      },
    });
    return null;
  };

  return (
    <div className="map-container" style={{ height: "600px", width: "100%" }}>
      <MapContainer
        center={[lat || 21.553435878238922, long || 39.18274357399476]}
        zoom={15}
        style={{ height: "100%", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker
          position={[lat || 21.553435878238922, long || 39.18274357399476]}
          icon={L.icon({ iconUrl: icon })}
        />
        {onMapClick && <MapClickHandler />}
      </MapContainer>
    </div>
  );
};

export default BranchMap;
