import { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import BranchMap from "Dashboard/Components/maps/BranchMap";

import Loader from "Components/Loader/Loader";
import {
  getAdminBranch,
  getAdminVendors,
  updateAdminBranchFormData,
} from "store/admin/adminActions";
import { getCities, getCountries, getRegions } from "store/general/generalActions";
import { formatDateToTime24 } from "utlis";

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required("ادخل اسم الفرع"),
  country_id: Yup.string().required("اختار الدولة"),
  city_id: Yup.string().required("اختار المدينة"),
  region_id: Yup.string().required("اختار المنطقة"),
  vendor_id: Yup.string().required("اختار مقدم الخدمة"),
  address: Yup.string().required("ادخل العنوان"),
  show_rates: Yup.string().required("تقييم الموظفين"),
});

const DEFAULT_COORDINATES = {
  lat: "24.7136",
  long: "46.6753",
};

const BranchesAdminEdit = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cities, regions, countries } = useSelector((state) => state.general);

  const initalValues = {
    id: "",
    name: "",
    country_id: "",
    city_id: "",
    region_id: "",
    address: "",
    show_rates: "0",
    image: "",
    lat: DEFAULT_COORDINATES.lat,
    long: DEFAULT_COORDINATES.long,
    vendor_id: "",
    manager_id: "",
    is_active: "",
  };
  const [formValues, setFormValues] = useState(initalValues);
  const [imageUploaded, setImageUploaded] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    dispatch(getAdminVendors())
      .unwrap()
      .then((res) => {
        const vendorsData = res.data.data;
        setVendors(vendorsData);
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || "حدث خطأ في تحميل مقدمي الخدمة";
        toast.error(errorMessage);
        console.error(error);
      });
  }, [dispatch]);

  const fetchBranchData = useCallback(() => {
    if (id) {
      setIsDataLoading(true);
      dispatch(getAdminBranch(id))
        .unwrap()
        .then((res) => {
          const branchData = res.data;
          setFormValues({
            id: branchData.id,
            name: branchData.name,
            country_id: branchData.country_id.toString(),
            city_id: branchData.city_id.toString(),
            region_id: branchData.region_id.toString(),
            address: branchData.address,
            show_rates: branchData.show_rates.toString(),
            vendor_id: branchData.vendor_id.toString(),
            manager_id: branchData.manager_id.toString(),
            lat: branchData.lat || DEFAULT_COORDINATES.lat,
            long: branchData.long || DEFAULT_COORDINATES.long,
            image: branchData.image || "",
            is_active: branchData.is_active.toString(),
          });

          if (branchData.image) {
            setImageUploaded(branchData.image);
          }
        })
        .catch((error) => {
          toast.error("حدث خطأ في تحميل بيانات الفرع");
          console.error(error);
        })
        .finally(() => setIsDataLoading(false));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) fetchBranchData();
  }, [fetchBranchData, id]);

  const handleInputChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUploaded(URL.createObjectURL(file));
      setFormValues((prev) => ({
        ...prev,
        image: file,
      }));
    }
  };

  useEffect(() => {
    return () => {
      if (imageUploaded) {
        URL.revokeObjectURL(imageUploaded);
      }
    };
  }, [imageUploaded]);

  const handleSubmit = useCallback(
    async (values) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      const formData = new FormData();
      const updatedValues = {
        ...values,
        manager_id: formValues.manager_id,
        lat: formValues.lat,
        long: formValues.long,
        image: formValues.image,
        is_active: formValues.is_active,
        vendor_id: values.vendor_id ? parseInt(values.vendor_id) : null,
      };

      Object.entries(updatedValues).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (typeof item === "object") {
              Object.entries(item).forEach(([subKey, subValue]) => {
                if (subKey.includes("time")) {
                  const valueInFormat = formatDateToTime24(subValue);
                  formData.append(`${key}[${index}][${subKey}]`, valueInFormat);
                } else {
                  formData.append(`${key}[${index}][${subKey}]`, subValue);
                }
              });
            } else {
              formData.append(`${key}[${index}]`, item);
            }
          });
        } else {
          formData.append(key, value);
        }
      });

      try {
        await dispatch(updateAdminBranchFormData({ id, data: formData })).unwrap();
        toast.success("تم التعديل بنجاح.");
        navigate("/dashboard/admin/admin-branches");
      } catch (error) {
        Object.values(error.errors || {})
          .flat()
          .forEach(toast.error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [formValues, id, isSubmitting, navigate, dispatch],
  );

  useEffect(() => {
    if (regions?.length === 0) dispatch(getRegions());
    if (cities?.length === 0) dispatch(getCities());
    if (countries?.length === 0) dispatch(getCountries());
  }, [dispatch, regions, cities, countries]);

  const handleMapClick = (lat, long) => {
    setFormValues((prev) => ({
      ...prev,
      lat,
      long,
    }));
  };

  if (isDataLoading) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage="تعديل الفرع" />
      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          titlePage="الرئيسية"
          routeHomePage="/dashboard/admin/homedashboard"
          titleInfoPage="تعديل الفرع"
        />
        <Link className="size-text-color-dark main-text-color fs-5" to="/dashboard/admin/branches">
          عودة
        </Link>
      </div>

      <div className="form-edit-add-branches" data-aos="fade-up">
        <FormField
          initialValues={formValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}>
          <div className="row g-1 g-sm-3">
            <InputField
              label="اسم الفرع"
              name="name"
              type="text"
              placeholder="اسم الفرع"
              value={formValues.name || ""}
              valueSelected="اسم الفرع"
              handleOnChange={(value) => handleInputChange("name", value)}
            />

            <SelectField
              name="vendor_id"
              label="مقدم الخدمة"
              options={
                vendors?.map((item) => ({
                  value: item.id,
                  label: item.name,
                })) || []
              }
              valueSelected="مقدم الخدمة"
              value={formValues.vendor_id}
              handleOnChange={(value) => handleInputChange("vendor_id", value)}
            />

            <SelectField
              name="country_id"
              label="الدولة"
              options={
                countries?.map((item) => ({
                  value: item.id,
                  label: item.name_ar,
                })) || []
              }
              valueSelected="الدولة"
              value={formValues.country_id}
              handleOnChange={(value) => handleInputChange("country_id", value)}
            />

            <SelectField
              name="city_id"
              label="المدينة"
              options={
                cities?.map((item) => ({
                  value: item.id,
                  label: item.name_ar,
                })) || []
              }
              valueSelected="المدينة"
              value={formValues.city_id}
              handleOnChange={(value) => handleInputChange("city_id", value)}
            />

            <SelectField
              name="region_id"
              label="العنوان الوطني"
              options={
                regions?.map((item) => ({
                  value: item.id,
                  label: item.name_ar,
                })) || []
              }
              valueSelected="العنوان الوطني"
              value={formValues.region_id}
              handleOnChange={(value) => handleInputChange("region_id", value)}
            />

            <InputField
              label="عنوان الفرع"
              name="address"
              type="text"
              placeholder="عنوان الفرع"
              value={formValues.address}
              valueSelected="عنوان الفرع"
              handleOnChange={(value) => handleInputChange("address", value)}
            />
            <SelectField
              name="is_active"
              label="الحالة"
              options={[
                { value: "1", label: "مفعل" },
                { value: "0", label: "غير مفعل" },
              ]}
              valueSelected="الحالة"
              value={formValues.is_active}
              handleOnChange={(value) => handleInputChange("is_active", value)}
            />
            <SelectField
              name="show_rates"
              label="تقييم الموظفين"
              options={[
                { value: "1", label: "نعم" },
                { value: "0", label: "لا" },
              ]}
              valueSelected="تقييم الموظفين"
              value={formValues.show_rates ?? "0"}
              handleOnChange={(value) => handleInputChange("show_rates", value)}
            />

            <div className="col-12">
              <div className="upload-image">
                <label htmlFor="upload-image" className="form-label">
                  صورة الفرع
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="upload-image"
                  onChange={handleFileChange}
                  accept="image/*"
                />
                {imageUploaded && (
                  <div className="preview-image">
                    <img src={imageUploaded} alt="Preview" width="170px" height="120px" />
                  </div>
                )}
              </div>
            </div>

            <div className="col-12">
              <BranchMap onMapClick={handleMapClick} lat={formValues.lat} long={formValues.long} />
            </div>

            <div className="col-12">
              <button
                type="submit"
                className="main-btn btn-submit px-5 mt-4"
                disabled={isSubmitting}>
                {isSubmitting ? "جاري التعديل..." : "تعديل"}
              </button>
            </div>
          </div>
        </FormField>
      </div>
    </>
  );
};

export default BranchesAdminEdit;
