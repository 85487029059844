import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { Link } from "react-router-dom";

const WebsiteSettings = () => {
  return (
    <>
      <HelmetInfo titlePage={"اعدادت الويب سايت"} />
      <div className="header-page-w" data-aos="fade-left">
        <HeaderPageInfo
          title={"اعدادت الويب سايت"}
          isShowLeftContent={true}
          contentPageLeft={
            <div className="link-back">
              <Link
                className="size-text-color-dark main-text-color fs-5"
                to="/dashboard/admin/settings">
                عودة
              </Link>
            </div>
          }
        />
      </div>
      <div className="all-cards-pay mt-3" data-aos="fade-up"></div>
    </>
  );
};

export default WebsiteSettings;
