import { useParams } from "react-router-dom";
import ClientsReport from "./ClientsReport";
import EmployeesReport from "./EmployeesReport";
import FinancialReport from "./FinancialReport";
import "./ReportDetails.css";
import OffersReport from "./ReportOffers";
import PackagesReport from "./ReportPackages";
import ProductsReport from "./ReportProducts";
import ServicesReport from "./ReportServices";

const reportsTranslation = {
  services: "تقرير الخدمات",
  offers: "تقرير العروض",
  packages: "تقرير الباقات",
  products: "تقرير المنتجات",
  customers: "تقرير العملاء",
  employees: "تقرير الموظفين",
  financial: "تقرير مالي",
  sales: "تقرير المبيعات",
};

const ReportDetails = () => {
  const { reportId } = useParams();

  const renderReport = () => {
    switch (reportId) {
      case "services":
        return <ServicesReport />;
      case "offers":
        return <OffersReport />;
      case "packages":
        return <PackagesReport />;
      case "products":
        return <ProductsReport />;
      case "customers":
        return <ClientsReport />;
      case "employees":
        return <EmployeesReport />;
      case "financial":
        return <FinancialReport />;
      default:
        return <div>Report not found</div>;
    }
  };

  return (
    <div className="report-details p-3">
      <div className="report-header">
        <div className="report-title mb-5">{reportsTranslation[reportId]}</div>
      </div>
      {renderReport()}
    </div>
  );
};

export default ReportDetails;
