import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCities, getRegions, updateCity } from "store/general/generalActions";

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";

const Cities = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [requestParams, setRequestParams] = useState({
    limit: 10,
    offset: 1,
    sort: "DESC",
    paginate: "true",
    field: "updated_at",
  });

  const fetchCities = useCallback(() => {
    setIsLoading(true);
    const params = { ...requestParams };
    if (searchText) {
      params.columns = ["name_ar"];
      params.operand = ["LIKE"];
      params.column_values = [`%${searchText}%`];
    }
    dispatch(getCities(params))
      .then(unwrapResult)
      .then((res) => {
        setData(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
        toast.error("Failed to fetch cities");
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, requestParams, searchText]);

  const fetchRegions = useCallback(() => {
    setIsLoading(true);
    dispatch(getRegions())
      .then(unwrapResult)
      .then((res) => {
        setRegions(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching regions:", error);
        toast.error("Failed to fetch regions");
      })
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    fetchRegions();
    fetchCities();
  }, [fetchCities, fetchRegions]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchText(searchText);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const handleToggleActive = (id, newValue) => {
    const city = data.find((c) => c.id === id);
    if (!city) return;

    dispatch(updateCity({ ...city, is_active: newValue ? "1" : "0" }))
      .then(unwrapResult)
      .then((res) => {
        if (res.success) {
          toast.success("تم التعديل بنجاح");
          fetchCities();
        }
      })
      .catch((error) => {
        console.error("Error updating city:", error);
        toast.error("فشل في تحديث المدينة");
      });
  };
  return (
    <>
      <HelmetInfo titlePage={"المدن"} />
      <div className="main-header-cities" data-aos="fade-down">
        <HeaderPageInfo
          title={"المدن"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={true}
              typeIcon={faPlus}
              textButton={"اضافة مدينة جديدة"}
              functionButton={() => navigate("add")}
              onSearchChange={setSearchText}
            />
          }
        />
      </div>
      {isLoading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : (
        <>
          <div className="table-content mt-3" data-aos="fade-up">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">اسم المدينة</th>
                  <th scope="col">المنطقة</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">الاجراءات</th>
                </>
              }
              dataTbody={
                <>
                  {data.map((city) => (
                    <tr key={city.id}>
                      <td>{city.name_ar}</td>
                      <td>{regions.find((region) => region.id === city.region_id)?.name_ar}</td>
                      <td>
                        <Toggleswitch
                          switchId={city.id}
                          nameSwitch={`city-${city.id}`}
                          value={city.is_active === "1"}
                          onChange={(newValue) => {
                            handleToggleActive(city.id, newValue);
                          }}
                        />
                      </td>
                      <td>
                        <IconsTable
                          typeIcon2={faEdit}
                          functionEdit={() => navigate(`edit/${city.id}`)}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              }
            />
          </div>
          <Pagination
            limit={requestParams.limit}
            setLimit={(limit) => setRequestParams((prev) => ({ ...prev, limit, offset: 1 }))}
            currentPage={requestParams.offset}
            setCurrentPage={(page) => setRequestParams((prev) => ({ ...prev, offset: page }))}
            limitOptions={[5, 10, 20, 30, 40]}
            currentPageItemsCount={currentPageItemCounts}
            totalItemsCount={totalItemsCount}
            totalPagesCount={totalPagesCount}
          />
        </>
      )}
    </>
  );
};

export default Cities;
