import React from 'react'
import HeaderAbout from './HeaderAbout/HeaderAbout'
import CardsAbout from './CardsAbout/CardsAbout'
import CounterHome from 'Components/HomeInfo/CounterHome/CounterHome'

const AboutInfo = () => {
  return (
    <main>
      <HeaderAbout />
      <CardsAbout/>
      <CounterHome/>
    </main>
  )
}

export default AboutInfo
