import { Link } from "react-router-dom";
import "./HeaderInfoPage.css";
const HeaderInfoPage = ({ title, isActiveLink, routePage, textLink, isNewContent, Newcontent }) => {
  return (
    <>
      <div
        data-aos="fade-left"
        className="header-update-profile  d-flex  justify-content-between  align-items-center  flex-wrap gap-3"
      >
        <h2 className="title">{title}</h2>
        {isActiveLink && (
          <Link to={routePage} className="back-link">
            {textLink}
          </Link>
        )}
        {isNewContent && <div className="new-content">{Newcontent}</div>}
      </div>
    </>
  );
};

export default HeaderInfoPage;
