import imgAbout from "../../../assets/images/main/03.png";
import "./HeaderAbout.css";
const HeaderAbout = () => {
  return (
    <div className="header-about padding-bottom-50">
      {/* ========= START CONTAINER ========= */}
      <div className="container">
        {/* ======== START ALL HEADER ABOUT ========== */}
        <div className="all-header-about">
          {/* ========= START ROW ========== */}
          <div className="row g-3 flex-wrap-reverse">
            {/* ========= START COL ========== */}
            <div className="col-12 col-md-7">
              {/* ======= START CONTENT INFO ABOUT ======== */}
              <div className="content-info-about" data-aos="fade-down">
                <h2 className="title">اكتشف من نحن</h2>
                <p className="text">
                  ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ
                  الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق
                  التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم
                  الرقمي في منتصف الثمانينات من القرن الماضي،
                </p>
                <p className="text">
                  ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ
                  الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق
                  التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم
                  الرقمي في منتصف الثمانينات من القرن الماضي،
                </p>
                <p className="text">
                  ظهرت نسخ من نص لوريم إيبسوم في مجال التنضيد على الأقل منذ
                  الستينات من القرن الماضي، عندما اشتهرت بالإعلانات عن أوراق
                  التحويل من شركة لتراسات2 تم إدخال لوريم إيبسوم إلى العالم
                  الرقمي في منتصف الثمانينات من القرن الماضي،
                </p>
              </div>
              {/* ======= END CONTENT INFO ABOUT ======== */}
            </div>
            {/* ========= END COL ========== */}
            {/* ========= START COL ========== */}
            <div className="col-12 col-md-5">
              {/* ========= START IMG ABOUT ========== */}
              <div className="img-about" data-aos="fade-up">
                <img
                  src={imgAbout}
                  alt="img about"
                  className="w-100 h-100 object-fit-cover image-anim"
                />
              </div>
              {/* ========= END IMG ABOUT ========== */}
            </div>
            {/* ========= END COL ========== */}
          </div>
          {/* ========= END ROW ========== */}
        </div>
        {/* ======== END ALL HEADER ABOUT ========== */}
      </div>
      {/* ========= END CONTAINER ========= */}
    </div>
  );
};

export default HeaderAbout;
