import { Link } from "react-router-dom";
import iconPlay from "../../../../assets/images/dashboardImages/icons/play.svg";
import imgLanding from "../../../../assets/images/dashboardImages/main/Desktop.png";
import imgBg from "../../../../assets/images/dashboardImages/main/dsahboardLanding.png";
import "./HeroContent.css";

const HeroContent = () => {
  return (
    <div className="landing-bg" style={{ backgroundImage: `url(${imgBg})` }}>
      <div className="container">
        {/* =========== START CONTENT HERO =========== */}
        <div className="content-hero">
          {/* ========== START ROW ============ */}
          <div className="row g-5  flex-column">
            {/* ========= START COL ======== */}
            <div className="col-12">
              <div className="info-hero" data-aos="fade-down">
                <h2 className="title-head">ستريم لاين</h2>
                <h2 className="title">نظام الحجز الإلكتروني المتكامل والمخصص لإدارة حجوزات صالونات الحلاقة</h2>
                <p className="text">برنامج حجز بسيط ومرن وقوي لعملك.</p>
                <Link to="/registerserviceprovideraccount" className="main-btn">
                  الأنضمام معنا
                </Link>
              </div>
            </div>
            {/* ========= END COL ======== */}
            {/** ======== START COL ========== */}
            <div className="col-12">
              <div className="main-info-hero" data-aos="fade-up">
                <div className="img-content">
                  <img src={imgLanding} alt="img" className="w-100 h-100 object-fit-cover" />
                </div>
                <div className="show-video cursor-pointer-1 pt-4 d-flex align-items-center justify-content-center gap-2">
                  <div className="icon-play">
                    <img src={iconPlay} alt="icon-play" />
                  </div>
                  <h2 className="text-play">مشاهدة الفيديو</h2>
                </div>
              </div>
            </div>
            {/** ======== END COL ========== */}
          </div>
          {/* ========== END ROW ============ */}
        </div>
        {/* =========== END CONTENT HERO =========== */}
      </div>
    </div>
  );
};

export default HeroContent;
